
import parse from 'html-react-parser';

export const ParseString = ({ string }) => {
    
    return parse(string.replaceAll('\n', '<br />'));
    // return parse(string.replaceAll('<br>', ''));
}

export const ParseHTML = ({ string }) => {
    
    return parse(string.replaceAll('<br>', ''));
}


export const StringToArray = ( string ) => {
    // string = string.replaceAll('\n', '<br />');
    return string.split('\n');
}