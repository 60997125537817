import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLottie } from "../../../components/layout/ButtonLottie";
import { useEffect } from "react";
import { computeProgress } from "../../../helpers/Common";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { VerticalHeader } from "../../../components/layout/VerticalHeader";

const schema = yup.object().shape({
    meter_reading: yup.string().required("Vennligst fyll ut feltet")
    .test('len', 'Må være 16 tegn', val => val.length === 16)
});
export const ProtocolStep7 = () => {

    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    const proStep7 = sessionStorage.getItem('protocol_step_7') ? JSON.parse(sessionStorage.getItem('protocol_step_7')) : {};
    // const proStep7 = {};
    const [isLottie, setIsLottie] = useState(null);
    const [isWaterMeter, setIsWaterMeter] = useState(false);
    const navigate = useNavigate();

    const handleBtnClick = ( e ) => {
        const type = e.currentTarget.dataset.type;
        const value = e.currentTarget.dataset.value;
        const id = e.currentTarget.dataset.id;

        proStep7[type] = value;

        setIsLottie(id);
        sessionStorage.setItem('protocol_step_7', JSON.stringify(proStep7));

        if(proStep7.water_meter){

            //add to progress for the progress bar in the homepage
            if(!protocolProgress.includes('step7')){
                protocolProgress.push('step7');
                sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
            }

            if(value === 'no'){
                setTimeout((function(){
                    setIsWaterMeter(false);
                    navigate('/protocol/step-8');
                }), 1000);
            }else{
                setIsWaterMeter(true);
            }

        }
    }

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues : {
            meter_reading : proStep7.meter_reading ? proStep7.meter_reading : '',
        }
    });
    
    const handleFormSubmit = (data) => {
        proStep7.meter_reading = data.meter_reading;
        sessionStorage.setItem('protocol_step_7', JSON.stringify(proStep7));
        if(!protocolProgress.includes('step7')){
            protocolProgress.push('step7');
            sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
        }

        navigate('/protocol/step-8');
    }

    useEffect(() => {
        if(proStep7.water_meter){
            setIsLottie(proStep7.water_meter === 'yes' ? 'water_yes' : 'water_no');
            setIsWaterMeter(proStep7.water_meter === 'yes' ? true : false);
        }
    }, [isLottie]);

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Bekreftelse' progress={7} totalProgress={14} />

            <div className="pt-4">
                <h3 className="text-bold mb-2">Er det vannmåler eller fjernvarme i eiendommen?</h3>
                <Row className="my-4">
                    <Col>
                        <Button 
                            className='btn btn-between w-100' 
                            data-id="water_yes" 
                            data-value='yes' 
                            data-type="water_meter"
                            onClick={ handleBtnClick }>
                                { isLottie === 'water_yes' ?  <ButtonLottie button='Ja' left='60px' /> :  <span>Ja</span> }
                        </Button>
                    </Col>
                    <Col>
                        <Button 
                            className='btn btn-between-white no-black w-100' 
                            data-id="water_no" 
                            data-value='no' 
                            data-type="water_meter"
                            onClick={ handleBtnClick }>
                                { isLottie === 'water_no' ?  <ButtonLottie button='Nei' left='60px' isBlack={true} /> :  <span>Nei</span> }
                        </Button>
                    </Col>
                </Row>

                {isWaterMeter &&
                <form onSubmit={handleSubmit( handleFormSubmit )} id='user-form'>
                    <FormGroup>
                        <Label for='meter_reading' className="mb-0 text-normal">Målerstand</Label>
                        <input {...register("meter_reading")} className={`form-control input-square ${errors.meter_reading && 'is-invalid'}`} />
                        <p className="form-error">{errors.meter_reading?.message}</p>
                    </FormGroup>
                    <FormGroup className="mt-4 px-5">
                        <Button type="submit" className="btn btn-between w-100">Neste</Button>
                    </FormGroup>
                </form>
                }

                <Row className="text-center my-4 mx-4">
                    <Col>
                        <Link to={'/protocol/step-6'} className="btn-between-white">Tilbake</Link>
                    </Col>

                    {(protocolProgress.includes('step7') && isLottie === 'water_no') &&
                    <Col>
                        <Link to="/protocol/step-8" className="btn-between w-100">Neste</Link>
                    </Col>
                    }
                </Row>
                </div>
            <Footer/>
        </div>
    );
}