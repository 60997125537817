import React, { useState, useEffect } from 'react';
import { Accordion, Input, Button, Progress, UncontrolledAccordion, AccordionItem, AccordionBody, AccordionHeader, Row, Col } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ButtonLottie } from '../../../../components/layout/ButtonLottie';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import { baseUrl, getHeaders, getDeliverTimes, formatPrice } from '../../../../helpers/Common';
import axios from 'axios';
import no from "date-fns/locale/nb"; // the locale you want
import { ProductImage } from '../../../../components/layout/ProductImage';
import moment from 'moment';
import { MovingLoadCompanyHeader } from './MovingLoadCompanyHeader';
import { FAQProduct } from '../../../faqs/FAQProduct';

export const MovingLoadCompanyStep2 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLottie, setIsLottie] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [deliveryTime, setDeliveryTime] = useState('8:00 - 10:00');
    const [providers, setProviders] = useState([]);
    const [pickupDate, setPickupDate] = useState(new Date());
    const [numberOfDays, setNumberOfDays] = useState(1);
    const [step2Items, getStep2Items] = useState(JSON.parse(sessionStorage.getItem('moving_service_step_2')));    
    const [open, setOpen] = useState([]);

    registerLocale("no", no); // register it with the name you want

    const toggle = (id) => {
        console.log(id);
        open === id ? setOpen() : setOpen(id);
    };

    const isButtonSuccess = (e) => {
        const link = e.currentTarget.dataset.link;
        const pid = e.currentTarget.dataset.pid;
        const position = e.currentTarget.dataset.position;
        const price = e.currentTarget.dataset.price;
        const title = e.currentTarget.dataset.title;

        setIsLottie(position);

        //save to session storage for future use;
        sessionStorage.setItem('moving_service_step_2', JSON.stringify({pid : pid, 
            pickupDate : pickupDate, 
            position : position, 
            number_of_days : numberOfDays, 
            delivery_time : deliveryTime, 
            price : price, 
            title : title }));

        setTimeout(function(){
            navigate(link);
        }, 1200)
    }

    //FETCH PRODUCTS
    const fetchProducts = () => {
        axios.get(baseUrl()+'relocation_service_providers', getHeaders()
        ).then(response => setProviders(response.data.data))
        .catch(error => console.error(error))
    }

    const handleNextStep = (e) => {
        const data = e.target.dataset;

        if(step2Items){
            navigate(data.link);
        }
    }

    useEffect(() => {
        fetchProducts();
    }, []);
    

    //users came from step 3
    useEffect(() => {
        console.log(step2Items);

        if(step2Items){
            setOpen([step2Items.position]);
            setNumberOfDays(parseInt(step2Items.number_of_days));
            setDeliveryTime(step2Items.delivery_time);
            
            //auto change button
            setIsLottie(step2Items.position);
            var newDate = moment(step2Items.pickupDate).format("YYYY/MM/DD")
            setPickupDate(new Date(newDate));
        }
    }, [])
    const verticalItems = providers.map((vObj, i) => {
        const position = 'pos-'+i;
        return <Accordion open={open} toggle={ toggle }  key={i}>
            <AccordionItem className='products-vertical mb-4' key={position}>
            <AccordionHeader targetId={position} className='products-vertical-header'>
                <h4 className='text-left w-100 mb-0 px-2 pt-2'>{vObj.title}</h4>
                <div className='mt-0 pt-1 d-flex justtify-content-start w-100 px-2'>
                    <img src="/images/5stars.svg" alt='star img' className='w-85px'/> 
                </div>
                <Row className='w-100 pb-4'>
                    <Col className='d-flex align-items-center pt-min'>
                            <ProductImage image={vObj.supplier} className='w-100' />
                    </Col>
                    <Col className='text-right'>
                        <p className='h4-regular text-right mb-0'>250 verifiserte flytteoppdrag</p>
                        <h3 className='text-right mt-2 p-price mb-0'>Kr. {formatPrice(vObj.price)},-</h3>
                    </Col>
                </Row>    
                {/* <button className='btn-between w-100 mb-4'>Velg</button>      */}
            </AccordionHeader>

            <AccordionBody accordionId={position} className='pt-0'>
                <div className='equipment-item'>
                    <p>{ vObj.long_description }</p>
                    <FAQProduct product='moving_truck' />
                    
                    <p className='mb-2 mt-4 text-bold'>Velg dato for flytting</p>
                    <div className='text-center'>
                        <DatePicker formatWeekDay={nameOfDay => nameOfDay.substr(0,3)}  minDate={moment().toDate()} disabledKeyboardNavigation locale="no" selected={pickupDate} onChange={(date:Date) => setPickupDate(date)} inline />
                    </div>

                    <p className='mb-2 mt-4 text-bold'>Ønsket tidspunkt</p>
                    <Input type='select' className='br-min' onChange={(e) => setDeliveryTime(e.target.value)} defaultValue={deliveryTime}>
                        {getDeliverTimes().map((times, key) => 
                            <option value={times} key={key}>Mellom { times }</option>
                        )}
                    </Input>

                    <p className='text-right f-20 text-bold my-5'>Kr. {formatPrice(vObj.price)},-</p>

                    <div className='mt-4 mb-2'>
                        <Button onClick={ isButtonSuccess } 
                            data-link="/verticals/moving-load/company/step-3" 
                            className='btn-between w-100' 
                            data-price={vObj.price}
                            data-position={position}
                            data-title={vObj.title}
                            data-pid={ vObj.id }>
                            { isLottie === position ? 
                            <ButtonLottie button='Bestill' left='80px'/> : 
                            <h4 id={'btn-text-'+position} className='mb-0'>Bestill</h4>
                            }
                        </Button>
                    </div>
                </div>
            </AccordionBody>
        </AccordionItem>
        </Accordion>
        }
    );

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <MovingLoadCompanyHeader />
            <ProgressBar progress="50" classes="my-3"/>
            <h4 className='mb-3'>Steg 2/4</h4>

            <hr className='fluid' />
            <div className='container-white py-4'>
                <h3 className='mb-3'>Flyttebyråer anbefalt for deg: </h3>
                    {verticalItems}
            
                    <hr className='fluid' />
                <div className='mx-3'>
                    <button data-link='/verticals/moving-load/company/step-3' className='mt-4 w-100 btn-between' onClick={ (e) => handleNextStep(e) }>Gå videre</button>
                    <div className='mt-4 text-center'>
                        <Link to='/verticals/moving-load/company/step-1' className='dark-link text-underline'>Gå tilbake</Link>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}