import React from 'react';
import { Navigation } from '../../components/layout/Navigation';
import PeopleList from '../registration/PeopleList';
import { UserAddAdultForm } from '../../components/layout/users/UserAddAdultForm';

export const SettingsUserAddAdult = () => {
    return (
        <div id='CreateAdultUserContainer'>
            <Navigation />
            <div className='py-4'>
                <p className='f-30 mb-0 text-bold'>Personer i hustanden</p>
            </div>
            <div className='container-white pb-4'>
                <PeopleList type='settings' />
                <UserAddAdultForm type='settings' />
            </div>
        </div>
    )
}