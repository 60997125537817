import React from 'react';
import { Navigation } from '../../components/layout/Navigation';
import { FormGroup } from 'reactstrap';
import PeopleList from './PeopleList';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { RegistrationHeader } from '../../components/layout/RegistrationHeader';


export const RegistrationPeopleList = () => {
    return (
        <div id='CreateUserContainer' className='AppContainer bg-between-green'>
            <Navigation isReg={true} />
            <p className='p-header text-white'>Household</p>
            <PeopleList />
            <div className='mt-5'>
                <Link to="/registration/person/new/over-18" className='dark-link'>
                    <FontAwesomeIcon icon={faPlus} className='text-violet'/> Legg til person <b>over 18</b> år i husstanden
                </Link> <br /><br />
                <Link to="/registration/person/new/under-18" className='dark-link'>
                    <FontAwesomeIcon icon={faPlus} className='text-violet' /> Legg til person <b>under  18</b> år i husstanden
                </Link>
            </div>
            <div className='my-5'>
                <FormGroup className='mt-4 pb-4 text-center'>
                    <Link  to="/"  className='btn btn-between' style={{width : '250px'}}>Fullfør registering</Link>
                </FormGroup>
                <FormGroup className='pb-4 text-center'>
                    <Link  to="/registration/address" className='dark-link text-underline' >Tilbake</Link>
                </FormGroup>
            </div>
        </div>
    )
}