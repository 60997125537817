import React, {useState, useEffect} from 'react';
import { Accordion, Button, AccordionItem, AccordionBody, AccordionHeader, Input, Label, Row, Col } from 'reactstrap';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import { ButtonLottie } from '../../../../components/layout/ButtonLottie';
import { Link, useNavigate } from 'react-router-dom';
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl, getFirstName, getHeaders } from '../../../../helpers/Common';
import { StringToArray } from '../../../../components/elements/ParseString';
import { ProductImage } from '../../../../components/layout/ProductImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
export const MovingCompanyPowerChecker = () => {
    const navigate = useNavigate();
    const [providers, setProviders] = useState([]);
    const [isLottie, setIsLottie] = useState(false);
    const [powerItems, getPowerItems] = useState(JSON.parse(sessionStorage.getItem('power_items')));    
    const [open, setOpen] = useState('pos-0');
    const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : [];
    const toggle = (id) => {
        console.log(id);
        open === id ? setOpen() : setOpen(id);
    };
    console.log(user);
    const isButtonSuccess = (e) => {
        const link      = e.currentTarget.dataset.link;
        const position  = e.currentTarget.dataset.position;
        const pid       = e.currentTarget.dataset.pid;
        const title     = e.currentTarget.dataset.pid;

        setIsLottie(position);

        sessionStorage.setItem('power_items', JSON.stringify({pid : pid, title, position : position}));
        
        setTimeout(function(){
            navigate(link);
        }, 1200)
    }

    const fetchProducts = () => {
        axios.get(baseUrl()+'power_service_offers/', getHeaders()
        ).then(response => setProviders(response.data.data))
        .catch(error => console.error(error))
    }

    useEffect(() => {
        fetchProducts();

    }, []);


    //users came from confirmation page
    useEffect(() => {
        console.log(powerItems);
        if(powerItems){
            setOpen(powerItems.position);
            setIsLottie(powerItems.position);
        }
    }, [])


    const handleNextStep = (e) => {
        navigate('/verticals/moving-company/power/complete-order');
    }


    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Strøm</h1>
                {/* <p className='mb-4'>
                Velg strømleverandør <br/>under
                </p> */}
                <ProgressBar progress="25" classes="my-3"/>
                <h4 className='mb-3'>Steg 1/4</h4>
            </div>
            <hr className='fluid' />
            <div className='container-white pt-4'>
                <p>Hei {getFirstName(user.name)}, <br/>Vi ser at du er eksiterende kunde hos Norges Energi. </p>
                <p className='mb-0'>Ønsker du å overføre strømavtalen til din nye bolig?</p>
                <div className='bg-gray p-4 br-15 mb-4 mt-2'>
                    <Row>
                        <Col xs={5} className='d-flex justify-content-center align-items-center'>
                        <ProductImage image={'sesongpris'} className='w-65px' />
                        </Col>
                        <Col xs={7} className='align-items-start d-flex justify-content-center flex-column'>
                            <h3 className='mb-0'>Julie Nilsen</h3>
                            <p className='mb-0'>Kunderådgiver</p>
                            <p className='mb-0'>+47 456 53 422</p>
                        </Col>
                    </Row>
                </div>

                <div className='text-center'>
                    <Link to={'/verticals/moving-company/power/confirm'} onClick={ isButtonSuccess } className='btn btn-between w-100 mb-2'>Ja takk!</Link>
                    <Link to={'/verticals/moving-company/power'} className='text-underline'>Se andre leverandører</Link>
                </div>
            </div>
            <Footer/>
        </div>
    );
}