import React from 'react';
import { Navigation } from './Navigation';
import { Footer } from './Footer';

export const PageNotFound = () => {

        return (
            <div className='AppContainer bg-between'>
            <Navigation />
            <div className='pb-5'>
                <img src='/images/purple/svg/404page.svg' alt='between icon hello'/>
                <p className='text-center p-header position-relative mt-4'>404 ERROR</p>
                <p className='pb-4 text-center p-header position-relative mt-4'>Side ikke funnet</p>
            </div>
            <Footer/>
        </div>
        );
}