import React, { useEffect, useState } from 'react';
import { Row, Col, Accordion, AccordionHeader, AccordionItem, AccordionBody, FormGroup, Input, Label} from 'reactstrap';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import { MovingLoadCompanyHeader } from './MovingLoadCompanyHeader';

export const MovingLoadCompanyStep1 = () => {
    const navigate = useNavigate();
    const [isUnpacked, setIsUnpacked] = useState(null);
    const [isStored, setIsStored] = useState(null);
    const [open, setOpen] = useState('1');
    const [step1Items, getStep1Items] = useState(JSON.parse(sessionStorage.getItem('moving_service_step_1')));    

    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    }

    const [sizeObjects, setSizeObjects] = useState([{
            position : '1',
            title : 'Eske/bag',
            type : 'box_quantity',
            count: step1Items ? step1Items.box_quantity : 0,
        },{
            position : '2',
            title : 'Liten ting',
            type : 'small_item_quantity',
            count: step1Items ? step1Items.small_item_quantity : 0,
        },{
            position : '3',
            title : 'Stor ting',
            type : 'big_item_quantity',
            count: step1Items ? step1Items.big_item_quantity : 0,
        },{
            position : '4',
            title : 'Spesialting',
            type : 'special_item_quantity',
            count: step1Items ? step1Items.special_item_quantity : 0,
        },
    ]);  

    const examples = [
        { position : '1', 
        title : 'Små ting',
        description1: '', 
        description2: '', 
        classifications: ['Må kunne bæres av 1 person', 'Maks 35 kg per ting'],
        image: 'small-thing.png',
        like : ['Stol', 'Lite bord', 'Lampe', 'Ski', 'Sykkel', 'Dekk']},
        { position : '2', 
        title : 'Store ting',
        description1: '', 
        description2: '', 
        classifications: ['Må kunne bæres av 3 personer', 'Maks 70 kg per ting'],
        image: 'large-thing.png',
        like : ['Kommode', 'Sofa', 'Vaskemaksin', 'Lenestol', 'Spisebord', 'Seng']},
        { position : '3', 
        title : 'Spesialting',
        description1: 'Ulike gjenstander som ikke passer kategoriene over. ', 
        description2: 'Om du har følgende gjenstander ønsker vi informasjon om dette:', 
        classifications: ['Piano', 'Kunst',  'Safe', 'Gjenstander over 70kg'],
        image: 'special-thing.png',
        like : []},
    ];

    //handle the + / -  button, then update the size objects
    const handleButtonClick = (key, type, count) => e => {
        let newSizeObjects = [...sizeObjects];
        newSizeObjects[key].count = type === '+' ? count + 1 : (count > 1 ? count - 1 : 0);
        setSizeObjects(newSizeObjects);
    }

    const handleNextStep = (e) => {
        var box_quantity            = document.getElementById('box_quantity').textContent;
        var small_item_quantity     = document.getElementById('small_item_quantity').textContent;
        var big_item_quantity       = document.getElementById('big_item_quantity').textContent;
        var special_item_quantity   = document.getElementById('special_item_quantity').textContent;

        //save to session storage for future use;
        sessionStorage.setItem('moving_service_step_1', JSON.stringify({box_quantity : parseInt(box_quantity), 
            small_item_quantity : parseInt(small_item_quantity), 
            big_item_quantity : parseInt(big_item_quantity), 
            special_item_quantity : parseInt(special_item_quantity)}));

        navigate('/verticals/moving-load/company/step-2');
    }


    //users came from step 2
    useEffect(() => {

    }, [])
    
    const items = sizeObjects.map((obj, key) => 
        <div className='moving-item-container mb-2 p-3 ' key={obj.position}>
            <Row>
                <Col className='text-bold'><h4 className='mb-0'>{ obj.title }</h4></Col>
                <Col className='text-right'>
                    <div className='d-flex w-100 justify-content-end'>
                        <button className='btn-circle-sm' onClick={ handleButtonClick(key, '-', obj.count) }>
                            <FontAwesomeIcon icon={faMinus} />
                        </button> 
                        <span id={obj.type} className='text-center text-bold f-16 receiver-counter'>{ obj.count }</span> 
                        <button className='btn-circle-sm' onClick={ handleButtonClick(key, '+', obj.count) }>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    );

    const exItems = examples.map((obj) => 
        <AccordionItem className='br-20 p-2 mb-4' > 
            <AccordionHeader targetId={obj.position}>
                <h4 className='mb-0'>{ obj.title }</h4>
            </AccordionHeader>
            <AccordionBody accordionId={obj.position}>
                <center>
                    <img src={'/images/'+obj.image} />
                </center>
                <div className='my-2'>
                    {obj.classifications &&
                    <>
                        {obj.classifications.map((classObj, i) =>
                            <FormGroup check>
                               <Input type="checkbox" checked className='custom-checkbox' />
                               <span className='ms-1'> { classObj }</span>
                            </FormGroup>                   
                        )}
                    </>
                    }

                    { obj.like.length > 0 && 
                    <>
                    <p className='text-center mt-4'>Typiske gjenstander</p>
                    <div className='examples-container'>
                        {obj.like.map((item, i) => 
                        <div className='examples-item'>{ item }</div>
                        )}
                    </div>
                    </>
                    }
                </div>
            </AccordionBody>
        </AccordionItem>
    );

    return (
        <div className='AppContainer bg-white'>  
            <Navigation />
            <MovingLoadCompanyHeader />
            <ProgressBar progress="25" classes="my-3"/>
            <h4 className='mb-3'>Steg 1/4</h4>
            <hr className='fluid' />
            <div className='container-white py-4'>
                <h3>Vi trenger litt informasjon</h3>
                {/* <div className='my-4 px-4 light-box'>
                    <p className='mb-2'>Ønsker du at flyttebyrået pakker ned gjenstandene sdine for deg? </p>
                    <Row>
                        <Col>
                            <button className={isUnpacked ? 'btn-between-normal active' : 'btn-between-normal'} onClick={ () => setIsUnpacked(true) }>Ja</button>
                        </Col>
                        <Col>
                            <button className={isUnpacked === false ? 'btn-between-normal active' : 'btn-between-normal'} onClick={ () => setIsUnpacked(false) }>Nei</button>
                        </Col>
                    </Row>
                </div>

                <div className='my-4 px-4 light-box'>
                    <p className='mb-2'>Må gjenstandene lagres mellom ut- og innflytting?</p>
                    <Row>
                        <Col>
                            <button className={isStored ? 'btn-between-normal active' : 'btn-between-normal'} onClick={ () => setIsStored(true) }>Ja</button>
                        </Col>
                        <Col>
                            <button className={isStored === false ? 'btn-between-normal active' : 'btn-between-normal'} onClick={ () => setIsStored(false) }>Nei</button>
                        </Col>
                    </Row>
                </div> */}

                <div className='my-4'>
                    <h4>Ca. størrelse på flyttelass </h4>
                    { items }
                </div>
                <h4 className='text-center'>Forklaring</h4>           

                <Accordion open={open} toggle={toggle}>
                    { exItems }
                </Accordion>

                <hr className='fluid' />
                <div className='px-4 mt-4'>
                    <button className='w-100 btn-between' onClick={ handleNextStep }>Gå videre</button>
                </div>

            </div>
            <Footer/>
        </div>
    );
}