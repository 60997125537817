import parse from 'html-react-parser';
import moment from 'moment/moment';
export const getLoggedUser = () => {
    const userStr = sessionStorage.getItem("user");
    if(userStr) return JSON.parse(userStr);
    else return null;
}

export const getToken = () => {
    return sessionStorage.getItem("token");
}

export const getFirstName = ( name ) => {
    var response = name.split(" ");

    return response[0];
}

export const setUserSession = (token, user) => {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("user", JSON.stringify(user));
}

export const getMovingOrderId = () => {
    return sessionStorage.getItem("moving_order");
}

export const formatDate = ( date ) => {
    return date.toISOString().substring(0, 10);
}


export const logout = () => {
    // sessionStorage.removeItem("token");
    // sessionStorage.removeItem("user");
    // sessionStorage.removeItem("moving_order");
    // sessionStorage.removeItem("from_address");
    // sessionStorage.removeItem("to_address");
    // sessionStorage.removeItem("moving_date");

    // //PRODUCTS CLEAR
    // sessionStorage.removeItem("moving_service_step_1");
    // sessionStorage.removeItem("moving_service_step_2");
    // sessionStorage.removeItem("moving_service_step_3");
    // sessionStorage.removeItem("postbox_names");
    // sessionStorage.removeItem("moving_materials");
    // sessionStorage.removeItem("cleaning_services");
    // sessionStorage.removeItem("garbage_services");

    sessionStorage.clear();
}

export const baseUrl = () => {
    return "https://api.betwn.no/v1/"; 
}

export const capsFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getHeaders = (isLogged) => {
    return { headers : {
        'Authorization' : 'Bearer '+getToken(),
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'text/json',
        'X-Requested-With': 'XMLHttpRequest'
    } }
}

export const underAgeValidate = (birthday) => {

	var optimizedBirthday = birthday.replace(/-/g, "/");
	var myBirthday = new Date(optimizedBirthday);
	var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';
	var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
    return myAge;
}

export const cleanString  = (str) => {
    return str.replace(/[^A-Z0-9]/ig, "").toLowerCase();
}

export const upFirst = (word) => {
    return word ? word.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : '';
}

export const getMainPersonId = () => {
    return JSON.parse(sessionStorage.getItem('user')).id;
}

export const displayAddressNozip = ( address, isBreak = false ) => {
    const obj = JSON.parse(address);

    address = capsFirst(obj.street)+', <br />'+' '+capsFirst(obj.place);
    address = !isBreak ? address : address.replace('<br />', '');
    
    return parse(address);
}

export const displayAddress = ( address, isBreak = false ) => {
    const obj = JSON.parse(address);

    address = capsFirst(obj.street)+' <br />'+capsFirst(obj.zipcode)+', '+capsFirst(obj.place);
    address = !isBreak ? address : address.replace('<br />', '');
    
    return parse(address);
}


export const displayAddressDefault = ( address ) => {
    const obj = JSON.parse(address);

    address = capsFirst(obj.street)+', '+capsFirst(obj.zipcode)+' '+capsFirst(obj.place);
    
    return parse(address);
}
export const getDeliverTimes = () => {
    return ['8:00 - 10:00', '10:00 - 12:00', '12:00 - 14:00', '14:00 - 16:00', '16:00 - 18:00', '18:00 - 20:00'];
}
 
export const computeProgress = (value, total) => {
    return (value / total) * 100;
}

export const getProductTitle = (category) => {
    const arr = { 'cleaning' : 'Flyttevask', 
    'moving_truck' : 'Flyttebyrå', 
    'container' : 'Container', 
    'garbage_taxi' : 'Søppeltaxi', 
    'garbage_bag' : 'Avfallsekk', 
    'car' : 'Leiebil', 
    'material' : 'Flyttemateriell', 
    'mailbox_sign' : 'Adresseendring & Skilt', 
    'insurance' : 'Forsikring', 
    'power' : 'Strøm'};

    return arr[category];
}
export const formatPrice = (total) => {
    let price = new Intl.NumberFormat('no-NO', {
        style: 'currency', currency: 'NOK'
    }).format(total);
    price = price.replace(/k/g, 'K');
    price = price.replace(/(\d)\s(\d)/g, '$1.$2');
    // replace zero decimal with -
    return price.replace(/,00$/g, ',-');
}
export const daysLeft = (date) => {
    // Calculate the difference in milliseconds between the given date and today's date
    const difference = moment(date, "YYYY-MM-DD") - moment();

    // Convert the difference to days
    const daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));

    return daysLeft;
  }

export const getDisabledTasks = () => {
    var tasks = sessionStorage.getItem('disabled_tasks');
    var finalTasks = [];

    tasks = tasks ? tasks.split(",") : [];

    if(tasks.length > 0){
        finalTasks = tasks.filter(function(task, i, self){
            // return task !== 'null' && self.indexOf(task) === i ;
            return task !== 'null';
        });
    }
    
    // finalTasks = [...new Set(finalTasks)];

    // sessionStorage.setItem('disabled_tasks', finalTasks);
    
    return finalTasks;

}