import React, { useEffect, useState } from 'react';
import {  Progress, UncontrolledAccordion, AccordionItem, AccordionBody, AccordionHeader, ListGroupItem, ListGroup, ListGroupItemHeading } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Navigation } from '../../components/layout/Navigation';
import { Footer } from '../../components/layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPower ,faList, faTruck, faCartShopping, faCreditCard, faCaretRight, faCircleChevronRight, faEnvelopeOpen, faHeadphones, faStar, faEdit, faCog, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { baseUrl, getHeaders } from '../../helpers/Common';
import axios from 'axios';
import '../../../src/fonts/flaticon/flaticon.css';
import { logout } from '../../helpers/Common';

export const ProfileHome = () => {
        const [user, setUser] = useState('');
        
        //get user profile details
        useEffect(() => {
            axios.get(baseUrl()+'users/me', getHeaders()).then(response => {
                setUser(response.data.data);
            }).catch(error => {
                console.error(error);
            });
        }, []);


    const verticalObjects = [{
            position : '1',
            title : 'Alle bestillinger',
            icon: 'list',
            path : '/profile/orders/all',
        },{
            position : '3',
            title : 'Innstillinger',
            icon: 'cog',
            path : '/settings'
        },
    ];

    const verticalObjects2 = [ {
            position : '1',
            title : 'Kundeservice',
            icon: 'headphones',
            path : '/'
        }, {
            position : '3',
            title : 'Ofte stilte spørsmål',
            icon: 'comments',
            path : '/faqs'
        }, {
            position : '3',
            title : 'Skriv en anmeldelse',
            icon: 'star',
            path : '/'
        },
    ];

    const verticalItems = verticalObjects.map((verticalObject) => 
        <Link to={ verticalObject.path }>
            <div class="gray-box mb-3" key={verticalObject.position}>
                <div class="gray-box-header">
                    <table className="w-100">
                        <tr>
                            <td width="30px">
                            <FontAwesomeIcon icon={['far', verticalObject.icon]} />
                            </td>
                            <td>
                                { verticalObject.title }
                                <FontAwesomeIcon icon={['far', 'chevron-right']} style={{color : '#727C8E'}} className='float-end mt-1'  />
                            </td>
                        </tr>
                    </table>          
                </div>
            </div>
        </Link>
    );

    const verticalItems2 = verticalObjects2.map((verticalObject2) => 
        <Link to={ verticalObject2.path }>
            <div class="gray-box mb-3" key={verticalObject2.position}>
                <div class="gray-box-header">
                    <table className="w-100">
                        <tr>
                            <td width="30px">
                            <FontAwesomeIcon icon={['far', verticalObject2.icon]} />
                            </td>
                            <td>
                                { verticalObject2.title }
                                <FontAwesomeIcon icon={['far', 'chevron-right']} style={{color : '#727C8E'}} className='float-end mt-1'  />
                            </td>
                        </tr>
                    </table>          
                </div>
            </div>
        </Link>
    );

    return (
        <div className='AppContainer bg-between-green'>
            <Navigation color='yellow' />
            <div className='pt-4'>
                <h1 className='text-white mb-0'>{ user.name }</h1>
                {/* <p className='mb-4'>{ user.email }</p> */}
                <p className='mb-4 text-white'>Under finner du din kontoinformasjon og innstinninger, samt kundeservice hvis du trenger hjelp fra oss.</p>
                {/* <button className='btn-between-xs' style={{width : "100px"}}>Endre profil</button> */}
            </div>
            <div className='py-4 mh-sm'>
                
                <div className='mb-4'>
                    <h4>Dine innstillinger</h4>
                    {verticalItems}
                </div>
                
                <div>
                    <h4 className='mb-2 pt-2'>Kontakt oss</h4>
                    {verticalItems2}
                    <Link to='/' onClick={() => logout()}>
                        <div class="mb-2 bg-black text-white text-center py-2 br-20">
                            {/* <div class="gray-box-header">
                                <table className="w-100">
                                    <tr>
                                        <td width="30px" className='text-white'>
                                            <FontAwesomeIcon icon={ faPowerOff } />
                                        </td>
                                        <td className='text-white'>
                                            Logg ut
                                            <FontAwesomeIcon icon={['far', 'chevron-right']} style={{color : '#727C8E'}} className='float-end mt-1'  />
                                        </td>
                                    </tr>
                                </table>          
                            </div> */}
                            <div className='p-2'>Logg ut</div>
                        </div>
                    </Link>
                </div>
            </div>
            <Footer/>
        </div>
    );
}