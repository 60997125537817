import React, {useState, useEffect} from 'react';
import { Footer } from '../../components/layout/Footer';
import { Navigation } from '../../components/layout/Navigation';
import { Nav, NavItem, TabContent, TabPane, Row, Col} from 'reactstrap';
import { Verticals } from '../verticals/Verticals';
import axios from 'axios';
import { baseUrl, displayAddressNozip, getDisabledTasks, getHeaders, getMovingOrderId } from '../../helpers/Common';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ProgressBar } from '../../components/layout/ProgressBar';

export const TaskHome = () => {
    const navigate = useNavigate();
    
    const [user, setUser] = useState('');
    const [orders, getOrders] = useState({});  
    const [open, setOpen] = useState([]);
    const [totalProgress, setTotalProgress] = useState(0);
    const [activeTasksCount, setActiveTasksCount] = useState(9 - getDisabledTasks().length);

    //get user profile details
    useEffect(() => {
        axios.get(baseUrl()+'users/me', getHeaders()).then(response => {
            setUser(response.data.data);
        }).catch(error => {
            console.error(error);
        });
    }, []);

    const verticalObjects = [{
        position : '1',
        title : 'Gjør boligen flytteklar',
        description: 'Her kan du bestille utvask, pappesker, snekker, Fretex og mye mer.',
        image: 'images/svg/Between_moving_boxes.svg',
        progress: 0,
        button: 'Kom i gang',
        path : '/verticals/moving-order/home',
        children : ['material', 'cleaning', 'garbage'],
        tasks : [{
                position : '1',
                title : 'Bestill flyttemateriell',
                description: 'Trenger du kanskje pappesker, teip og bobleplast?',
                image: 'images/yellow/svg2/materials-new.svg',
                progress: 0,
                button: 'Sjekk utvalget',
                path : '/verticals/moving-order/boxes/step-1',
                type : 'material',
                enabled : true
            }, {
                position: '2',
                title: 'Bestill utvask',
                description: 'Trenger du kanskje pappesker, teip og bobleplast?', 
                image: 'images/purple/PNG/between-cleaning.png',
                progress: 0,
                button: 'Finn vaskebyrå',
                path : '/verticals/moving-order/cleaning-materials',
                type : 'cleaning',
                enabled : true
            }, {
                position: '3',
                title: 'Bestill søppeltjenester',
                description: 'Har du søppel som må kastes? Her får du hjelp til å bli kvitt den.',
                image: 'images/yellow/svg2/garbage.svg',
                progress: 0,
                button: 'Finn leverandør',
                path : '/verticals/moving-order/garbage-services',
                type : 'garbage',
                enabled : true
            }
            ]
        }, {
            position: '2',
            title: 'Flytting av flyttelass',
            description: 'Her kan du bestille flyttebyrå, mellomlagring og leiebil.', 
            image: 'images/yellow/svg2/moving-service.svg',
            progress: 0,
            button: 'Kom i gang',
            path : '/verticals/moving-load/home',
            children : ['relocation', 'car'],
            tasks : [{
                    position : '1',
                    title : 'Bestill flyttebyrå',
                    description: 'Her kan du plukke og velge mellom sertifiserte flyttebyråer.',
                    image: 'images/svg/Between_moving_van.svg',
                    progress: 0,
                    button: 'Finn flyttebyrå',
                    path : '/verticals/moving-load/company/step-1',
                    enabled : true,
                    type : 'relocation'
                }, {
                    position: '2',
                    title: 'Lei en flyttebil',
                    description: 'Har du ikke noe imot å gjøre all bæringen selv? Da kan du leie en flyttebil her.', 
                    image: 'images/yellow/svg2/rental-car.svg',
                    progress: 0,
                    // button: 'Finn leiebil',
                    button: 'Kommer snart',
                    path : '/verticals/moving-load/car',
                    enabled : false,
                    type : 'car'
                },
            ]
        }, {
            position: '3',
            title: 'Adresse og post',
            description: 'Her kan du enkelt oppdatere adressen din hos over 15 000 selskaper og kontoer.',
            image: 'images/svg/Between_change_address.svg',
            button: 'Kom i gang',
            path : '/verticals/address-and-post',
            children : ['address_change', 'postbox'],
            tasks : [{
                    position : '1',
                    title : 'Endre adresser',
                    description: 'Her kan du endre adresse hos alle selskaper du har et kundeforhold til.',
                    image: 'images/yellow/svg2/address-change.svg',
                    progress: 0,
                    button: 'Søk etter selskaper',
                    path : '/verticals/address-and-post/relocation',
                    type : 'address_change',
                    enabled : true,
                }, {
                    position: '2',
                    title: 'Postkasseskilt',
                    description: 'Sørg for at posten kommer frem med et postkasseskilt.', 
                    image: 'images/pink/svg2/postbox.svg',
                    progress: 0,
                    button: 'Bestill postkasseskilt',
                    path : '/verticals/address-and-post/mailbox',
                    type : 'postbox',
                    enabled : true,
                },
            ]
        }, {
            position: '4',
            title: 'Avtaler',
            description: 'Her kan du flytte eller bytte forsikringsavtale og strømavtale for din nye bolig.',
            image: 'images/pink/svg2/agreements2.svg',
            button: 'Kom i gang',
            path : '/verticals/moving-company/home',
            children : ['power', 'insurance', 'tvandinternet', 'fitness_center'],
            tasks : [{
                position : '1',
                title : 'Sett opp strøm',
                description: 'Her kan du finne strømavtale til din nye bolig',
                image: 'images/yellow/svg2/power.svg',
                progress: 0,
                button: 'Finn strømavtale',
                path : '/verticals/moving-company/power/check',
                type : 'power',
                enabled : true,
            }, {
                position: '2',
                title: 'Forsikring',
                description: 'Når du flytter må du også flytte forsikringene dine. Vi hjelper deg her.', 
                image: 'images/svg/Between_agreement.svg',
                progress: 0,
                button: 'Flytt forsikring',
                path : '/verticals/moving-company/insurance',
                type : 'insurance',
                enabled : true,
            }, {
                position: '3',
                title: 'TV & Internett',
                description: 'Når du flytter må du også flytte forsikringene dine. Vi hjelper deg her.', 
                image: 'images/purple/svg/tv.svg',
                progress: 0,
                button: 'Flytt forsikring',
                path : '/verticals/moving-company/insurance',
                type : 'tvandinternet',
                enabled : true,
            }, {
                position: '4',
                title: 'Treningsenter',
                description: 'Når du flytter må du også flytte forsikringene dine. Vi hjelper deg her.', 
                image: 'images/purple/svg/neighbor.svg',
                progress: 0,
                button: 'Flytt forsikring',
                path : '/verticals/moving-company/insurance',
                type : 'fitness_center',
                enabled : true,
            },
        ]
        },
    ];

    const fetchOrders = (type) => axios.get(baseUrl()+'moving_orders/'+getMovingOrderId()+'/orders', getHeaders()
    ).then(response =>  {getOrders(response.data.data ? response.data.data : {})
        var pCtr = 0; 
        if(response.data.data && response.data.data.length !== 0){
            verticalObjects.map((obj, i) => {
                var childProgress = 0;
                obj.children.map((child) =>{
                    if(response.data.data[child]){
                        pCtr ++;
                        childProgress ++;
                    }
                })

                const activeChildren = obj.children.filter(function(child){
                    return !getDisabledTasks().includes(child);
                })

                if(childProgress !== activeChildren.length){
                    setOpen(open => [...open, obj.position]);  
                }
            });
            setTotalProgress((100 / activeTasksCount) * parseInt(pCtr));
        }else{
            var defaultOpen = ['1', '2', '3', '4'];
            verticalObjects.map((obj, i) => {
                var childrenLength = obj.children.length;
                let arr1 = obj.children,
                arr2 = getDisabledTasks(),
                compare = (a1, a2) => arr1.filter(v => arr2.includes(v)).length;
                var matched = compare(arr1, arr2);

                //remove the vertical that all tasks are disabled
                if(childrenLength === matched){
                    defaultOpen = defaultOpen.filter(function(item) {
                        return item !== obj.position;
                    })
                }
            });
            setOpen(defaultOpen);
        }
    })
    .catch(error => console.error(error));

    useEffect(() => {
        fetchOrders();
    }, [])

    //check if the registration is finished, return to reg if not
    if(!sessionStorage.getItem('from_address')){
        navigate('/registration/address', {replace : true});
    }else{
        return (
            <div className='AppContainer bg-white'>
                <Navigation />
                    <div className='pt-4'>
                        <div className='normal-header'>
                            <h1 className='h1 f-35'>Oppgaver</h1>
                            <p className='f-18'>Hos oss får du oversikt og hjelp til å faselitere hele flytteprosessen.</p>
                        </div>
                        <div className='container-white'>
                            <Row>
                                <Col>
                                    <Verticals disabledTasks={getDisabledTasks()} orders={orders} open={open} setOpen={setOpen} verticalObjects={verticalObjects}/>
                                </Col>
                            </Row>
                        </div>
                    <Footer/>
                </div>
            </div>
        )
    }
}