import React, { useState, useEffect, useCallback } from "react";
import { DefaultHeaderLogo } from '../../components/elements/DefaultHeaderLogo';
import { BetweenLogo } from '../../components/elements/BetweenLogo';
import { VippsButton } from '../../components/elements/VippsButton';
import { setUserSession, baseUrl, getHeaders } from '../../helpers/Common';
import { Alert } from '../../components/utils/Alert';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormGroup, Button } from "reactstrap";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { AuthFooter } from "../../components/elements/AuthFooter";

const Invitation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  // const phone_number = '+47'+query.get('phone');
  const phone_number = '+639178713844';
  const name    = query.get('name');
  const token   = sessionStorage.getItem('pinAccessToken') ? sessionStorage.getItem('pinAccessToken') : null;
  // const [pincode, setPincode] = useState(sessionStorage.getItem('pincode') ? sessionStorage.getItem('pincode') : null);
  const [pincode, setPincode] = useState('0522');
  
  //save the data to session storage
  sessionStorage.setItem('invitationData', JSON.stringify({ name: query.get('name'), 
  email: query.get('email'),
  phone: query.get('phone'), 
  birthdate: query.get('birthdate'),
  moving_from_address: query.get('moving_from_address'), 
  moving_from_address_id: query.get('moving_from_address_id'), 
  moving_to_address: query.get('moving_to_address'), 
  moving_to_address_id: query.get('moving_to_address_id'), 
  moving_date: query.get('moving_date') }));
  
  const schema = yup.object().shape({
    pincode:  yup.string().required("Vennligst fyll ut feltet")
    .oneOf([[pincode]], 'Pinkoden stemmer ikke!')
    .min(4, 'Må være nøyaktig 4 sifre')
    .max(4, 'Må være nøyaktig 4 sifre')
  });
  
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const generatePincode = () => {
    const pin = Math.floor(1000 + Math.random() * 9000);
    sessionStorage.setItem('pincode', pin);
    console.log('generate pin code', pin);

    return pin;
  }

  const sendPincode = () => axios.post('https://sms-microservices.betwn.no/rest-api/sms/v1/send-message', 
  { 'mobile-number' : phone_number, 'sender' : 'Between AS', 'message' : 'Hi, enter this pincode 0522' }, 
  { headers : {
      'Authorization' : 'Bearer '+token,
  } }
  ).then(response => {
      console.log('send sms', response);
  }).catch(error => {
      console.error(error);
  });

  const fetchToken = ( ) => axios.post('https://access-tokens-microservices.betwn.no/rest-api/access-tokens/v1/access-token/init', 
    { 'client_id' : '89021349320443523434898983434006', 'secret' : 'kwi452hSKHzwjqTSgtwGG5ZdrfVFvvVb' }).then(response => {
        
      //set access token;
      const at = response.data.data.access_token;
      console.log('set access token', at);
      sessionStorage.setItem('pinAccessToken', at);
      if(at){
        //send pincode after getting access token
        sendPincode();
      }
    }).catch(error => {
        console.error(error);
    });

    useEffect(() => {
      if(!token && !pincode ){
        //check if access code exists already
        if(!token){
          console.log('token missing, fetch new one', token);
          fetchToken();
        }
      }
    }, [pincode]);

  const onSubmitHandler = (data) => {
    console.log('submit form', data);
      navigate("/create-password", { replace: true });
  };
  return (
    <div className="AppContainer bg-between-green" style={{height : 'auto'}}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
            <DefaultHeaderLogo />
            <div className='my-4'>
                {/* <BetweenLogo /> */}
                <h1 className="text-center text-white">Hello, {name}</h1>
                <p className="text-center text-white">Confirm your one time password</p>
            </div>
            {/* <div className='mt-5'>
                <VippsButton text="Lag bruker med" />
            </div>
            <p className='mt-4 mb-4 text-center'>Eller lag bruker manuelt</p> */}
        <FormGroup>
          <input {...register("pincode")} placeholder="Enter pincode" type="text" className="form-control" />
          <p className="form-error">{errors.pincode?.message}</p>
        </FormGroup>
        <FormGroup className='mt-4 pb-4 text-center'>
            <Button type='submit' className='btn-between' style={{width : '250px'}}>Confirm with pin code</Button>
        </FormGroup>
      </form>

      <AuthFooter />
    </div>
  );
};

export default Invitation;