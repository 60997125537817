import React, { useState, useEffect } from 'react';
import { Accordion, Input, Button, Progress, UncontrolledAccordion, AccordionItem, AccordionBody, AccordionHeader, Row, Col } from 'reactstrap';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ButtonLottie } from '../../../../components/layout/ButtonLottie';
import { Link, useNavigate } from 'react-router-dom';
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl, formatPrice, getDeliverTimes, getHeaders } from '../../../../helpers/Common';
import { ProductImage } from '../../../../components/layout/ProductImage';
import no from "date-fns/locale/nb"; // the locale you want
import moment from 'moment';
import { FAQProduct } from '../../../faqs/FAQProduct';

export const MovingOrderCleaningMaterials = () => {
    const navigate = useNavigate();
    const [isLottie, setIsLottie] = useState(false);
    const [providers, setProviders] = useState([]);
    const [pickupDate, setPickupDate] = useState(new Date());   
    const [deliveryTime, setDeliveryTime] = useState('8:00 - 10:00');
    const [cleaningServicesItems, getCleaningServicesItems] = useState(JSON.parse(sessionStorage.getItem('cleaning_services')));    
    const [open, setOpen] = useState('');

    const toggle = (id) => {
        console.log(id);
        open === id ? setOpen() : setOpen(id);
    };

    registerLocale("no", no); // register it with the name you want

    const isButtonSuccess = (e) => {
        const link      = e.currentTarget.dataset.link;
        const position  = e.currentTarget.dataset.position;
        const pid       = e.currentTarget.dataset.pid;
        const price     = e.currentTarget.dataset.price;
        const title     = e.currentTarget.dataset.title;

        setIsLottie(position);

        //add to session storage for future use
        sessionStorage.setItem('cleaning_services', JSON.stringify({pid : pid,
             pickup_date : pickupDate, 
             delivery_time : deliveryTime, 
             price : price, 
             title : title,
             position : position}));

        setTimeout(function(){
            navigate(link);
        }, 1200)
    }


    //FETCH PRODUCTS PENDING
    const fetchProducts = () => {
        axios.get(baseUrl()+'cleaning_service_providers', getHeaders()
        ).then(response => { 
            if(response.data.status.code === '500'){
                navigate('/registration/address', { replace: true });
            }else{
                setProviders(response.data.data)
            }
        })
        .catch(error => console.error(error))
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    
    //users came from confirmation page
    useEffect(() => {
        console.log(cleaningServicesItems);
        if(cleaningServicesItems){
            
            setOpen(cleaningServicesItems.position);
            
            setIsLottie(cleaningServicesItems.position);
            
            var newDate = moment(cleaningServicesItems.pickup_date).format("YYYY/MM/DD")
            setPickupDate(new Date(newDate));

            setDeliveryTime(cleaningServicesItems.delivery_time);
            console.log(cleaningServicesItems.delivery_time)
        }
    }, [])
    
    const handleNextStep = (e) => {
        navigate('/verticals/moving-order/cleaning-materials/complete-order');
    }
    
    const verticalItems = providers.map((vObj, i) => {
        const position = 'pos-'+i;
        return <AccordionItem className='products-vertical mt-4' key={position}>
            <AccordionHeader targetId={position} className='products-vertical-header'>
                <h4 className='text-left w-100 mb-0 px-2 pt-2'>{vObj.title}</h4>
                <div className='mt-0 pt-1 d-flex justtify-content-start w-100 px-2'>
                    <img src="/images/5stars.svg" alt='star img' className='w-85px'/> 
                </div>
                <Row className='w-100 pb-4'>
                    <Col className='d-flex align-items-center pt-min'>
                            <ProductImage image={vObj.title} className='mh-70' />
                    </Col>
                    <Col className='text-right'>
                        <p className='h4-regular text-right mb-0'>250 verifiserte flytteoppdrag</p>
                        <h3 className='text-right mt-2 p-price mb-0'>Kr. {formatPrice(vObj.price)},-</h3>
                    </Col>
                </Row>    
                {/* <button className='btn-between w-100 mb-4'>Velg</button>      */}
            </AccordionHeader>

            <AccordionBody accordionId={position} className='pt-0'>
                <ul className='ps-4'>
                    <li>100% fornøyd garanti!</li>
                    <li className='lh-small'>Rengjøring av alle flater opp til 3,5 meter.</li>
                    <li>Inkludert alle hvitevarer</li>
                </ul>
                <FAQProduct product='cleaning' />
                <div className='equipment-item'>

                    <hr className='mt-4'/>
                    <h4 className='mb-2 mt-4'>Ønsket dato</h4>
                    <div className='text-center'>
                    <DatePicker formatWeekDay={nameOfDay => nameOfDay.substr(0,3)}  minDate={moment().toDate()} disabledKeyboardNavigation locale="no" selected={pickupDate} onChange={(date : Date) => setPickupDate(date)} inline />
                    </div>
                    <h4 className='mb-2 mt-4'>Ønsket tidspunkt for start av vask</h4>
                    <Input type='select' className='br-min' onChange={(e) => setDeliveryTime(e.target.value)} defaultValue={deliveryTime}>
                        {getDeliverTimes().map((times, key) => 
                            <option value={times} key={key}>Mellom { times }</option>
                        )}
                    </Input>

                    <h3 className='text-right mt-4 p-price'>Kr. {formatPrice(vObj.price)},-</h3>
                    <div className='mt-4 mb-3 px-2'>
                        <Button onClick={ isButtonSuccess } 
                            data-link="/verticals/moving-order/cleaning-materials/complete-order" 
                            className='btn-between w-100' 
                            data-position={position}
                            data-title={vObj.title}
                            data-price={vObj.price}
                            data-pid={ vObj.id }>
                            { isLottie === position ? 
                            <ButtonLottie button='Bestill' left='80px' /> : 
                            <span id={'btn-text-'+position}>Bestill</span>
                            }
                        </Button>
                    </div>
                </div>
            </AccordionBody>
        </AccordionItem>
    });

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                {/* <img src='/images/g-1.webp' alt='between icon hello'
                    style={{position : "absolute",
                            width : "131.91px", 
                            height : "179.73px",
                            right : 20,
                            top : 70}} /> */}
                <h1>Utvask</h1>
                <ProgressBar progress="50" classes="my-3"/>
                <h4 className='mb-3'>Steg 1/2</h4>
                {/* <p className='mb-4 mt-4'>
                Under ser du de beste <br/> verifisertevaskeselskapene <br/> for din bolig
                </p> */}
            </div>
            <hr className='fluid' />
            <div className='container-white pt-4'>
                <h3>Selskaper anbefalt for deg:</h3>
                <Accordion open={open} toggle={toggle}>
                    {verticalItems}
                </Accordion>

            </div>
                <hr className='fluid' />
                {cleaningServicesItems &&
                <div className='mx-3 mb-4 mt-4'>
                    <button className='w-100 btn-between' onClick={ handleNextStep }>Gå videre</button>
                </div>
                }
            <Footer/>
        </div>
    );
}