import React, {useState} from 'react';
import { AccordionItem, AccordionBody, AccordionHeader, Accordion, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ProgressBar } from '../../components/layout/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from '../../helpers/Common';
import { TaskItem } from '../tasks/TaskItem';
import {  getDisabledTasks } from '../../helpers/Common';

export const Verticals = ( {orders, verticalObjects, open, setOpen} ) => {
    const [disabledTasks, setDisabledTasks] = useState(getDisabledTasks());
    const [refresh, setRefresh] = useState(true);

    const verticalItems = verticalObjects.map((obj, i) => {
        var progress = 0;
        var totalActive    = obj.children.length;
        if(obj.children){
            obj.children.map((child) => {
                if(orders[child]){
                    progress += 1;
                }
                
                //reduce the disabled tasks to total
                if(disabledTasks.includes(child)){
                    totalActive -= 1;
                }
            }); 

            progress = (100 / totalActive) * parseInt(progress);

            //if no active tasks inside the vertical, make it finished
            if(totalActive === 0){
                progress = 100;
            } 
        }
        
        //new tasks format
        const taskItems = obj.tasks.map((tObj, i) => 
        <>
        <div id={`${tObj.type}`} className={obj.tasks.length !== i+1 ? `mb-5` : 'mb-4'} key={i}>
            <TaskItem 
                setRefresh={setRefresh} 
                orders={orders} 
                obj={tObj} 
                open={open} 
                setOpen={setOpen} 
                disabledTasks={disabledTasks}
                etDisabledTasks={setDisabledTasks} 
            />
        </div>
        </>
        
        

        );
 
        return <>
            <div className={`bg-between-lgreen br-20 ${i !== 3 ?'mb-5' : 'mb-4'}`} key={i}>
                <div className='px-4 pt-4 border-bottom-white'>
                    <h2>{ obj.title }</h2>
                </div>
                <div className='pt-4 px-4 pb-2'>
                    { taskItems }
                </div>
            </div>
            </>
        }
    );

    return (
        <div className='mt-4'>
            {verticalItems}
        </div>
    );
}