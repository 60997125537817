import React, { useState, useEffect } from "react";
import { DefaultHeaderLogo } from '../../components/elements/DefaultHeaderLogo';
import { Alert } from '../../components/utils/Alert';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormGroup, Button } from "reactstrap";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { setUserSession, baseUrl, getHeaders } from '../../helpers/Common';
import { AuthFooter } from "../../components/elements/AuthFooter";
import moment from "moment";

const CreatePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const name    = query.get('name');
  const invData = JSON.parse(sessionStorage.getItem('invitationData'));

  const schema = yup.object().shape({
    password: yup.string().required("Vennligst fyll ut feltet")
    .min(8, "Passordet skal inneholde minst 8 tegn")
    .max(32, "Passord må være på maksimalt 32 tegn"),
    confirmPassword: yup.string().oneOf([yup.ref("password"), null])
  });
  
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {

    console.log('invitation data', invData);

    data.name = invData.name;
    data.email = invData.email;
    data.phone = invData.phone;
    data.birthdate = moment(invData.birthdate).format('YYYY-MM-DD');
    data.moving_from_address = invData.moving_from_address;
    data.moving_to_address = invData.moving_to_address;
    data.moving_date = invData.moving_date;
    
    console.log('submit form', data);
    axios.post(baseUrl()+'register', data, getHeaders()).then(response => {
      console.log(response.data);
        if(response.data.status.code === "200"){
          const newToken = response.data.data.access_token;
          const moId = response.data.data.moving_order_id;

            //set user token access
            setUserSession(response.data.data.access_token, response.data.data);
            //set user token access
            sessionStorage.setItem("token", newToken);
            sessionStorage.setItem("moving_order", moId);
            sessionStorage.setItem("user", JSON.stringify(response.data.data.user));

            navigate("/registration/address", { replace: true });
            
        }else{
            console.log(response);
            Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
        }
        
    }).catch(error => {
        console.error(error);
    });
  };
  return (
    <div className="AppContainer bg-between-green" style={{height : 'auto'}}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
            <DefaultHeaderLogo />
            <div className='my-4'>
                {/* <BetweenLogo /> */}
                <h1 className="text-center text-white">Hello, {name}</h1>
                <p className="text-center text-white">Create a password to complete registration.</p>
            </div>
            {/* <div className='mt-5'>
                <VippsButton text="Lag bruker med" />
            </div>
            <p className='mt-4 mb-4 text-center'>Eller lag bruker manuelt</p> */}
        <FormGroup>
          <input {...register("password")} placeholder="Passord" type="password" className="form-control text-left " />
          <p className="form-error">{errors.password?.message}</p>
        </FormGroup>
        <FormGroup>
          <input {...register("confirmPassword")} placeholder="Gjenta passord" type="password" className="form-control  " />
          <p className="form-error"> {errors.confirmPassword && "Passord stemmer ikke"} </p>
        </FormGroup>
        <FormGroup className='mt-4 pb-4 text-center'>
            <Button type='submit' className='btn-between' style={{width : '250px'}}>Create password</Button>
        </FormGroup>
      </form>

      <AuthFooter />
    </div>
  );
};

export default CreatePassword;