import { Navigation } from "../../../../components/layout/Navigation";
import { Footer } from "../../../../components/layout/Footer";
import { Button, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Form } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLottie } from "../../../../components/layout/ButtonLottie";
import { useEffect } from "react";
import { VerticalHeader } from "../../../../components/layout/VerticalHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { cleanString, formatPrice } from "../../../../helpers/Common";


export const MovingBoxesStep3 = () => {

    const boxesProgress = sessionStorage.getItem('boxes_progress') ? JSON.parse(sessionStorage.getItem('boxes_progress')) : [];
    var   boxesStep1    = sessionStorage.getItem('boxes_step_1')   ? sessionStorage.getItem('boxes_step_1') : '';
    var   boxesStep3    = sessionStorage.getItem('boxes_step_3')   ? JSON.parse(sessionStorage.getItem('boxes_step_3')) : [];
    const navigate = useNavigate();

    const [additionalProducts, setAdditionalProducts] = useState(sessionStorage.getItem('boxes_step_3')   ? JSON.parse(sessionStorage.getItem('boxes_step_3')).additional_products : [
        {added : false, subTotal : 19, quantity : 0, isQty : true, position : 0, title : 'Tapetkniv', content : 'Enkel arbeidskniv med smalt blad.', price : 19},
        {added : false, subTotal : 249, quantity : 0, isQty : true, position : 1, title : 'Garderobeekse', content : 'Stor og solid garderobeeske for oppbevaring og transport av klær. Klær på kleshenger kan flyttes rett fra skapet til esken. ', price : 249},
        {added : false, subTotal : 99, quantity : 0, isQty : true, position : 2, title : 'Bobleplast', content : '10m x 50cm. 2 lags, 50% resirkulert. Beskytter mot støtskader og hindrer riper under transporten. ', price : 99},
        {added : false, subTotal : 25, quantity : 0, isQty : true, position : 3, title : 'Avfallssekker', content : 'Avfallsekker med hank. Rull med 10 stk. av 125l.', price : 25},
    ])

    const handleButtonClick2 = (key, type, count) => e => {
        let newSizeObjects = [...additionalProducts];
        const newCounter = type === '+' ? count + 1 : (count > 1 ? count - 1 : 0);
        newSizeObjects[key].quantity = newCounter;
        newSizeObjects[key].subTotal    = newSizeObjects[key].quantity * newSizeObjects[key].price;

           if(newCounter !== 0){
                newSizeObjects[key].added = true;
           }else{
                newSizeObjects[key].added = false;
           }

        setAdditionalProducts(newSizeObjects);
        sessionStorage.setItem('boxes_step_3', JSON.stringify({additional_products : newSizeObjects}));
    }

    const handleProductClick = (key, type) => {
        let tempProducts = [...additionalProducts];
        tempProducts[key].added = type === 'add' ? true : false;
        setAdditionalProducts(tempProducts);
        sessionStorage.setItem('boxes_step_3', JSON.stringify({additional_products : tempProducts}));   
    }

    const handleNextPage = () => {

            //add to progress for the progress bar in the homepage
            if(!boxesProgress.includes('step3')){
                boxesProgress.push('step3');
                sessionStorage.setItem('boxes_progress', JSON.stringify(boxesProgress));
            }
            
            //proceed to step 3;
            navigate('/verticals/moving-order/boxes/step-4');
    }

    const displayAdditionalProduct = additionalProducts.map((product, i) => 
        <div className="bg-light p-4 mb-2 br-20" key={i}>
            <Row>
                <Col xs={4} className="d-flex align-items-center justify-content-center">
                    <img src={`/images/boxes/${cleanString(product.title)}2.png`} 
                        alt={product.title}
                        className='img-fluid'
                    />
                </Col>
                <Col>
                <p className="mb-0 text-bold">{product.title}</p>
                <p className="mb-0">{product.content}</p>

                <Row className="mt-4">
                    <Col className="text-bold">
                        {(product.subTotal && product.subTotal !== 0) ? formatPrice(product.subTotal) : formatPrice(product.price)}
                    </Col>
                    <Col>
                    {!product.isQty ?
                        !product.added ?
                            <Button 
                            data-product={product.title} 
                            data-type='add' 
                            className="btn btn-dark btn-sm f-10 w-100" 
                            onClick={() =>  handleProductClick(i, 'add') }>
                            <FontAwesomeIcon icon={faPlus} className='me-2' />
                            Legg till
                            </Button>
                        :
                        <Button 
                        data-product={product.title} 
                        data-type='delete'
                        className="btn btn-danger btn-sm f-10 w-100" 
                        onClick={() => handleProductClick(i, 'delete') }>
                            <FontAwesomeIcon icon={faMinus} className='me-2' />
                            Slett
                            </Button>
                    :
                    <>
                    <div className='d-flex w-100 justify-content-end align-items-center'>
                    <a className='btn-circle-xs' onClick={ handleButtonClick2(i, '-', product.quantity) }>
                        <FontAwesomeIcon icon={faMinus} />
                    </a> 
                    <span id={'garderobeekse'} 
                        className='text-center mx-1 ' 
                        style={{width : '20px'}} >
                            { product.quantity }
                    </span>
                    <a className='btn-circle-xs' onClick={ handleButtonClick2(i, '+', product.quantity) }>
                        <FontAwesomeIcon icon={faPlus} />
                    </a>
                </div>
                    </>
                    }
                    </Col>
                </Row>
                </Col>
            </Row>
        </div>
    );

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Flyttemateriell' progress={3} totalProgress={4} />

            <div className="pt-4">
                <h3>Tilleggsprodukter</h3>
                <Row className="mt-4">
                    <Col>
                    { displayAdditionalProduct }
                    </Col>
                </Row>
                <Row className="text-center my-4 mx-3">
                    <Col>
                        <Link to="/verticals/moving-order/boxes/step-2" className="btn-between-white w-100">Tilbake</Link>
                    </Col>
                    <Col>
                        <Button onClick={ handleNextPage } className="w-100 btn-between">Gå videre</Button>
                    </Col>
                </Row>
            </div>
            <Footer/>
        </div>
    );
}