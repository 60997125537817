import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';

import { baseUrl, getHeaders, getMainPersonId } from '../../helpers/Common';

export default function PeopleList( {type = 'registration'} ) {
    const [memberItems, setMemberItems] = useState([]);
    const movingOrderId = sessionStorage.getItem('moving_order');
    const [formModal, setFormModal] = useState(false);

    const fetchUsers = () => {
        axios.get(baseUrl()+'moving_orders/'+movingOrderId+'/users', getHeaders()
        ).then(response => setMemberItems(response.data.data))
        .catch(error => console.error(error))
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <div className='pt-4'>
            <ListGroup>
                { memberItems.map((memberObj, i) => {
                   return <div key={i}>
                       <Link to={'/'+type+'/user/edit/'+ memberObj.id } className='dark-link' state= {{ 
                        name : memberObj.name, 
                        email : memberObj.email, 
                        phone : memberObj.phone,
                        birthdate : memberObj.birthdate, 
                        role : memberObj.role, 
                        isExtra : getMainPersonId() != memberObj.id ? true : false }}> 
                        <ListGroupItem  className={`br-15 p-4 ${getMainPersonId() !== memberObj.id && 'mt-2'}`}>
                            <ListGroupItemHeading>{ memberObj.name } {getMainPersonId() == memberObj.id && '(flytteleder)'}</ListGroupItemHeading>
                            <ListGroupItemText>
                                E-post: { memberObj.email }
                            </ListGroupItemText>
                            <ListGroupItemText>
                                Telefon: { memberObj.phone } 
                                <Link to="" className='float-end'>
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </Link>
                            </ListGroupItemText>
                            <ListGroupItemText>
                                Fødselsdato: { memberObj.birthdate }
                            </ListGroupItemText>
                        </ListGroupItem>
                    </Link>
                    </div>
                }) 
             }

            </ListGroup>
        </div>
    )
}