import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLottie } from "../../../components/layout/ButtonLottie";
import { useEffect } from "react";
import { computeProgress } from "../../../helpers/Common";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { VerticalHeader } from "../../../components/layout/VerticalHeader";

const schema = yup.object().shape({
    comment: yup.string()
});
export const ProtocolStep10 = () => {

    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    const proStep10 = sessionStorage.getItem('protocol_step_10') ? JSON.parse(sessionStorage.getItem('protocol_step_10')) : {};
    // const proStep10 = {};
    const navigate = useNavigate();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues : {
            comment : proStep10.comment ? proStep10.comment : '',
        }
    });
    
    const handleFormSubmit = (data) => {

        console.log(data);

        proStep10.comment = data.comment;
        sessionStorage.setItem('protocol_step_10', JSON.stringify(proStep10));
        if(!protocolProgress.includes('step10')){
            protocolProgress.push('step10');
            sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
        }

        navigate('/protocol/step-11');
    }

    useEffect(() => {
        if(proStep10.comment){
            setValue('comment', proStep10.comment);
        }
    }, []);

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Pro & Contra' progress={10} totalProgress={14} />
            
            <div className="pt-4">

                <h4 className="text-bold f-20">Eventuell fordeling av fellesutgifter/husleie/kommunale avgifter (pro & contra) foretaes direkte mellom partene. 
Utestående som er forfalt til betaling vil bli betalt av megler i oppgjøret.</h4>

                <form onSubmit={handleSubmit( handleFormSubmit )} className='mt-4'>
                    <FormGroup>
                        <Label for='comment' className="mb-0 text-normal">Kommentar</Label>
                        <textarea {...register("comment")} className={`form-control input-square ${errors.comment && 'is-invalid'}`} />
                        <p className="form-error">{errors.comment?.message}</p>
                    </FormGroup>
                    <FormGroup className="px-5">
                        <Button type="submit" className="btn btn-between w-100">Neste</Button>
                    </FormGroup>
                </form>
                <Row className="text-center my-4 mx-5">
                    <Col>
                        <Link to={'/protocol/step-9'} className="btn-between-white" style={{maxWidth : '150px'}}>Tilbake</Link>
                    </Col>
                </Row>
                </div>
            <Footer/>
        </div>
    );
}