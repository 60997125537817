import React, { useState, useEffect } from 'react';
import { Navigation } from '../../components/layout/Navigation';
import { RegistrationHeader } from '../../components/layout/RegistrationHeader';
import {FormGroup, Button, Label, Row, Form, Col} from 'reactstrap';
import { baseUrl, getHeaders, underAgeValidate} from '../../helpers/Common';
import { Alert } from '../../components/utils/Alert';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';
import { useNavigate} from "react-router-dom";
import { Link } from 'react-router-dom';
import PeopleList from './PeopleList';
import { UserAddAdultForm } from '../../components/layout/users/UserAddAdultForm';


const schema = yup.object().shape({
    name: yup.string().required("Vennligst fyll ut feltet"),
    birthdate: yup.string().required("Vennligst fyll ut feltet").test("birthdate", "Personen må være over 18 år", (value) => {
        return underAgeValidate(value) >= 18;
      }),
    phone: yup.string().required("Vennligst fyll ut feltet").min(8, "Mobilnummer må være på minst 8 tegn").max(32, "Mobilnummer må være på maksimalt 32 tegn"),
    email: yup.string().required("Vennligst fyll ut feltet").email("Vennligst se over at informasjonen er riktig"),
  });

export const RegistrationNewPersonAdult = () => {
    const navigate = useNavigate();
    const [dateFocus, setIsDateFocus] = useState( false );
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
      resolver: yupResolver(schema),
    });

    useEffect(() => {
        reset()
      }, [])
      
    const onSubmitHandler = (data) => {
        
        axios.post(baseUrl()+'users', data, getHeaders()
        ).then(response => {
            if(response.data.status.code === "200"){
                const newUserId  = response.data.data.id;
                const movingOrderId = sessionStorage.getItem('moving_order');
                const memberData = {moving_order: parseInt(movingOrderId), user: newUserId, role : 'editor'};

                //link new user to membership
                axios.post(baseUrl()+'memberships', memberData, getHeaders()
                ).then(response => {

                    console.log('adding user to membership', response);
                    if(response.data.status.code === "200"){
                        // Alert('success', 'Ny bruker lagt til!', '', '', function() {
                            navigate("/registration/users", { replace: true });
                        // })
                    }else{
                        Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
                    }
                }).catch(error => {
                    console.error(error);
                });
            }else{
                Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
            }
        }).catch(error => {
            console.error(error);
        });
      };


    return (
        <div id='CreateAdultUserContainer' className='AppContainer bg-between'>
            <Navigation />
            <RegistrationHeader title='Husstand' />
            <div className='container-white pb-4'>
                <PeopleList />
                <UserAddAdultForm />
            </div>
        </div>
    )
}