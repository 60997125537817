import React, {useEffect, useState} from 'react';
import { Input, Button, Accordion, AccordionItem, AccordionBody, AccordionHeader, Row, Col } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ButtonLottie } from '../../../../components/layout/ButtonLottie';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import moment from 'moment';
import { MovingLoadCompanyHeader } from './MovingLoadCompanyHeader';
import no from "date-fns/locale/nb"; // the locale you want
import { date } from 'yup';

export const MovingLoadCompanyStep3 = () => {
    const navigate = useNavigate();
    const [isLottie, setIsLottie] = useState(false);
    const [deliveryTime, setDeliveryTime] = useState('8:00 - 10:00');
    const [open, setOpen] = useState(['1']);
    const [storage, setStorage ] = useState(false);
    const [unPacked, setIsUnpacked] = useState(false);
    const [step2Items, getStep2Items] = useState(JSON.parse(sessionStorage.getItem('moving_service_step_2')));    
    const [step3Items, getStep3Items] = useState(JSON.parse(sessionStorage.getItem('moving_service_step_3')));
    const [numberOfDays, setNumberOfDays] = useState(1);
    const [storagePrice, setStoragePrice] = useState(250);
    const storageFixedPrice = 250;
    const [storageDeliveryDate, setStorageDeliveryDate] = useState(new Date());

    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    }

    registerLocale("no", no); // register it with the name you want

    const isButtonSuccess = (e) => {
        const link = e.currentTarget.dataset.link;
        const position = e.currentTarget.dataset.position;
        const title = e.currentTarget.dataset.title;

        setIsLottie(position);

        setStorage({storage : true, title : title, delivery_date : storageDeliveryDate, number_of_days : numberOfDays, storage_price : storagePrice, delivery_date : storageDeliveryDate});
    }

    const handleCheckChange = (e) =>{
        var data = e.target.dataset;
        var isCheck = e.target.checked;
        var entireHouse = document.getElementById('option-check-1');
        var entireHouseCont = document.getElementById('option-container-1');
        var kitchen = document.getElementById('option-check-2');
        var kitchenCont = document.getElementById('option-container-2');
        var garage = document.getElementById('option-check-3');
        var garageCont = document.getElementById('option-container-3');
        const activeElement = document.getElementById("option-container-"+data.position);

        var options = document.getElementsByClassName('options-pink');
        
        if(data.position === '1' && isCheck){
            kitchen.checked = false;
            kitchenCont.classList.remove('active');
            garage.checked = false;
            garageCont.classList.remove('active');
            entireHouseCont.classList.add('active');
        }
        
        if((data.position === '2' || data.position === '3') && isCheck){
            entireHouse.checked = false;
            entireHouseCont.classList.remove('active');

            document.getElementById('option-check-'+data.position).checked = true;
            document.getElementById('option-container-'+data.position).classList.add('active');
        }

        
        if((data.position === '2' || data.position === '3') && !isCheck){
            document.getElementById('option-check-'+data.position).checked = false;
            document.getElementById('option-container-'+data.position).classList.remove('active');
        }

    }

    const handleClickNextStep = () => {

        var boxes = document.querySelectorAll('input[name=option-check]');
        var unpack = [];

        for (let i = 0; i < boxes.length; i++) {
            if(boxes[i].checked){
                var data = boxes[i].dataset;
                var arr = {position : data.position, title : data.title, price : data.price, type :data.type};
                unpack.push(arr);
            }  
        }
        
        if(unpack.length === 0)
            unpack = false;
        
            console.log(storage)
        //save to session storage for future use;
        sessionStorage.setItem('moving_service_step_3', JSON.stringify({unpack : unpack, storage : storage }));
        navigate("/verticals/moving-load/company/complete-order");

    }

    //back from complete order page 
    useEffect(() => {
        
        if(step2Items && step2Items.pickupDate){
            // setStorageDeliveryDate(moment(step2Items.pickupDate));
            
            var newDate = moment(step2Items.pickupDate).format('YYYY-MM-DD');
            setStorageDeliveryDate(new Date(newDate));
        }

        if(step3Items && step3Items.unpack){
            setIsUnpacked(step3Items.unpack.position);
            step3Items.unpack.map((pack) => {
                document.getElementById('option-check-'+pack.position).checked = true;
                document.getElementById('option-container-'+pack.position).classList.add('active');
            })
        }

        if(step3Items && step3Items.storage){

            setIsLottie('2');
                var newDate = moment(step3Items.storage.delivery_date).format("YYYY/MM/DD")
                setStorageDeliveryDate(new Date(newDate));

        //     //assign the storage back
                setStorage(step3Items.storage);
                setNumberOfDays(step3Items.storage.number_of_days);
                setStorageDeliveryDate(new Date(newDate));
                setStoragePrice(step3Items.storage.storage_price);
        }
    }, []);

    const handleChangeDate = (date) => {
        const pickupDate = moment(step2Items.pickupDate).format('YYYY-MM-DD');
        const deliveryDate = moment(date).format('YYYY-MM-DD');

        console.log(pickupDate, deliveryDate);

        const diff = moment(deliveryDate).diff(pickupDate, 'day', false);

        setNumberOfDays(diff);
        setStoragePrice(diff * storageFixedPrice);

        if(step3Items && step3Items.storage){
            setStorage({storage : true, title : 'Mellomlagring', delivery_date : storageDeliveryDate, number_of_days : diff, storage_price : diff * storageFixedPrice});
        }

        setStorageDeliveryDate(date)
    }
    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <MovingLoadCompanyHeader />
            <ProgressBar progress="75" classes="my-3"/>
            <h4 className='mb-4'>Steg 3/4</h4>
            <hr className='fluid' />

            <div className='container-white py-4'>
                <h3 className='mb-3'>Tilleggstjenester</h3>
                <Accordion open={open} toggle={toggle} className='products-vertical relocation-vertical'>
                    <AccordionItem className='br-20 mb-4' > 
                        <AccordionHeader targetId='1'>
                            <h4 className='mb-0'>Nedpakking av bolig</h4>
                        </AccordionHeader>
                        <AccordionBody accordionId='1'>
                            <p>Vi tar med nødvendig utstyr og pakker ned boligen for deg. Velg om du ønsker at vi pakker ned hele eller deler av bolig under.</p>
                            <div className={`options-pink px-4 py-2 ${unPacked == '1' && 'active'}`} id='option-container-1'>
                                Hele boligen <br />
                                <i>Kr. 6500</i>
                                <Input id='option-check-1' type='checkbox' name='option-check' onChange={ handleCheckChange } data-position='1' data-title='Hele boligen' data-price='6500' data-type='entire_house' className='custom-checkbox float-end'/>
                            </div>
                            <div className={`options-pink px-4 py-2 mt-2 ${unPacked == '2' && 'active'}`} id='option-container-2'>
                                Kun kjøkken <br />
                                <i>Kr. 1000</i>
                                <Input id='option-check-2' type='checkbox' name='option-check' onChange={ handleCheckChange } data-position='2' data-title='Kun kjøkken' data-price='1000' data-type='kitchen_only' className='custom-checkbox float-end'/>
                            </div>
                            <div className={`options-pink px-4 py-2 mt-2 ${unPacked == '3' && 'active'}`} id='option-container-3'>
                                Kun bod & garasje <br />
                                <i>Kr. 2000</i>
                                <Input id='option-check-3' type='checkbox' name='option-check' onChange={ handleCheckChange }  data-position='3' data-title='Kun bod & garasje' data-price='2000' data-type='garage_only' className='custom-checkbox float-end'/>
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className='br-20 mb-4' > 
                        <AccordionHeader targetId='2'>
                        <h4 className='mb-0'>Mellomlagring</h4>
                        </AccordionHeader>
                        <AccordionBody accordionId='2'>
                            <p>Du har valgt hentedato {moment(step2Items.pickupDate).format('DD, MMMM YYYY')}. Når vil du at vi leverer til ny bolig?</p>
                            <center>
                                <DatePicker formatWeekDay={nameOfDay => nameOfDay.substr(0,3)} minDate={moment(step2Items.pickupDate, "DD-MM-YYYY").add(1, 'd')} disabledKeyboardNavigation locale="no" selected={storageDeliveryDate} onChange={(date:Date) => handleChangeDate(date)} inline />
                            </center>

                            <div className='mt-4'>
                                <p className='mb-0'>Antall dager: { numberOfDays }</p>
                                <p className='mb-0'>Pris: { storagePrice },-</p>
                                <p><a className='dark-link text-underline text-bold cursor-pointer'>Klikk her</a> for mer informasjon</p>
                            </div>

                            <div className='mt-4 mb-2'>
                                <Button onClick={ isButtonSuccess } 
                                    className='btn-between w-100' 
                                    data-position='2'
                                    data-title='Mellomlagring'>
                                    { isLottie === '2' ? 
                                    <ButtonLottie button='Bestilt' left='80px'/> : 
                                    <span id={'btn-text-2'}>Bestill</span>
                                    }
                                </Button>
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
                <hr className='fluid' />
                <div className='mx-3 mt-4'>
                    <button to='/verticals/moving-load/company/complete-order' className='w-100 btn-between' onClick={ handleClickNextStep }>Gå videre</button>
                    <div className='mt-4 text-center'>
                        <Link to='/verticals/moving-load/company/step-2' className='dark-link text-underline'>Tilbake</Link>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}