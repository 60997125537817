import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCircle } from "@fortawesome/pro-regular-svg-icons";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import parse from 'html-react-parser';

export const ChecklistDetails = () => {
    const location  = useLocation();
    const checklist = location.state.checklist;

    const handleClick = (e) => {
        console.log(e);
    }

    const checklistItems = checklist.list.map((obj, i) => 
        <div className="mb-4">
            <Row>
                <Col xs={10}>
                    {parse(obj)} 
                </Col>
                <Col>
                    <FontAwesomeIcon icon={faCircle} className='mt-2 f-25 cursor-pointer float-end' onClick={(e) => handleClick(e) } />
                </Col>
            </Row>
        </div>
    );
    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <p className='p-header'>{ checklist.title }</p>
                <div className="mb-4 position-relative w-100 d-flex justify-content-center">
                    <img src='/images/purple/svg/checklist.svg' style={{width: '250px'}} alt={'checklist'}/>
                </div>
                <ProgressBar progress={checklist.progress} />
                <p className="mt-2">{checklist.progress}% ferdig</p>
            </div>
            <hr className="fluid" />
            <div className="pt-4">
                { checklistItems }
            </div>
            <Footer/>
        </div>
    );
}