import React from 'react';
import {  Col, Navbar, NavbarBrand, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";
import confetti from "../../../../lottie/confetti.json";
import { Navigation } from '../../../../components/layout/Navigation';

export const MovingCompanyPowerConfirm = () => {

        return ( <div className='AppContainer bg-white' style={{minHeight : "750px"}}>
                    <Navigation />
                    <div className='px-4'>
                        <img alt='power confirm' src='/images/purple/PNG/power_confirm.png' className='img-fluid' />
                    </div>
                    <p className='f-35 mt-4 texg-bold'>Boligen din har nå <br/> fått strøm!</p>
                    <p>Gratulerer, du er et steg nærmere å gjøre boligen din klar til bruk! Følg med i notifikasjoner for oppdatering og status.</p>
                    <p className='mt-4 mb-5'>Lykke til med flyttingen!</p>

                    <Row className='mb-5'>
                        <Col className='px-4'>
                            <Link replace to="/tasks#power" className='btn-between w-100'>Gå tilbake til oversikten</Link>
                        </Col>
                    </Row>
                    
                <Lottie animationData={confetti} loop={false} style={{width : '100%', position: 'absolute', bottom : '410px', left : '0px'}} />
                </div>
        );
}