import React, { useState } from "react";
import { DefaultHeaderLogo } from '../../components/elements/DefaultHeaderLogo';
import { BetweenLogo } from '../../components/elements/BetweenLogo';
import { VippsButton } from '../../components/elements/VippsButton';
import { setUserSession, baseUrl, getHeaders } from '../../helpers/Common';
import { Alert } from '../../components/utils/Alert';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormGroup, Button } from "reactstrap";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { isMobile } from "react-device-detect";

const schema = yup.object().shape({
  name: yup.string().required("Vennligst fyll ut feltet"),
  birthdate: yup.string().required("Vennligst fyll ut feltet").test("birthdate", "Vennligst velg en gyldig fødselsdato", (value) => {
    return moment().diff(value, 'years', false)  >= 18;
  }),
  phone: yup.string().required("Vennligst fyll ut feltet").min(8, "Mobilnummer må være på minst 8 tegn").max(32, "Mobilnummer må være på maksimalt 32 tegn"),
  email: yup.string().required("Vennligst fyll ut feltet").email("Vennligst se over at informasjonen er riktig"),
  password: yup.string().required("Vennligst fyll ut feltet").min(8, "Passordet skal inneholde minst 8 tegn").max(32, "Passord må være på maksimalt 32 tegn"),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
});

const Register = () => {
  const navigate = useNavigate();
  const adultBday = new Date().setFullYear(new Date().getFullYear() - 18);
  const [dateFocus, setIsDateFocus] = useState( false );

  const handleDateOnFocus = (e) => {
    var bdayField = document.getElementById('birthdate');
    if(bdayField.value === ''){
      document.getElementById('birthdate').value = moment(adultBday).format('YYYY-MM-DD');
    }
    setIsDateFocus(true);
  }

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmitHandler = (data) => {
    axios.post(baseUrl()+'register', data, getHeaders()).then(response => {
      console.log(response.data);
        if(response.data.status.code === "200"){
          const newToken = response.data.data.access_token;
          const moId = response.data.data.moving_order_id;

            //set user token access
            setUserSession(response.data.data.access_token, response.data.data);
            //set user token access
            sessionStorage.setItem("token", newToken);
            sessionStorage.setItem("moving_order", moId);
            sessionStorage.setItem("user", JSON.stringify(response.data.data.user));


            navigate("/registration/address", { replace: true });
            
        }else{
            console.log(response);
            Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
        }
        
    }).catch(error => {
        console.error(error);
    });
    // reset();
  };
  return (
    <div className="AppContainer bg-between-green">
      <form onSubmit={handleSubmit(onSubmitHandler)}>
            <DefaultHeaderLogo />
            <div className='mt-4'>
                <BetweenLogo />
            </div>
            <div className='mt-5'>
                <VippsButton text="Lag bruker med" />
            </div>
            <p className='mt-4 mb-4 text-center'>Eller lag bruker manuelt</p>
        <FormGroup>
          <input {...register("phone")} placeholder="Mobilnummer" type="text" className="form-control" />
          <p className="form-error">{errors.phone?.message}</p>
        </FormGroup>
        <FormGroup>
          <input {...register("name")} placeholder="Fullt navn" type="text" className="form-control" />
          <p className="form-error">{errors.name?.message}</p>
        </FormGroup>
        <FormGroup>
          <input {...register("email")} placeholder="E-post" type="email" className="form-control" />
          <p className="form-error">{errors.email?.message}</p>
        </FormGroup>
        <FormGroup>
          {!dateFocus && isMobile &&
          <span className="date-placeholder">Fødselsdato</span>
          }
          <input {...register("birthdate")} placeholder="Fødselsdato" onFocus={(e) => handleDateOnFocus(e) } type="date" className="form-control  " id="birthdate" />
          <p className="form-error">{errors.birthdate?.message}</p>
        </FormGroup>

            <p className='mt-4 text-center text-bold'>Velg passord</p>
        <FormGroup>
          <input {...register("password")} placeholder="Passord" type="password" className="form-control text-left " />
          <p className="form-error">{errors.password?.message}</p>
        </FormGroup>
        <FormGroup>
          <input {...register("confirmPassword")} placeholder="Gjenta passord" type="password" className="form-control  " />
          <p className="form-error"> {errors.confirmPassword && "Passord stemmer ikke"} </p>
        </FormGroup>
        <FormGroup className='mt-4 pb-4 text-center'>
            <Button type='submit' className='btn-between' style={{width : '250px'}}>Lag bruker</Button>
        </FormGroup>
      </form>
    </div>
  );
};

export default Register;