import React, { useState, useEffect } from 'react';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Navigation } from '../../components/layout/Navigation';
import { Footer } from '../../components/layout/Footer';
import axios from 'axios';
import { getHeaders, baseUrl} from '../../helpers/Common';
import { ParseString } from '../../components/elements/ParseString';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FAQs = () => {
    const [faqs, getFaqs] = useState(null);
    const [open, setOpen] = useState('1');

    const toggle = (id) => {
        console.log(id);
        open === id ? setOpen() : setOpen(id);
    };
    //get all members
    const fetchFaqs = () => {
        axios.get(baseUrl()+'faqs', getHeaders()
        ).then(response => getFaqs(response.data.data))
        .catch(error => console.error(error))
    }       
    
     
    useEffect(() => {
        fetchFaqs();
    }, []);
    const productFaqs = [{
            title : 'Flyttevask',
            category : 'cleaning'}, {
            title : 'Flyttebyrå',
            category : 'moving_truck'}, {
            title : 'Container',
            category : 'container'}, {
            title : 'Søppeltaxi',
            category : 'garbage_taxi'}, {
            title : 'Avfallsekk',
            category : 'garbage_bag'}, {
            title : 'Leiebil',
            category : 'car'}, {
            title : 'Flyttemateriell',
            category : 'moving_material'}, {
            title : 'Adresseendring & Skilt',
            category : 'mailbox_sign'}, {
            title : 'Forsikring',
            category : 'insurance'}, {
            title : 'Strøm',
            category : 'power'}];

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='py-4'>
                <p className='p-header mb-0'>Ofte stilte spørsmål</p>
                {/* <img src='/images/Between_tips.png' alt='between icon hello'
                        style={{position : "absolute",
                                width : "170px", 
                                right : 20,
                                top : 70}} /> */}
            </div>
            <hr className='fluid' />
            <div className='container-white py-4'>
                <p className='f-20 text-bold'>Appen</p>
                { faqs?.map((obj, i) => {
                    if(obj.category === 'app'){
                        const id = 'pos-'+i;
                        return <Accordion open={open} className='mb-2' toggle={toggle}>
                            <AccordionItem key={i} className='vertical-classic p-2'>
                                <AccordionHeader targetId={ id }> <span>{obj.question}</span> </AccordionHeader>
                                <AccordionBody accordionId={ id }>
                                    <ParseString string={obj.answer}></ParseString>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                        }
                    }
                )} 
                <p className='f-20 text-bold mt-4'>Produkter</p>
                { productFaqs?.map((obj, i) => {
                    return <div className='gray-box mb-2'>
                            <div className='gray-box-header'>
                                <div className='w-100'>
                                <Link to={`/faq/products/${obj.category}`} state={{title : obj.title}} className='link-black'>
                                    {obj.title}
                                    <FontAwesomeIcon icon={['far', 'chevron-right']} style={{color : '#727C8E'}} className='float-end mt-1'  />
                                </Link>
                                 </div>
                            </div>
                        </div>
                    }
                )} 
                <div className='pb-4 text-center mt-4'>
                    <Link  to="/settings" className='dark-link text-underline' >Tilbake</Link>
                </div>
            </div>
            <Footer/>
        </div>
    );
}