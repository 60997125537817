export const MovingLoadCompanyHeader = () => {
    return (
        <div className='pt-4'>
            {/* <img src='/images/Between_moving_van.png' alt='between icon hello'
                style={{position : "absolute",
                        width : "174.81px", 
                        right : 0,
                        top : 90}} /> */}
            <h1 className='p-header mb-0'>Flyttebyrå</h1>
            {/* <p className='mb-5'> Før vi kan finne de beste <br/> flyttebyråene for deg <br/> trenger vi litt mer <br/> informasjon.  </p> */}
        </div>
    );
}