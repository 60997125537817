import React from 'react';
import { Navigation } from '../../components/layout/Navigation';
import { Footer } from '../../components/layout/Footer';
import { TabContent, TabPane} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ProgressBar } from '../../components/layout/ProgressBar';

export const Timeline = () => {
    const timelineObjects = [{
            id : 1,
            title : 'Torsdag',
            description: 'Søknad for barnehageplass er sendt ',
            date: '20.05',
            type: 'normal',
            status : 1
        },{
            id : 2,
            title : 'Torsdag',
            description: 'Utvask av leilighet bestilt!',
            date: '25.05',
            type: 'normal',
            status : 1
        },{
            id : 3,
            title : 'Mandag',
            description: 'Flyttebryå er bestilt!',
            date: '29.05',
            type: 'normal',
            status: 1
        },{
            id : 4,
            title : 'Lørdag',
            description: 'Trenger du å kaste søppel, er det fiont å bestille dette nå.',
            date: '18.07',
            type : 'garbage',
            status : 0
        },{
            id : 5,
            title : 'Torsdag',
            description: 'Bytt fastlege, det kan du gjøre raskt og enkelt under.',
            date: '25.07',
            type : 'gp',
            status : 0
        },{
            id : 6,
            title : 'Torsdag',
            description: 'Oslo flyttevask ankommer for å utføre flyttvask rundt kl 14:00. Du vil bli varslet på sms når dem nærmer seg.',
            date: '26.07',
            type: 'normal',
            status: 0
        },{
            id : 7,
            title : 'Lørdag',
            description: 'Vi trenger at du bekrefter flyttelasset. Dette må gjøres senest 2 dager før flyttedagen',
            date: '28.07',
            type : 'garbage',
            status : 0
        },{
            id : 8,
            title : 'Torsdag',
            description: 'Selve flyttedagen! Flyttegutta AS ankommer kl 09:00 for å starte med flytting. ',
            date: '25.07',
            type: 'normal',
            status: 0
        },{
            id : 9,
            title : 'Fredag',
            description: 'Angi målestand av boligens strømmåler direkte her',
            date: '26.05',
            type : 'power',
            status : 0
        }
    ];

    const timelineItems = timelineObjects.map((tObj, i) => 
        <div className={`timeline-section ps-3`} key={i}>
            <div className={`${tObj.id !== 9 && 'time-line-text pb-5'} ps-4`}>
                { tObj.status === 0 ? 
                <span className='dot bg-white'></span>
                : 
                <span className='dot dark d-flex justify-content-center align-items-center'>
                    <FontAwesomeIcon icon={ faCheck } style={{color : '#fff', fontSize: '20px'}} />
                </span>
                }
                
                <div className='ms-2'>
                    <p className='text-bold mb-0'>{tObj.title}{tObj.date}</p> { tObj.description }
                
                { tObj.type === 'gp' &&
                    <a target="_blank" href="https://helsenorge.no" className='ps-4 text-left btn btn-between w-100 mt-2' rel="noopener noreferrer">Gå til Helsenorge.no
                    <FontAwesomeIcon icon={ faChevronRight } style={{color : '#fff', fontSize: '14px', float : 'right'}} className='mt-1 pe-2' />
                    </a>
                }

                {/* Power */}
                { tObj.type === 'power' &&
                <>
                    <div className='mt-2'>
                    <input value={'5 684 534'} type={'text'} className='form-control br-15 h-50px'/>
                    <a target="_blank" href="https://helsenorge.no" className='mt-2 btn btn-between w-100 ps-4 text-left' rel="noopener noreferrer">Angi målestand
                    <FontAwesomeIcon icon={ faChevronRight } style={{color : '#fff', fontSize: '14px', float : 'right'}} className='mt-1 pe-2' />
                    </a>
                    </div>
                </>
                
                }

                {/* gb */}
                { tObj.type === 'garbage' &&
                <>
                    {/* <div className='mt-2 row'>
                        <div className='col'>
                            <img src='/images/garbage2.png' width={'150px'} alt='Garbage' />
                        </div>
                        <div className='col pt-4'>
                            <ProgressBar progress={ 0 } classes="mt-3"/>
                            0% ferdig
                        </div>
                    </div> */}
                    <button className='btn btn-between w-100 mt-2 text-left ps-4'>Bestill søppeltjenester
                        <FontAwesomeIcon icon={ faChevronRight } style={{color : '#fff', fontSize: '14px', float : 'right'}} className='mt-1 pe-2' />
                    </button>
                </>
                }
                </div>
                </div>
        </div>
    );

    return (
        <div className='AppContainer bg-between-green'>
        <Navigation color='yellow' />
            <div className='pt-4'>        
                <h1 className='text-white'>Tidslinje</h1>
                <p className='text-white'>Under ser du en oversikt over viktige <br/> kommende hendelser i flyttingen din.</p>
                <div>
                    {/* <NavTab /> */}

                    <TabContent>
                        <TabPane>
                            <div className='timeline-container my-4'>
                                { timelineItems }
                            </div>
                        </TabPane>
                    </TabContent>
                </div>
            <Footer/>
        </div>
    </div>



    );
}