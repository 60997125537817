import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { Button, Col, FormGroup,  Label,  ListGroupItem,  ListGroupItemHeading,  ListGroupItemText,  Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { baseUrl, capsFirst, computeProgress, displayAddress, getHeaders } from '../../../helpers/Common';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { VerticalHeader } from "../../../components/layout/VerticalHeader";

const schema = yup.object().shape();
export const ProtocolStep6 = () => {

    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    const movingOrderId = sessionStorage.getItem('moving_order');
    const [memberItems, setMemberItems] = useState([]);
    const sellerAddress = sessionStorage.getItem('seller_address_object') ? sessionStorage.getItem('seller_address_object') : null;
    const invoiceAddress = sessionStorage.getItem('invoice_address_object') ? sessionStorage.getItem('invoice_address_object') : null;
    var sellerAddressFloor = sessionStorage.getItem('seller_address_floor') ? sessionStorage.getItem('seller_address_floor') : null;
    var sellerAddressRoom = sessionStorage.getItem('seller_address_room') ? sessionStorage.getItem('seller_address_room') : null;
    var invoiceAddressFloor = sessionStorage.getItem('invoice_address_floor') ? sessionStorage.getItem('invoice_address_floor') : null;
    var invoiceAddressRoom = sessionStorage.getItem('invoice_address_room') ? sessionStorage.getItem('invoice_address_room') : null;
    const [selectedSeller, setSelectedSeller] = useState(sessionStorage.getItem('invoice_receiver') ? JSON.parse(sessionStorage.getItem('invoice_receiver')) : 0);
    const [timer, setTimer] = useState(null);
    const [far, setFar] = useState([]);
    var far2 = [];
    const navigate = useNavigate();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      });

    const handleInputChange = (e) => {
        const value = e.target.value;
        const type  = e.target.dataset.type;
        const optionCont = document.getElementById(type+'-address-container');
        const optionList = document.getElementById(type+'-address-options');

        //hide and empty the options
        optionCont.classList.add('d-none');
        optionList.innerHTML = "<li>Laster...</li>";

        //search for address
        if(timer){
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() =>{
                if(value.length > 1){
                    optionCont.classList.remove('d-none');
                    // load options using API call
                    axios.post("https://addresses-microservices.betwn.no/rest-api/addresses/v1/address/search", 
                    { "address-search" : value } ).then(result => {
                        //remove loading message for the results
                        optionList.innerHTML = "";
                        if(result.data.status.code === '110'){
                            createAddressNode(type, e.target, optionList, null);
                        }else{
                            // return result.data.data.addresses;
                            const addresses = result.data.data.addresses;
                            for (var key in addresses) {
                                const address = addresses[key];
                                createAddressNode(type, e.target, optionList, address);
                            }
                            
                        }
                    })
    
                }
            }, 500)
        )
    }

    const handleAddressClick = (type, input, address, isNew = false) => {

        var inputField = document.getElementById(input.id);
        inputField.value = getAddressText(address);

        setValue('invoice_address', getAddressText(address));
        setValue('invoice_address_floor', null);
        setValue('invoice_address_room', null);

        if(isNew){
            invoiceAddressFloor = null;
            invoiceAddressRoom = null;
        }
        //save to session the selected address object
        sessionStorage.setItem('invoice_address_object', JSON.stringify(address));

        //check if there's floor and rooms;
        const addressId = address.id;
        axios.get("https://addresses-microservices.betwn.no/rest-api/addresses/v1/address/"+addressId+"/details").then(result => {
            const details = result.data.data.address_details.details.sections;
            var floorsAndRooms = [];
            var rooms  = [];
            var previousKey = null;
            const floorsAndRoomsCont = document.getElementById(type+"-floor-container");

            for (var key in details) {
                if(key.substring(0, 1) !== "U"  && key.substring(0, 1) !== "Z"){
                    const roomNumber = details[key].id;
                    var floorNumber = key.substring(2, 3);
                    const currentKey = floorNumber;

                    if(previousKey !== currentKey){
                        rooms = [roomNumber];
                        floorsAndRooms[floorNumber] = rooms;
                    }else{
                        rooms.push(roomNumber);
                    }
                    previousKey = currentKey;
                }
            };

            //add floor and rooms to state;
            // setFar(floorsAndRooms);
            far2 = floorsAndRooms;
            setFar(floorsAndRooms);
            
            //delay to wait for the state;
            setTimeout(function(){
                if(floorsAndRooms.length > 0){
                    floorsAndRoomsCont.classList.remove('d-none');
                    document.getElementById(type+'-floors').innerHTML = "";
                    document.getElementById(type+'-rooms').innerHTML = "";
                    
                    createFloorsNode(type, floorsAndRooms);

                    // const hideType = type === 'from' ? 'to' : 'from';
                    // clearRoomsAndFloors(hideType);
                }else{
                    floorsAndRoomsCont.classList.add('d-none');
                }
            }, 200);

        })


        //clear inputs
        clearInputs();
    }

    const createFloorsNode = (type, options) => {

        setValue('invoice_address_floor', '1');
        
        for (var key in options) {
            
            const floorSelect = document.getElementById(type+'-floors');

            var option = document.createElement('option');
            option.innerHTML = key;
            option.value = key;
            
            floorSelect.appendChild(option);
        }

        if(invoiceAddressFloor !== null){
            setValue('invoice_address_floor', invoiceAddressFloor);
            createRoomsNode(type, parseInt(invoiceAddressFloor));
        }else{
            createRoomsNode(type, 1);
        }

    }

    const createRoomsNode = (type, floor) => {
        
        setValue('invoice_address_room', 'H0101');
        //get rooms select
        const roomSelect = document.getElementById(type+'-rooms');
        
        far2[floor].forEach(element => {
            var option = document.createElement('option');
            option.innerHTML = element;
            
            roomSelect.appendChild(option);
        });

        if(invoiceAddressRoom){
            setValue('invoice_address_room', invoiceAddressRoom);
        }
    }

    const createAddressNode = (type, input, ul, address) => {
        var addressText = address ? getAddressText(address) : 'Adressen ble ikke funnet';
        var li = document.createElement("li");
        var text = document.createTextNode(addressText);

        if(address){
            li.onclick = () => handleAddressClick(type, input, address, true);
        }

        li.appendChild(text);
        ul.appendChild(li);
    }

    const clearInputs = () => {
        const inputFromContainer = document.getElementById('from-address-container');
        const inputFromOptions   = document.getElementById('from-address-options');
        inputFromContainer.classList.add('d-none');
        inputFromOptions.innerHTML = "";
    }

    const getAddressText = (address) => {
        return capsFirst(address.street) + ', ' + address.zipcode + ' '+capsFirst(address.place)
    }

    const handleChangeFloor = (e, type,  far) => {
        console.log( far[e.value] );
        setValue('invoice_address_room', far[e.value][0]);

        const floorsSelect = document.getElementById(type+'-rooms');
    
        //clear the floors
        floorsSelect.innerHTML = "";
    
        far[e.value].forEach(element => {
            var option = document.createElement('option');
            option.innerHTML = element;
            option.value = element;

            floorsSelect.appendChild(option);
        });
    }

    const onSubmitHandler = (data) => {
        sessionStorage.setItem('invoice_address_room', data.invoice_address_room);
        sessionStorage.setItem('invoice_address_floor', data.invoice_address_floor);
        sessionStorage.setItem('invoice_receiver', JSON.stringify(selectedSeller));

        console.log('nbext', selectedSeller);

        if(!protocolProgress.includes('step6')){
            protocolProgress.push('step6');
            sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
        }

        navigate('/protocol/step-7');
    }

    const fetchUsers = () => {
        axios.get(baseUrl()+'moving_orders/'+movingOrderId+'/users', getHeaders()
        ).then(response => {
            setMemberItems(response.data.data);

            if(selectedSeller === 0){
                setSelectedSeller(response.data.data[0]);
            }
        })
        .catch(error => console.error(error))
    }

    const handleSellerSelect = ( e ) => {
        setSelectedSeller(e);
    }

    useEffect(() => {
        fetchUsers();
    }, []);


    console.log(selectedSeller);


    const sellerItems = memberItems.map((memberObj, i) => {
        return <div key={i}>
                <ListGroupItem onClick={() => handleSellerSelect(memberObj) } className={`br-15 p-4 bg-light dark-gray mb-2 cursor-pointer ${selectedSeller.id !== memberObj.id && 'seller-disabled'}`}>
                    <ListGroupItemHeading className="font-18">
                        { memberObj.name }
                    </ListGroupItemHeading>
                    {selectedSeller.id === memberObj.id &&
                    <ListGroupItemText className="mb-0">
                        <img style={{right : 40, top : 40, position: 'absolute', width : '44px'}} src="/images/badge-check.png" alt="badge check" /> 
                    </ListGroupItemText>
                    }
                    <ListGroupItemText>
                        { invoiceAddress ? displayAddress(invoiceAddress) : displayAddress(sellerAddress)} 
                        {
                        ((invoiceAddressRoom !== null && invoiceAddressRoom !== 'null') || (sellerAddressRoom !== null && sellerAddressRoom !== 'null')) && 
                        <span className="ms-2">({invoiceAddressRoom || sellerAddressRoom})</span>
                        }
                    </ListGroupItemText>
                    {selectedSeller.id === memberObj.id &&
                    <ListGroupItemText>
                    Eller, annen adresse:
                        <FormGroup>
                            <input  
                            {...register("invoice_address")}
                                type='text' 
                                onChange={(e) => handleInputChange(e) } 
                                data-type='from' 
                                id='from-address-input'
                                className={`input-square form-control ${errors.invoice_address && 'is-invalid'}`}
                             />
                            <p className="form-error mb-0">{errors.invoice_address?.message}</p>

                            <div className='position-relative'>
                                <div className='address-options d-none position-absolute bg-light' id='from-address-container'>
                                    <ul id='from-address-options'></ul>
                                </div>
                            </div>
                            <div id="from-floor-container" className='mt-4 d-none'>
                                <div className='row'>
                                    <div className='col'>
                                        <select 
                                        {...register("invoice_address_floor")} 
                                        id='from-floors' 
                                        className='form-select input-square' 
                                        onChange={ (e) => handleChangeFloor(e.target, 'from', far) }>
                                            
                                        </select>
                                    </div>
                                    <div className='col'>
                                        <select 
                                        {...register("invoice_address_room")} 
                                        id='from-rooms' 
                                        className='form-select input-square'></select>
                                    </div>
                                </div>
                            </div>
                        </FormGroup>
                    </ListGroupItemText>   
                    }
                </ListGroupItem>
            </div>
        }) 
    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Ansvarlig for strømbestilling' progress={6} totalProgress={14} />

            <div className="pt-4">
                <h3 className="text-bold">Vennligst velg adresse du ønsker faktura sendt til</h3>
                <div className="mb-4 br-15">
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        { sellerItems }
                        <FormGroup className="mt-4 text-center">
                            <p className="f-14">Strøm blir bestilt på <b>{memberItems[selectedSeller]?.name}</b> ved bekreftelse av ansvarlig for strømbestillingen.</p>
                        </FormGroup>
                        <FormGroup className="px-5">
                            <Button type="submit" className="btn btn-between w-100">Neste</Button>
                        </FormGroup>
                    </form>    
                </div>
                <Row className="text-center mb-4 mx-5">
                    <Col>
                        <Link to={'/protocol/step-5'} className="btn-between-white" style={{maxWidth : '150px'}}>Tilbake</Link>
                    </Col>
                </Row>
                </div>
            <Footer/>

        </div>
    );
}