import { Col, Row, } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import { formatPrice } from "../../../../helpers/Common";


export const MovingBoxesPrices = ( props ) => {
    var   boxesStep2    = sessionStorage.getItem('boxes_step_2')   ? JSON.parse(sessionStorage.getItem('boxes_step_2')) : '';
    var   boxesStep2cp    = sessionStorage.getItem('boxes_step_2_size_objects')   ? JSON.parse(sessionStorage.getItem('boxes_step_2_size_objects')) : [];
    var   boxesStep3  = sessionStorage.getItem('boxes_step_3')   ? JSON.parse(sessionStorage.getItem('boxes_step_3')) : [];
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalVat, setTotalVat] = useState(0);

    console.log(boxesStep3);

    useEffect(() => {
        const prices = document.getElementsByClassName('prices');
        var tempPrice = 0;
        if(prices.length > 0){
            for (let index = 0; index < prices.length; index++) {
                const element = prices[index];
                const price = element.dataset.price;
                
                tempPrice = tempPrice + parseInt(price);
            }
            const vat = 0.25;
            const revenue = tempPrice;
            const revWithoutVat = revenue / (1 + vat);
            const tempVat = revenue - revWithoutVat;

            setTotalPrice(tempPrice);
            setTotalVat(tempVat);
            sessionStorage.setItem('boxes_total_items_price', tempPrice);
            sessionStorage.setItem('boxes_total_items_vat', tempVat);
        }

    }, []);
    return (
        <div className="my-4">
            <h3>{props.title ? props.title : 'Pris'}</h3>
            {boxesStep2.selected_product === 'packaged' ?
            
            <Row>
                <Col>
                Packaged Materials
                </Col>
                <Col className="text-bold text-right">
                <span className="prices" data-price='1899'>{formatPrice(1899)}</span>
                </Col>
            </Row>
            :
            boxesStep2cp.map((product, i) => {
            if(product.quantity !== 0){
                return <Row key={i}>
                    <Col>
                    {product.quantity} {product.title}
                    </Col>
                    <Col className="text-bold text-right d-flex align-items-center justify-content-end">
                        <span className="prices" data-price={product.subTotal ? product.subTotal : product.quantity * product.price}>
                            {product.subTotal ? formatPrice(product.subTotal) : formatPrice(product.quantity * product.price)}
                        </span>
                    </Col>
                </Row>
                }
            })
            }
            {boxesStep3 &&
            boxesStep3.additional_products.map((product, i) => {
                if(product.quantity !== 0){
                    return <Row key={i}>
                    <Col> {product.quantity !== 0 && product.quantity} {product.title} </Col>
                    <Col className="text-bold text-right d-flex align-items-center justify-content-end">
                        <span className="prices" data-price={product.subTotal ? product.subTotal: product.quantity * product.price}>
                            {product.subTotal ? formatPrice(product.subTotal) : formatPrice(product.quantity * product.price)}
                        </span>
                    </Col>
                </Row>
                }
            })
            }
            <hr />
            <Row>
                <Col><h4 className="mb-0">Totalt</h4></Col>
                <Col className="text-bold text-right">{formatPrice(totalPrice)}</Col>
            </Row>
            <Row className="f-14">
                <Col>Moms</Col>
                <Col className="text-bold text-right">{formatPrice(totalVat)}</Col>
            </Row>
        </div>
    );
}