import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { Button, Col, FormGroup,  Label,  Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { computeProgress } from '../../../helpers/Common';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import { VerticalHeader } from "../../../components/layout/VerticalHeader";

const schema = yup.object().shape({
    meter_reading: yup.string().required("Vennligst fyll ut feltet")
    .test('len', 'Må være 16 tegn', val => val.length >= 4),
    meter_number: yup.string().required("Vennligst fyll ut feltet")
    .test('len', 'Må være 16 tegn', val => val.length >= 4),
});

export const ProtocolStep4 = () => {

    const [isCommonMeter, setIsCommonMeter] = useState(sessionStorage.getItem('meter_check') && sessionStorage.getItem('meter_check') === 'yes' ? true : false);
    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    const navigate = useNavigate();

    console.log(sessionStorage.getItem('meter_check'));

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues : {
            meter_check : sessionStorage.getItem('meter_check') === 'yes' ? true : false,
            meter_reading : sessionStorage.getItem('meter_reading') ? sessionStorage.getItem('meter_reading') : '',
            meter_number : sessionStorage.getItem('meter_number') ? sessionStorage.getItem('meter_number') : '',
        }
    });
    
    const handleFormSubmit = (data) => {
        sessionStorage.setItem('meter_check', data.meter_check);
        sessionStorage.setItem('meter_reading', data.meter_reading);
        sessionStorage.setItem('meter_number', data.meter_number);

        nextStepProceed();
    }

    const handleCheckbox = ( event ) => {
        setIsCommonMeter(event.target.checked);
    }

    const handleNextStep = () => {
        sessionStorage.setItem('meter_check', 'yes');
        nextStepProceed();
    }


    const nextStepProceed = () => {
        if(!protocolProgress.includes('step4')){
            protocolProgress.push('step4');
            sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
        }
        
        navigate('/protocol/step-5');
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Avlesning av strømmåler' progress={4} totalProgress={14} />

            <div className="pt-4">

                <h4 className="text-bold mb-2">Strømmåler er avlest i fellesskap</h4>
                <p className="mb-0">Målernummeret kan inneholde inntil 16 siffer, og det er viktig at dere noterer hele nummeret.</p>
                <form onSubmit={handleSubmit( handleFormSubmit )} id='user-form'>
                    <FormGroup className="mt-2" >
                        <table>
                            <tbody>
                                <tr>
                                    <td><input onClick={ handleCheckbox } {...register("meter_check")} value='yes' type={'checkbox'} id='check-meter' /></td>
                                    <td className="d-flex align-items-center justify-content-center">
                                        <Label className="text-normal ms-1" for="check-meter">Strømanlegget har fellesmåling / Ingen måler</Label></td>
                                </tr>
                            </tbody>
                        </table>
                    </FormGroup>
                    {!isCommonMeter &&
                    <>
                    <FormGroup>
                        <Label for='meter_reading' className="mb-0">Målerstand</Label>
                        <input {...register("meter_reading")} className={`form-control input-square ${errors.meter_reading && 'is-invalid'}`} />
                        <p className="form-error">{errors.meter_reading?.message}</p>
                    </FormGroup>
                    <FormGroup>
                        <Label for='meter_number' className="mb-0">Målernummer</Label>
                        <input {...register("meter_number")} className={`form-control input-square ${errors.meter_number && 'is-invalid'}`} />
                        <p className="form-error">{errors.meter_number?.message}</p>
                    </FormGroup>
                    <FormGroup className="px-5">
                        <Button type="submit" className="btn btn-between w-100">Neste</Button>
                    </FormGroup>
                    </>
                    }

                </form>
                <Row className="text-center mb-4 mx-4">
                    <Col>
                        <Link to={'/protocol/step-3'} className="btn-between-white" style={{maxWidth : '150px'}}>Tilbake</Link>
                    </Col>
                    {isCommonMeter &&
                    <Col>
                        <Button onClick={ handleNextStep } className="btn-between w-100">Neste</Button>
                    </Col>
                    }
                </Row>
                </div>
            <Footer/>

        </div>
    );
}