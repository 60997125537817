import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Button, Col, Form, Label, Row } from "reactstrap";
import * as yup from "yup";
import { baseUrl, getHeaders } from "../../helpers/Common";




const schema = yup.object().shape({
    name: yup.string().required("Vennligst fyll ut feltet"),
    // birthdate: yup.string().required("Vennligst fyll ut feltet").test("birthdate", "Personen må være over 18 år", (value) => {
    //     return underAgeValidate(value) >= 18;
    //   }),
    birthdate: yup.string().required("Vennligst fyll ut feltet"),
    phone: yup.string().required("Vennligst fyll ut feltet").min(8, "Mobilnummer må være på minst 8 tegn").max(32, "Mobilnummer må være på maksimalt 32 tegn"),
    email: yup.string().required("Vennligst fyll ut feltet").email("Vennligst se over at informasjonen er riktig"),
  });
export const NewUserForm = ( props ) => {

    const { register, handleSubmit, reset, setError, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const handleSaveUser = (data) => {
        if(props.formDetails.action === 'new' && props.formDetails.type === "buyer"){
            props.handleUpdateBuyers(data);
        }else{
            axios.post(baseUrl()+'users', data, getHeaders()
            ).then(response => {
                if(response.data.status.code === "200"){
                    const newUserId  = response.data.data.id;
                    const movingOrderId = sessionStorage.getItem('moving_order');
                    const memberData = {moving_order: parseInt(movingOrderId), user: newUserId, role : 'editor'};
    
                    //link new user to membership
                    axios.post(baseUrl()+'memberships', memberData, getHeaders()
                    ).then(response => {
                        if(response.data.status.code === "200"){
                            props.fetchUsers();
                            props.setIsNewSeller(!props.isNewSeller);
                            reset();
                        }else{
                            Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                }else{
                    setError('email', { type: 'custom', message: response.data.status.message })
                    Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
                }
            }).catch(error => {
                console.error(error);
            });
        } 
    }

   return <Form onSubmit={handleSubmit(handleSaveUser)} id='user-form'>
        <Row>
            <Col xs={6}>
                <Label for="name" className="mb-0">Fornavn</Label>
                <input {...register("name")} type="text" className={`form-control input-square ${errors.name && 'is-invalid'}`} defaultValue={props.formData.name || ''}/>
                <p className="form-error">{errors.name?.message}</p>
            </Col>
            <Col xs={6}>
                <Label for="phone" className="mb-0">Telefon</Label>
                <input {...register("phone")} type="text" className={`form-control input-square ${errors.phone && 'is-invalid'}`} defaultValue={props.formData.phone || ''}/>
                <p className="form-error">{errors.phone?.message}</p>
            </Col>
        </Row>
        <Row className="mt-2">
            <Col xs={6}>
                <Label for="email" className="mb-0">E-post</Label>
                <input {...register("email")} type="email" className={`form-control input-square ${errors.email && 'is-invalid'}`} defaultValue={props.formData.email || ''}/>
                <p className="form-error">{errors.email?.message}</p>
            </Col>
            <Col xs={6}>
                <Label for="birthdate" className="mb-0">Fødselsdato</Label>
                <input {...register("birthdate")} type="date" className={`form-control input-square ${errors.birthdate && 'is-invalid'}`} defaultValue={props.formData.birthdate || ''}/>
                <p className="form-error">{errors.birthdate?.message}</p>
            </Col>
        </Row>
        <Row className="mt-4 text-center px-4">
            <Col>
                <Button className="btn btn-between-sm w-100" type="submit">Legg til</Button>
            </Col>
        </Row>
    </Form>
}