import React, { useState, useEffect } from 'react';
import { Tooltip, Accordion, AccordionBody, AccordionHeader, AccordionItem, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ButtonLottie } from '../../../../components/layout/ButtonLottie';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import no from "date-fns/locale/nb"; // the locale you want
import axios from 'axios';
import { baseUrl, getHeaders, getDeliverTimes, formatPrice } from '../../../../helpers/Common';
import { ProductImage } from '../../../../components/layout/ProductImage';
import moment from 'moment';
import { FAQProduct } from '../../../faqs/FAQProduct';
import { TooltipItem } from '../../../../components/layout/TooltipItem';

export const MovingOrderGarbageServices = () => {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [isLottie, setIsLottie] = useState(false);
    const [showProducts, isShowProducts] = useState(false);
    const [pickupDate, setPickupDate] = useState(new Date());
    const [numberOfDays, setNumberOfDays] = useState(1);
    const [deliveryTime, setDeliveryTime] = useState('8:00 - 10:00');
    const [providers, setProviders] = useState([]);
    const [category, setCategory] = useState(null);
    const [containerType, setContainerType] = useState(null);
    const [garbageServicesItems, getGarbageServicesItems] = useState(JSON.parse(sessionStorage.getItem('garbage_services')));   
    // const [open, setOpen] = useState('pos-0');
    const [open, setOpen] = useState(null);
    const [infoOpen, setInfoOpen] = useState('1');
    const [faqCategory, setFaqCategory] = useState(null);
    const [modalProduct, setModalProduct] = useState(null);

      
    const toggle = (id) => {
        open === id ? setOpen() : setOpen(id);
    };

    const infoToggle = (id) => {
        infoOpen === id ? setInfoOpen() : setInfoOpen(id);
    };
    // const toggleTooltip = (id) => {
    //     tooltipOpen === id ? setTooltipOpen() : setTooltipOpen(id);
    // };

    registerLocale("no", no); // register it with the name you want

    const isButtonSuccess = (e) => {
        const link      = e.currentTarget.dataset.link;
        const position  = e.currentTarget.dataset.position;
        const title     = e.currentTarget.dataset.title;
        const price     = e.currentTarget.dataset.price;
        const pid       = e.currentTarget.dataset.pid;
        const category  = e.currentTarget.dataset.category;
        const type      = e.currentTarget.dataset.type;

        setIsLottie(position);

        //add to session for future use
        sessionStorage.setItem('garbage_services', JSON.stringify({pid : pid, 
            pickup_date : pickupDate, 
            number_of_days : numberOfDays, 
            delivery_time : deliveryTime,
            title : title,
            position : position,
            category : category,
            type : type,
            price : price }))

        setTimeout(function(){
            navigate(link);
        }, 1200)
    }

    const toggle2 = (data) => {
        setModalProduct(data.product);

        setModal(!modal)
    };

    const handleClick = (data, type) => {

        //set product
        setFaqCategory(data.product);
        //empty providers before loading
        setProviders([]);
        
        const optionId = "options-"+type;
        var isActive = false;

        const activeElement = document.getElementById(optionId);
        var options = document.getElementsByClassName('options-white');

        if(!activeElement.classList.contains('active')){
            isActive = true;
        }

        //remove active classes to options
        for (let i = 0; i < options.length; i++) {
            options[i].classList.remove('active');                
        }

        //add active classes to selected option or not            
        if(isActive){
            activeElement.classList.add('active');
            isShowProducts(true);
        }else{
            isShowProducts(false);
        }

        setCategory(data.category);
        setContainerType(type);
        fetchProducts(data.category);
        setNumberOfDays(0);
        setOpen(null); 
        // setOpen('pos-0'); 

    }

    //FETCH PRODUCTS PENDING
    const fetchProducts = (category = null) => {
        axios.get(baseUrl()+'garbage_service_offers', getHeaders()
        ).then(response => {
            setProviders(providers => response.data.data.filter((obj) => {
                return category === obj.category;
            }));
        }) 
        .catch(error => console.error(error))
    }
     
    //users came from confirmation page
    useEffect(() => {
        console.log(garbageServicesItems);
        if(garbageServicesItems){

            fetchProducts(garbageServicesItems.category);
            document.getElementById('options-'+garbageServicesItems.type).classList.add('active');
            isShowProducts(true);

            setOpen(garbageServicesItems.position); 
            
            // setIsLottie(garbageServicesItems.position);
            
            var newDate = moment(garbageServicesItems.pickup_date).format("YYYY/MM/DD")
            setPickupDate(new Date(newDate));

            setDeliveryTime(garbageServicesItems.delivery_time);

            setNumberOfDays(parseInt(garbageServicesItems.number_of_days));

            setContainerType(garbageServicesItems.type);

        }
    }, []); 

    const handleNextStep = (e) => {
        navigate('/verticals/moving-order/garbage-services/complete-order');
    }

    const verticalItems = providers.map((vObj, i) => {
        const position = 'pos-'+i;
        return <AccordionItem className='products-vertical mb-4' key={position}>
            {/* <AccordionHeader targetId={position} className='products-vertical-header'>
                <p className='text-left w-100 mb-0 px-2 pt-2'>{vObj.title}</p>
                <div className='mt-0 pt-1 d-flex justtify-content-start w-100 px-2'>
                    <img src="/images/5stars.svg" alt='star img'/> 
                </div>
                <Row>
                    <Col className='d-flex align-items-center justify-content-center'>{vObj.supplier && <ProductImage image={vObj.supplier} className='w-100px' />}</Col>
                    <Col> 
                        <div className='mt-3'>
                            <p className='text-normal text-right mt-2'>250 verifiserte flytteoppdrag</p>
                            <p className='text-right f-20 mt-4 p-price'>Kr. {Math.round(vObj.price)},-</p>
                        </div>
                    </Col>
                </Row>    
                <button className='btn-between w-100 mb-4'>Velg</button>     
            </AccordionHeader> */}
            <AccordionHeader targetId={position} className='products-vertical-header'>
                <h4 className='text-left w-100 mb-0 px-2 pt-2'>{vObj.title}</h4>
                <div className='mt-0 pt-1 d-flex justtify-content-start w-100 px-2'>
                    <img src="/images/5stars.svg" alt='star img' className='w-85px'/> 
                </div>
                <Row className='w-100 pb-4'>
                    <Col className='d-flex align-items-center pt-min'>
                    {vObj.supplier && <ProductImage image={vObj.supplier} className='w-100px' />}
                    </Col>
                    <Col className='text-right'>
                        <p className='h4-regular text-right mb-0'>250 verifiserte flytteoppdrag</p>
                        <h3 className='text-right mt-2 p-price mb-0'>Kr. {formatPrice(vObj.price)},-</h3>
                    </Col>
                </Row>    
                {/* <button className='btn-between w-100 mb-4'>Velg</button>      */}
            </AccordionHeader>



            <AccordionBody accordionId={position} className='pt-0'>
                <div className='equipment-item'>
                    <p>{ vObj.short_description }</p>
                    
                    <FAQProduct product={faqCategory}/>
                    <p className='mb-2 mt-4 text-bold'>Ønsket dato</p>
                    <div className='text-center'>
                        <DatePicker formatWeekDay={nameOfDay => nameOfDay.substr(0,3)}  minDate={moment().toDate()} disabledKeyboardNavigation locale="no" selected={pickupDate} onChange={(date:Date) => setPickupDate(date)} inline />
                    </div>

                    { category !== 'plass i en varebil' &&
                    <>
                    <p className='mb-2 mt-4 text-bold'>Antall dager du ØNSKER Å LEIE</p>
                    <Input type='select' className='br-min' onChange={(e) => setNumberOfDays(e.target.value)} defaultValue={numberOfDays}>
                        <option selected value={1}>1 dag</option>
                        <option value={2}>2 dager</option>
                        <option value={3}>3 dager</option>
                    </Input>
                    </>
                    }
                    <p className='mb-2 mt-4 text-bold'>Ønsket leveransetidspunkt</p>
                    <Input type='select' className='br-min' onChange={(e) => setDeliveryTime(e.target.value)} defaultValue={deliveryTime}>
                        {getDeliverTimes().map((times, key) => 
                            <option value={ times } key={key}>Mellom { times }</option>
                        )}
                    </Input>

                    <p className='text-right f-20 text-bold my-5'>Kr. { formatPrice(vObj.price)},-</p>

                    <div className='mt-4 mb-2'>
                        <Button onClick={ isButtonSuccess } 
                            data-link="/verticals/moving-order/garbage-services/complete-order" 
                            className='btn-between w-100' 
                            data-position={position}
                            data-title={ vObj.title }
                            data-category={ vObj.category }
                            data-type={ containerType }
                            data-price={ vObj.price }
                            data-pid={ vObj.id }>
                            { isLottie === position ? 
                            <ButtonLottie button='Bestilt' left='80px'/> : 
                            <h4 id={'btn-text-'+position} className='mb-0'>Bestill</h4>
                            }
                        </Button>
                    </div>
                </div>
            </AccordionBody>
        </AccordionItem>
        }
    );
    
    return (
        <div className='AppContainer bg-white'>
                <Navigation />
                <div className='pt-4'>
                    {/* <img src='/images/Between_moving_van.png' alt='between icon hello'
                        style={{position : "absolute",
                        width : "200px",
                        right : -23,
                        top : 145}} /> */}
                    <p className='p-header position-relative'>Søppeltjenester</p>
                    <ProgressBar progress="50" classes="my-3"/>
                    <p className='text-bold'>Steg 1/2</p>
                    {/* <p className='my-4 position-relative'>
                    Vi hjelper deg finne den <br/> beste søppeltjenesten <br/>  for dinbolig
                    </p> */}
                </div>
                <hr className='fluid' />
                <div className='container-white py-4'>
                    <p className='f-20 text-bold'>Velg blant flere leverandører</p>

                    <div className='px-2'>
                        {/* <p className='text-bold'>Velg søppeltjeneste</p> */}
                        <Row>
                            <Col>
                                <div className='options-white p-3' id='options-car'>
                                        <img src='/images/truck-icon.png' style={{width: '80px'}} data-product='garbage_taxi' data-category='plass i en varebil' onClick={((e) => handleClick(e.target.dataset, 'car'))}/>
                                    <p className='f-14 text-bold mb-0 px-0'>Søppeltaxi</p>
                                    <button className='btn-between-xs mt-2 px-0' data-product='Søppeltaxi' onClick={(e) => toggle2(e.target.dataset)}>Informasjon</button>
                                </div>
                            </Col>
                            <Col>
                                <div className='options-white p-3' id='options-bag' >
                                    <img src='/images/garbage-bag-icon.png' style={{width: '80px'}} data-product='garbage_bag' data-category='avfallsekk' onClick={((e) => handleClick(e.target.dataset, 'bag'))}/>
                                    <p className='f-14 text-bold mb-0'>Avfalllsekk </p>
                                    <button className='btn-between-xs mt-2 px-0' data-product='Avfallssekk' onClick={(e) => toggle2(e.target.dataset)}>Informasjon</button>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <div className='options-white p-3' id='options-container'>
                                    <Row>
                                        <Col>
                                            <img src='/images/container-icon.png' style={{width: '80px'}} data-product='container' data-category='container' onClick={((e) => handleClick(e.target.dataset, 'container'))}/>
                                        </Col>
                                        <Col>
                                            <p className='f-14 text-bold mb-0'>Container</p>
                                            <button className='btn-between-xs mt-2' data-product='Container' onClick={(e) => toggle2(e.target.dataset)}>Informasjon</button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    { showProducts ? 
                        <>
                        <Accordion open={open} className='pt-4' toggle={toggle}>
                            {verticalItems}
                        </Accordion>
                        {garbageServicesItems &&
                        <>
                        <hr className='fluid' />
                        <div className='mx-4 mt-4'>
                            <button className='w-100 btn-between' onClick={ handleNextStep }>Gå videre</button>
                        </div>
                        </>
                        }
                        </>
                        : 
                        <div className='py-2'>&nbsp;</div>
                    }


               
                </div>
                <Footer/>

                <Modal isOpen={modal} toggle={toggle2}>
                    <ModalHeader className='d-block p-4'>
                        <span>{modalProduct}</span>
                        <button className='btn-close float-end pt-3' onClick={ toggle2 }></button>
                    </ModalHeader>
                    <ModalBody className='p-4'>
                        { modalProduct === 'Container' ? 
                        <>
                        <Accordion open={infoOpen} className='mb-2' key={'1'} toggle={infoToggle}>
                            <AccordionItem key={'1'} className='vertical-classic p-2'>
                                <AccordionHeader targetId={'1'}> <span>Viktig informasjon</span> </AccordionHeader>
                                <AccordionBody accordionId={'1'}>
                                        <ul>
                                            <li>Containeren er 10m3 og er den mest populære typen container. <span className='dark-link text-underline' id="tooltipContainer">Se bilde her.</span></li>
                                            <li>Denne containertypen kjøres ut med liftbil <span className='dark-link text-underline' id="tooltipContainer2">(se bilde av liftbil her)</span>. Bilen trenger 10 meter for å sette fra seg containeren. Bredde på eventuelle porter/passasjer må være minst 3 meter, og takhøyde minst 4 meter.</li>
                                            <li>Ikke fyll containeren over kanten.</li>
                                            <li>Kun beregnet for blandet avfall. Se beskrivelse av avfall som ikke kan kastes under.</li>
                                            <li>Du trenger ikke å være tilstede når vi henter sekken, såfremt sekken er synlig og at kranbil enkelt kommer til.</li>
                                        </ul>
                                        <TooltipItem src='/images/containere-hero.webp' id='tooltipContainer'></TooltipItem>
                                        <TooltipItem src='/images/container2.webp' id='tooltipContainer2'></TooltipItem>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem key={'2'} className='vertical-classic p-2 mt-4'>
                                <AccordionHeader targetId={'2'}> <span>Dette kan du ikke kaste i containeren</span> </AccordionHeader>
                                <AccordionBody accordionId={'2'}>
                                    <ul>
                                        <li>Avfall i fargetette avfallssekker</li>
                                        <li>Farlig avfall (maling, batterier, impregnert trevirke o.l.)</li>
                                        <li>Elektrisk og elektronisk avfall (alt som går på strøm eller batteri)</li>
                                        <li>Dekk</li>
                                        <li>Matavfall</li>
                                        <li>Flytende avfall</li>
                                        <li>Rene masser (fliser, jord, stein, tegl, takstein, fyllmasse, eller betong)</li>
                                        <li>Forurensede masser</li>
                                        <li>Gips</li>
                                        <li>Isolasjon</li>
                                    </ul>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                        </>
                        : modalProduct === 'Søppeltaxi'?
                        <>
                        
                        <Accordion open={infoOpen} className='mb-2' key={'1'} toggle={infoToggle}>
                            <AccordionItem key={'1'} className='vertical-classic p-2'>
                                <AccordionHeader targetId={'1'}> <span>Hva er søppeltaxi?</span> </AccordionHeader>
                                <AccordionBody accordionId={'1'}>
                                    <p>Søppeltaxi er en tjeneste der vi kommer hjem til deg og henter ditt avfall. Søppeltaxien henter, sorterer og kjører bort alt type avfall, slik at du slipper å tenke på noe som helst. Taxien kjører til nærmeste miljøstasjon og sorterer avfallet der.</p>
                                    <p>Det er mulig å kvitte seg med avfall som veier opp til cirka 800 kilo, hvilket tilsvarer cirka 8m3. <span className='dark-link text-underline cursor-pointer' id="tooltipTaxi">Se bilde her</span></p>
                                    <TooltipItem src='/images/taxi.png' id='tooltipTaxi'></TooltipItem>
                                </AccordionBody>
                            </AccordionItem>       
                            <AccordionItem key={'2'} className='vertical-classic p-2 mt-4'>
                                <AccordionHeader targetId={'2'}> <span>Viktig informasjon</span> </AccordionHeader>
                                <AccordionBody accordionId={'2'}>
                                    <ul>
                                        <li>Total pris for søppeltaxi beregnes etter utført oppdrag på grunn av behov for å vite mengde søppel. Vi har prisgaranti på søppeltaxi, og garanterer laveste pris, eller så betaler vi mellomlegget.</li>
                                        <li>Det eneste du må gjøre er å gi tilgang på det avfallet som skal kastet. Er avfallet tilgjengelig trenger du ikke være til stede. Har du informasjon eller ønsker å gjøre en avtale kan du alltid kontakte våre flyttekonsulenter.</li>
                                        <li>Sorte sekker har blitt forbudt på avfallsmottak i Norge. Derfor er det viktig at du eventuelt bruker gjennomsiktig sekk. </li>
                                        <li>Søppeltaxien henter alt mulig av avfall. Bæring av for eksempel møblement ut av bolig er også inkludert i prisen.</li>
                                    </ul>
                                </AccordionBody>
                            </AccordionItem>                      
                        </Accordion>
                    </>
                        :
                        <>
                            <Accordion open={infoOpen} className='mb-2' key={'1'} toggle={infoToggle}>
                                <AccordionItem key={'1'} className='vertical-classic p-2'>
                                    <AccordionHeader targetId={'1'}> <span>Hva er en avfallsekk?</span> </AccordionHeader>
                                    <AccordionBody accordionId={'1'}>
                                        <p>Avfallsekker passer veldig godt til kasting av restavfall i forbindelse med flytting - og der en container er for stor. Avfallsekkene kommer i 3 ulike størrelser:</p>
                                        <ul>
                                            <li>Tralle (160L)
                                                <span className='dark-link text-underline ms-2 cursor-pointer' id="tooltipSmall">Se bilde her</span>
                                            </li>
                                            <li>Medium (800L)
                                                <span className='dark-link text-underline ms-2 cursor-pointer' id="tooltipMedium">Se bilde her</span>
                                            </li>
                                            <li>Stor (2200L) 
                                                <span className='dark-link text-underline ms-2 cursor-pointer' id="tooltipBig">Se bilde her</span>
                                            </li>
                                        </ul>
                                        <TooltipItem src='/images/smartbag_small.jpeg' id='tooltipSmall'></TooltipItem>
                                        <TooltipItem src='/images/smartbag_medium.jpeg' id='tooltipMedium'></TooltipItem>
                                        <TooltipItem src='/images/smartbag_big.jpeg' id='tooltipBig'></TooltipItem>
                                    </AccordionBody>
                                </AccordionItem>  
                                <AccordionItem key={'3'} className='vertical-classic p-2 mt-4'>
                                    <AccordionHeader targetId={'3'}> <span>Viktig informasjon</span> </AccordionHeader>
                                    <AccordionBody accordionId={'3'}>
                                        <ul>
                                            <li>Vi sender avfallsekken til deg i posten. Du kan ha sekken så lenge du ønsker, og en kranbil plukker den opp på valgt hentedato. Vi anbefaler at du begrenser tiden avfallsekken står ute med avfall i – da dette kan hindre mobilitet spesielt i byene.</li>
                                            <li>Du kan kun fylle blandet avfall i sekken. Se beskrivelse av avfall som ikke kan kastes under.</li>
                                            <li>Du trenger ikke å være tilstede når vi henter sekken, såfremt sekken er synlig og at kranbil enkelt kommer til.</li>
                                        </ul>
                                    </AccordionBody>
                                </AccordionItem> 
                                <AccordionItem key={'4'} className='vertical-classic p-2 mt-4'>
                                    <AccordionHeader targetId={'4'}> <span>Dette kan du ikke kaste i sekken</span> </AccordionHeader>
                                    <AccordionBody accordionId={'4'}>
                                        <ul>
                                            <li>Avfall i fargetette avfallssekker</li>
                                            <li>Farlig avfall (maling, batterier, impregnert trevirke o.l.)</li>
                                            <li>Elektrisk og elektronisk avfall (alt som går på strøm eller batteri)</li>
                                            <li>Dekk</li>
                                            <li>Matavfall</li>
                                            <li>Flytende avfall</li>
                                            <li>Rene masser (fliser, jord, stein, tegl, takstein, fyllmasse, eller betong)</li>
                                            <li>Forurensede masser</li>
                                            <li>Gips</li>
                                            <li>Isolasjon</li>
                                        </ul>
                                    </AccordionBody>
                                </AccordionItem>  
                            </Accordion>
                        </>
                        }
                        <center className='mt-5'>
                            <button className='btn-between w-190' onClick={ toggle2 }>Lukk</button>
                        </center>
                    </ModalBody>
                </Modal>
            </div>
        );
}