import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { VerticalHeader } from "../../../components/layout/VerticalHeader";

const schema = yup.object().shape({
    retention: yup.mixed().oneOf(['Intet tilbakehold', 'Partene er enige om tilbakehold', 'Kjøper krever tilbakehold'], 'Vennligst velg 1 alternativ.')
});
export const ProtocolStep11 = () => {

    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    var proStep11  = sessionStorage.getItem('protocol_step_11') ? JSON.parse(sessionStorage.getItem('protocol_step_11')) : {};
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues : {
            retention : proStep11.retention ? proStep11.retention : false,
        }
    });
    
    const handleFormSubmit = (data) => {

        proStep11 = data;
        sessionStorage.setItem('protocol_step_11', JSON.stringify(proStep11));
        if(!protocolProgress.includes('step11')){
            protocolProgress.push('step11');
            sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
        }

        navigate('/protocol/step-12');
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Tilbakehold' progress={11} totalProgress={14} />
            
            <div className="pt-4">
                <form onSubmit={handleSubmit( handleFormSubmit )}>
                    <FormGroup check className="ps-0">
                        <input type="radio" {...register("retention")} value="Intet tilbakehold" id="no_retention" />
                        <Label check className="mb-0 text-normal ms-2" for='no_retention'>Intet tilbakehold</Label>
                    </FormGroup>
                    <FormGroup check className="ps-0">
                        <input type="radio" {...register("retention")} value="Partene er enige om tilbakehold" id="with_retention" />
                        <Label check className="mb-0 text-normal ms-2" for='with_retention'>Partene er enige om tilbakehold</Label>
                    </FormGroup>
                    <FormGroup check className="ps-0 is-invalid">
                        <input type="radio" {...register("retention")} value="Kjøper krever tilbakehold" id="require_retention" />
                        <Label check className="mb-0 text-normal ms-2" for='require_retention'>Kjøper krever tilbakehold</Label>
                    </FormGroup>
                    
                    {errors.retention &&
                    <FormGroup className="ms-0">
                        <p className="form-error ms-0">{errors.retention?.message}</p>
                    </FormGroup>
                    }
                    <FormGroup className="px-5 mt-4">
                        <Button type="submit" className="btn btn-between w-100">Neste</Button>
                    </FormGroup>
                    <FormGroup>
                    </FormGroup>
                </form>
                <Row className="text-center mb-4 mt-2 mx-5">
                    <Col>
                        <Link to={'/protocol/step-10'} className="btn-between-white" style={{maxWidth : '150px'}}>Tilbake</Link>
                    </Col>
                </Row>
                </div>
            <Footer/>
        </div>
    );
}