import React from 'react';
import {  Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ConfirmationLayout } from '../../../../components/layout/ConfirmationLayout';

export const AddressAndPostMailboxConfirm = () => {

        return (
            <ConfirmationLayout>
                <p className='f-30 mt-4'>Bestilling <br/> gjennomført!</p>
                <p className='mt-4 mb-5'>For detaljer, se alle bestillinger på <br/> min side.   </p>

                <Row>
                    {/* <Col>
                        <Link replace to="/verticals/address-and-post" className='btn-between w-100'>Hjem</Link>
                    </Col> */}
                    <Col className='px-4'>
                        <Link replace to="/tasks" className='btn-between w-100'>Gå tilbake til oversikten</Link>
                    </Col>
                </Row>
            </ConfirmationLayout>
        );
}