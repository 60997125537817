import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Login } from '../pages/auth/Login';
import  Register  from '../pages/auth/Register';
import { ProtectedRoute } from "../pages/auth/ProtectedRoute";
import { UnprotectedRoute } from "../pages/auth/UnprotectedRoute";

import { RegistrationAddress } from '../pages/registration/RegistrationAddress';
import { RegistrationPeopleList } from '../pages/registration/RegistrationPeopleList';
import { RegistrationEditPerson } from '../pages/registration/RegistrationEditPerson';
import { RegistrationNewPersonKid } from '../pages/registration/RegistrationNewPersonKid';
import { RegistrationNewPersonAdult } from '../pages/registration/RegistrationNewPersonAdult';

import { TaskHome } from '../pages/tasks/TaskHome';
import { Dashboard } from '../pages/Dashboard';
import { Timeline } from '../pages/timeline/Timeline';

import { MovingOrderHome } from '../pages/verticals/moving-orders/MovingOrderHome';
import { MovingOrderEquipments } from '../pages/verticals/moving-orders/moving-equipments/MovingOrderEquipments';
import { MovingOrderEquipmentsCompleteOrder } from '../pages/verticals/moving-orders/moving-equipments/MovingOrderEquipmentsCompleteOrder';
import { MovingOrderEquipmentsConfirm } from '../pages/verticals/moving-orders/moving-equipments/MovingOrderEquipmentsConfirm';
import { MovingOrderCleaningMaterials } from '../pages/verticals/moving-orders/cleaning-materials/MovingOrderCleaningMaterials';
import { MovingOrderCleaningMaterialsCompleteOrder } from '../pages/verticals/moving-orders/cleaning-materials/MovingOrderCleaningMaterialsCompleteOrder';
import { MovingOrderCleaningMaterialsConfirm } from '../pages/verticals/moving-orders/cleaning-materials/MovingOrderCleaningMaterialsConfirm';
import { MovingOrderGarbageServices } from '../pages/verticals/moving-orders/garbage-services/MovingOrderGarbageServices';
import { MovingOrderGarbageServicesSelect } from '../pages/verticals/moving-orders/garbage-services/MovingOrderGarbageServicesSelect';
import { MovingOrderGarbageServicesCompleteOrder } from '../pages/verticals/moving-orders/garbage-services/MovingOrderGarbageServicesCompleteOrder';
import { MovingOrderGarbageServicesConfirm } from '../pages/verticals/moving-orders/garbage-services/MovingOrderGarbageServicesConfirm';

import { MovingLoadHome } from '../pages/verticals/moving-load/MovingLoadHome';
import { MovingLoadCompanyStep1 } from '../pages/verticals/moving-load/company/MovingLoadCompanyStep1';
import { MovingLoadCompanyStep2 } from '../pages/verticals/moving-load/company/MovingLoadCompanyStep2';
import { MovingLoadCompanyStep3 } from '../pages/verticals/moving-load/company/MovingLoadCompanyStep3';
import { MovingLoadCompanyCompleteOrder } from '../pages/verticals/moving-load/company/MovingLoadCompanyCompleteOrder';
import { MovingLoadCompanyConfirm } from '../pages/verticals/moving-load/company/MovingLoadCompanyConfirm';

import { MovingCompanyHome } from '../pages/verticals/moving-company/MovingCompanyHome';
import { MovingCompanyPower } from '../pages/verticals/moving-company/power/MovingCompanyPower';
import { MovingCompanyPowerCompleteOrder } from '../pages/verticals/moving-company/power/MovingCompanyPowerCompleteOrder';
import { MovingCompanyPowerConfirm } from '../pages/verticals/moving-company/power/MovingCompanyPowerConfirm';
import { MovingCompanyInsurance } from '../pages/verticals/moving-company/insurance/MovingCompanyInsurance';
import { MovingCompanyInsuranceConfirm } from '../pages/verticals/moving-company/insurance/MovingCompanyInsuranceConfirm';

import { AddressAndPost } from '../pages/verticals/address-and-post/AddressAndPost';
import { AddressAndPostRelocation } from '../pages/verticals/address-and-post/relocation/AddressAndPostRelocation';
import { AddressAndPostRelocationCompleteOrder } from '../pages/verticals/address-and-post/relocation/AddressAndPostRelocationCompleteOrder';
import { AddressAndPostRelocationConfirm } from '../pages/verticals/address-and-post/relocation/AddressAndPostRelocationConfirm';
import { AddressAndPostMailbox } from '../pages/verticals/address-and-post/mailbox/AddressAndPostMailbox';
import { AddressAndPostMailboxCompleteOrder } from '../pages/verticals/address-and-post/mailbox/AddressAndPostMailboxCompleteOrder';
import { AddressAndPostMailboxConfirm } from '../pages/verticals/address-and-post/mailbox/AddressAndPostMailboxConfirm';

import { ProfileHome } from '../pages/profile/ProfileHome';
import { ProfileOrdersAll } from '../pages/profile/orders/ProfileOrdersAll';
import { ProfileOrderDetails } from '../pages/profile/orders/ProfileOrderDetails';

import { SettingsHome } from '../pages/settings/SettingsHome';
import { SettingsAddress } from '../pages/settings/SettingsAddress';
import { SettingsTerms } from '../pages/settings/SettingsTerms';
import { SettingsPrivacy } from '../pages/settings/SettingsPrivacy';
import { SettingsUsers } from '../pages/settings/SettingsUsers';
import { SettingsUserEdit } from '../pages/settings/SettingsUserEdit';
import { SettingsUserAddAdult } from '../pages/settings/SettingsUserAddAdult';
import { SettingsUserAddMinor } from '../pages/settings/SettingsUserAddMinor';

import { ProtocolStep1 } from '../pages/verticals/protocol/ProtocolStep1';
import { Contract } from '../pages/verticals/contract/Contract';

import { FAQs } from '../pages/faqs/FAQs';
import { FAQDetails } from '../pages/faqs/FAQDetails';

import { PageNotFound } from '../components/layout/PageNotFound';
import { ValidateRoute } from '../pages/auth/ValidateRoute';
import { Checklist } from '../pages/verticals/checklist/Checklist';
import { Neighbor } from '../pages/verticals/neighbor/Neighbor';
import { Tips } from '../pages/verticals/tips/Tips';
import { ChecklistDetails } from '../pages/verticals/checklist/ChecklistDetails';
import { TipsDetails } from '../pages/verticals/tips/TipsDetails';
import { Upgrades } from '../pages/verticals/upgrades/Upgrades';
import Invitation from '../pages/auth/Invitation';
import CreatePassword from '../pages/auth/CreatePassword';
import { RegistrationToAddress } from '../pages/registration/RegistrationToAddress';
import { RegistrationNewAddress } from '../pages/registration/RegistrationNewAddress';
import ScrollToTop from '../components/utils/ScrollToTop';
import { MovingCompanyPowerChecker } from '../pages/verticals/moving-company/power/MovingCompanyPowerChecker';
import { Protocol } from '../pages/verticals/protocol/Protocol';
import { ProtocolStep2 } from '../pages/verticals/protocol/ProtocolStep2';
import { ProtocolStep3 } from '../pages/verticals/protocol/ProtocolStep3';
import { ProtocolStep4 } from '../pages/verticals/protocol/ProtocolStep4';
import { ProtocolStep5 } from '../pages/verticals/protocol/ProtocolStep5';
import { ProtocolStep6 } from '../pages/verticals/protocol/ProtocolStep6';
import { ProtocolStep7 } from '../pages/verticals/protocol/ProtocolStep7';
import { ProtocolStep8 } from '../pages/verticals/protocol/ProtocolStep8';
import { ProtocolStep9 } from '../pages/verticals/protocol/ProtocolStep9';
import { ProtocolStep10 } from '../pages/verticals/protocol/ProtocolStep10';
import { ProtocolStep11 } from '../pages/verticals/protocol/ProtocolStep11';
import { ProtocolStep12 } from '../pages/verticals/protocol/ProtocolStep12';
import { ProtocolStep13 } from '../pages/verticals/protocol/ProtocolStep13';
import { ProtocolCompleteOrder } from '../pages/verticals/protocol/ProtocolCompleteOrder';
import { ProtocolConfirm } from '../pages/verticals/protocol/ProtocolConfirm';

import { MovingBoxesStep1 } from '../pages/verticals/moving-orders/moving-boxes/MovingBoxesStep1';
import { MovingBoxesStep2 } from '../pages/verticals/moving-orders/moving-boxes/MovingBoxesStep2';
import { MovingBoxesStep3 } from '../pages/verticals/moving-orders/moving-boxes/MovingBoxesStep3';
import { MovingBoxesStep4 } from '../pages/verticals/moving-orders/moving-boxes/MovingBoxesStep4';
import { MovingBoxesCompleteOrder } from '../pages/verticals/moving-orders/moving-boxes/MovingBoxesCompleteOrder';
import { MovingBoxesStatus } from '../pages/verticals/moving-orders/moving-boxes/MovingBoxesStatus';
import { MovingBoxesPayment } from '../pages/verticals/moving-orders/moving-boxes/MovingBoxesPayment';
import { MovingBoxesConfirm } from '../pages/verticals/moving-orders/moving-boxes/MovingBoxesConfirm';

const AllRoutes = () => {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route element={<UnprotectedRoute />}>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path ="/register" element={ <Register/> } />
                <Route path ="/invitation" element={ <Invitation/> } />
                <Route path ="/create-password" element={ <CreatePassword/> } />
                </Route>

                {/* Protected Routes */}
                <Route element={<ProtectedRoute />}>
                {/* Registration process */}
                <Route path ="/registration/address" element={ <RegistrationAddress /> } />
                {/* <Route path ="/registration/address" element={ <RegistrationNewAddress /> } /> */}
                {/* <Route path ="/registration/from-address" element={ <RegistrationFromAddress /> } /> */}
                <Route path ="/registration/from-address" element={ <RegistrationNewAddress /> } />
                <Route path ="/registration/to-address" element={ <RegistrationToAddress /> } />
                <Route path ="/registration/users" element={ <RegistrationPeopleList/> } />
                <Route path ="/registration/person/new/under-18" element={ <RegistrationNewPersonKid/> } />
                <Route path ="/registration/person/new/over-18" element={ <RegistrationNewPersonAdult/> } />
                <Route path ="/registration/user/edit/:id" element={ <RegistrationEditPerson/> } />

                {/* Verticals */}
                <Route path ="/dashboard" element={ <Dashboard/> } />
                <Route path ="/tasks" element={ <TaskHome/> } />
                <Route path ="/timeline" element={ <Timeline/> } />

                {/* Equipment Materials */}
                <Route path ="/verticals/moving-order/home" element={ <MovingOrderHome/> } />
                <Route element={<ValidateRoute type='material' />}>  
                    <Route path ="/verticals/moving-order/equipments" element={ <MovingOrderEquipments/> } />
                    <Route path ="/verticals/moving-order/equipments/complete-order" element={ <MovingOrderEquipmentsCompleteOrder/> } />
                    <Route path ="/verticals/moving-order/equipments/confirm" element={ <MovingOrderEquipmentsConfirm/> } />
                    <Route path ="/verticals/moving-order/boxes/step-1" element={ <MovingBoxesStep1/> } />
                    <Route path ="/verticals/moving-order/boxes/step-2" element={ <MovingBoxesStep2/> } />
                    <Route path ="/verticals/moving-order/boxes/step-3" element={ <MovingBoxesStep3/> } />
                    <Route path ="/verticals/moving-order/boxes/step-4" element={ <MovingBoxesStep4/> } />
                    <Route path ="/verticals/moving-order/boxes/complete-order" element={ <MovingBoxesCompleteOrder/> } />
                    <Route path ="/verticals/moving-order/boxes/status" element={ <MovingBoxesStatus/> } />
                    <Route path ="/verticals/moving-order/boxes/payment" element={ <MovingBoxesPayment/> } />
                    <Route path ="/verticals/moving-order/boxes/confirm" element={ <MovingBoxesConfirm/> } />
                </Route>

                {/* Cleaning Materials */}
                
                <Route element={<ValidateRoute type='cleaning' />}>  
                    <Route path ="/verticals/moving-order/cleaning-materials" element={ <MovingOrderCleaningMaterials/> } />
                    <Route path ="/verticals/moving-order/cleaning-materials/complete-order" element={ <MovingOrderCleaningMaterialsCompleteOrder/> } />
                    <Route path ="/verticals/moving-order/cleaning-materials/confirm" element={ <MovingOrderCleaningMaterialsConfirm/> } />
                </Route>
                
                {/* Garbage services */}
                <Route element={<ValidateRoute type='garbage' />}>  
                    <Route path ="/verticals/moving-order/garbage-services" element={ <MovingOrderGarbageServices/> } />
                    <Route path ="/verticals/moving-order/garbage-services/select" element={ <MovingOrderGarbageServicesSelect/> } />
                    <Route path ="/verticals/moving-order/garbage-services/complete-order" element={ <MovingOrderGarbageServicesCompleteOrder/> } />
                    <Route path ="/verticals/moving-order/garbage-services/confirm" element={ <MovingOrderGarbageServicesConfirm/> } />
                </Route>

                {/* Moving companies */}
                <Route path ="/verticals/moving-company/home" element={ <MovingCompanyHome/> } />
                
                <Route element={<ValidateRoute type='power' />}>  
                    <Route path ="/verticals/moving-company/power/check" element={ <MovingCompanyPowerChecker/> } />
                    <Route path ="/verticals/moving-company/power" element={ <MovingCompanyPower/> } />
                    <Route path ="/verticals/moving-company/power/complete-order" element={ <MovingCompanyPowerCompleteOrder/> } />
                    <Route path ="/verticals/moving-company/power/confirm" element={ <MovingCompanyPowerConfirm/> } />
                </Route>
                <Route element={<ValidateRoute type='insurance' />}>  
                    <Route path ="/verticals/moving-company/insurance" element={ <MovingCompanyInsurance/> } />
                    <Route path ="/verticals/moving-company/insurance/confirm" element={ <MovingCompanyInsuranceConfirm/> } />
                </Route>

                {/* Moving services */}
                <Route path ="/verticals/moving-load/home" element={ <MovingLoadHome/> } />
                
                <Route element={<ValidateRoute type='relocation' />}>  
                    <Route path ="/verticals/moving-load/company/step-1" element={ <MovingLoadCompanyStep1/> } />
                    <Route path ="/verticals/moving-load/company/step-2" element={ <MovingLoadCompanyStep2/> } />
                    <Route path ="/verticals/moving-load/company/step-3" element={ <MovingLoadCompanyStep3/> } />
                    <Route path ="/verticals/moving-load/company/complete-order" element={ <MovingLoadCompanyCompleteOrder/> } />
                    <Route path ="/verticals/moving-load/company/confirm" element={ <MovingLoadCompanyConfirm/> } />
                </Route>
                {/* Address change and Postbox */}
                <Route path ="/verticals/address-and-post" element={ <AddressAndPost/> } />
                
                <Route element={<ValidateRoute type='address_change' />}>  
                    <Route path ="/verticals/address-and-post/relocation" element={ <AddressAndPostRelocation/> } />
                    <Route path ="/verticals/address-and-post/relocation/complete-order" element={ <AddressAndPostRelocationCompleteOrder/> } />
                    <Route path ="/verticals/address-and-post/relocation/confirm" element={ <AddressAndPostRelocationConfirm/> } />
                </Route>
                <Route element={<ValidateRoute type='postbox' />}>  
                    <Route path ="/verticals/address-and-post/mailbox" element={ <AddressAndPostMailbox/> } />
                    <Route path ="/verticals/address-and-post/mailbox/complete-order" element={ <AddressAndPostMailboxCompleteOrder/> } />
                    <Route path ="/verticals/address-and-post/mailbox/confirm" element={ <AddressAndPostMailboxConfirm/> } />
                </Route>
                {/* Profile */}
                <Route path ="/profile" element={ <ProfileHome/> } />
                <Route path ="/profile/orders/all" element={ <ProfileOrdersAll/> } />
                <Route path ="/profile/orders/:product" element={ <ProfileOrderDetails/> } />

                {/* Settings */}
                <Route path ="/settings" element={ <SettingsHome/> } />
                <Route path ="/settings/address" element={ <SettingsAddress/> } />
                <Route path ="/settings/terms" element={ <SettingsTerms/> } />
                <Route path ="/settings/privacy" element={ <SettingsPrivacy/> } />
                <Route path ="/settings/users" element={ <SettingsUsers/> } />
                <Route path ="/settings/user/new/under-18" element={ <SettingsUserAddMinor/> } />
                <Route path ="/settings/user/new/over-18" element={ <SettingsUserAddAdult/> } />
                <Route path ="/settings/user/new/over-18" element={ <SettingsUserAddAdult/> } />
                <Route path ="/settings/user/edit/:id" element={ <SettingsUserEdit/> } />

                {/* FAQS */}
                <Route path ="/faqs" element={ <FAQs/> } />
                <Route path ="/faq/products/:category" element={ <FAQDetails/> } />

                {/* PROTOCOL */}
                <Route path ="/protocol" element={ <Protocol/> } />
                <Route path ="/protocol/step-1" element={ <ProtocolStep1/> } />
                <Route path ="/protocol/step-2" element={ <ProtocolStep2/> } />
                <Route path ="/protocol/step-3" element={ <ProtocolStep3/> } />
                <Route path ="/protocol/step-4" element={ <ProtocolStep4/> } />
                <Route path ="/protocol/step-5" element={ <ProtocolStep5/> } />
                <Route path ="/protocol/step-6" element={ <ProtocolStep6/> } />
                <Route path ="/protocol/step-7" element={ <ProtocolStep7/> } />
                <Route path ="/protocol/step-8" element={ <ProtocolStep8/> } />
                <Route path ="/protocol/step-9" element={ <ProtocolStep9/> } />
                <Route path ="/protocol/step-10" element={ <ProtocolStep10/> } />
                <Route path ="/protocol/step-11" element={ <ProtocolStep11/> } />
                <Route path ="/protocol/step-12" element={ <ProtocolStep12/> } />
                <Route path ="/protocol/step-13" element={ <ProtocolStep13/> } />
                <Route path ="/protocol/complete-order" element={ <ProtocolCompleteOrder/> } />
                <Route path ="/protocol/confirm" element={ <ProtocolConfirm/> } />

                {/* CONTRACT */}
                <Route path ="/contract" element={ <Contract/> } />

                {/* CHECKLIST */}
                <Route path ="/checklist" element={ <Checklist/> } />
                <Route path ="/checklist/details" element={ <ChecklistDetails/> } />

                {/* NEIGHBOR */}
                <Route path ="/neighbor" element={ <Neighbor/> } />

                {/* UPGRADES */}
                <Route path ="/upgrades" element={ <Upgrades/> } />

                {/* TIPS AND ARTICLES */}
                <Route path ="/tips-and-articles" element={ <Tips/> } />
                <Route path ="/tips-and-articles/:id" element={ <TipsDetails/> } />

                <Route path ="*" element={ <PageNotFound/> } />
                </Route>
            </Routes>
        </Router>
    );
};

export default AllRoutes;
