import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { Button, Col, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLottie } from "../../../components/layout/ButtonLottie";
import { useEffect } from "react";
import { computeProgress } from "../../../helpers/Common";
import { VerticalHeader } from "../../../components/layout/VerticalHeader";

export const ProtocolStep1 = () => {

    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    const proStep1 = sessionStorage.getItem('protocol_step_1') ? JSON.parse(sessionStorage.getItem('protocol_step_1')) : {};
    const [isLottie, setIsLottie] = useState(null);
    const [isLottie2, setIsLottie2] = useState(null);
    const navigate = useNavigate();

    const handleBtnClick = ( e ) => {
        const type = e.currentTarget.dataset.type;
        const value = e.currentTarget.dataset.value;
        const id = e.currentTarget.dataset.id;

        proStep1[type] = value;

        if(type === 'confirmation'){
            setIsLottie(id);
        }else{
            setIsLottie2(id);
        }
        
        sessionStorage.setItem('protocol_step_1', JSON.stringify(proStep1));
        if(proStep1.confirmation && proStep1.both_represented){

            //add to progress for the progress bar in the homepage
            if(!protocolProgress.includes('step1')){
                protocolProgress.push('step1');
                sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
            }
            
            //proceed to step 2;

            setTimeout((function(){
                navigate('/protocol/step-2');
            }), 1000);
        }
    }

    useEffect(() => {
        if(proStep1.confirmation){
            setIsLottie(proStep1.confirmation === 'yes' ? 'conf_yes' : 'conf_no');
        }

        if(proStep1.both_represented){
            setIsLottie2(proStep1.both_represented === 'yes' ? 'rep_yes' : 'rep_no');
        }
    }, [isLottie, isLottie2]);

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Bekreftelse' progress={1} totalProgress={14} />

            <div className="pt-4">
                <Row className="text-center">
                    <Col>
                        <p>Kjøper og selger har mottatt bereftelse på at penger er innbetalt pá meglers Klientkonto, og at overtakelse kan fine sted.</p>
                        <Row className="mx-2">
                            <Col>
                                <Button 
                                    className='btn btn-between w-100' 
                                    data-id="conf_yes" 
                                    data-value='yes' 
                                    data-type="confirmation"
                                    onClick={ handleBtnClick }>
                                        { isLottie === 'conf_yes' ?  <ButtonLottie button='Ja' left='60px' /> :  <span>Ja</span> }
                                </Button>
                            </Col>
                            <Col>
                                <Button 
                                    className='btn btn-between-white no-black w-100' 
                                    data-id="conf_no" 
                                    data-value='no' 
                                    data-type="confirmation"
                                    onClick={ handleBtnClick }>
                                        { isLottie === 'conf_no' ?  <ButtonLottie button='Nei' left='60px' isBlack={true} /> :  <span>Nei</span> }
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr className="fluid my-4" />
            
                <Row className="text-center mb-4">
                    <Col>
                        <p>Er bade selger og kjøper representert pa overtakelsen?</p>
                        <Row className="mx-2">
                            <Col>
                                <Button 
                                    className='btn btn-between  w-100' 
                                    data-id="rep_yes" 
                                    data-value='yes' 
                                    data-type="both_represented"
                                    onClick={ handleBtnClick }>

                                    { isLottie2 === 'rep_yes' ?  <ButtonLottie button='Ja' left='60px' /> :  <span>Ja</span> }
                                
                                </Button>
                            </Col>
                            <Col>
                                <Button 
                                    className='btn btn-between-white no-black w-100' 
                                    data-id="rep_no" 
                                    data-value='no' 
                                    data-type="both_represented"
                                    onClick={ handleBtnClick }>
                                        { isLottie2 === 'rep_no' ?  <ButtonLottie button='Nei' left='60px' isBlack={true} /> :  <span>Nei</span> }
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="text-center my-4 mx-4">
                    <Col>
                        <Link to={'/protocol'} className="btn-between-white">Tilbake</Link>
                    </Col>

                    {protocolProgress.includes('step1') &&
                    <Col>
                        <Link to="/protocol/step-2" className="btn-between w-100">Neste</Link>
                    </Col>
                    }
                </Row>
                </div>
            <Footer/>
        </div>
    );
}