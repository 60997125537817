import React, { useState, useEffect } from 'react';
import { Tooltip } from 'reactstrap';

export const TooltipItem = (props) => {
    const { src, id } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);
  
    const toggle = () => setTooltipOpen(!tooltipOpen);
  
    return (
      <span>
        <Tooltip
          placement={'bottom'}
          isOpen={tooltipOpen}
          target={id}
          toggle={toggle}
        >
            <img src={src} className='w-100' />
                        <button className='btn-close float-end' style={{position : 'absolute', 'right' : 5}} toggle={toggle}></button>
        </Tooltip>
      </span>
    );
}