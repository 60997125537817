import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { Col, Row } from "reactstrap";
import { capsFirst, displayAddress } from "../../../helpers/Common";
import moment from "moment";
import { Link } from "react-router-dom";

export const Protocol = () => {
    const user = sessionStorage;
    const moving_date      = sessionStorage.getItem('moving_date').replaceAll('"', "");
    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    const currentProgress  = protocolProgress.length === 0 ? 0 : (protocolProgress.length / 14) * 100;
    const address          = sessionStorage.getItem('to_address') ? JSON.parse(sessionStorage.getItem('to_address')) : null;
    //set the default buyer
    const buyers = [{
        name : 'Torstein Torstest',
        email : 'Torstein.naes@gmail.com',
        phone : '92 45 50 24',
        birthdate : moment('1992/08/06').format('MM.DD.YY')
    }]
    console.log(address);   
    
    if(!sessionStorage.getItem('protocol_buyer')){
        sessionStorage.setItem('protocol_buyer', JSON.stringify(buyers));
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Overtagelses-protokoll</h1>
                <div className="position-relative w-100 d-flex justify-content-center">
                    <img src='/images/purple/svg/checklist.svg' alt="checklist" style={{width: '250px'}}/>
                </div>
            </div>
            <div className="py-4">
                <h4 className="text-bold mb-2">Sjekkliste overtagelsesbefaring</h4>
                <ProgressBar progress={ currentProgress } />
                <h4 className="mb-3 mt-2">{Math.round(currentProgress)}% ferdig</h4>
                <hr className="fluid" />
                
                <Row className="my-4">
                    <Col>
                        <h3 className="text-center">{capsFirst(address.street)} <br/> {capsFirst(address.zipcode)} {capsFirst(address.place)}</h3>
                        <h3 className="text-center">{ moment(moving_date).format("MM.DD.YY") }</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Link to="/protocol/step-1" className="btn btn-between w-100">Start befaring</Link>
                    </Col>
                </Row>
            </div>
            <Footer/>
        </div>
    );
}