import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {Col, Navbar, Row} from 'reactstrap';
import { baseUrl, getMovingOrderId, getHeaders } from '../../helpers/Common';
import axios from 'axios';
import { NavbarBrand} from 'reactstrap';
import { logout } from '../../helpers/Common';
import { slide as Menu } from 'react-burger-menu'
import '../../css/menu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';

export const Navigation = ( { isReg = false, color = 'black' }) => {

    // Collapse isOpen State
    const regLink = '/registration/address';
    const user = JSON.parse(sessionStorage.user);
    const fetchMovingOrderSettings = () => axios.get(baseUrl()+'moving_orders/'+getMovingOrderId()+'/settings/disabled_tasks', getHeaders()
    ).then(response =>  {
        if(response.data){
            //save current settings to session
            sessionStorage.setItem('disabled_tasks', response.data.data ? response.data.data.value : []);
        }
    }); 

    useEffect(() => {
        fetchMovingOrderSettings();
    }, [])
    
    return (
        <>
        {!isMobile &&
        <Helmet>
            <style type="text/css">{`
                .bm-overlay, .bm-menu-wrap{
                    margin-top: -7.1rem;
                    position: absolute!important;
                }
            `}
            </style>
        </Helmet>
        }
        <div className='position-relative'>
            <Navbar color="faded" light>
                <NavbarBrand href={!isReg ? '/' : regLink} className="me-auto">
                    <img src={`/images/svg/${color === 'black' ? 'Symbol_positive.svg' : 'between-logo-yellow.svg'}`} width="48px" height="63px" alt='between icon'/>
                </NavbarBrand>
            </Navbar>
        </div>
            {/* <div id="mySidenav" class="sidenav">
                <a href="javascript:void(0)" class="closebtn" onClick={ closeNav }>&times;</a>
                <a href="#">About</a>
                <a href="#">Services</a>
                <a href="#">Clients</a>
                <a href="#">Contact</a>
            </div> */}
            <Menu right width={ 150 }>
                <div className='bg-between py-2 px-3'>
                    <img className='img-fluid' src='/images/between.png' style={{width : '70px'}} alt='between logo' />
                    <table className='mt-2'>
                        <tbody>
                            <tr>
                                <td><FontAwesomeIcon icon={faUser} className='f-14' /></td>
                                <td className='ps-1 f-14'>{ user.name }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='my-4 px-3'>
                    <Link to={'/'} className='btn btn-dark text-white br-10 w-100'>Dashbord</Link>
                </div>
                <Link to={!isReg ? '/' : regLink} className='nav-link text-bold pb-0'>Hjem</Link>
                <Link to={!isReg ? '/timeline' : regLink} className='nav-link text-bold text-bold pb-0'>Tidslinje</Link>
                <Link to={!isReg ? '/settings' : regLink} className='nav-link text-bold text-bold pb-0'>Innstillinger</Link>
                <Link to={!isReg ? '/settings' : regLink} className='nav-link text-bold text-bold pb-0'>Chat</Link>
                <Link to={!isReg ? '/settings' : regLink} className='nav-link text-bold text-bold pb-0'>Kontakt oss</Link>
                <Link to={!isReg ? '/settings' : regLink} className='nav-link text-bold text-bold pb-0'>Hjelp</Link>
                <Link to='/' className='nav-link' onClick={() => logout()}>Logg ut</Link>
            </Menu>
        </>
    )
}