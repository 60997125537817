import React from 'react';
import {  Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ConfirmationLayout } from '../../../../components/layout/ConfirmationLayout';

export const MovingCompanyInsuranceConfirm = () => {

        return (
            <ConfirmationLayout>
                <p className='f-30 mt-4'>Flytting av <br/> forsikring fullført!</p>
                <p className='mt-4 mb-5'>Din forsikringsrådgiver hos <br/> Gjensidige ringer deg i løpet av 2 virkedager for å bekrefte <br/> flyttingen. </p>

                <Row>
                    {/* <Col>
                        <Link replace to="/verticals/moving-company/home" className='btn-between w-100'>Hjem</Link>
                    </Col> */}
                    <Col className='px-4'>
                        <Link replace to="/tasks" className='btn-between w-100'>Gå tilbake til oversikten</Link>
                    </Col>
                </Row>
            </ConfirmationLayout>
        );
}