import React, {useState} from 'react';
import { Navigation } from '../../components/layout/Navigation';
import { RegistrationHeader  } from '../../components/layout/RegistrationHeader';
import {FormGroup, Input, Label, Button, Form, Row, Col} from 'reactstrap';
import AsyncSelect  from 'react-select/async';
import axios from 'axios';
import { baseUrl, getHeaders, capsFirst, getMovingOrderId, displayAddress } from '../../helpers/Common';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import { formatDate } from "../../helpers/Common";
import { isMobile } from 'react-device-detect';
import { AuthFooter } from '../../components/elements/AuthFooter';

const schema = yup.object().shape({
    movingDate: yup.string().required("Vennligst fyll ut feltet").test("birthdate", "Vennligst velg en gyldig fødselsdato", (value) => {
        var today = new Date();
        var selectedDate = new Date(value);
        // console.log(selectedDate > today);
        return selectedDate > today;
      }),
});


export const RegistrationAddress = () => {
    
    const navigate = useNavigate();
    const [inputValue, setValue]                            = useState('');
    const [isBuildingFromAddress, setIsBuildingFromAddress] = useState(sessionStorage.getItem('from_address_building') ? true : false);
    const [isBuildingToAddress, setIsBuildingToAddress]     = useState(sessionStorage.getItem('to_address_building') ? true : false);
    const [fromSelectedValue, fromSetSelectedValue]         = useState(null);
    const [toSelectedValue, toSetSelectedValue]             = useState(null);
    const [fromSelectedAddressId, fromSetSelectedAddressId] = useState(sessionStorage.getItem('from_address_id') ? sessionStorage.getItem('from_address_id') : null);
    const [toSelectedAddressId, toSetSelectedAddressId]     = useState(sessionStorage.getItem('to_address_id') ? sessionStorage.getItem('to_address_id') : null);

    const [fromCurrentFloors, setFromCurrentFloors]                 = useState([]);
    const [fromCurrentRooms, setFromCurrentRooms]                   = useState([]);
    const [fromCurrentFloorsAndRooms, setFromCurrentFloorsAndRooms] = useState([]);
    
    const [toCurrentFloors, setToCurrentFloors]                     = useState([]);
    const [toCurrentRooms, setToCurrentRooms]                       = useState([]);
    const [toCurrentFloorsAndRooms, setToCurrentFloorsAndRooms]     = useState([]);

    const [movingDate, setMovingDate] = useState(sessionStorage.getItem('moving_date'));
    const [fromAddressPlaceHolder, setfromAddressPlaceHolder]   = useState(sessionStorage.getItem('from_address') ? displayAddress(sessionStorage.getItem('from_address'), true) : 'Din adresse');
    const [toAddressPlaceHolder, setToAddressPlaceHolder]       = useState(sessionStorage.getItem('to_address') ? displayAddress(sessionStorage.getItem('to_address'), true) : 'Adresse');
    // const [fromAddressPlaceHolder, setfromAddressPlaceHolder]   = useState(sessionStorage.getItem('from_address') ? sessionStorage.getItem('from_address') : 'Din adresse');
    // const [toAddressPlaceHolder, setToAddressPlaceHolder]       = useState(sessionStorage.getItem('to_address') ? sessionStorage.getItem('to_address') : 'Adresse');
    const [dateFocus, setIsDateFocus] = useState( false );

    const [tempFromAddress, setTempFromAddress] = useState(sessionStorage.getItem('from_address') ? sessionStorage.getItem('from_address') : null);
    const [tempFromAddressID, setTempFromAddressID] = useState(sessionStorage.getItem('from_address_id') ? sessionStorage.getItem('from_address_id') : null);
    const [tempFromFloor, setTempFromFloor] = useState(null);
    const [tempToAddress, setTempToAddress] = useState(sessionStorage.getItem('to_address') ? sessionStorage.getItem('to_address') : null);
    const [tempToAddressID, setTempToAddressID] = useState(sessionStorage.getItem('to_address_id') ? sessionStorage.getItem('to_address_id') : null)
    const [tempToFloor, setTempToFloor] = useState(null);

    //IDS FROM SEARCH NOT IDS FROM SAVED
    const [tempRawFromAddressID, setTempRawFromAddressID] = useState(sessionStorage.getItem('from_raw_address_id') ? sessionStorage.getItem('from_raw_address_id') : null);
    const [tempRawToAddressID, setTempRawToAddressID] = useState(sessionStorage.getItem('to_raw_address_id') ? sessionStorage.getItem('to_raw_address_id') : null);

    const [fromFloorDefaultValue, setFromFloorDefaultValue] = useState(sessionStorage.getItem('from_address_floor') ? sessionStorage.getItem('from_address_floor') : null);
    const [fromRoomDefaultValue, setFromRoomDefaultValue]   = useState(sessionStorage.getItem('from_address_room') ? sessionStorage.getItem('from_address_room') : null);
    const [toFloorDefaultValue, setToFloorDefaultValue]     = useState(sessionStorage.getItem('to_address_floor') ? sessionStorage.getItem('to_address_floor') : null);
    const [toRoomDefaultValue, setToRoomDefaultValue]       = useState(sessionStorage.getItem('to_address_room') ? sessionStorage.getItem('to_address_room') : null);
    
    const [isFromAddress, setIsFromAddress] = useState(true);
    const [isToAddress, setIsToAddress]     = useState(true);
    // console.log(sessionStorage);


    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      });

    // handle input change event
    const fromHandleInputChange = value => {
        setValue('name', 'value', {shouldValidate : true});
    };

    
    // handle selection
    const fromHandleChange = value => {
        const fromAddress = {street : value.street, zipcode : value.zipcode, place : value.place, house_type : 'tomannsbolig', house_size : 1}
        fromSetSelectedValue(value);
        postAddress('from', fromAddress);

        sessionStorage.setItem('from_raw_address_id', value.id);
        setTempRawFromAddressID(value.id);
        getAddressDetails(value.id, 'from');
    }

    // handle input change event
    const toHandleInputChange = value => {
        setValue(value);
    };
    
    // handle selection
    const toHandleChange = value => {
        const toAddress = {street : value.street, zipcode : value.zipcode, place : value.place, house_type : 'tomannsbolig', house_size : 1}
        toSetSelectedValue(toAddress);
        postAddress('to', toAddress);

        sessionStorage.setItem('to_raw_address_id', value.id);
        setTempRawToAddressID(value.id);
        
        getAddressDetails(value.id, 'to');
    }

    //handle changing floor number
    const handleFromFloorChange = value => {
        setFromCurrentRooms(fromCurrentFloorsAndRooms[value]);
    }
    //handle changing floor number
    const handleToFloorChange = value => {
        setToCurrentRooms(toCurrentFloorsAndRooms[value]);
    }

    const getAddressDetails = (id, type, isSesh = false) => {

        //get address details to check if ita building or house
        axios.get("https://addresses-microservices.betwn.no/rest-api/addresses/v1/address/"+id+"/details").then(result => {
            const details = result.data.data.address_details.details.sections;
            if(!details['Z000']){
                var floors = [];
                var previousKey = null;
                var rooms = [];
                var currentFloors = [];
                var firstSelectedRooms = [];
                var firstSelectedFloor = [];

                //parse the building details characters
                var ctr = 0;
                for (var key in details) {
                    if(key.substring(0, 1) !== "U"){
                        const roomNumber = details[key].id;
                        var floorNumber = key.substring(2, 3);
                        const currentKey = floorNumber;

                        if(previousKey !== currentKey){
                            rooms = [roomNumber];

                            if(floorNumber){
                                floors[floorNumber] = rooms;
                            }
                            currentFloors.push(floorNumber);
                        }else{
                            rooms.push(roomNumber);
                        }

                        previousKey = currentKey;

                        //set floors 
                        if(type === 'from'){
                            setFromCurrentFloors(currentFloors);                        
                        }else{
                            setToCurrentFloors(currentFloors);                        
                        }

                        if(ctr == 0){
                            firstSelectedRooms = rooms;
                        }
                    }
                    ctr++;
                };

                if(type == 'from'){
                    setIsBuildingFromAddress(true);
                }else{
                    setIsBuildingToAddress(true);
                }
            }else{                           
                if(type === 'from'){
                    setIsBuildingFromAddress(false);
                }else{
                    setIsBuildingToAddress(false);
                }


                //empty the floors and rooms if not a building
                if(type === 'from'){
                    setFromCurrentFloorsAndRooms([]);
                    setFromCurrentFloors([]);
                }else{
                    setToCurrentFloorsAndRooms([]);
                    setToCurrentFloors([]);
                    setToCurrentRooms([])
                }
            }
            if(type == 'from'){
                setFromCurrentFloorsAndRooms(floors);
                setFromCurrentRooms(firstSelectedRooms);
            }else{
                setToCurrentFloorsAndRooms(floors);
                setToCurrentRooms(firstSelectedRooms);
            }  


            if(isSesh){
                if(isBuildingFromAddress && sessionStorage.getItem('from_address')){
                    setFromCurrentFloors(JSON.parse(sessionStorage.getItem('from_address_floors')));
                    setFromCurrentRooms(JSON.parse(sessionStorage.getItem('from_address_rooms')));
                }
                
                if(isBuildingToAddress && sessionStorage.getItem('to_address')){
                    setToCurrentFloors(JSON.parse(sessionStorage.getItem('to_address_floors')));
                    setToCurrentRooms(JSON.parse(sessionStorage.getItem('to_address_rooms')));
                }
            }

        })
    }


    //check if building from previous update
    useState(() => {
        if(isBuildingToAddress && sessionStorage.getItem('to_address')){
            getAddressDetails(tempRawToAddressID, 'to', true);
        }

        if(isBuildingFromAddress && sessionStorage.getItem('from_address')){
            getAddressDetails(tempRawFromAddressID, 'from', true);      
        }
    })

    //create new address when selecting then get the ID - TEMP
    const postAddress = (type, data) => {
        axios.post(baseUrl()+'addresses', data, getHeaders()
            ).then(response => {
                data.id = response.data.data.id;
                if(type === 'from'){
                    fromSetSelectedAddressId(data.id);
                    setTempFromAddress(JSON.stringify(data));
                    setTempFromAddressID(data.id);
                }else{
                    toSetSelectedAddressId(data.id);
                    setTempToAddress(JSON.stringify(data));
                    setTempToAddressID(data.id);
                }
        }).catch(error => {
            console.error(error);
        });
    }

    // load options using API call
    const fetchData = (inputValue, callback ) => {
        axios.post("https://addresses-microservices.betwn.no/rest-api/addresses/v1/address/search", 
        { "address-search" : inputValue } ).then(result => {
            const options = [];
            console.log(result.data)
            if(result.data.status.code !== '110'){
                const respObj = result.data.data.addresses;
                Object.keys(respObj).map((key, index) => {
                    const obj = respObj[index];
                    //push addresses to options
                    options[key] = { id : obj.id, street : obj.street, place: obj.place, zipcode : obj.zipcode, details : obj.details };
                });

                //load callback
                callback(options);

            }else{
                callback([]);
            }
        })
    }

    //create new moving address
    const onSubmitHandler = (data) => {

        //validate addresses
        setIsFromAddress(!parseInt(fromSelectedAddressId) ? false : true);
        setIsToAddress(!parseInt(toSelectedAddressId) ? false : true);

        const formData = {'old_address' : parseInt(fromSelectedAddressId), 'new_address' : parseInt(toSelectedAddressId), 'date' : data.movingDate};
        
        //send the address to create moving address
        axios.put(baseUrl()+'moving_orders/'+getMovingOrderId(), formData, getHeaders()
            ).then(response => {
                if(response.data.status.code === "200" && response.data.status.message === "ok"){

                    //save the moving order and addresses to local storage
                    // console.log("create moving order", response);

                    //save address on on local
                    sessionStorage.setItem("from_address", tempFromAddress);
                    sessionStorage.setItem("from_address_id", tempFromAddressID);
                    sessionStorage.setItem("to_address", tempToAddress);
                    sessionStorage.setItem("to_address_id", tempToAddressID);
                    sessionStorage.setItem("moving_date", data.movingDate);

                    if(isBuildingFromAddress){
                        sessionStorage.setItem("from_address_building", isBuildingFromAddress);
                        sessionStorage.setItem("from_address_floor", document.getElementById('fromAddressFloor').value);
                        sessionStorage.setItem("from_address_room", document.getElementById('fromAddressRoom').value);
                        sessionStorage.setItem("from_address_floors", JSON.stringify(fromCurrentFloors));
                        sessionStorage.setItem("from_address_rooms", JSON.stringify(fromCurrentRooms));
                    }

                    if(isBuildingToAddress){
                        sessionStorage.setItem("to_address_building", isBuildingToAddress);
                        sessionStorage.setItem("to_address_floor", document.getElementById('toAddressFloor').value);
                        sessionStorage.setItem("to_address_room", document.getElementById('toAddressRoom').value);
                        sessionStorage.setItem("to_address_floors", JSON.stringify(toCurrentFloors));
                        sessionStorage.setItem("to_address_rooms", JSON.stringify(toCurrentRooms));
                    }
                    

                    var fromDet = isBuildingFromAddress ? document.getElementById('fromAddressRoom').value : 'Z000';
                    var toDet   = isBuildingToAddress ? document.getElementById('toAddressRoom').value : 'Z000';

                    // setExtraDetails(parseInt(tempRawFromAddressID), fromDet);
                    // setExtraDetails(parseInt(tempRawToAddressID), toDet, true);
                    navigate("/registration/users", { replace: true });

                }
        }).catch(error => {
            console.error(error);
        });


        //update the address for extra details (floor, house size, house type, room)
        const setExtraDetails = (addressId, detail, next = false) => {
            axios.get("https://addresses-microservices.betwn.no/rest-api/addresses/v1/address/"+addressId+"/details/"+detail,
            ).then(response => {

                if(response.data.status.code === '200'){
                    var data = response.data.data.address_details.details.sections[detail][0];
                    console.log('details result', data);
                    console.log('address id: ', addressId);

                    console.log(JSON.parse(tempFromAddress));
                    console.log(tempToAddress);

                    if(next){
                        // navigate("/registration/users", { replace: true });
                    }
                }else{
                    if(next){
                        // navigate("/registration/users", { replace: true });
                    }
                }
                
            }).catch(error => {
                console.error(error);
            });
        }
    };

    //address selected custom style
    const customStyles = {
        control : (base, state) => ({
            ...base,
            color: '#000!important',
            borderRadius : '40px',
            height : '60px',
            paddingLeft : '1rem',
        }),
        placeholder : (base, state) => ({
            ...base,
            color: '#000!important',
        }),
        dropdownIndicator : (base, state) => ({
            display : 'none'
        }),
        indicatorSeparator : (base, state) => ({
            display : 'none'
        })
      }

    const noOptionsMessage = (input) => input.length >= 2 ? "Adressen ble ikke funnet" : null;

    return (
        <div id='CreateUserContainer' className='AppContainer bg-between-green'>
            <Navigation isReg={true} />
            <div className='mt-5'>
            <p className='p-header text-white'>Adresse</p>
        </div>
            <form onSubmit={handleSubmit(onSubmitHandler)} className='pt-5 form-address'>                    
                <FormGroup>
                    <Label className='fw-bold'>Hvor flytter du fra?</Label>
                    <AsyncSelect
                    noOptionsMessage={ noOptionsMessage }
                    loadingMessage={() => 'Laster...'}
                    styles={ customStyles }
                    placeholder={ fromAddressPlaceHolder }
                    cacheOptions
                    defaultOptions={false}
                    value={fromSelectedValue}
                    getOptionLabel={e => capsFirst(e.street) + ', ' + e.zipcode + ' '+capsFirst(e.place)}
                    getOptionValue={e => e.id}
                    loadOptions={fetchData}
                    onInputChange={fromHandleInputChange}
                    onChange={fromHandleChange}
                    />
                    {!isFromAddress &&
                    <p className="form-error">Vennligst fyll ut feltet</p>
                    }
                    {/* from address to use in moving_order */}
                    <input type='hidden' name='fromAddressId' value={fromSelectedAddressId || ''}/>
                </FormGroup>
                { isBuildingFromAddress &&
                <FormGroup id='fromAddressDetailsFormGroup'>
                    <Row>
                        <Col xs={4}>
                            <Label className='fw-bold'>Etasje</Label>
                                <Input id='fromAddressFloor' type='select' name='formAddressFloor' onChange={(e) => handleFromFloorChange(e.target.value) }>
                                {fromCurrentFloors.map((floor, key) => {
                                    return <option value={ floor } key={key} selected={fromFloorDefaultValue === floor ? true : false}>{ floor }</option>
                                })}
                                </Input>
                        </Col>
                        <Col xs={8}>
                            <Label className='fw-bold'>Leilighet</Label>
                            <Input id='fromAddressRoom' type='select' name='fromAddressRoom'>
                                {fromCurrentRooms  &&
                                    fromCurrentRooms.map((room, key) => {
                                        return <option value={ room } key={ key } selected={fromRoomDefaultValue === room ? true : false}>{ room } ({key + 1}. dør fra venstre)</option>
                                    })
                                }
                            </Input>
                        </Col>  
                    </Row>
                </FormGroup>
                }
                <FormGroup className='mt-4'>
                    <Label className='fw-bold'>Hvor flytter du til?</Label>
                    <AsyncSelect
                    styles={ customStyles }
                    noOptionsMessage={ noOptionsMessage }
                    loadingMessage={() => 'Laster...'}
                    placeholder={ toAddressPlaceHolder }
                    cacheOptions
                    defaultOptions={false}
                    value={toSelectedValue}
                    getOptionLabel={e => capsFirst(e.street) + ', ' + e.zipcode + ' '+capsFirst(e.place)}
                    getOptionValue={e => e.id}
                    loadOptions={fetchData}
                    onInputChange={toHandleInputChange}
                    onChange={toHandleChange}/>
                    
                    {!isToAddress && 
                    <p className="form-error">Vennligst fyll ut feltet</p>
                    }
                    {/* to address to use in moving_order */}
                    <input type='hidden' name='toAddressId' value={toSelectedAddressId || ''}/>
                </FormGroup>
                { isBuildingToAddress &&
                <FormGroup id='toAddressDetailsFormGroup'>
                    <Row>
                        <Col xs={4}>
                            <Label className='fw-bold'>Etasje</Label>
                            <Input id='toAddressFloor' type='select' name='toAddressFloor' onChange={(e) => handleToFloorChange(e.target.value) }>
                            {toCurrentFloors.map((floor, key) => {
                                return <option value={ floor } key={key} selected={toFloorDefaultValue === floor ? true : false}>{ floor }</option>
                            })} 
                            </Input>
                        </Col>
                        <Col xs={8}>
                            <Label className='fw-bold'>Leilighet</Label>
                            <Input id='toAddressRoom' type='select' name='toAddressRoom'>
                                {toCurrentRooms  &&
                                    toCurrentRooms.map((room, key) => {
                                        return <option value={ room } key={ key }  selected={toRoomDefaultValue === room ? true : false}> { room } ({key + 1}. dør fra venstre)</option>
                                    }) 
                                }
                            </Input>
                        </Col>  
                    </Row>
                </FormGroup>
                }
                <FormGroup>
                    <Label className='fw-bold'>Når flytter du inn?</Label>
                    {!dateFocus && movingDate === null && isMobile &&
                    <span className="date-placeholder" style={{color : 'color: hsl(0, 0%, 20%)!important', marginTop : '54px'}}>Flyttedato</span>
                    }
                    <input {...register("movingDate")} type="date" onFocus={() => setIsDateFocus(true) } className="form-control" defaultValue={ movingDate } />
                    <p className="form-error">{errors.movingDate?.message}</p>
                </FormGroup>
                <FormGroup className='mt-5 pb-4 text-center'>
                    <Button type='submit' className='btn-between' style={{width : '250px'}}>Neste</Button>
                </FormGroup>
            </form>
            
      <AuthFooter />
        </div>
    )
}