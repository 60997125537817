import { Navigation } from "../../../../components/layout/Navigation";
import { Footer } from "../../../../components/layout/Footer";
import { Button, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Form } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLottie } from "../../../../components/layout/ButtonLottie";
import { useEffect } from "react";
import { VerticalHeader } from "../../../../components/layout/VerticalHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { cleanString, displayAddress, displayAddressDefault, formatPrice } from "../../../../helpers/Common";
import moment from "moment/moment";
import { MovingBoxesPrices } from "./MovingBoxesPrices";
import { MovingBoxesTimeline } from "./MovingBoxesTimeline";

export const MovingBoxesStatus = () => {
    var   boxesStep2    = sessionStorage.getItem('boxes_step_2')   ? JSON.parse(sessionStorage.getItem('boxes_step_2')) : '';
    const navigate = useNavigate();
    const toAddress  = sessionStorage.getItem('to_address') ? sessionStorage.getItem('to_address') : {}

    const timelineObjects = [{
        id : 1,
        title : 'Order received',
        date: '25.06.23',
        status : 'finished'
    },{
        id : 2,
        title : 'Order prepared',
        date: '25.06.23',
        status : 'finished'
    },{
        id : 3,
        title : 'Order sent',
        date: '25.06.23',
        status : 'current'
    },{
        id : 4,
        title : 'Order on the way',
        date: null,
        order_number : '331991238823',
        status: 'pending'
    },{
        id : 5,
        title : 'Order delivered',
        date: '25.06.23',
        status: 'pending'
    }];
    const timelineItems = timelineObjects.map((tObj, i) => 
        <div className={`timeline-section ps-3`} key={i}>
            <div className={`${tObj.id !== 5 && 'pb-4'} time-line-text-purple ps-4 d-flex align-items-center`}>
                { tObj.status === 'finished' ? 
                <div className="position-absolute" style={{left: 28}}>
                    <img src="/images/boxes/purple-check-light.png"  alt='' style={{width : '26px'}}/>
                </div>
                : tObj.status === 'current' ?
                <div className="position-absolute" style={{left: 28}}>
                    <img src="/images/boxes/purple-check-solid.png"  alt='' style={{width : '26px'}}/>
                </div>
                 :
                 <div className="position-absolute d-flex align-items-center justify-content-center" style={{left: 34}}>
                     <img src="/images/boxes/pending-circle.png"  alt='' style={{width : '15px'}}/>
                 </div>
                }
                
                <div className={tObj.status === 'pending' && 'dim-div'}>
                    <p className='mb-0'>{tObj.title} {tObj.date ? '-' : ':'} {tObj.date ? tObj.date : tObj.order_number}</p>
                </div>
            </div>
        </div>
    );

    console.log(sessionStorage);
    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <VerticalHeader title='Ordrestatus' progress={false}/>
            <div className="my-4">
                <div className="mb-2">
                    <p className="mb-0 text-bold">#35346</p>
                    <p className="mb-0">Forventet levering: {moment(boxesStep2.delivery_date).format("DD MMMM, YYYY")}</p>
                </div>
                <div>
                    <p className="mb-0">Leveringsadresse:</p>
                    <p className="mb-0 text-bold">{displayAddressDefault(toAddress)}</p>
                </div>
            </div>
            <hr className="fluid" />

            <MovingBoxesTimeline timeline={timelineObjects} />

            {/* <hr className="fluid" /> */}
            {/* <MovingBoxesPrices title={'Sammendrag'} /> */}

            <Row className="text-center my-4 mx-5">
                <Col>
                    <Link to="/verticals/moving-order/boxes/complete-order" className="btn-between-white w-100" style={{paddingTop : '8px', lineHeight: '15px'}}>Tilbake til ordrebekreftelse</Link>
                </Col>
            </Row>

        <Row className="text-center my-4 mx-5">
            <Col>
                <Link to="/verticals/moving-order/boxes/payment" className="btn-between w-100">* PAYMENT *</Link>
            </Col>
        </Row>
            <Footer/>
        </div>
    );
}