import React from 'react';
import {Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, Collapse,  NavLink } from 'reactstrap';
import Lottie from "lottie-react";
import checkCircle from "../../lottie/check-circle.json";
import confetti from "../../lottie/confetti.json";

export const ConfirmationLayout = ({children}) => {

    return (
        <div className='AppContainer bg-between' style={{minHeight : "750px"}}>
            <Navbar color="faded" light >
                <NavbarBrand className="me-auto" href="/" >
                    <img src='/images/between-icon.webp' width="38px" height="53px" alt='between icon'/>
                </NavbarBrand>
            </Navbar>
            <div className='text-center' style={{paddingTop : '30px', paddingBottom : '80px'}}>
                <div className='align-center w-100'>
                    <Lottie animationData={checkCircle} loop={false} className="lottie-container" />
                </div>
                {children}
                <Lottie animationData={confetti} loop={false} style={{width : '100%', position: 'absolute', bottom : '310px', left : '0px'}} />
            </div>
        </div>
    )
}