import React, {useState} from 'react';
import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col} from 'reactstrap';
import classnames from 'classnames';
import { displayAddress } from '../../../helpers/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export const Neighbor = () => {
    // State for current active Tab
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
  
    // Toggle active state for Tab
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }
  

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1 className='mb-0'>Nabolag</h1>
                <h4 className="mb-3">Økern/Risløkka vestre</h4>
                {/* <div className="position-relative w-100 d-flex justify-content-center">
                    <img src='/images/purple/svg/terms.svg' style={{width: '250px'}}/>
                </div> */}
            </div>
            <hr className="fluid" />
            <div className="py-4">

            <Nav tabs className='tabs-purple'>
                <NavItem>
                    <NavLink className={classnames({ active: currentActiveTab === '1' })} onClick={() => { toggle('1'); }} >
                    Hva sier naboene?
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: currentActiveTab === '2' })} onClick={() => { toggle('2'); }} >
                    Utvalgte fakta
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className='mt-2' activeTab={currentActiveTab}>
                <TabPane tabId="1">
                    <h4 className='mb-1'>{ displayAddress(sessionStorage.getItem('from_address'), true) }</h4>
                    <Row>
                        <Col className='pe-1'>
                            <div className='p-3 br-20 bg-gray neighbor-cont'>
                                <p className='f-15 text-bold mb-2'>Nabolaget oppleves som veldig trygt</p>
                                <p className='f-14'>88/100 Veldig trygt</p>
                                <div className='d-flex justify-content-end'>
                                    <img src='/images/house-lock.svg' alt='house lock' />
                                </div>
                            </div>
                        </Col>
                        <Col className='ps-1'>
                            <div className='p-3 br-20 bg-gray neighbor-cont'>
                                <p className='f-15 text-bold mb-2'>Meget god kvalitet på skolene</p>
                                <p className='f-14'>86/100 Veldig bra</p>
                                <div className='d-flex justify-content-end'>
                                    <img src='/images/school.svg' alt='school' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col className='pe-1'>
                            <div className='p-3 br-20 bg-gray neighbor-cont'>
                                <p className='f-15 text-bold mb-2'>Meget god kvalitet på barnehagene</p>
                                <p className='f-14'>86/100 Veldig bra</p>
                                <div className='d-flex justify-content-end'>
                                    <img src='/images/children.svg' alt='children' />
                                </div>
                            </div>
                        </Col>
                        <Col className='ps-1'>
                            <div className='p-3 br-20 bg-gray neighbor-cont'>
                                <p className='f-15 text-bold mb-2'>Gode turmuligheter</p>
                                <p className='f-14'>84/100 Nærhet til skog og mark</p>
                                <div className='d-flex justify-content-end'>
                                    <img src='/images/trees.svg' alt='trees' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                <h4 className='mb-1'>{ displayAddress(sessionStorage.getItem('to_address'), true) }</h4>
                    <Row>
                        <Col className='pe-1'>
                            <div className='p-3 br-20 bg-gray'>
                                <p className='f-15 text-bold mb-2'>Kort gangavstand til offentlig transport</p>
                                <p className='f-14 mb-0'>Buss 4 min<br/>
                                Knutepunkt 11 min <br/>
                                T-bane 12 min </p>
                            </div>
                        </Col>
                        <Col className='ps-1'>
                            <div className='p-3 br-20 bg-gray'>
                                <p className='f-15 text-bold mb-2'>Kort gangavstand til skole</p>
                                <p className='f-14 mb-0'>Refstad (1-7 kl.) 9 min <br/>
                                Løren (1-7 kl.) 13 min <br/>
                                Vollebekk (1-10 kl.) 17 min
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col className='pe-1'>
                            <div className='p-3 br-20 bg-gray'>
                                <p className='f-15 text-bold mb-2'>Mange barnefamilier i nabolaget</p>
                                <p className='f-14 mb-0'>35% barnefamilier <br/>&nbsp; <br/>&nbsp;</p>
                            </div>
                        </Col>
                        <Col className='ps-1'>
                            <div className='p-3 br-20 bg-gray'>
                                <p className='f-15 text-bold mb-2'>Kort gangavstand til barnehage</p>
                                <p className='f-14 mb-0'>Maurtua (0-3 år) 5 min<br/>
                                Orelia (3-6 år) 6 min<br/>
                                Økern (1-6 år) 7 min</p>
                            </div>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
            
            <div className='mt-4'>
                <p className='f-20 text-bold'>Oppdag ditt nabolag</p>
                <div className='px-3'>
                    <Row>
                        <Col xs={4} className='pe-1'>
                            <button className='btn-purple-sm w-100'>Barnehager</button>
                        </Col>
                        <Col xs={4} className='px-1'>
                            <button className='btn-purple-sm w-100'>Butikker</button>
                        </Col>
                        <Col xs={4} className='ps-1'>
                            <button className='btn-purple-sm active w-100'>Fastleger</button>
                        </Col>
                    </Row>
                </div>
                <div className='mt-4'>
                    <img src='/images/map.svg' width={'100%'} alt='map' />
                </div>
            </div>

            <div className='mt-4'>
                <div className='px-4 py-3 bg-gray br-20'>
                    <p className='text-bold mb-0'>Stig Olav Løset Gundersen
                    <FontAwesomeIcon icon={faChevronRight} className='float-end pt-1' />
                    </p>
                    <div style={{marginTop : '-8px'}}>
                        <img src='/images/5stars.svg' alt='5 stars'/> <span className='f-12'>9 vurderinger</span>
                    </div>
                    <div className='mt-2'>
                        <Row>
                            <Col>
                            <p className='mb-0'>Veitvet legesenter</p>
                            <img src='/images/person-walking.svg' alt='person walking' /> <span className='f-12'>15 min</span>
                            <img src='/images/car-side.svg' className='ms-3' alt='car side' /> <span className='f-12'>5 min</span>
                            </Col>
                            <Col className='text-right'>
                                <p className='mb-0'>Estimert ventetid</p>
                                <p className='mb-0 f-20 text-bold text-purple'>1-2 år</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                <div className='px-4 py-3 bg-gray br-20'>
                    <p className='text-bold mb-0'>Karina Bjerkeli Ridderseth
                    <FontAwesomeIcon icon={faChevronRight} className='float-end pt-1' />
                    </p>
                    <div style={{marginTop : '-8px'}}>
                        <img src='/images/4stars.svg' alt='5 stars'/> <span className='f-12'>6 vurderinger</span>
                    </div>
                    <div className='mt-2'>
                        <Row>
                            <Col>
                            <p className='mb-0'>Veitvet legesenter</p>
                            <img src='/images/person-walking.svg' alt='person walking' /> <span className='f-12'>10 min</span>
                            <img src='/images/car-side.svg' className='ms-3' alt='car side' /> <span className='f-12'>3 min</span>
                            </Col>
                            <Col className='text-right'>
                                <p className='mb-0'>Estimert ventetid</p>
                                <p className='mb-0 f-20 text-bold text-purple'>Ledig</p>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='mt-4 text-center'>
                    <p className='mb-0 f-20 text-bold text-purple'>Se alle</p>
                </div>
            </div>

            </div>
            <Footer/>
        </div>
    );
}