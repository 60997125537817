import Lottie from "lottie-react";
import checkCircle from "../../lottie/check-circle-sm.json";
import checkCircleBlack from "../../lottie/check-circle-black.json";

export const ButtonLottie = ({ button = 'Skilt valgt', left = '120px', isBlack = false }) => {
    return (
        <span className="d-flex align-items-center justify-content-center">
            <Lottie animationData={isBlack ? checkCircleBlack : checkCircle} loop={false} className="lottie-container-sm" style={{position : 'absolute', marginRight : left}} />
            <h4 className="text-center mb-0">{ button }</h4>
        </span>
    )
}