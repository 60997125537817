import React, { useEffect, useState } from 'react';
import { Navigation } from '../../../components/layout/Navigation';
import { Footer } from '../../../components/layout/Footer';
import axios from 'axios';
import { baseUrl, getHeaders, getMovingOrderId, getDisabledTasks } from '../../../helpers/Common';
import { VerticalItem } from '../../../components/layout/VerticalItem';

export const MovingLoadHome = () => {
    const [orders, getOrders] = useState({});  
    const [open, setOpen] = useState([]);
    const [disabledTasks, setDisabledTasks] = useState(getDisabledTasks());

    console.log(disabledTasks);

    const toggle = (id) => {
        console.log(id);
        open === id ? setOpen() : setOpen(id);
    };

    const untoggle = () => {

    }
        
    const verticalObjects = [{
            position : '1',
            title : 'Bestill flyttebyrå',
            description: 'Her kan du plukke og velge mellom sertifiserte flyttebyråer.',
            image: '../../images/svg/Between_moving_van.svg',
            progress: 0,
            button: 'Finn flyttebyrå',
            path : '/verticals/moving-load/company/step-1',
            enabled : true,
            type : 'relocation'
        }, {
            position: '2',
            title: 'Lei en flyttebil',
            description: 'Har du ikke noe imot å gjøre all bæringen selv? Da kan du leie en flyttebil her.', 
            image: '../../images/yellow/svg2/rental-car.svg',
            progress: 0,
            // button: 'Finn leiebil',
            button: 'Kommer snart',
            path : '/verticals/moving-load/car',
            enabled : false,
            type : 'car'
        },
    ];

    const fetchOrders = (type) => axios.get(baseUrl()+'moving_orders/'+getMovingOrderId()+'/orders', getHeaders()
    ).then(response => {
        if(response.data.data){
            
            setOpen([]);
            getOrders(response.data.data);

            verticalObjects.map((obj) => {
                if((!response.data.data[obj.type] && !open.includes(obj.position)) && !disabledTasks.includes(obj.type)){
                    setOpen(open => [...open, obj.position]);  
                }
            })
        }else{
            verticalObjects.map((obj) => {
                if(!disabledTasks.includes(obj.type)){
                    setOpen(open => [...open, obj.position]);  
                }
            })
        }
    })
    .catch(error => console.error(error));

    useEffect(() => {
        fetchOrders();

    // axios.delete(baseUrl()+'moving_orders/'+getMovingOrderId()+'/orders/material', getHeaders()
    // ).then(response => { console.log(response); console.log('ey'); } )
    // .catch(error => console.error(error));
    }, [])

    const verticalItems = verticalObjects.reverse().sort((a, b) => !disabledTasks.includes(a.type) ? -1 : !disabledTasks.includes(b.type) ? 1 : 1).map((obj) => 

    <>
        <VerticalItem orders={orders} obj={obj} open={open} setOpen={setOpen} disabledTasks={disabledTasks} setDisabledTasks={setDisabledTasks} />
    </>
    );

    return (
        <div className='AppContainer bg-between'>
            <Navigation />
            <div className='pt-4'>
                <img src='/images/Between_moving_van.png' alt='between icon hello'
                    style={{position : "absolute",
                            width : "200px", 
                            right : -20,
                            top : 130}} />

                <p className='p-header'>Flyttelass</p>
                <p className='mb-4 position-relative'> Bestill et flyttebyrå og <br/> slipp alt stress, eller flytt <br/> selv med våre flyttebiler. </p>
            </div>
            <div className='container-white'>
                <div className='container-white pt-4'>
                    {verticalItems}
                </div>
            </div>
            <Footer/>
        </div>
    );
}