import { Navigate, Outlet } from "react-router-dom";
import { getToken } from '../../helpers/Common';

export const UnprotectedRoute = ({ children }) => {
  const token = getToken();

  if (token) {
    // user is authenticated - force to dashboard page
    return <Navigate to="/dashboard" />;
  }  
  
  return (
    <div>
        <Outlet/>
    </div>
  )  
};