import React from 'react';

export const RegistrationHeader = ({title, body}) => {
    return (
        <div className='mt-5'>
            <img src='/images/Between_wave.webp' className='float-top-left' alt='between icon hello' style={{top : '120px'}} />
            <p className='p-header'>{ title }</p>
            <p className='mb-5 f-12 fw-semibold'> { body } </p>
        </div>
    )
}