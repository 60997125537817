import React from 'react';
import { formatPrice } from '../helpers/Common';
import { Link } from 'react-router-dom';

export const OrderInvoice = ({orders}) => {
    var sum = 0;
    var mva = 0;
    let element = (
        <div className="container" style={{margin: '2rem 1rem', width: '600px',
        fontSize: '14px',
        fontFamily: `'Times New Roman', Times, serif`}}>
        <div className="cross-image" style={{border: '1px solid #DEDEDE', 
            color: '#DEDEDE', 
            transform: 'rotate(-30deg) translateZ(0)',
            fontSize: '70px',
            position: 'absolute',
            zIndex: '999999',
            textAlign: 'center',
            opacity: '1',
            top: '200px',
            width : '560px;'}}>MIDLERTIDIG</div>
            <img src="/images/between.png" style={{width : '150px'}}/>
            <table style={{marginTop : '1rem', width : '100%'}}>
                <tr>
                    <td width="400px"></td>
                    <td>Fakturadato: </td>
                    <td> </td>
                </tr>
                <tr>
                    <td> </td>
                    <td className="text-bold">Forfallsdato:</td>
                    <td> </td>
                </tr>
                <tr>
                    <td className="pt-3 text-bold">FAKTURERES TIL</td>
                    <td className="pt-3 text-bold">Between AS</td>
                </tr>
                <tr>
                    <td className="text-bold pt-3">Tomas Ivanov</td>
                    <td className="pt-3">Tordenskiolds gate 2</td>
                </tr>
                <tr>
                    <td>Bergerveien 22 <br /> 1396, Billingstad</td>
                    <td>Org. nr.: 924 729 341 <br />
                        kundesenter@between.as</td>
                </tr>
            </table>
            <table className="mt-5 invoice-table w-100" cellSpacing="0" 
                style={{marginTop : '1rem', width : '100%', 
                borderSpacing: 0, borderCollapse: 'collapse!important', 
                border: '1px solid #c4bc96'}}>
                <thead>
                    <th style={{backgroundColor: '#d9d9d9' , borderRight: '1px solid #c4bc96', paddingTop: '.5rem', paddingBottom: '.5rem', 'textAlign' : 'center'}}>BESKRIVELSE</th>
                    <th style={{backgroundColor: '#d9d9d9' , borderRight: '1px solid #c4bc96', paddingTop: '.5rem', paddingBottom: '.5rem', 'textAlign' : 'center'}}>ANTALL</th>
                    <th style={{backgroundColor: '#d9d9d9' , borderRight: '1px solid #c4bc96', paddingTop: '.5rem', paddingBottom: '.5rem', 'textAlign' : 'center'}}>PRIS</th>
                    <th style={{backgroundColor: '#d9d9d9' , borderRight: '1px solid #c4bc96', paddingTop: '.5rem', paddingBottom: '.5rem', 'textAlign' : 'center'}}>SUM</th>
                    <th style={{backgroundColor: '#d9d9d9' , borderRight: '1px solid #c4bc96', paddingTop: '.5rem', paddingBottom: '.5rem', 'textAlign' : 'center'}}>MVA</th>
                </thead>
                <tbody>
                  { Object.keys(orders).map((key) => {
                    const order   = orders[key];
                    var details = [];
                    var price   = order.total;
                    if(key == 'cleaning'){
                        details = order.provider;
                    }else if(key == 'garbage'){
                        details = order.offer;
                    }else if(key == 'power'){
                        details = order.offer;
                        price = order.offer.subscription_price;
                    }else if(key == 'insurance'){
                        details = order.provider;
                        price = <Link to='/' className='dark-link text-underline'>Se avtale</Link>;
                        details.supplier = details.title;
                    }else if(key === 'material'){
                        details = order.units[0].offer;
                    }else if(key === 'postbox'){
                        details = order.units[0].offer;
                    }

                    if(key !== 'address_change' && key !== 'insurance'){
                    return (<tr>
                    <td style={{paddingLeft : '0.5rem', paddingRight : '0.5rem', borderRight: '1px solid #c4bc96', paddingTop: '.5rem', paddingBottom: '.5rem'}}>
                    { key !== 'relocation' ? details.title : 'Flyttelass' }
                    </td>
                    <td style={{paddingLeft : '0.5rem', paddingRight : '0.5rem', borderRight: '1px solid #c4bc96', paddingTop: '.5rem', paddingBottom: '.5rem'}}>1</td>
                    <td style={{paddingLeft : '0.5rem', paddingRight : '0.5rem', borderRight: '1px solid #c4bc96', paddingTop: '.5rem', paddingBottom: '.5rem'}}>
                      {formatPrice(price)}
                    </td>
                    <td style={{paddingLeft : '0.5rem', paddingRight : '0.5rem', borderRight: '1px solid #c4bc96', paddingTop: '.5rem', paddingBottom: '.5rem'}}>
                      {formatPrice(price)}
                    </td>
                    <td style={{paddingLeft : '0.5rem', paddingRight : '0.5rem', borderRight: '1px solid #c4bc96', paddingTop: '.5rem', paddingBottom: '.5rem'}}>{formatPrice(parseFloat(price) * 0.25)} (25%)</td>
                    </tr>)

                    sum = sum + price;
                    mva = mva + parseFloat(price) * 0.25;
                    }
                  })
                  }
                </tbody>
            </table>
            <table className="mt-3 w-100" cellSpacing="0" >
                <tbody>
                    <tr>
                        <td colSpan="4" align="right">SUM</td>
                        <td align="right" width="200px" className="pe-2">{ sum } kr</td>
                    </tr>
                    <tr>
                        <td colSpan="4" align="right">MVA</td>
                        <td align="right" width="200px" className="pe-2">{ mva } kr</td>
                    </tr>
                    <tr>
                        <td colSpan="4" align="right" className="text-bold">Sum å betale</td>
                        <td align="right" width="200px" className="pe-2">{ sum + mva } kr</td>
                    </tr>
                </tbody>
            </table>
            <hr className="mt-3" />
            <table className="w-100" cellSpacing="0" >
                <tbody>
                    <tr>
                        <td width="400px">DNB</td>
                        <td></td>
                        <td>KID:</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Kontonummer:</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    return element;
}