import React, {useState, useEffect} from 'react';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import { ButtonLottie } from '../../../../components/layout/ButtonLottie';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl, getHeaders, getMovingOrderId } from '../../../../helpers/Common';
import { ProductImage } from '../../../../components/layout/ProductImage';

export const MovingCompanyInsurance = () => {
    const [isLetter, setIsLetter] = useState(false);
    const navigate = useNavigate();
    const [providers, setProviders] = useState([]);
    const [pid, setPid] = useState(null);
    const [isLottie, setIsLottie] = useState(false);

    const handleInsuranceClick = (data) => {
        setIsLetter(true);
        setPid(data.id);
    }

    const handleSubmitClick = (data) => {
        if(pid)
        setIsLottie(true);

        axios.post(baseUrl()+'moving_orders/'+getMovingOrderId()+'/insurance',
        { provider_id : pid } , getHeaders()
        ).then(response => {
            if(response.data.status.code === '200'){
                navigate('/verticals/moving-company/insurance/confirm', { replace: true });
            }else{
                //already exists, back to home
                // navigate('/', {replace : true});
            }
            console.log(response);
        } 
            
        ).catch(error =>{
            console.error(error)
            // navigate('/', {replace : true});
        }
        );
    }

    const options = [
        { value: 'gjensidige', src:  '../../images/gjensidige.png' },
        { value: 'storebrand', src: '../../images/storebrand.png' },
        { value: 'fremtind', src: '../../images/fremtind.png' },
        { value: 'tryg', src: '../../images/tryg.png' },
      ]

      const fetchProducts = () => {
        axios.get(baseUrl()+'insurance_service_providers', getHeaders()
        ).then(response => setProviders(response.data.data))
        .catch(error => console.error(error))
    }

    useEffect(() => {
        fetchProducts();

    }, []);

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Forsikringsavtale</h1>
                <ProgressBar progress="100" classes="my-3"/>
                <h4 className='mb-3'>Steg 1/1</h4>
                {/* <p className='mb-4'>
                Velg ditt forsikringselskap nedenfor og vi flytter din forsikring. 
                </p> */}
            </div>
            <hr className='fluid' />
            <div className='container-white pt-4'>
                <h3 className='mb-3'>Velg ditt forsikringsselskap:</h3>
                <h4 className='text-bold'>Hvilke forsikringselskap har du i dag?</h4>

                <ListGroup className='list-insurance '>
                    {providers.map((obj, i) => 
                        <ListGroupItem key={i} className='border-bottom ins-item' data-id={obj.id} onClick={ (e) => handleInsuranceClick(e.target.dataset) }>
                            <ProductImage image={obj.title} className='h-24px' />
                        </ListGroupItem>
                    )}
                    <ListGroupItem>
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td className='f-14'>Finner du ikke ditt selskap?</td>
                                    <td align='center'>
                                        <Button className='btn-between-yellow'>Klikk her</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ListGroupItem>
                </ListGroup>

                { isLetter ?
                <div className='text-center px-3 mt-5'>
                    <div className='position-relative'>

                    <img src='/images/path14.png' alt='between icon hello'
                        style={{
                            position: 'absolute',
                            width : "100px",
                            top : '-40px', 
                            right : '-30px'}} />
                    <p className='f-11'
                        style={{
                            position : 'absolute',
                            width : "100px", 
                            right : '-40px',
                            top : '-10px',
                            textAlign : 'left'}}>100% fornødgarranti</p>
                    </div>
                    <p className='mb-3 text-bold'>Hva skjer videre</p>
                    <div className='light-box'>
                        <p className='text-left mb-3 text-bold'>Du blir kontaktet av Gjensidige</p>
                        <p className='text-left f-14 mb-3'>Vi flytter flytter alle dine forsikringer hos Gjensidige. For boligrelaterte forsikringer som innboforsikring og eventuelt husforsikring vil du få tilbud om en ny avtale tilpasset din nye bolig. </p>
                        <p className='text-left f-14'>Din forsikringsrådgiver hos Gjensidige ringer deg i løpet av 2 virkedager for å bekrefte flyttingen.</p>
                    </div>

                    <div className='py-4'>
                        <button className='mt-4 btn-between-yellow-lg' onClick={ handleSubmitClick }>
                        { isLottie ? 
                        <ButtonLottie  button='Send inn' left='100px' /> : 
                        <span>Send inn</span>
                        } 
                        </button>
                    </div>
                </div> 
                : 
                <div className='py-4'></div>
                }
            </div>
            <Footer/>
        </div>
    );
}