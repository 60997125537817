import React, { useEffect, useState } from 'react';
import {  Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from '../../../components/layout/Navigation';
import { Footer } from '../../../components/layout/Footer';  
import { ProgressBar } from '../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl, getHeaders, formatPrice, displayAddress, capsFirst } from '../../../helpers/Common';
import moment from 'moment';
import { VerticalHeader } from '../../../components/layout/VerticalHeader';

export const ProtocolCompleteOrder = () => {
    const protocol1         = sessionStorage.getItem('protocol_step_1') ? JSON.parse(sessionStorage.getItem('protocol_step_1')) : null;
    const sellersName       = sessionStorage.getItem('sellers_name') ? JSON.parse(sessionStorage.getItem('sellers_name')) : null;
    const buyersName        = sessionStorage.getItem('buyers_name') ? JSON.parse(sessionStorage.getItem('buyers_name')) : null;
    const sellerAddressObj  = sessionStorage.getItem('seller_address_object') ? sessionStorage.getItem('seller_address_object') : null;
    const meterReading      = sessionStorage.getItem('meter_reading') ? sessionStorage.getItem('meter_reading') : null;
    const meterNumber       = sessionStorage.getItem('meter_number')  ? sessionStorage.getItem('meter_number')  : null;
    const meterCheck        = sessionStorage.getItem('meter_check')   ? sessionStorage.getItem('meter_check')   : null;
    const powerItem         = sessionStorage.getItem('protocol_power_item')   ? JSON.parse(sessionStorage.getItem('protocol_power_item')) : null;
    const invoiceReceiver   = sessionStorage.getItem('invoice_receiver')   ? JSON.parse(sessionStorage.getItem('invoice_receiver')) : null;
    const protocol7   = sessionStorage.getItem('protocol_step_7')  ? JSON.parse(sessionStorage.getItem('protocol_step_7'))  : null;
    const protocol8   = sessionStorage.getItem('protocol_step_8')  ? JSON.parse(sessionStorage.getItem('protocol_step_8'))  : null;
    const protocol9   = sessionStorage.getItem('protocol_step_9')  ? JSON.parse(sessionStorage.getItem('protocol_step_9'))  : null;
    const protocol10  = sessionStorage.getItem('protocol_step_10') ? JSON.parse(sessionStorage.getItem('protocol_step_10')) : null;
    const protocol11  = sessionStorage.getItem('protocol_step_11') ? JSON.parse(sessionStorage.getItem('protocol_step_11')) : null;
    const protocol12  = sessionStorage.getItem('protocol_step_12') ? JSON.parse(sessionStorage.getItem('protocol_step_12')) : null;
    const protocol13  = sessionStorage.getItem('protocol_step_13') ? JSON.parse(sessionStorage.getItem('protocol_step_13')) : null;
    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    const navigate = useNavigate();
    const [signatureModal, setSignatureModal] = useState(false);
    const [viewSignature, setViewSignature] = useState(null);
    const toggleModal = ( id ) =>{

        if(protocol13.signatures[id]){
            setViewSignature(protocol13.signatures[id]);
        }

        setSignatureModal(!signatureModal);
    } 

    const handleClickSend = () => {
        if(!protocolProgress.includes('step14')){
            protocolProgress.push('step14');
            sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
        }
        navigate('/protocol/confirm');
    }

    const displayValuesByColumn = (question, answer, isCaps = true) =>{
        return <Row>
            <Col xs={6}>{question}</Col>
            <Col className='float-end'>{capsFirst(answer)}</Col>
        </Row>
    }
    const displayValuesByRow = (question, answer, isCaps = true) =>{
        return <Row>
                <Col>
                    <p className='text-bold mb-0'>{question}</p>
                    <p className='mb-0'>{isCaps ? capsFirst(answer) : answer}</p>
                </Col>
            </Row>
    }

    const yesOrNo = ( val ) => {
        return val === 'false' ? 'No' : 'Yes';
    }

    const hr = () => {
        return <hr></hr>
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <VerticalHeader title='Fullfør bestilling' progress={14} totalProgress={14} />

            <div className='pt-3'>
                {protocol1 && <>
                <Row>
                    <Col>
                        <h3>Bekreftelse</h3>
                        { displayValuesByColumn('Bereftelse mottatt', protocol1.confirmation) }
                        { displayValuesByColumn('Tilstedeværelse av Selger og Kjøper', protocol1.both_represented) }
                    </Col>
                </Row>
                { hr() }</>
                }
                <Row>
                    <Col>
                        <h3>Kjøper og selger</h3>
                        {displayValuesByRow('Selger', sellersName.join(", "))}
                        {displayValuesByRow('Kjøper', buyersName.join(", "))}
                    </Col>
                </Row>
                { hr() }
                <Row>
                    <Col>
                        <h3>Ny adresse selger</h3>
                        {displayValuesByRow('Adresse', displayAddress(sellerAddressObj, true), false)}
                    </Col>
                </Row>
                { hr() }
                <Row>
                    <Col>
                        <h3>Avlesning av strømmåler</h3>
                        { displayValuesByColumn('Fellesmåling', yesOrNo(meterCheck)) }
                        {/* { displayValuesByColumn('Målerstand', meterReading) } */}
                        {/* { displayValuesByColumn('Målernummer', meterNumber) } */}
                    </Col>
                </Row>
                { hr() }
                <Row>
                    <Col>
                        <h3>Bestilling av strøm</h3>
                        { displayValuesByColumn('Avtaler', powerItem.title) }
                    </Col>
                </Row>
                { hr() }
                <Row>
                    <Col>
                        <h3>Ansvarlig for strømbestilling</h3>
                        { displayValuesByColumn('Mottaker', invoiceReceiver.name) }
                    </Col>
                </Row>
                { hr() }
                <Row>
                    <Col>
                        <h3>Bekreftelse</h3>
                        { displayValuesByColumn('Vannmåler eller fjernvarme', protocol7.water_meter) }
                        { protocol7.water_meter !== 'no' && displayValuesByColumn('Målerstand', protocol7.meter_reading) }
                    </Col>
                </Row>
                { hr() }
                <Row>
                    <Col>
                        <h3>Bestilling av strøm</h3>
                        { displayValuesByColumn('Kommentar', protocol8.comment ? protocol8.comment : 'N/A') }
                    </Col>
                </Row>
                { hr() }
                <Row>
                    <Col>
                        <h3>Brannsikkerhet</h3>
                        { protocol9.røykvarsler && displayValuesByColumn('Røykvarsler', 'Yes') }
                        { protocol9.brannslukningsapparat && displayValuesByColumn('Brannslukningsapparat', 'Yes') }
                        { protocol9.brannslange && displayValuesByColumn('Brannslange', 'Yes') }
                        { protocol9.boligalarm && displayValuesByColumn('Boligalarm', 'Yes') }
                        { displayValuesByColumn('Kommentar', protocol9.comment ? protocol9.comment : 'N/A') }
                    </Col>
                </Row>
                { hr() }
                <Row>
                    <Col>
                        <h3>Pro & Contra</h3>
                        { displayValuesByColumn('Kommentar', protocol10.comment ? protocol10.comment : 'N/A') }
                    </Col>
                </Row>
                { hr() }
                <Row>
                    <Col>
                        <h3>Tilbakehold</h3>
                        { displayValuesByColumn('Tilbakehold', protocol11.retention) }
                    </Col>
                </Row>
                { hr() }
                <Row>
                    <Col>
                        <h3>Nøkler</h3>
                        { displayValuesByColumn('Kommentar', protocol12.comment ? protocol12.comment : 'N/A') }
                    </Col>
                </Row>
                { hr() }
                <Row>
                    <Col>
                        <h3>Signatur</h3>
                        {protocol13.signatures &&
                            protocol13.signatures.map((sign, i) => {
                                if(sign.signature){
                                    return <Row>
                                    <Col>
                                        {sign.name}
                                    </Col>
                                    <Col>
                                        <p onClick={() => toggleModal(i) } className='text-black text-underline mb-0 cursor-pointer'>View signature</p>
                                    </Col>
                                </Row>
                                }
                            })                        
                        }
                    </Col>
                </Row>
                <div className='mb-4 mt-5'>
                    <Row>
                        <Col>
                            <Link to={'/protocol/step-13'}className='btn-between-white w-100'>Gå tilbake</Link>
                        </Col>
                        <Col>
                            <Button data-link='/protocol/confirm' className='btn-between w-100' onClick={ handleClickSend }>Send bestilling</Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer/>
            <Modal isOpen={ signatureModal } toggle={toggleModal} centered size='sm'>
                <ModalBody className='text-center'>
                    <h3>Signatur</h3>
                    <Row className='my-4'>
                        <Col><img className='img-fluid' src={viewSignature?.signature} alt='signature'/></Col>
                    </Row>
                    <Row className='f-12'>
                        <Col><strong>{viewSignature?.name}</strong> <br /> {moment(viewSignature?.timestamp).format('DD MMMM, YYYY HH:MM:SS A')}</Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    );
}