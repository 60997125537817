import { Navigation } from "../../../../components/layout/Navigation";
import { Footer } from "../../../../components/layout/Footer";
import { Button, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Form } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLottie } from "../../../../components/layout/ButtonLottie";
import { useEffect } from "react";
import { VerticalHeader } from "../../../../components/layout/VerticalHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { cleanString, displayAddress, displayAddressDefault, formatPrice } from "../../../../helpers/Common";
import moment from "moment/moment";
import { MovingBoxesPrices } from "./MovingBoxesPrices";

export const MovingBoxesCompleteOrder = () => {
    const boxesProgress = sessionStorage.getItem('boxes_progress') ? JSON.parse(sessionStorage.getItem('boxes_progress')) : [];
    var   boxesStep1    = sessionStorage.getItem('boxes_step_1')   ? sessionStorage.getItem('boxes_step_1') : '';
    var   boxesStep2    = sessionStorage.getItem('boxes_step_2')   ? JSON.parse(sessionStorage.getItem('boxes_step_2')) : '';
    var   boxesStep2cp    = sessionStorage.getItem('boxes_step_2_size_objects')   ? JSON.parse(sessionStorage.getItem('boxes_step_2_size_objects')) : [];
    var   boxesStep3  = sessionStorage.getItem('boxes_step_3')   ? JSON.parse(sessionStorage.getItem('boxes_step_3')) : [];
    const navigate = useNavigate();
    const [totalPrice, setTotalPrice] = useState(0);
    const fromAddress = sessionStorage.getItem('from_address') ? sessionStorage.getItem('from_address') : {}
    const toAddress  = sessionStorage.getItem('to_address') ? sessionStorage.getItem('to_address') : {}
    const [isLottie, setIsLottie]   = useState(null);


    useEffect(() => {
        const prices = document.getElementsByClassName('prices');
        var tempPrice = 0;
        if(prices.length > 0){
            for (let index = 0; index < prices.length; index++) {
                const element = prices[index];
                const price = element.dataset.price;
                
                tempPrice = tempPrice + parseInt(price);
            }
            setTotalPrice(tempPrice);
            sessionStorage.setItem('boxes_total_items_price', tempPrice);
        }

    }, []);

    const handleBtnConfirm = () => {
        sessionStorage.setItem('boxes_order_confirmed', true);
        setIsLottie(true);

        setTimeout(function(){
            navigate('/verticals/moving-order/boxes/complete-order');
        }, 1000)
    }

    const timelineObjects = [{
        id : 1,
        title : 'Torsdag',
        description: 'Bestilling mottatt',
        date: '20.05',
        status : 'current'
    },{
        id : 2,
        title : 'Onsdag',
        description: 'Du blir kontaktet for å bekrefte tidspunkt for levering',
        date: '18.06',
        status : 'pending'
    },{
        id : 3,
        title : 'Tirsdag',
        description: 'Forventet leveringsdato',
        date: '20.06',
        status: 'pending'
    },{
        id : 4,
        title : 'Onsdag',
        description: 'Du blir kontaktet for å bekrefte tidspunkt for henting',
        date: '01.07',
        type: 'normal',
        status: 'pending'
    },{
        id : 5,
        title : 'Tirsdag',
        description: 'Forventet leveringsdato',
        date: '20.06',
        status: 'pending'
    }];
    const timelineItems = timelineObjects.map((tObj, i) => 
        <div className={`timeline-section ps-3`} key={i}>
            <div className={`${tObj.id !== 5 && 'pb-4'} time-line-text-purple ps-4 d-flex align-items-center`}>
                { tObj.status === 'current' ? 
                <div className="position-absolute" style={{left: 28}}>
                    <img src="/images/boxes/purple-check-light.png"  alt='' style={{marginTop: '-1px' , width : '26px'}}/>
                </div>
                : 
                <div className="position-absolute d-flex align-items-center justify-content-center" style={{left: 34}}>
                    <img src="/images/boxes/pending-circle.png"  alt='' style={{width : '15px'}}/>
                </div>
                }
                
                <div className='ms-2'>
                    <p className='text-bold mb-0'>{tObj.title} {tObj.date}</p> { tObj.description }
                </div>
            </div>
        </div>
    );
    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <VerticalHeader title='Fullfør bestilling' progress={false}/>
            <MovingBoxesPrices />
            <div className="my-4">
                {boxesStep1 === 'friendly_boxes' &&
                <div>
                    <h3>Hente detaljer</h3>
                    <p className="mb-0">{displayAddressDefault(fromAddress)}</p>
                    <p><strong>Dato:</strong> {moment(boxesStep2.pickup_date).format('MM.DD.YYYY')} </p>
                </div>
                }
                <div>
                    <h3>Leverings detaljer</h3>
                    <p className="mb-0">{displayAddressDefault(toAddress)}</p>
                    <p><strong>Dato:</strong> {moment(boxesStep2.delivery_date).format('MM.DD.YYYY')} </p>
                </div>
            </div>
            <div className="my-4">
                <h3>Leveringsinformasjon</h3>
                <p>Vi vil kontakte deg senest 2 dager før hente- og leveringsdato å bekrefte noen siste detaljer. </p>
                <p>Har du noen spørsmål i mellomtiden kan du ta kontakt med våre flyttekonsulenter.</p>
            </div>
            <div className="my-4">
                <h3 className="mb-4">Hva skjer nå?</h3>
                {timelineItems}
            </div>
            <Row className="text-center mb-4 mt-5">
                <Col>
                    <Link to="/tasks" className="btn-between-white w-100" style={{paddingTop : '8px', lineHeight: '15px'}}>Gå til neste oppgave</Link>
                </Col>
                <Col>
                    <Link to="/verticals/moving-order/boxes/status" className="w-100 btn-between">Se ordre status</Link>
                </Col>
            </Row>
            <Footer/>
        </div>
    );
}