import React, { useEffect, useState } from 'react';
import {  Button, Col, Row } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';  
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl, getHeaders, getMovingOrderId, formatDate } from '../../../../helpers/Common';
import moment from 'moment';

export const MovingCompanyPowerCompleteOrder = () => {
    const navigate = useNavigate();
    const moving_order = getMovingOrderId();
    const [powerItems, getPowerItems] = useState(JSON.parse(sessionStorage.getItem('power_items')));    
    console.log(powerItems);

    const handleClickSend = () => {
        axios.post(baseUrl()+'moving_orders/'+moving_order+'/power',
        { offer_id : powerItems.pid } , getHeaders()
        ).then(response => {
            if(response.data.status.code === '200'){
                navigate('/verticals/moving-company/power/confirm', { replace: true });
            }else{
                //already exists, back to home
                navigate('/', {replace : true});
            }
            console.log(response);
        } 
            
        ).catch(error =>{
            console.error(error)
            navigate('/', {replace : true});
        }
        );
    }
        return (
            <div className='AppContainer bg-white'>
                <Navigation />
                <div className='pt-4'>
                    <h1>Fullfør bestilling</h1>
                    <ProgressBar progress="100" classes="my-3"/>
                    <h4 className='mb-3'>Steg 2/2</h4>
                    {/* <p>For å fullføre bestilling må vi bekrefte noe informasjon og  informasjon om hvordan du ønsker å betale. </p> */}
                </div>
                <hr className='fluid' />
                <div className='container-white pt-4'>
                    <h3 className='mb-0'>Bestillingsdetaljer</h3>
                    
                    <div className='mt-4'>
                        <h3 className='mb-0'>Adresse:</h3>
                        <p>Skogvollveien 5C <br/>0572 Oslo</p>
                    </div>
                    
                    <div className='mt-4'>
                        <h3 className='mb-0'>Dato for oppstart:</h3>
                        <p>{ moment(sessionStorage.getItem('moving_date')).format("DD, MM YYYY") }</p>
                    </div>

                    <div className='mt-4'>
                    <h3 className='mb-0'>Leveringsinformasjon:</h3>
                        <p>For at strømleverandøren skal kunne aktivers din avtale må du sende inn målepunktid. Dette er et nummer som står på strømmåleren i boligen.</p>
                        <p>Vi hjelper deg med å finne og sende inn målerpunktid etter du har flyttet inn.</p>
                    </div>
                    <div className='my-4'>
                        <Row>
                            <Col>
                                <Link to='/verticals/moving-company/power' className='btn-between-white w-100'>Gå tilbake</Link>
                            </Col>
                            <Col>
                                <Button className='btn-between w-100' onClick={ handleClickSend }>Send bestilling</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Footer/>
            </div>
        );
}