import React, { useEffect, useState } from 'react';
import { ListGroupItem, ListGroup, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import { getHeaders, upFirst, baseUrl, displayAddress, getLoggedUser } from '../../../../helpers/Common';
import axios from 'axios';
import ReactPaginate from 'react-paginate';

export const AddressAndPostRelocation = () => {
    const navigate = useNavigate();
    const [inputSearch, setInputSearch] = useState('');
    const [companiesResult, setCompaniesResult] = useState([]);
    const [companiesSelected, setCompaniesSelected] = useState(sessionStorage.getItem("companies_selected") ? JSON.parse(sessionStorage.getItem("companies_selected")) : []);
    const [modal, setModal] = useState(false);
    const [company, setCompany] = useState({id: null, name: null});
    const [pageSize, setPageSize] = useState(0);
    const pageCount = 7;

    const [memberItems, setMemberItems] = useState([]);
    const moving_order = sessionStorage.getItem('moving_order');
    const [mainUserId, setMainUserId] = useState(0);

    //get all members
    const fetchUsers = () => {
        axios.get(baseUrl()+'moving_orders/'+moving_order+'/users', getHeaders()
        ).then(response => {
            setMemberItems(response.data.data);

            //temp
            setMainUserId(response.data.data[0].id);
            console.log('woo id', mainUserId);
        })
        .catch(error => console.error(error))
    }

    useEffect(() => {
        fetchUsers();

        console.log(memberItems);
    }, []);


    const toggle = (e) => {
        setCompany({...company, name : e.target.dataset.name, id : e.target.dataset.id});
        setModal(!modal)
    };

    //search companies    
    const fetchCompanies = (type, keyword) => {
        const data = type === 'category' ? {'organization-category' : keyword} : {'organization-search' : keyword};
        axios.post('https://organizations-microservices.betwn.no/rest-api/organizations/v1/organization/search',
        data).then(response => {
            if(response.data.status.code === '200'){
                const companies = response.data.data.organizations;
                setCompaniesResult(companies);
            }
            
        }).catch(error => {
            console.error(error);
        });
    } 

    useEffect(() => {
        fetchCompanies('category', 'ofte brukte');
    }, [])

    //fetch selected companies
    const fetchSelectedCompanies = (type, keyword) => {
        axios.get(baseUrl()+'moving_orders/'+moving_order+'/address_change', getHeaders()
        ).then(response => {
            if(response.data.status.code === '404'){

            }
        })
        .catch(error => console.error(error))
    } 

    useEffect(() => {
        fetchSelectedCompanies();
    }, [])

    //select company and add to company list
    const handleCategoryClick = (e, type) => {
        const optionId = "option-"+type;
        var isActive = false;

        const activeElement = document.getElementById(optionId);
        var options = document.getElementsByClassName('btn-between-white');

        if(!activeElement.classList.contains('active')){
            isActive = true;
        }

        //remove active classes to options
        for (let i = 0; i < options.length; i++) {
            options[i].classList.remove('active');                
        }

        type = type === 'ofte' ? 'ofte brukte' : type; 

        fetchCompanies('category', type);

        //add active classes to selected option or not            
        if(isActive){
            activeElement.classList.add('active');
        }

        setInputSearch('');
    }

    //add selected company to the selected list
    const handleCompanyAddClick  = (e) => {
        var isExists = false;
        var users = [];
        var userCheckBoxes = document.querySelectorAll('input[name=users]:checked');
        //hide modal
        toggle(e);

        //check if company already added
        Object.keys(companiesSelected).map((key, index) => {
            if(companiesSelected[key].organization_number === e.target.dataset.id){
                isExists = true;
            }
        });

        for (let index = 0; index < userCheckBoxes.length; index++) {
            var checkbox = userCheckBoxes[index];
            if(checkbox.value){
                users.push( {id : parseInt(checkbox.value)});
            }
        }

        if(users.length === 0){
            users = [ {id : mainUserId} ];
        }
        //is not exists, add to the company list
        if(!isExists){
            setCompaniesSelected([...companiesSelected, {organization_number : e.target.dataset.id, organization_name : e.target.dataset.name, users : users}]);
        }
    }

    //remove company to list
    const handleRemoveCompany = (e) => {
        console.log(e.target.dataset.id);
        setCompaniesSelected(companiesSelected.filter(item => item.organization_number !== e.target.dataset.id));
    }

    //company search
    const handleCompanySearch =(e) => {
        var value = e.target.value;

        if(value.length >= 1){
            console.log(value);
            fetchCompanies('search', e.target.value);
        }

        if(value.length === 0){
            fetchCompanies('category', 'ofte brukte');
        }

        setInputSearch(e.target.value);

    }

    //submit to the api
    const handleClickNext = () => {
        console.log(companiesSelected);

        if(companiesSelected.length > 0){
            console.log(companiesSelected);

            //save compaies to session
            sessionStorage.setItem("companies_selected", JSON.stringify(companiesSelected));
            navigate('/verticals/address-and-post/relocation/complete-order');
        }

        // axios.post(baseUrl()+'moving_orders/'+moving_order+'/address_change',
        // {organizations: companiesSelected }, getHeaders()
        // ).then(response => {
        //     if(response.data.status.code === '200'){

        //         //save companies to session
        //         sessionStorage.setItem('selected_companies', companiesSelected);
        //         navigate('/verticals/address-and-post/relocation/confirm', { replace: true });
        //     }else{
        //         //already exists, back to home
        //         // navigate('/', {replace : true});
        //     }
        //     console.log(response);
        // } 
            
        // ).catch(error =>{
        //     console.error(error)
        //     // navigate('/', {replace : true});
        // }
        // );
    }

    function Items({ currentItems }) {
        return (
          <>
          {currentItems && Object.keys(currentItems).map((item, key) => (
            <ListGroupItem key={key} className='upfirst bg-dark-gray'> 
                <Row>
                    <Col xs={8}>
                    <span>{ upFirst(currentItems[item].name) }</span>
                    </Col>
                    <Col xs={4}>
                    <button className='px-2 float-end btn btn-between-option btn-sm f-14' data-id={ currentItems[item].id } data-name={ currentItems[item].name } onClick={(e) => toggle(e)}>Legg til</button>
                    </Col>
                </Row>
            </ListGroupItem>
          ))}
            </>
        );
    } 
      
    function PaginatedItems({ itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    
    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
    
        setCurrentItems(Object.keys(companiesResult).slice(itemOffset, endOffset).reduce((result, key) => {

        result[key] = companiesResult[key];
        return result;
        }, {}));

        setPageCount(Math.ceil(Object.entries(companiesResult).length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);
    
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % Object.entries(companiesResult).length;
        setItemOffset(newOffset);
    };
    
    return (
        <>
        <ListGroup className='pe-0 py-0'>
        <Items currentItems={currentItems} />
        </ListGroup>
        <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
        />
        </>
    );
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Flyttemeldinger</h1>
                <ProgressBar progress="50" classes="my-3"/>
                <h4 className='mb-3'>Steg 1/2</h4>
                {/* <p className='mb-4'>Velg selskaper du ønsker å sende flyttemelding til. Du kan enten bruke kategorier eller søke manuelt etter selskaper.</p> */}
            </div>
            <hr className='fluid' />
            <div className='container-white py-4'>
                <div>
                    <h3 className='mb-3'>Velg de selskapene du ønsker å sende flyttemelding til:</h3>
                    <div className='bg-gray p-4 br-20'>
                        <div className='px-3'>
                            <Row>
                                <Col xs={6} className='pe-1'>
                                    <button id='option-ofte' className=" br-20 px-0 btn btn-between-white active" onClick={((e) => handleCategoryClick(e, 'ofte'))}>Ofte brukte</button></Col>
                                <Col xs={6} className='ps-1'>
                                    <button id='option-aviser' className="btn btn-between-white" onClick={((e) => handleCategoryClick(e, 'aviser'))}>Aviser</button>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={6} className='pe-1'>
                                    <button id='option-medlemskap' className="px-0 btn btn-between-white" onClick={((e) => handleCategoryClick(e, 'medlemskap'))}>Medlemskap</button></Col>
                                <Col xs={6} className='ps-1'>
                                    <button id='option-magasiner' className="btn btn-between-white" onClick={((e) => handleCategoryClick(e, 'magasiner'))}>Magasiner</button>
                                </Col>
                            </Row>
                        </div>
                        <div className='mt-4'>
                            <p className='mb-0'>Søk etter selskaper</p>
                            <input type="text" className="form-control input-square" placeholder="Søk etter selskap eller organisasjon" onChange={ handleCompanySearch } value={ inputSearch } />
                        </div>
                        <div className='bg-dark-gray mt-4 pt-2 pb-2'>
                            <PaginatedItems itemsPerPage={7} />
                        </div>
                    </div>
                    {companiesSelected.length !== 0 &&
                    <>
                    
                    <p className='mb-0 mt-4 mb-0'>Selskaper du har valgt:</p>
                    <div className='bg-dark-gray p-4'>
                        { Object.keys(companiesSelected).map((key, index) => {
                            return  <Row key={key} >
                                <Col xs={8}>
                                <span>{ upFirst(companiesSelected[key].organization_name) }</span>
                                </Col>
                                <Col xs={4}>
                                    <button className='btn-close-sm float-end mt-2' data-id={companiesSelected[key].organization_number}  onClick={(e) => handleRemoveCompany(e) }></button>
                                </Col>
                            </Row>
                        }) }
                    </div>
                    </>
                        }

                    <div className='my-5 mx-4'>
                        <button to="/verticals/address-and-post/relocation/confirm" className='btn btn-between w-100' onClick={ handleClickNext }>Gå videre</button>
                    </div>
                </div>
                
                
            </div>
            <Footer/>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className='d-block'>
                    <span className='upfirst'>{ upFirst(company.name) }</span>
                    <button className='btn-close float-end pt-3' onClick={ toggle }></button>
                </ModalHeader>
                <ModalBody>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <td>Flytter fra</td>
                            <td>Flytter til</td>
                        </tr>
                    </thead> 
                    <tbody>
                        <tr>
                            <td>{ displayAddress(sessionStorage.getItem('from_address'))}</td>
                            <td>{ displayAddress(sessionStorage.getItem('to_address'))}</td>
                        </tr>
                    </tbody>
                </table>
                        
                { memberItems && memberItems.length > 1 &&
                <table className='table mt-4'>
                    <thead>
                        <tr>
                            <td colSpan={2} className='text-bold'>Flyttemeldingen gjelder for</td>
                        </tr>
                    </thead>
                    <tbody>
                    {memberItems.map((memberObj, i) => {
                    return <tr key={i}>
                                <td width='15px'>
                                    <input type='checkbox' name='users' id={'users_'+i} value={memberObj.id}/>
                                </td>
                                <td>
                                    <label for={'users_'+i}>{ memberObj.name }</label>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
                }
                <button data-id={ company.id } data-name={company.name} id='btnAddCompany' onClick={(e) => handleCompanyAddClick(e) } className=' mt-4 btn-between w-100 br-min' >Bekreft flyttemelding</button>
                </ModalBody>
            </Modal>
        </div>
    );
}