import React from 'react';
import { Navigation } from '../../components/layout/Navigation';
import { RegistrationHeader } from '../../components/layout/RegistrationHeader';
import { UserEditForm } from '../../components/layout/users/UserEditForm';

export const RegistrationEditPerson = () => {

    return (
        <div id='EditUserContainer' className='AppContainer bg-between'>
            <Navigation />
            <RegistrationHeader title='Husstand'/>
            <div className='container-white pt-2 pb-4'>
                <UserEditForm type='registration' />
            </div>
        </div>
    )
}