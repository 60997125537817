import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, Button, Accordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";
import axios from 'axios';
import { getHeaders, baseUrl, getProductTitle} from '../../helpers/Common';
import { ParseHTML } from '../../components/elements/ParseString';
import { TooltipItem } from '../../components/layout/TooltipItem';

export const FAQProduct = ({ product }) => {
    const [open, setOpen] = useState('pos-0');
    const [modal, setModal] = useState(false);
    const [faq, getFaq] = useState(null);
    
    const modalToggle = (e) => {
        setModal(!modal)
    };

    const accordionToggle = (id) => {
        open === id ? setOpen() : setOpen(id);
    };
    const fetchFaq = () => {
        axios.get(baseUrl()+'faqs?subcategory='+product, getHeaders()
        ).then(response => getFaq(response.data.data))
        .catch(error => console.error(error))
    }       
    
    useEffect(() => {
        fetchFaq();

        console.log(product);
    }, []); 

    return (
    <>
        <div className='text-center'>
            <Button className='px-3 btn-between' onClick={ modalToggle }><h4 className='mb-0'>Viktig informasjon</h4></Button>
        </div>

        <Modal isOpen={modal} toggle={modalToggle}>
            <ModalHeader className='d-block'>{ getProductTitle(product) }
             <button className='btn-close float-end pt-3' onClick={ modalToggle }></button></ModalHeader>
            <ModalBody>
            { faq?.map((obj, i) => {
            const id = 'pos-'+i;
            return <Accordion open={open} className='mb-2' toggle={accordionToggle} key={i}>
                <AccordionItem key={i} className='vertical-classic p-2'>
                    <AccordionHeader targetId={ id }> <span>{obj.question}</span> </AccordionHeader>
                    <AccordionBody accordionId={ id }>
                        <ParseHTML string={obj.answer}></ParseHTML>
                        
                        {product === 'container' &&
                        <>
                        <TooltipItem src='/images/containere-hero.webp' id='tooltipContainer'></TooltipItem>
                        <TooltipItem src='/images/container2.webp' id='tooltipContainer2'></TooltipItem>
                        </>
                        }

                        {product === 'garbage_bag' &&
                        <>
                            <TooltipItem src='/images/smartbag_small.jpeg' id='tooltipSmall'></TooltipItem>
                            <TooltipItem src='/images/smartbag_medium.jpeg' id='tooltipMedium'></TooltipItem>
                            <TooltipItem src='/images/smartbag_big.jpeg' id='tooltipBig'></TooltipItem>
                        </>
                        }
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
            }
            )}
            <center className='mt-5'>
                <button className='btn-between w-190' onClick={ modalToggle }>Lukk</button>
            </center>
            </ModalBody>
        </Modal>
    </>
    );
}