import React, { useState, useEffect } from 'react';
import { Navigation } from '../../../components/layout/Navigation';
import { Footer } from '../../../components/layout/Footer';
import axios from 'axios';
import { baseUrl, getHeaders, getMovingOrderId, getDisabledTasks } from '../../../helpers/Common';
import { VerticalItem } from '../../../components/layout/VerticalItem';

export const AddressAndPost = () => {    
    const [orders, getOrders] = useState({});  
    const [open, setOpen] = useState([]);
    const [disabledTasks, setDisabledTasks] = useState(getDisabledTasks());

    const toggle = (id) => {
        console.log(id);
        open === id ? setOpen() : setOpen(id);
    };

    const untoggle = () => {

    }

    const verticalObjects = [{
            position : '1',
            title : 'Endre adresser',
            description: 'Her kan du endre adresse hos alle selskaper du har et kundeforhold til.',
            image: '../../images/yellow/svg2/address-change.svg',
            progress: 0,
            button: 'Søk etter selskaper',
            path : '/verticals/address-and-post/relocation',
            type : 'address_change',
            enabled : true,
        }, {
            position: '2',
            title: 'Postkasseskilt',
            description: 'Sørg for at posten kommer frem med et postkasseskilt.', 
            image: '../../images/pink/svg2/postbox.svg',
            progress: 0,
            button: 'Bestill postkasseskilt',
            path : '/verticals/address-and-post/mailbox',
            type : 'postbox',
            enabled : true,
        },
    ];

    const fetchOrders = (type) => axios.get(baseUrl()+'moving_orders/'+getMovingOrderId()+'/orders', getHeaders()
    ).then(response => {
        if(response.data.data){
            
            setOpen([]);
            getOrders(response.data.data);

            verticalObjects.map((obj) => {
                if((!response.data.data[obj.type] && !open.includes(obj.position)) && !disabledTasks.includes(obj.type)){
                    setOpen(open => [...open, obj.position]);  
                }
            })
        }else{
            verticalObjects.map((obj) => {
                if(!disabledTasks.includes(obj.type)){
                    setOpen(open => [...open, obj.position]);  
                }
            })
        }
    })
    .catch(error => console.error(error));

    useEffect(() => {
        fetchOrders();
    }, [])

    const verticalItems = verticalObjects.reverse().sort((a, b) => !disabledTasks.includes(a.type) ? -1 : !disabledTasks.includes(b.type) ? 1 : 1).map((obj) =>
    <>
        <VerticalItem orders={orders} obj={obj} open={open} setOpen={setOpen} disabledTasks={disabledTasks} setDisabledTasks={setDisabledTasks} />
    </>
    );

    return (
        <div className='AppContainer bg-between'>
            <Navigation />
            <div className='pt-4'>
                <img src='/images/Between_agreement2.png' alt='between icon hello'
                    style={{position : "absolute",
                            width : "200px",
                            right: "0px",
                            top: "230px"}} />

                <p className='p-header'>Adresse og postkasseskilt</p>
                <p>Her kan du endre <br/> adresse hos alle <br/> selskaper du har et <br/> kundeforhold til.</p>
                <p className='mb-4'>Du kan også bestille <br/> postkasseskilt.</p>
            </div>
            <div className='container-white pt-4'>
                {verticalItems}
            </div>
            <Footer/>
        </div>
    );
}