import React, { useState } from 'react';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ProgressBar } from '../../components/layout/ProgressBar';
import { getHeaders, baseUrl, getMovingOrderId } from '../../helpers/Common';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faLock } from '@fortawesome/free-solid-svg-icons';

export const TaskItem = ({orders, obj, open, setOpen, disabledTasks, setDisabledTasks, setRefresh}) => {
    const [isDisable, setIsDisable] = useState(false);
    const [selectedTask, setSelectedTask] = useState({task : null, position : null});
    const [modal, setModal] = useState(false);
    
    const toggle = (id) => {
        console.log(id);
        open === id ? setOpen() : setOpen(id);
    };

    const untoggle = () => {

    }

    const handleButtonClick = (e) => {
        setRefresh(false);

        var dArr = disabledTasks;

        console.log('before: ', disabledTasks);

        if(isDisable){
            dArr.push(selectedTask.task);

            updateDisableTask(dArr);

        }else{
            
            dArr = dArr.filter(function(item) {
                return item !== selectedTask.task
            })

            updateDisableTask(dArr); 
        }
        
        setModal(!modal);
    }

    const updateDisableTask = async (tasks) => {

        var data = {name : 'disabled_tasks', value : String(tasks)}
        axios.put(baseUrl()+'moving_orders/'+getMovingOrderId()+'/settings/disabled_tasks', data, getHeaders(),
        ).then(response =>  {
            console.log(response.data.data.value, tasks);
            sessionStorage.setItem('disabled_tasks', String(tasks));

            //set tasks to this
            console.log('set tasjs to this: '+tasks);
            setDisabledTasks(tasks)
            
        });

        if(isDisable){
            setOpen(open.filter(item => item !== selectedTask.position));
        }else{
            setOpen([...open, selectedTask.position]);
        }

    }
        
    const toggleModal = (position, task, isDisable) => {

        setIsDisable(isDisable);

        setSelectedTask({task : task, position : position});
        
        setModal(!modal)

        console.log(position, task, isDisable);
    };

    return (
        <>
        {/* <Accordion className={`vertical-card mb-4 ${orders[obj.type] && "vertical-ordered"} ${disabledTasks.includes(obj.type) && " accordion-disabled div-disabled cursor-pointer"}`} open={open}  toggle={orders[obj.type] ? untoggle : (disabledTasks.includes(obj.type) ? (e) => toggleModal(obj.position, obj.type, false) : toggle)} key={obj.position}>
            <AccordionItem className='vertical-classic p-2' > 
                <AccordionHeader targetId={obj.position} className='w-progress'>
                    <table className="w-100">
                        <tbody>
                            <tr>
                                <td>{ obj.title }</td>
                            </tr>
                            { !disabledTasks.includes(obj.type) ?
                            <>
                                <tr>
                                    <td>
                                        { orders[obj.type] && 
                                        <i className='f-15 f-normal'>Oppgave fullført</i>
                                        }
                                        {obj.enabled === true &&
                                            <ProgressBar progress={orders[obj.type] ? 100 : obj.progress} classes="mt-3"/>
                                        }
                                    </td>
                                </tr>
                                {orders[obj.type] &&
                                    <tr>
                                    <Link to={`/profile/orders/${obj.type}`} className='mt-4 btn-between w-100'>See detaljer</Link>
                                    </tr>
                                }
                            </>
                            :
                            <>
                            <FontAwesomeIcon icon={['far', 'lock']} className='position-absolute float-end mt-1 accordion-lock' />
                            </>
                            }
                            </tbody>
                    </table>
                    
                </AccordionHeader>
                <AccordionBody accordionId={obj.position} isOpen={open === obj.position}>

                    <>
                    <p>
                        { obj.description }
                    </p>
                    {obj.image !== '' &&
                    <div className='vertical-image text-center'>
                        <img src={ obj.image } alt={ obj.image }/>
                    </div>
                    }

                    <div className='vertical-footer mt-4'>
                        <Link to={!orders[obj.type] ? obj.path : `/profile/orders/${obj.type}`} className={`btn-between w-100 ${obj.enabled === false && 'disabled'}`}> { !orders[obj.type] ? obj.button : 'Se detaljer' }</Link>
                        {obj.enabled === true &&
                            <Button className="btn-link text-italic f-14" onClick={(e) => toggleModal(obj.position, obj.type, true)}>Fjern oppgave</Button>
                        }
                    </div>
                    </>
                
                </AccordionBody>
            </AccordionItem>
        </Accordion> */}


        <div className={`${disabledTasks.includes(obj.type) && " div-disabled cursor-pointer"}`} key={obj.position} onClick={disabledTasks.includes(obj.type) ? (e) => toggleModal(obj.position, obj.type, false) : untoggle}>
           
            {!disabledTasks.includes(obj.type) ?
            <Link to={!orders[obj.type] ? obj.path : `/profile/orders/${obj.type}`}>
                <h4 className='mb-0'>{ obj.title } 
                    <FontAwesomeIcon icon={faChevronRight} className='float-end mt-1' />
                </h4>
            </Link>
            : 
             <div>
                <h4 className='mb-0'>{ obj.title } 
                    <FontAwesomeIcon icon={faLock} className='float-end mt-1' />
                </h4>
             </div>
            }
           
            <Row>
                <Col xs={6}>
                    <Link to={!orders[obj.type] ? obj.path : `/profile/orders/${obj.type}`} className={`${disabledTasks.includes(obj.type) && 'disabled-link'}`}>
                            <img src={obj.image} className={'w-100'} alt={obj.title}/>
                    </Link>
                </Col>
                <Col xs={6} className='d-flex align-items-end'>
                    <div className='w-100 f-17'>
                        <Link to={!orders[obj.type] ? obj.path : `/profile/orders/${obj.type}`} className={`${disabledTasks.includes(obj.type) && 'disabled-link'}`}>
                            <ProgressBar progress={orders[obj.type] ? 100 : obj.progress} classes="mt-3 mb-1"/>
                            <p className='text-bold mb-0'>{orders[obj.type] ? '100%' : '0%'} ferdig</p>
                        </Link>
                    </div>
                </Col>
            </Row>
            
            {!disabledTasks.includes(obj.type) && !orders[obj.type] &&
            <div className='mt-2'>
                <a className="btn-link f-15 cursor-pointer text-black" onClick={(e) => toggleModal(obj.position, obj.type, true)}>Fjern oppgave</a>
            </div>
            }
        </div>

        <Modal isOpen={modal} className='text-center modal-gray' centered={true} toggle={toggleModal}>
            <ModalBody>
                <p className="text-bold f-20">{!isDisable ? 'Du har deaktivert denne oppgaven' : 'Ønsker du å deaktivere oppgaven'}</p>
                {isDisable ? 
                <p className="text-italic">(du kan åpne den igjen senere hvis du ønsker)</p>
                :
                <p>Ønsker du å åpne den igjen?</p>
                }
                
                <div className="mt-4">
                    <button className='btn-between px-4' onClick={ handleButtonClick }>Ja</button>
                    <button className='btn-between px-4 ms-2' onClick={(e) => toggleModal(e)}>Nei</button>
                </div>
            </ModalBody>
        </Modal>
        </>
    );
}