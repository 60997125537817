import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

export const Tips = () => {

    const checklistObj = [{
        position : '1',
        title : 'Alt du glemmer i en flytteprosess!',
        image : 'Between_tips.png',
        type: 'tips',
        text : ['En lang artikkel om dette. Du har gjort boligen din flytteklar! Følg med i notifica-tions for oppdatering og status på de ulike leveransene. Du vil også motta en bekreftelse på dine bestillinger.',
                'Lykke til med flyttingen!']
        },
        {
        position : '2',
        title : 'Gjør andre ting enn å flytte?',
        image : 'article-1.png',
        type: 'article',
        text : ['En lang artikkel om dette. Du har gjort boligen din flytteklar! Følg med i notifica-tions for oppdatering og status på de ulike leveransene. Du vil også motta en bekreftelse på dine bestillinger.',
                'Lykke til med flyttingen!']
        },{
        position : '3',
        title : 'Hvordan pakker du smart og trygt?',
        image : 'Between_tips.png',
        type: 'tips',
        text : ['En lang artikkel om dette. Du har gjort boligen din flytteklar! Følg med i notifica-tions for oppdatering og status på de ulike leveransene. Du vil også motta en bekreftelse på dine bestillinger.',
                'Lykke til med flyttingen!']
        },{
        position : '4',
        title : 'Nå er tiden inne for rydd og kast.',
        image : 'Between_tips.png',
        type: 'tips',
        text : ['En lang artikkel om dette. Du har gjort boligen din flytteklar! Følg med i notifica-tions for oppdatering og status på de ulike leveransene. Du vil også motta en bekreftelse på dine bestillinger.',
                'Lykke til med flyttingen!']
        },{
        position : '5',
        title : 'Alt du glemmer i en flytteprosess!',
        image : 'article-2.png',
        type: 'article',
        text : ['En lang artikkel om dette. Du har gjort boligen din flytteklar! Følg med i notifica-tions for oppdatering og status på de ulike leveransene. Du vil også motta en bekreftelse på dine bestillinger.',
                'Lykke til med flyttingen!']
        },
    ];


    const verticalItems = checklistObj.map((obj, i) => 
    <Link to={{pathname : `/tips-and-articles/${obj.position}`, checklist : obj.list, progress : obj.progress}} state={{object : obj }} key={i}>
        <div className={`p-4 mb-4 br-20 ${obj.type === 'article' ? `bg-purple` : `bg-light-yellow`}`}>
            <Row>
                <Col xs={3}>
                    <img src={`/images/articles/${obj.image}`} alt={''} width={70} />
                </Col>
                <Col className="ps-3 d-flex align-items-center">
                <h4>{obj.title}</h4>
                </Col>
                <Col className="pt-3" xs={1}>
                    <FontAwesomeIcon icon={faChevronRight} className={'float-end'} />
                </Col>
            </Row>
        </div>
    </Link>
    );

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Tips & artikler</h1>
                <div className="position-relative w-100 d-flex justify-content-center">
                    <img src='/images/purple/svg/tips.svg' style={{width: '250px'}}/>
                </div>
            </div>
            <div className="pt-4">
                { verticalItems }
            </div>
            <Footer/>
        </div>
    );
}