import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import React from "react";

const MySwal = withReactContent(Swal);
export const Alert = (alertType, title, message = '', footer = '', callback) => {
    console.log(message);
    MySwal.fire({
        icon:  alertType ,
        title: <strong> { title } </strong>,
        text:  message,
        footer: footer
    }).then(callback);
};
