import React, { useState } from "react";
import { Progress } from "reactstrap";

export const ProgressBar = ({ progress = 0, classes }) => {
    const [stepProgress, setStepProgress] = useState(0);     
    
    const updateProgressNowHandler = setInterval(() => {
            setStepProgress(progress)
            clearInterval(updateProgressNowHandler)
       }, 200)

    return (
        <span>
            <Progress value={ stepProgress } className={ classes }/>
        </span>
    )
}