import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { ButtonLottie } from "../../../components/layout/ButtonLottie";

import { VerticalHeader } from "../../../components/layout/VerticalHeader";
import SignaturePad from "react-signature-canvas";
import axios from "axios";
import { baseUrl, getHeaders } from "../../../helpers/Common";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";

export const ProtocolStep13 = () => {

    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    const prostep13 = sessionStorage.getItem('protocol_step_13') ? JSON.parse(sessionStorage.getItem('protocol_step_13')) : {};
    const navigate = useNavigate();
    const [memberItems, setMemberItems] = useState([]);
    const movingOrderId = sessionStorage.getItem('moving_order');
    const [buyers, setBuyers]  = useState(JSON.parse(sessionStorage.getItem('protocol_buyer')));
    const [signees, setSignees] = useState(null);
    const [signatures, setSignatures] = useState(prostep13.signatures ? prostep13.signatures : null);
    const [signing, setSigning] = useState(0);
    const [signEmpty, setSignEmpty] = useState(false);
    let signCanvas = useRef({});


    const handleSendSignature = () => {

        console.log(signCanvas.current.isEmpty());
        if(signCanvas.current.isEmpty()){
            setSignEmpty(true)
        }else{
            const next = signing + 1; 
            setSigning(next);
    
            const signature = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    
                let tempSignees = [...signees];
                tempSignees[signing].signature = signature;
                tempSignees[signing].timestamp = new Date();
                setSignees(tempSignees);
    
                sessionStorage.setItem('protocol_step_13', JSON.stringify({signatures : tempSignees}));
                
                clearSignature();
    
            if(next  === signees.length){
                handleNextPage();
            }

            setSignEmpty(false);
        }
    }

    const handleNextPage = () => {
        if(!protocolProgress.includes('step13')){
            protocolProgress.push('step13');
            sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
        }
        sessionStorage.setItem('protocol_complete', true);
        navigate('/protocol/complete-order');
    }

    const handleNoSignatureBtn = () => {
        const next = signing + 1; 
        setSigning(next);
        
        clearSignature();

        if(next  === signees.length){
            handleNextPage();
        }
    }

    const fetchUsers = () => {
        axios.get(baseUrl()+'moving_orders/'+movingOrderId+'/users', getHeaders()
        ).then(response => {
            if(response.data.status.code === '200'){
                setMemberItems(response.data.data);
                const users = response.data.data;
                var sArr = [];
                var buyersArr = [];
                var sellersArr = [];
                
                users.map( user => {
                    sArr.push({name : user.name, signature : ''});
                    sellersArr.push(user.name);
                });

                
                buyers.map(buyer => {
                    sArr.push({name : buyer.name, signature : ''});
                    buyersArr.push(buyer.name);
                });

                setSignees(sArr);
                
                sessionStorage.setItem('sellers_name', JSON.stringify(sellersArr));
                sessionStorage.setItem('buyers_name', JSON.stringify(buyersArr));
                //add to session to display in complete order page
            }
        })
        .catch(error => console.error(error))
    }

    const clearSignature = () => signCanvas.current.clear();

    useEffect(() => {
        fetchUsers();
    }, []); 

    const handleOnBegin = () => {
        setSignEmpty(false);
        console.log('heey');
    };

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Signatur' progress={13} totalProgress={14} />
            
            <div className="pt-4">
                {signees &&
                <div>
                    <h3>Signatur: { signees[signing].name }</h3>
                    <div className="f-14 d-flex align-items-end justify-content-between">
                        <p className="mb-0">Signer her:</p>
                        <p className="mb-0 text-black float-end cursor-pointer" onClick={ clearSignature }>Clear</p>

                    </div>
                    <SignaturePad 
                    onBegin= {() => signCanvas.handleOnBegin }
                    onEnd= {() => signCanvas.handleOnBegin }
                    ref={ signCanvas }
                    penColor='black'
                    canvasProps={{height: isMobile ? 500 : 150,
                                    className: `mb-4 w-100 br-15 ${!signEmpty ? 'border-1' : 'border-red'}`}}
                    />
                    <Row>
                        <Col>
                            <Button 
                                className='btn btn-between w-100' 
                                data-id="conf_yes" 
                                data-value='yes' 
                                data-type="confirmation"
                                onClick={ handleSendSignature }>
                                    Send inn
                                    {/* { isLottie === i ?  <ButtonLottie button='Send inn' left='100px' /> :  <span>Send inn</span> } */}
                            </Button>
                        </Col>
                        <Col>
                            <Button 
                                className='btn btn-between-white no-black w-100' 
                                data-id="conf_no" 
                                data-value='no' 
                                data-type="confirmation"
                                onClick={ handleNoSignatureBtn }>
                                    Ikke tilstede
                                    {/* { isLottie === i ?  <ButtonLottie button='Ikke tilstede' left='120px' isBlack={true} /> :  <span>Ikke tilstede</span> } */}
                            </Button>
                        </Col>
                    </Row>
                </div>
                }
                <Row className="text-center my-4 mx-5">
                    <Col>
                        <Link to={'/protocol/step-12'} className="btn-between-white" style={{maxWidth : '150px'}}>Tilbake</Link>
                    </Col>
                </Row>
                </div>
            <Footer/>
        </div>
    );
}