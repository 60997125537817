import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { Alert, Button, Col, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import * as yup from "yup";
import { baseUrl, computeProgress, getHeaders, getMainPersonId, underAgeValidate } from '../../../helpers/Common';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { NewUserForm } from "../../../components/layout/NewUserForm";
import { VerticalHeader } from "../../../components/layout/VerticalHeader";

const schema = yup.object().shape({
    name: yup.string().required("Vennligst fyll ut feltet"),
    // birthdate: yup.string().required("Vennligst fyll ut feltet").test("birthdate", "Personen må være over 18 år", (value) => {
    //     return underAgeValidate(value) >= 18;
    //   }),
    birthdate: yup.string().required("Vennligst fyll ut feltet"),
    phone: yup.string().required("Vennligst fyll ut feltet").min(8, "Mobilnummer må være på minst 8 tegn").max(32, "Mobilnummer må være på maksimalt 32 tegn"),
    email: yup.string().required("Vennligst fyll ut feltet").email("Vennligst se over at informasjonen er riktig"),
  });
export const ProtocolStep2 = () => {

    const movingOrderId = sessionStorage.getItem('moving_order');
    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    const navigate = useNavigate();
    const [memberItems, setMemberItems] = useState([]);
    const [userModal, setUserModal] = useState(false);
    const [formDetails, setFormDetails] = useState({action : '', type : ''});
    const [formData, setFormData] = useState({ name : '', phone : '', email : '', birthdate : ''});
    const [buyers, setBuyers]  = useState(JSON.parse(sessionStorage.getItem('protocol_buyer')));
    const [isNewSeller, setIsNewSeller] = useState(false);
    const [isNewBuyer, setIsNewBuyer] = useState(false);

    const { register, handleSubmit, reset, setError, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const fetchUsers = () => {
        axios.get(baseUrl()+'moving_orders/'+movingOrderId+'/users', getHeaders()
        ).then(response => {
            setMemberItems(response.data.data)
        })
        .catch(error => console.error(error))
    }

    const toggle  = ( action, type) => {
        setUserModal(!userModal);
        setFormDetails({ action : action, type : type });
    };

    useEffect(() => {
        fetchUsers();
    }, []);


    const handleUpdateBuyers = ( data ) => {
        buyers.push(data);
        sessionStorage.setItem('protocol_buyer', JSON.stringify(buyers));

        // setBuyers(buyers => ([...buyers, data]));
        setIsNewBuyer(!isNewBuyer);
    }

    const handleNextStepClick = ( ) => {
        // step 2 done, add to next step; 
        if(!protocolProgress.includes('step2')){
            protocolProgress.push('step2');
            sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
        }
        navigate('/protocol/step-3'); 
    }
 
    const handleSaveUser = (data) => {
        if(formDetails.action === 'new' && formDetails.type === "buyer"){
            buyers.push(data);
            sessionStorage.setItem('protocol_buyer', JSON.stringify(buyers));
            setBuyers(buyers);
            toggle(); 
        }else{
            axios.post(baseUrl()+'users', data, getHeaders()
            ).then(response => {
                if(response.data.status.code === "200"){
                    const newUserId  = response.data.data.id;
                    const movingOrderId = sessionStorage.getItem('moving_order');
                    const memberData = {moving_order: parseInt(movingOrderId), user: newUserId, role : 'editor'};
    
                    //link new user to membership
                    axios.post(baseUrl()+'memberships', memberData, getHeaders()
                    ).then(response => {
                        
                        console.log('adding user to membership', response);
                        if(response.data.status.code === "200"){
                            fetchUsers();
                            toggle();
                        }else{
                            Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                }else{
                    setError('email', { type: 'custom', message: response.data.status.message })
                    Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onModalClose = () => {
        reset();
    }
    const sellerItems = memberItems.map((memberObj, i) => {
            return <div key={i}>
                    {i !== 0 && <div className="px-4"><hr className="my-0"/></div>}
                    <ListGroupItem className={`br-15 p-4 bg-light`}>
                        <ListGroupItemHeading className="font-18">{ memberObj.name }</ListGroupItemHeading>
                        <ListGroupItemText className="mb-0">
                            E-post: { memberObj.email }
                        </ListGroupItemText>
                        <ListGroupItemText className="mb-0">
                            Telefon: { memberObj.phone } 
                        </ListGroupItemText>
                        <ListGroupItemText className="mb-0">
                            Fødselsdato: { memberObj.birthdate }
                        </ListGroupItemText>
                    </ListGroupItem>
                </div>
            }) 

    const buyerItems = buyers.map((memberObj, i) => {
            return <div key={i}>
                    {i !== 0 && <div className="px-4"><hr className="my-0"/></div>}
                    <ListGroupItem className={`br-15 p-4 bg-light`}>
                        <ListGroupItemHeading className="font-18">{ memberObj.name }</ListGroupItemHeading>
                        <ListGroupItemText className="mb-0">
                            E-post: { memberObj.email }
                        </ListGroupItemText>
                        <ListGroupItemText className="mb-0">
                            Telefon: { memberObj.phone } 
                        </ListGroupItemText>
                        <ListGroupItemText className="mb-0">
                            Fødselsdato: { memberObj.birthdate }
                        </ListGroupItemText>
                    </ListGroupItem>
                </div>
            }) 

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Kjøper og selger' progress={2} totalProgress={14} />

            <div className="pt-4">

                {/* SELLER */}
                <h4 className="text-bold mb-2 f-20">Selger</h4>
                <div className="mb-4 bg-light br-15">
                    { sellerItems }
                </div>
                {!isNewSeller ? 
                <Row className="px-5">
                    <Col>
                        <Button className="btn btn-between w-100" onClick={(e) => setIsNewSeller(!isNewSeller)}>Legg til fler</Button>
                    </Col>
                </Row>
                : 

                <NewUserForm 
                    fetchUsers={ fetchUsers }
                    formDetails={{action : 'new', type : 'seller'}}
                    formData={formData} 
                    setIsNewSeller={setIsNewSeller}
                    isNewSeller={isNewSeller}
                    />
                }
                {/* SELLER */}
                <h4 className="text-bold mb-2 mt-4 f-20">Kjøper</h4>
                <div className="mb-4 bg-light br-15">
                    { buyerItems }
                </div>
                {!isNewBuyer ? 
                <Row className="px-5">
                    <Col>
                        <Button className="btn btn-between w-100" onClick={(e) => setIsNewBuyer(!isNewBuyer)}>Legg til fler</Button>
                    </Col>
                </Row>
                : 

                <NewUserForm 
                buyers={buyers}
                    setBuyers={ setBuyers }
                    fetchUsers={ fetchUsers }
                    formDetails={{action : 'new', type : 'buyer'}}
                    formData={formData} 
                    handleUpdateBuyers={ handleUpdateBuyers }
                    />
                }

                <Row className="text-center my-4 mx-4">
                    <Col>
                        <Link to={'/protocol/step-1'} className="btn-between-white">Tilbake</Link>
                    </Col>
                    <Col>
                        <Button onClick={ handleNextStepClick } className="btn-between w-100">Neste</Button>
                    </Col>
                </Row>
                
                </div>
            <Footer/>
            <Modal isOpen={userModal} toggle={ toggle } centered onClosed={ onModalClose }>
                <ModalBody className="bg-white">
                    <Form onSubmit={handleSubmit(handleSaveUser)} id='user-form'>
                        <Row>
                            <Col xs={6}>
                                <Label for="name" className="mb-0">Fornavn</Label>
                                <input {...register("name")} type="text" className={`form-control input-square ${errors.name && 'is-invalid'}`} defaultValue={formData.name || ''}/>
                                <p className="form-error">{errors.name?.message}</p>
                            </Col>
                            <Col xs={6}>
                                <Label for="phone" className="mb-0">Telefon</Label>
                                <input {...register("phone")} type="text" className={`form-control input-square ${errors.phone && 'is-invalid'}`} defaultValue={formData.phone || ''}/>
                                <p className="form-error">{errors.phone?.message}</p>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={6}>
                                <Label for="email" className="mb-0">E-post</Label>
                                <input {...register("email")} type="email" className={`form-control input-square ${errors.email && 'is-invalid'}`} defaultValue={formData.email || ''}/>
                                <p className="form-error">{errors.email?.message}</p>
                            </Col>
                            <Col xs={6}>
                                <Label for="birthdate" className="mb-0">Fødselsdato</Label>
                                <input {...register("birthdate")} type="date" className={`form-control input-square ${errors.birthdate && 'is-invalid'}`} defaultValue={formData.birthdate || ''}/>
                                <p className="form-error">{errors.birthdate?.message}</p>
                            </Col>
                        </Row>
                        <Row className="mt-4 text-center px-4">
                            <Col>
                                <Button className="btn-between-white" onClick={ toggle }>Tukk</Button>
                            </Col>
                            <Col>
                                <Button className="btn btn-between-sm w-100" type="submit">Legg til</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}