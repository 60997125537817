import React, { useState, useEffect } from 'react';
import { baseUrl, getHeaders, getMovingOrderId, getMainPersonId } from '../../../helpers/Common';
import { Alert } from '../../utils/Alert';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FormGroup, Label, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const schema = yup.object().shape({
    name: yup.string().required("Vennligst fyll ut feltet"),
    birthdate: yup.string().required("Vennligst fyll ut feltet"),
    phone: yup.string().min(8, "Mobilnummer må være på minst 8 tegn").max(32, "Mobilnummer må være på maksimalt 32 tegn").required("Vennligst fyll ut feltet"),
    email: yup.string().email("Ugyldig epost").required("Vennligst fyll ut feltet"),
  });

export const UserEditForm = ( {type = 'registration'}) => {
    const {id, name, email, phone, birthdate} = useParams();
    const location = useLocation();
    const userData = location.state;
    const navigate = useNavigate();
    const [isReseted, setReset] = React.useState(false);
    const [isExtra, setIsExtra] = useState(false);
    const [role, setRole] = useState(false);
    const { register, handleSubmit, reset, trigger, formState: { errors } } = useForm({
        mode: "onTouched",
      resolver: yupResolver(schema),
    });
    
    //update user data
    const onSubmitHandler = (data) => {
        
        axios.put(baseUrl()+'users/'+id, data, getHeaders()).then(response => {
            if(response.data.status.code === "200"){
    
                //update user data to session if the updated user is the logged in user
                if(id === getMainPersonId()){
                    sessionStorage.setItem("user", JSON.stringify(response.data.data));
                }

                // Alert('success', 'Brukerdata oppdatert!', '', '', function() {
                    navigate("/"+type+"/users", { replace: true });
                // })
    
            }else{
                Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
            }
        }).catch(error => {
            console.error(error);
        });
        // reset();
      };

      //delete extra person
    const handleDeletePerson = () => {
        axios.delete(baseUrl()+'moving_orders/'+getMovingOrderId()+'/users/'+id, getHeaders()
        ).then(response => { 
            navigate("/"+type+"/users", { replace: true });
        }).catch(error => console.error(error));
    };


      useEffect(() => {
        isReseted && trigger();
      }, [trigger, isReseted])

      useEffect(() => {
        setIsExtra(location.state.isExtra);
        setRole(location.state.role);
      })

    return (
        <div id='EditUserContainer'>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <input type='hidden' {...register("id")} name='id' value={id}/>
                    <div className='mt-5'>
                        <FormGroup>
                            <Label className='fw-bold pb-3'>Personalia</Label>
                            <input {...register("name")} placeholder="Fullt navn" type="text" className="form-control" defaultValue={userData.name} />
                            <p className="form-error">{errors.name?.message}</p>
                        </FormGroup>
                        { role !== 'viewer' &&
                        <>
                        <FormGroup>
                            <input {...register("phone")} placeholder="Telefonnummer" type="text" className="form-control" defaultValue={userData.phone} />
                            <p className="form-error">{errors.phone?.message}</p>
                        </FormGroup>
                        <FormGroup>
                            <input {...register("email")} placeholder="E-post" type="email" className="form-control" defaultValue={userData.email} />
                            <p className="form-error">{errors.email?.message}</p>
                        </FormGroup>
                        </>
                        }
                        <FormGroup>
                            <input {...register("birthdate")} placeholder="Fødselsdato" type="date" className="form-control" defaultValue={userData.birthdate} />
                            <p className="form-error">{errors.birthdate?.message}</p>
                        </FormGroup>
                        {isExtra && 
                        <FormGroup>                            
                            <a className='dark-link text-underline cursor-pointer' onClick={ handleDeletePerson } >Slett person</a>
                        </FormGroup>
                        }
                </div>
                <div className='mt-5 mb-4'>
                    <FormGroup className='mt-4 pb-4 text-center'>
                        <Button type='submit' className='btn-between' style={{width : '250px'}}>Oppdater</Button>
                    </FormGroup>
                    <FormGroup className='pb-4 text-center'>
                        <Link  to={"/"+type+"/users"} className='dark-link text-underline' >Tilbake</Link>
                    </FormGroup>
                </div>
            </form>
        </div>
    )
}