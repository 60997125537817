import { Navigation } from "../../../../components/layout/Navigation";
import { Footer } from "../../../../components/layout/Footer";
import { Button, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLottie } from "../../../../components/layout/ButtonLottie";
import { useEffect } from "react";
import { VerticalHeader } from "../../../../components/layout/VerticalHeader";

export const MovingBoxesStep1 = () => {

    const boxesProgress = sessionStorage.getItem('boxes_progress') ? JSON.parse(sessionStorage.getItem('boxes_progress')) : [];
    var   boxesStep1    = sessionStorage.getItem('boxes_step_1')   ? sessionStorage.getItem('boxes_step_1') : '';
    const [isLottie, setIsLottie]   = useState(null);
    const navigate = useNavigate();
    const [infoModal, setInfoModal] = useState(false);
    const [modalType, setModalType] = useState('friendly_box');

    const toggleInfoModal  = ( e ) => {
        const type = e.currentTarget.dataset.value;
        setInfoModal(!infoModal);
        setModalType(type);
    };

    const friendlyBoxInfos = [
        'Enklere, raskere, billigere og mer miljøvennlig',
        'Bedre kvalitet, tryggere transport',
        'Unike innsatser til glass og tallerkener',
        'Ingen montering, kassene leveres rene og klare til pakking',
        '100% frie for skadedyr',
        'Vi leverer og henter gratis – innen 48 timer',
        'Behold kassene så lenge du ønsker',
    ];

    const handleBtnClick = ( e ) => {
        const value = e.currentTarget.dataset.value;

        boxesStep1  = value;
        if(boxesStep1){

            setIsLottie(boxesStep1);
            sessionStorage.setItem('boxes_step_1', boxesStep1);

            // //add to progress for the progress bar in the homepage
            if(!boxesProgress.includes('step1')){
                boxesProgress.push('step1');
                sessionStorage.setItem('boxes_progress', JSON.stringify(boxesProgress));

            }
            
            //proceed to step 2;
            setTimeout((function(){
                
                //clear the custom boxes in step 2
                sessionStorage.removeItem('boxes_step_2_size_objects');
                sessionStorage.removeItem('boxes_step_2');

                const arr = boxesProgress.filter(function(item) {
                    return item !== 'step2'
                })
                sessionStorage.setItem('boxes_progress', JSON.stringify(arr));

                navigate('/verticals/moving-order/boxes/step-2');
            }), 1000);
        }
    }

    useEffect(() => {
        if(boxesStep1 !== ""){
            setIsLottie(boxesStep1);
        }
    }, [boxesStep1, setIsLottie]);

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Flyttemateriell' progress={1} totalProgress={4} />

            <div className="pt-4">
                <div className="bg-light p-4 br-20">
                    <Row className="text-center">
                        <Col>
                            <h4 className="mb-0 f-20">Lei miljøvennlige plastkasser som levereres og hentes</h4>
                            
                            <div className="my-4">
                                <img src="/images/boxes/klar-kasse.png" className="w-100" alt="klar-kasse" />
                            </div>
                            <Row>
                                <Col>
                                    <Button 
                                        className='btn btn-between-white no-black w-100 text-bold' 
                                        data-value='friendly_box'  
                                        onClick={ toggleInfoModal }>
                                            Mer info
                                    </Button>
                                </Col>
                                <Col>
                                    <Button 
                                        className='btn btn-between w-100 text-bold' 
                                        data-value='friendly_box' 
                                        disabled={isLottie === 'friendly_box' ? true : false}
                                        onClick={ handleBtnClick }>
                                            { isLottie === 'friendly_box' ?  <ButtonLottie button='Velg' left='70px' /> :  <span>Velg</span> }
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                
                <div className="bg-light p-4 br-20 mt-4">
                    <Row className="text-center">
                        <Col>
                            <h4 className="mb-0 f-20">Lei miljøvennlige plastkasser som levereres og hentes</h4>
                            <div className="my-4">
                                <img src="/images/boxes/pappeske.png" style={{width : '190px'}} alt="pappeske" />
                            </div>
                            <Row>
                                <Col>
                                    <Button 
                                        className='btn btn-between-white no-black w-100' 
                                        data-value='regular_box' 
                                        onClick={ toggleInfoModal } >
                                            Mer info
                                    </Button>
                                </Col>
                                <Col>
                                    <Button 
                                        className='btn btn-between w-100' 
                                        data-value='regular_box' 
                                        disabled={isLottie === 'regular_box' ? true : false}
                                        onClick={ handleBtnClick }>
                                            { isLottie === 'regular_box' ?  <ButtonLottie button='Velg' left='70px' /> :  <span>Velg</span> }
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                {boxesProgress.includes('step1') &&
                <Row className="text-center my-4 mx-5">
                    <Col>
                        <Link to="/verticals/moving-order/boxes/step-2" className="btn-between w-100 text-bold" style={{maxWidth : '150px'}}>Neste</Link>
                    </Col>
                </Row>
                }
                </div>
            <Footer/>
            {/* FRIENDLY BOX MODAL */}
            <Modal isOpen={infoModal} toggle={ toggleInfoModal } centered>
                <ModalHeader toggle={ toggleInfoModal } className='bg-light'>
                <h3 className="mb-0">Klar Kasse tilbyr miljøvennlige flyttekasser til utleie</h3>
                </ModalHeader>
                <ModalBody className="bg-light p-4">
                    {modalType === 'friendly_box' ?
                    <>
                    <Row>
                        <Col>
                            
                            <p>Vi leverer, du flytter, vi henter!</p>
                            <p className="mb-0 text-bold">Hva får jeg plass til i eskene?</p>
                            <p>15-20 gensere, 12-15 par sko, 30-40 t-skjorter eller 15-20 jeansbukser</p>
                            <div className="my-4">
                                <img src="/images/boxes/klar-kasse-2.png" className="w-100" alt="klar-kasse 2" />
                            </div>
                            <ul>
                                {friendlyBoxInfos.map((info, i) => {
                                    return <li key={i}>{info}</li>
                                })}
                            </ul>
                            <div className="my-4">
                                <img src="/images/boxes/klar-kasse.png" className="w-100" alt="klar kasse" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 mx-5 text-center">
                        <Col>
                            <Button 
                                className='btn btn-between-white no-black w-100' 
                                style={{maxWidth : '200px'}}
                                onClick={ toggleInfoModal }>
                                    Lukk
                            </Button>
                        </Col>
                        <Col>
                            <Button 
                                className='btn btn-between w-100' 
                                data-value='friendly_box' 
                                disabled={isLottie === 'friendly_box' ? true : false}
                                onClick={ handleBtnClick }>
                                    { isLottie === 'friendly_box' ?  <ButtonLottie button='Velg' left='70px' /> :  <span>Velg</span> }
                            </Button>
                        </Col>
                    </Row></>
                    :
                    <>
                    <Row>
                        <Col>
                            <h3 className="text-center">Kjøp kvalitetsesker, levert hjem til deg</h3>
                            <div className="my-4 text-center">
                                <img src="/images/boxes/pappeske.png" style={{width : '190px'}} alt="pappeske" />
                            </div>
                            <p className="mb-0">Vi har 2 typer flyttesker,  40L og 75L. Målene på eskene er: </p>
                            <p className="mb-0">40L = Lengde 48cm, Bredde 30cm, Høyde 32cm</p>
                            <p className="mb-0">75L = Lengde 58cm, Bredde 35cm, Høyde 41cm</p>
                            <p className="mt-4 mb-0">Det kan være lurt å kjøpe med bobleplast, strekkfilm og litt teip om du ønsker å beskytte innholdet best mulig.</p>
                        </Col>
                    </Row>
                    <Row className="mt-4 mx-5 text-center">
                        <Col>
                            <Button 
                                className='btn btn-between-white no-black w-100' 
                                style={{maxWidth : '200px'}}
                                onClick={ toggleInfoModal }>
                                    Lukk
                            </Button>
                        </Col>
                        <Col>
                            <Button 
                                className='btn btn-between w-100' 
                                data-value='regular_box' 
                                disabled={isLottie === 'regular_box' ? true : false}
                                onClick={ handleBtnClick }>
                                    { isLottie === 'regular_box' ?  <ButtonLottie button='Velg' left='70px' /> :  <span>Velg</span> }
                            </Button>
                        </Col>
                    </Row>
                    </>
                    }

                </ModalBody>
            </Modal>
        </div>
    );
}