import React from 'react';
import { ListGroupItem, ListGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Navigation } from '../../components/layout/Navigation';
import { Footer } from '../../components/layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faShieldAlt, faComments, faFileCircleCheck, faUsers, faMapPin,  faList, faTruck, faCartShopping, faCreditCard, faCaretRight, faCircleChevronRight, faEnvelopeOpen, faHeadphones, faStar, faEdit } from '@fortawesome/free-solid-svg-icons';
import { displayAddress } from '../../helpers/Common';


export const SettingsAddress = () => {
        
    const verticalObjects = [{
            position : '1',
            title : 'Adresser',
            icon: faMapPin,
            path : '/settings/address',
        }, {
            position : '2',
            title : 'Betalingsmetoder',
            icon: faCreditCard,
            path : '/settings/payments/method'
        }, {
            position : '3',
            title : 'Personer i hustanden',
            icon: faUsers,
            path : '/settings/users'
        },
    ];

    return (
        <div className='AppContainer bg-between'>
            <Navigation />
            <div className='pt-4'>
                <p className='p-header'>Adresser</p>
            </div>
            <div className='container-white py-4'>
                <ListGroup className='profile-list-group pe-0 py-0'>
                    <ListGroupItem key="1" className='p-3 br-20px'> 
                        <p className='mb-0 text-bold'>Flytter fra:</p>
                        <p className='mb-0'>{ displayAddress(sessionStorage.getItem('from_address')) }</p>
                    </ListGroupItem>
                    <ListGroupItem key="2" className='p-3 mt-4 br-20px'> 
                        <p className='mb-0 text-bold'>Flytter til:</p>
                        <p className='mb-0'>{ displayAddress(sessionStorage.getItem('to_address')) }</p>
                    </ListGroupItem>
                </ListGroup>
                <p className='mt-4 text-center text-bold'>Er det noe feil?</p>
                <p className='text-center'>Hvis det er noe feil med dine adresser, kontakt oss ved å trykke på “kontakt oss” nede i høyre hjørne av vinduet.</p>
                <div className='pb-4 text-center'>
                    <Link  to="/settings" className='dark-link text-underline' >Tilbake</Link>
                </div>
            </div>
            <Footer/>
        </div>
    );
}