export const MovingBoxesTimeline = ( props ) => {

    const timelineItems = props.timeline.map((tObj, i) => 
    <div className={`timeline-section ps-3`} key={i}>
        <div className={`${tObj.id !== 5 && 'pb-4'} time-line-text-purple ps-4 d-flex align-items-center`}>
            { tObj.status === 'finished' ? 
            <div className="position-absolute" style={{left: 28}}>
                <img src="/images/boxes/purple-check-light.png"  alt='' style={{width : '26px'}}/>
            </div>
            : tObj.status === 'current' ?
            <div className="position-absolute" style={{left: 28}}>
                <img src="/images/boxes/purple-check-solid.png"  alt='' style={{width : '26px'}}/>
            </div>
            :
            <div className="position-absolute d-flex align-items-center justify-content-center" style={{left: 34}}>
                <img src="/images/boxes/pending-circle.png"  alt='' style={{width : '15px'}}/>
            </div>
            }
            
            <div className={tObj.status === 'pending' ? 'dim-div' : ''}>
                <p className='mb-0'>{tObj.title} {tObj.date ? '-' : ':'} {tObj.date ? tObj.date : tObj.order_number}</p>
            </div>
        </div>
    </div>
    );

    return (
        <div className="my-4">
            {timelineItems}
        </div>
    );
}