import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import { Footer } from '../../../components/layout/Footer';
import { Navigation } from '../../../components/layout/Navigation';
import { ProgressBar } from '../../../components/layout/ProgressBar';

export const Upgrades = () => {


    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Fullfør bestilling</h1>
                    <ProgressBar progress="100" classes="my-3"/>
                    <h4 className='mb-3'>Steg 2/2</h4>
                {/* <p className='mb-4'>For å fullføre bestilling må vi bekrefte noe informasjon og  informasjon om hvordan du ønsker å betale. </p> */}
            </div>
            <hr className='fluid' />
            <div className='container-white pt-4'>
                <p className='f-20 text-bold'>Sikkerhet</p>
                <Row className=''>
                    <Col>
                        <img src='/images/upgrade-alarm.svg' className='w-100' />
                    </Col>
                    <Col>
                        <img src='/images/upgrade-smart-door.svg' className='w-100' />
                    </Col>
                </Row>
                <p className='f-20 text-bold mt-4'>Energi</p>
                <Row className=''>
                    <Col>
                        <img src='/images/upgrade-aircon.svg' className='w-100' />
                    </Col>
                    <Col>
                        <img src='/images/upgrade-awning.svg' className='w-100' />
                    </Col>
                </Row>
            </div>
            <Footer/>
        </div>
    );
}