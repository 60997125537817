import React, { useState, useEffect } from 'react';
import {FormGroup, Button, Label, Row, Form, Col} from 'reactstrap';
import { baseUrl, getHeaders, underAgeValidate} from '../../../helpers/Common';
import { Alert } from '../../utils/Alert';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';
import { useNavigate} from "react-router-dom";
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';


const schema = yup.object().shape({
    name: yup.string().required("Vennligst fyll ut feltet"),
    birthdate: yup.string().required("Vennligst fyll ut feltet").test("birthdate", "Personen må være over 18 år", (value) => {
        return underAgeValidate(value) >= 18;
      }),
    phone: yup.string().required("Vennligst fyll ut feltet").min(8, "Mobilnummer må være på minst 8 tegn").max(32, "Mobilnummer må være på maksimalt 32 tegn"),
    email: yup.string().required("Vennligst fyll ut feltet").email("Vennligst se over at informasjonen er riktig"),
  });

export const UserAddAdultForm = ({type = 'registration'}) => {
    const navigate = useNavigate();
    const [dateFocus, setIsDateFocus] = useState( false );
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
      resolver: yupResolver(schema),
    });

    useEffect(() => {
        reset()
      }, [])
      
    const onSubmitHandler = (data) => {
        
        axios.post(baseUrl()+'users', data, getHeaders()
        ).then(response => {
            if(response.data.status.code === "200"){
                const newUserId  = response.data.data.id;
                const movingOrderId = sessionStorage.getItem('moving_order');
                const memberData = {moving_order: parseInt(movingOrderId), user: newUserId, role : 'editor'};

                //link new user to membership
                axios.post(baseUrl()+'memberships', memberData, getHeaders()
                ).then(response => {

                    console.log('adding user to membership', response);
                    if(response.data.status.code === "200"){
                        // Alert('success', 'Ny bruker lagt til!', '', '', function() {
                            navigate("/"+type+"/users", { replace: true });
                        // })
                    }else{
                        Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
                    }
                }).catch(error => {
                    console.error(error);
                });
            }else{
                Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
            }
        }).catch(error => {
            console.error(error);
        });
      };


    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <FormGroup>
                <Label className='fw-bold pb-3'>Person over 18 år:</Label>
                <input {...register("name")} placeholder="Fullt navn" type="text" className="form-control"/>
                <p className="form-error">{errors.name?.message}</p>
            </FormGroup>
            <FormGroup>
                <input {...register("phone")} placeholder="Telefonnummer" type="text" className="form-control"/>
                <p className="form-error">{errors.phone?.message}</p>
            </FormGroup>
            <FormGroup>
                <input {...register("email")} placeholder="E-post" type="email" className="form-control"/>
                <p className="form-error">{errors.email?.message}</p>
            </FormGroup>
            <FormGroup>
                {!dateFocus && isMobile &&
                <span className="date-placeholder" style={{color : 'color: hsl(0, 0%, 20%)!important', marginTop : '20px', marginLeft : '16px'}}>Fødselsdato</span>
                }
                <input {...register("birthdate")} placeholder="Fødselsdato" type="date" className="form-control" onFocus={() => setIsDateFocus(true) }/>
                <p className="form-error">{errors.birthdate?.message}</p>
            </FormGroup>
            <FormGroup className='row mt-5'>
                <div className='col'>
                    <Button type='submit' className='btn-between-sm w-100'>Legg til</Button>
                </div>
                <div className='col'>
                    <Link  to={"/"+type+"/users"} className='btn-between w-100' >Tilbake</Link>
                </div>
            </FormGroup>
        </form>
    )
}