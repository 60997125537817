import React, {useState, useEffect} from 'react';
import { Footer } from '../components/layout/Footer';
import { Navigation } from '../components/layout/Navigation';
import axios from 'axios';
import { baseUrl, computeProgress, daysLeft, displayAddress, getDisabledTasks, getHeaders, getMovingOrderId } from '../helpers/Common';
import { Link, useNavigate } from 'react-router-dom';
import { ProgressBar } from '../components/layout/ProgressBar';
import { Col, Row } from 'reactstrap';

export const Dashboard = () => {
    const navigate = useNavigate();
    const [totalProgress, setTotalProgress] = useState(0);
    const [activeTasksCount, setActiveTasksCount] = useState(9 - getDisabledTasks().length);
    const [user, setUser] = useState('');
    const [orders, getOrders] = useState({});  
    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    const currentProtocolProgress  = protocolProgress.length !== 0 ? computeProgress(protocolProgress.length, 14) : 0;
    const [timeOfDay, setTimeOfDay] = useState('');
    const movingDate = sessionStorage.moving_date ? new Date(sessionStorage.moving_date) : new Date();
    //get user profile details
    useEffect(() => {
        axios.get(baseUrl()+'users/me', getHeaders()).then(response => {
            setUser(response.data.data);
        }).catch(error => {
            console.error(error);
        });

        const now = new Date();
        const hour = now.getHours();
        if (hour >= 5 && hour < 12) {
          setTimeOfDay('morgen');
        } else if (hour >= 12 && hour < 18) {
          setTimeOfDay('ettermiddag');
        } else {
          setTimeOfDay('kveld');
        }

    }, []);

    // console.log(daysLeft('2023/02/25'));
    console.log(sessionStorage);
    
    const fetchOrders = (type) => axios.get(baseUrl()+'moving_orders/'+getMovingOrderId()+'/orders', getHeaders()
    ).then(response =>  {getOrders(response.data.data ? response.data.data : {})
        if(response.data.data && response.data.data.length !== 0){
            setTotalProgress((100 / activeTasksCount) * parseInt(Object.keys(response.data.data).length));
        }
    })
    .catch(error => console.error(error));

    useEffect(() => {
        fetchOrders();
    }, [])

    const preparationObj = [{
        title : 'Kontrakt', image : 'images/purple/svg/contract.svg', link : '/contract'
    },{
        title : 'Sjekklister', image : 'images/purple/svg/checklist.svg', link : '/checklist'
    },{
        title : 'Nabolag', image : 'images/purple/PNG/between_tips.png', link : '/neighbor'
    },{
        title : 'Tips & artikler', image : 'images/purple/svg/tips.svg', link : '/tips-and-articles'
    }];

    //check if the registration is finished, return to reg if not
    if(!sessionStorage.getItem('from_address')){
        navigate('/registration/address', {replace : true});
    }else{
        return (
            <div className='AppContainer bg-white'>
                <Navigation />
                    <div className='d-flex justify-cotent-start my-4'>
                        <div>
                            {/* <div className='bg-light br-15' style={{width : '70px', height: '70px'}}>TN</div> */}
                            
                            <img src='images/dp.png' alt='display' width={55}/>
                        </div>
                        <div className='ms-4'>
                            <h2 className='mb-2'>God {timeOfDay}, </h2>
                            <p className='mb-0'>{ user.name }</p>
                        </div>
                    </div>

                    <div className='my-2'>
                        <ProgressBar progress={ 50 }/>
                        <div className='my-2 text-bold f-16'>{Math.round(5)} dager igjen</div>
                    </div>
                    {/* <div className='d-flex text-white mb-4'>
                        <img src='images/cloud.svg' alt='Clouds'/>
                        <div className='ms-4 f-18 text-bold lh-normal d-flex align-items-end'>
                            Været på flyttedagen <br/> 25.10.2022
                        </div>
                    </div>
                    <p className='text-white f-18 lh-normal'>
                    <b>Flytter fra:</b> { displayAddress(sessionStorage.getItem('from_address'), true) } <br/>
                    <b>Flytter til:</b> { displayAddress(sessionStorage.getItem('to_address'), true) }
                    </p> */}
                    <div className='content my-4'>
                        <div className='row'>
                            <div className='col pe-2'>
                            <Link to={'/tasks'}>
                                <div className='dash bg-yellow p-4 dash-w-image'>
                                    <div className='dash-title mt-1 mb-3 h-double text-bold f-18'>Oppgaver</div>
                                    <ProgressBar progress={ Math.round(totalProgress) }/>
                                    <div className='mt-2 f-18'>{Math.round(totalProgress)}% ferdig</div>
                                </div>
                            </Link>
                            </div>
                            <div className='col ps-2'>
                                <Link to={'/protocol'}>
                                    <div className='dash bg-yellow p-4 dash-w-image'>
                                        <div className='dash-title mt-1 mb-3 h-double text-bold f-18'>Overtagelses - protokoll</div>
                                        <ProgressBar progress={ Math.round(currentProtocolProgress)}/>
                                        <div className='mt-2 f-18'>{ Math.round(currentProtocolProgress)}% ferdig</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='my-4'>
                        <h3>Gjør deg klar til å flytte</h3>
                        {preparationObj.map((obj, i) => {
                            return <Link to={obj.link} key={i}><div className='hover-pink d-flex align-items-center px-4 py-3 bg-light br-20 mb-3 cursor-pointer'>
                                <div><img src={obj.image} alt={obj.title} width={'50px'}/></div>
                                <div className='ps-3 text-bold'>{obj.title}</div>
                                <div className='ms-auto'></div>
                            </div></Link>
                        })}
                        </div>
                        {/* <div className='row mt-4'>
                            <div className='col pe-2'> 
                                <Link to={'/contract'}>
                                <div className='dash bg-gray p-4 dash-w-image'>
                                    <div className='dash-title mb-1 text-bold f-18'>Kontrakt</div>
                                    <div className='dash-body d-flex align-items-center justify-content-center'>
                                        <img src='images/purple/svg/contract.svg' alt='Contact' width={88.39}/>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className='col ps-2'> 
                                <Link to={'/checklist'}>
                                <div className='dash bg-gray p-4 dash-w-image'>
                                    <div className='dash-title mb-1 text-bold f-18'>Sjekklister</div>
                                    <div className='dash-body d-flex align-items-center justify-content-center'>
                                        <img src='images/purple/svg/checklist.svg' alt='Checklist' width={106.33}/>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col pe-2'> 
                                <Link to={'/neighbor'}>
                                <div className='dash bg-gray p-4 dash-w-image'>
                                    <div className='dash-title mb-1 text-bold f-18'>Nabolag</div>
                                    <div className='dash-body d-flex align-items-center justify-content-center'>
                                        <img src='images/purple/PNG/between_tips.png' alt='Neighbor' width={85.93}/>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className='col ps-2'>
                                <Link to={'/tips-and-articles'}>
                                <div className='dash bg-gray p-4 dash-w-image'>
                                    <div className='dash-title mb-1 text-bold f-18'>Tips & artikler</div>
                                    <div className='dash-body d-flex align-items-center justify-content-center'>
                                        <img src='images/purple/svg/tips.svg' alt='Tips' width={93.08}/>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div> */}
                    </div>

                    <Footer/>
                
            </div>
        )
    }
}