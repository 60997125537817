import React, { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Navigation } from '../../components/layout/Navigation';
import { Footer } from '../../components/layout/Footer';
import { FAQDetailsLayout } from './FAQDetailsLayout';
import { getProductTitle } from '../../helpers/Common';

export const FAQDetails = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState('1');
    const location = useLocation();
    const {category} = useParams();

    const toggle = (id) => {
        console.log(id);
        open === id ? setOpen() : setOpen(id);
    };

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='py-4'>
                <p className='p-header mb-0'>Ofte stilte spørsmål</p>
                {/* <img src='/images/Between_tips.png' alt='between icon hello'
                        style={{position : "absolute",
                                width : "170px", 
                                right : 20,
                                top : 70}} /> */}
            </div>
            <hr className='fluid' />
            <div className='container-white py-4'>
                <p className='f-20 text-bold'>{getProductTitle(category)}</p>
                <FAQDetailsLayout category={category} />
                <div className='pb-4 text-center mt-4'>
                    <Link  to="#" onClick={() => navigate(-1)} className='dark-link text-underline' >Tilbake</Link>
                </div>
            </div>
            <Footer/>
        </div>
    );
}