import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const TipsDetails = () => {
    const location = useLocation();
    const object   = location.state.object;


    const textObj = object.text.map((obj, i) => 
        <p>{ obj }</p>
    );

    return (
        <div className={`AppContainer ${object.type === 'tips' ? 'bg-between' : 'bg-white'}`}>
            <Navigation />
            <div className="py-4">
                <div className="position-relative w-100 d-flex justify-content-center mb-4">
                    {object.type === 'tips' ? 
                    <>
                        <img src='/images/purple/svg/tips.svg' style={{width: '250px'}} alt='Tips from details page'/>
                    </>
                    :
                    <>
                        <img src={`/images/articles/big/${object.image}`} className='w-100' alt="Girl with a bonet"/>
                    </>
                    }
                </div>
                <h1>{ object.title }</h1>
                <div className="mt-4 pb-5">
                    { textObj }
                </div>
                <div className="text-center mt-5">
                    <Link to="/tips-and-articles" className="btn btn-between">Gå tilbake til oversikten</Link>
                </div>
            </div>
            <Footer/>
        </div>
    );
}