import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Navigation } from '../../../components/layout/Navigation';
import { Footer } from '../../../components/layout/Footer';
import { baseUrl, getHeaders, getMovingOrderId, displayAddress, formatPrice } from '../../../helpers/Common';
import axios from 'axios';
import moment from 'moment';
import {FAQDetailsLayout} from '../../faqs/FAQDetailsLayout';
import { Row, Col, Button } from 'reactstrap';

export const ProfileOrderDetails = () => {
    const { product } = useParams();
    const [order, getOrder] = useState([]);  
    const [companyName, setCompanyName] = useState('');
    const [productName, setProductName] = useState('');
    const [price, setPrice] = useState(null);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const to_address = sessionStorage.getItem('to_address');
    const [user, setUser] = useState('');
    const [faqProduct, setFaqProduct] = useState(null);
    
    var unitsTotalPrice = order.storage ? order.storage.subtotal : 0;

    const fetchOrders = (type) => axios.get(baseUrl()+'moving_orders/'+getMovingOrderId()+'/'+product, getHeaders()
    ).then(response => {
        getOrder(response.data.data);
        var o = response.data.data;
        if(product === 'cleaning'){
            setCompanyName(o.provider.supplier);
            setProductName(o.provider.title);
            setPrice(formatPrice(o.total)+',-'); 
            setDeliveryDate(o.date);
            setFaqProduct('cleaning')
        }else if(product === 'garbage'){
            setCompanyName(o.offer.supplier);
            setProductName(o.offer.title);
            setPrice(formatPrice(o.offer.price)+',-');
            setDeliveryDate(o.date);
            setFaqProduct(o.offer.category === 'avfallsekk' ? 'garbage_bag' : o.offer.category === 'plass i en varebil' ? 'garbage_taxi' : 'container')
            console.log(o.offer.category)
        }else if(product === 'power'){
            setCompanyName(o.offer.supplier);
            setProductName(o.offer.title);
            setPrice(formatPrice(o.offer.subscription_price)+',-');
            setDeliveryDate(o.date);
            setFaqProduct('power')
        }else if(product === 'insurance'){
            setCompanyName(o.provider.title);
            setProductName(o.provider.title);
            setPrice(<Link to='/' className='dark-link text-underline'>Se avtale</Link>);
            setDeliveryDate(o.date);
            setFaqProduct('insurance')
        }else if(product === 'material'){
            setCompanyName(o.units[0].offer.supplier);
            setProductName(o.units[0].offer.title);
            setDeliveryDate(o.created_at);
            setPrice(formatPrice(o.total)+',-');
            setFaqProduct('material')
        }else if(product === 'relocation'){
            setPrice(formatPrice(o.total)+',-');
            setFaqProduct('moving_truck')
        }
        // console.log(order);
    }).catch(error => console.error(error));

    useEffect(() => {
        fetchOrders();
    }, [])
        
    //get user profile details
    useEffect(() => {
        axios.get(baseUrl()+'users/me', getHeaders()).then(response => {
            setUser(response.data.data);
        }).catch(error => {
            console.error(error);
        });
    }, []);

    console.log(order);

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <p className='p-header position-relative mb-0'>Ordrebekreftelse</p>
                {companyName ? 
                <p>{ companyName }</p>
                :
                <br /> 
                }
            </div>
            <hr className='fluid' />
            <div className='container-white pt-4'>
                <div className='light-box p-4'>
                    <p className='f-20 text-bold mb-0'>Din bestilling</p>
                    <div className='mt-4'>
                        <p>{ productName }</p>
                        <table className='w-100'>
                            <tbody>
                                {product === 'material' && order.units ? 
                                <>
                                {order.units.map((unit) => {
                                    unitsTotalPrice = parseInt(unitsTotalPrice) + product !== 'relocation' ? parseInt(unit.offer.price) : parseInt(unit.price);
                                    return <tr>
                                        <td>{unit.offer.title}</td>
                                    <td align='right' className='text-bold'>{ formatPrice(unit.offer.price) },-</td>
                                    </tr>
                                })}
                                <tr>
                                    <td colSpan={2}><hr /></td>
                                </tr>
                                <tr>
                                    <td className='text-bold'>Totalt inkl.MVA</td>
                                    <td align='right' className='text-bold'>Kr. { unitsTotalPrice },-</td>
                                </tr>
                                </>
                                : product === 'relocation' && order.units  ? 
                                <>
                                {/* {order.units.map((unit) => {
                                    unitsTotalPrice = parseInt(unitsTotalPrice) + parseInt(unit.subtotal);
                                    return <tr> 
                                        <td>{quantitles[unit.type]}</td>
                                    <td align='right' className='text-bold'>{ formatPrice(unit.subtotal) },-</td>
                                    </tr>
                                })}  */}
                                {order.storage &&
                                <>
                                     <tr> 
                                         <td>Mellomlagring</td> 
                                         <td align='right' className='text-bold'>{ formatPrice(order.storage.subtotal) },-</td>
                                     </tr>
                                 </>
                                }
                                <tr>
                                    <td colSpan={2}><hr /></td>
                                </tr>
                                <tr>
                                    <td className='text-bold'>Totalt inkl.MVA</td>
                                    <td align='right' className='text-bold'>Kr. { unitsTotalPrice },-</td>
                                </tr>
                                </>
                                :
                                <>
                                <tr>
                                    <td className='text-bold'>Totalt inkl.MVA</td>
                                    <td align='right'  className='text-bold'>Kr. { price }</td>
                                </tr>
                                </>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-4'>
                        <p className='text-bold mb-0'>Leveringsadresse</p>
                        { user.name }<br />
                        { displayAddress(to_address) }
                    </div>
                    <div className='mt-4'>
                        <p className='text-bold mb-0'>Dato for utvask</p>
                        { moment(deliveryDate).format('D.MMMM YYYY') }
                    </div>
                    <div className='mt-4'>
                        <p className='text-bold mb-0'>Viktig informasjon</p>
                        <p> Du blir kontaktet på telefon for å avtale nøyaktig tidspunkt og tilgang til bolig noen dager før. (Delivery description)
                        </p>
                    </div>
                </div>

                <div className='p-4'>
                    <p className='f-20 text-bold mb-0'>Hva skjer nå?</p>
                    <div className='w-100 mt-4 d-flex flex-column align-content-center justify-content-center align-items-center'>
                        <div className='orders-timeline purple'>
                            <p className='text-bold mb-0'>Torsdag 25.05</p>
                            <span className='f-14'>Bestilling bekreftet</span>
                        </div>
                        <div className='orders-time-line'>
                                <span className='orders-dot'></span>
                        </div>
                        <div className='orders-timeline light-box'>
                            <p className='text-bold'>26.05 - 28.05</p>
                            <span className='f-14 text-left'>
                                Vi kontakter deg på telefon for å avtale dagen for utvask                            
                            </span>
                        </div>
                        <div className='orders-time-line'>
                                <span className='orders-dot'></span>
                        </div>
                        <div className='orders-timeline light-box'>
                            <p className='text-bold'>Tirsdag 25.06</p>
                            <span className='f-14 text-left'>
                            Dato for utvask                           
                            </span>
                        </div>
                    </div>
                </div>

                <p className='f-20 text-bold mb-0 px-4'>Informasjon</p>
                    <div className='my-4'>
                        {faqProduct &&
                        <FAQDetailsLayout category={faqProduct} />
                        }
                    </div>

            </div>
            
            <div className='pt-3 pb-4'>
                    <Row>
                        <Col>
                            <Link to='/tasks' className='btn-between-white w-100 px-0' style={{lineHeight: '0.9rem'}}>Gå til neste oppgave</Link>
                        </Col>
                        <Col>
                            <Link to='/profile/orders/all' className='btn-between w-100'>Se alle ordre</Link>
                        </Col>
                    </Row>
                </div>
            <Footer/>
        </div>
    );
}