import React from 'react';
import { Navigation } from '../../components/layout/Navigation';
import { RegistrationHeader } from '../../components/layout/RegistrationHeader';
import { UserEditForm } from '../../components/layout/users/UserEditForm';

export const SettingsUserEdit = (type = 'registration') => {

    return (
        <div id='EditUserContainer'>
            <Navigation />
            <div className='py-4'>
                <p className='f-30 mb-0 text-bold'>Personer i hustanden</p>
            </div>
            <div className='container-white pt-2 pb-4'>
                <UserEditForm type='settings' />
            </div>
        </div>
    )
}