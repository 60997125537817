import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

export const Checklist = () => {

    const checklistObj = [{
        position : '1',
        title : 'Sjekkliste 1',
        progress: 0,
        list: [
            'Pakk ut - Ta deg god tid til å pakke ut alle tingene fra flyttelasset.',
            'Bytt låser - Hvem vet hva de tidligere eierne gjorde med nøklene sine?',
            'Finn stengeventiler - I tilfelle det er en vannlekkasje eller en reparasjoner',
            'Finn sikringskapet - Av og til må du kanskje bytte ut en sikring eller tilbakestille den.',
            'Finn og test røykvarslerne dine - Røykvarslere bør testet minst en gang i året og du vet ikke når de ble testet sist.',
            'Lag en beredskapsplan - Sørg for at alle i husstanden har en klar forståelse av hva de skal gjøre i nødstilfeller.',
            '<b>Løsning for hvis du låser deg ut</b> - En eller annen gang kommer du til å låse deg ute. Hva gjør du da? ',
            'Finn og test røykvarslerne dine - Røykvarslere bør testet minst en gang i året og du vet ikke når de ble testet sist.',
            'Lag sjekkliste for vedlikehold- Ting som å rengjøre takrennene, hagearbeid, tømme sluk på badet eller sjekke brøytetjenester',
        ]},{
        position : '21',
        title : 'Sjekkliste 2',
        progress: 100,
        list: [
            'Pakk ut - Ta deg god tid til å pakke ut alle tingene fra flyttelasset.',
            'Bytt låser - Hvem vet hva de tidligere eierne gjorde med nøklene sine?',
            'Finn stengeventiler - I tilfelle det er en vannlekkasje eller en reparasjoner',
            'Finn sikringskapet - Av og til må du kanskje bytte ut en sikring eller tilbakestille den.',
            'Finn og test røykvarslerne dine - Røykvarslere bør testet minst en gang i året og du vet ikke når de ble testet sist.',
            'Lag en beredskapsplan - Sørg for at alle i husstanden har en klar forståelse av hva de skal gjøre i nødstilfeller.',
            '<b>Løsning for hvis du låser deg ut</b> - En eller annen gang kommer du til å låse deg ute. Hva gjør du da? ',
            'Finn og test røykvarslerne dine - Røykvarslere bør testet minst en gang i året og du vet ikke når de ble testet sist.',
            'Lag sjekkliste for vedlikehold- Ting som å rengjøre takrennene, hagearbeid, tømme sluk på badet eller sjekke brøytetjenester',
        ]},{
        position : '3',
        title : 'Sjekkliste 3',
        progress: 0,
        list: [
            'Pakk ut - Ta deg god tid til å pakke ut alle tingene fra flyttelasset.',
            'Bytt låser - Hvem vet hva de tidligere eierne gjorde med nøklene sine?',
            'Finn stengeventiler - I tilfelle det er en vannlekkasje eller en reparasjoner',
            'Finn sikringskapet - Av og til må du kanskje bytte ut en sikring eller tilbakestille den.',
            'Finn og test røykvarslerne dine - Røykvarslere bør testet minst en gang i året og du vet ikke når de ble testet sist.',
            'Lag en beredskapsplan - Sørg for at alle i husstanden har en klar forståelse av hva de skal gjøre i nødstilfeller.',
            '<b>Løsning for hvis du låser deg ut</b> - En eller annen gang kommer du til å låse deg ute. Hva gjør du da? ',
            'Finn og test røykvarslerne dine - Røykvarslere bør testet minst en gang i året og du vet ikke når de ble testet sist.',
            'Lag sjekkliste for vedlikehold- Ting som å rengjøre takrennene, hagearbeid, tømme sluk på badet eller sjekke brøytetjenester',
        ]},{
        position : '4',
        title : 'Sjekkliste 4',
        progress: 0,
        list: [
            'Pakk ut - Ta deg god tid til å pakke ut alle tingene fra flyttelasset.',
            'Bytt låser - Hvem vet hva de tidligere eierne gjorde med nøklene sine?',
            'Finn stengeventiler - I tilfelle det er en vannlekkasje eller en reparasjoner',
            'Finn sikringskapet - Av og til må du kanskje bytte ut en sikring eller tilbakestille den.',
            'Finn og test røykvarslerne dine - Røykvarslere bør testet minst en gang i året og du vet ikke når de ble testet sist.',
            'Lag en beredskapsplan - Sørg for at alle i husstanden har en klar forståelse av hva de skal gjøre i nødstilfeller.',
            '<b>Løsning for hvis du låser deg ut</b> - En eller annen gang kommer du til å låse deg ute. Hva gjør du da? ',
            'Finn og test røykvarslerne dine - Røykvarslere bør testet minst en gang i året og du vet ikke når de ble testet sist.',
            'Lag sjekkliste for vedlikehold- Ting som å rengjøre takrennene, hagearbeid, tømme sluk på badet eller sjekke brøytetjenester',
        ]}
    ];


    const verticalItems = checklistObj.map((obj, i) => 
    <Link to={{pathname : '/checklist/details', checklist : obj.list, progress : obj.progress}} state={{checklist : obj }} key={i}>
        <div className={`p-4 mb-4 br-20 ${obj.progress === 100 ? `bg-purple` : `bg-light-yellow`}`}>
            <h4>{obj.title} <FontAwesomeIcon icon={faChevronRight} className={'float-end'} /></h4>
            <Row>
                <Col>
                    <img src={`images/${obj.progress === 100 ? 'purple/svg' : 'yellow/svg2'}/checklist.svg`} alt={''} />
                </Col>
                <Col className="pt-3 d-flex align-items-end">
                    <div className="w-100">
                        <ProgressBar progress={obj.progress} />
                        <h4 className="mt-2">{obj.progress}% ferdig</h4>
                    </div>
                </Col>
            </Row>
        </div>
    </Link>
    );

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Sjekklister</h1>
                <p>Hos oss får du oversikt og hjelp til å faselitere hele flytteprosessen.</p>
            </div>
            <div className="pt-4">
                { verticalItems }
            </div>
            <Footer/>
        </div>
    );
}