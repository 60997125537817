import React, { useState, useEffect } from 'react';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import axios from 'axios';
import { getHeaders, baseUrl} from '../../helpers/Common';
import { ParseString } from '../../components/elements/ParseString';

export const FAQDetailsLayout = ({category}) => {
    const [faq, getFaq] = useState(null);
    const [open, setOpen] = useState('1');

    const toggle = (id) => {
        open === id ? setOpen() : setOpen(id);
    };

    //get all FAQ details
    const fetchFaq = () => {
        axios.get(baseUrl()+'faqs?subcategory='+category, getHeaders()
        ).then(response => getFaq(response.data.data))
        .catch(error => console.error(error))
    }       
    
    useEffect(() => {
        fetchFaq();
    }, []); 

    return (
        <>
        { faq?.map((obj, i) => {
            const id = 'pos-'+i;
            return <Accordion open={open} className='mb-2' toggle={toggle} key={i}>
                <AccordionItem key={i} className='vertical-classic p-2'>
                    <AccordionHeader targetId={ id }> <span>{obj.question}</span> </AccordionHeader>
                    <AccordionBody accordionId={ id }>
                        <ParseString string={obj.answer}></ParseString>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
            }
        )} 
        </>
    );
}