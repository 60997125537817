import React from 'react';
import {isMobile} from 'react-device-detect';
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'
import AllRoutes from './routes/AllRoutes';

library.add(far);

function App() {

  return (
    <div className={!isMobile ? "App-desktop bg-between" : "App bg-between"}>
        <AllRoutes />
    </div>
  );
}

export default App;
 