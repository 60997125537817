import React, { useState, useEffect } from 'react';
import {FormGroup, Button, Label} from 'reactstrap';
import { baseUrl, getHeaders, underAgeValidate } from '../../../helpers/Common';
import { Alert } from '../../utils/Alert';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

const schema = yup.object().shape({
    name: yup.string().required("Vennligst fyll ut feltet"),
    birthdate: yup.string().required("Vennligst fyll ut feltet").test("birthdate", "Personen må være under 18", (value) => {

        return underAgeValidate(value)  < 18 && moment().toDate() >=  moment(value);
      }),
  });

export const UserAddMinorForm = ({type = 'registration'}) => {
    const navigate = useNavigate();
    const [dateFocus, setIsDateFocus] = useState( false );
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
      resolver: yupResolver(schema),
    });

    useEffect(() => {
        reset()
      }, [])

    const onSubmitHandler = (data) => {

        //minor flag
        data.is_minor = 1;
        
        axios.post(baseUrl()+'users', data, getHeaders()
        ).then(response => {
            if(response.data.status.code === "200"){
                const newUserId  = response.data.data.id;
                const movingOrderId = sessionStorage.getItem('moving_order');

                const memberData = {moving_order: parseInt(movingOrderId), user: newUserId, role : 'viewer'};

                //link new user to membership
                axios.post(baseUrl()+'memberships', memberData, getHeaders()
                ).then(response => {

                    console.log('adding user to membership', response);
                    if(response.data.status.code === "200"){
                        // Alert('success', 'Ny bruker lagt til!', '', '', function() {
                            navigate("/"+type+"/users", { replace: true });
                        // })
                    }else{
                        Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
                    }
                }).catch(error => {
                    console.error(error);
                });
            }else{
                Alert('error', 'Error '+response.data.status.code, response.data.status.message, '<a href="/register">Vær så snill, prøv på nytt</a>', null)
            }
        }).catch(error => {
            console.error(error);
        });
      };

    console.log(underAgeValidate('2022-02-21'));

    return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
        <FormGroup>
            <Label className='fw-bold pb-3'>Person under 18 år:</Label>
            <input type='hidden' name='is_minor' value='1' />
            <input {...register("name")} placeholder="Fullt navn" type="text" className="form-control"/>
            <p className="form-error">{errors.name?.message}</p>
        </FormGroup>
        <FormGroup>
            {!dateFocus && isMobile &&
            <span className="date-placeholder" style={{color : 'color: hsl(0, 0%, 20%)!important', marginTop : '20px'}}>Fødselsdato</span>
            }
            <input {...register("birthdate")} placeholder="Fødselsdato" type="date" className="form-control" onFocus={() => setIsDateFocus(true) }/>
            <p className="form-error">{errors.birthdate?.message}</p>
        </FormGroup>
        <FormGroup className='row mt-5'>
            <div className='col'>
                <Button type='submit' className='btn-between-sm w-100'>Legg til</Button>
            </div>
            <div className='col'>
                {/* <Button type='button' onClick={() => reset({
                    name : '',
                    birthdate : ''
                })} className='btn-between-sm w-100'>Fjern</Button> */}
                <Link  to={"/"+type+"/users"} className='btn-between w-100' >Tilbake</Link>
            </div>
        </FormGroup>
    </form>
    )
}