import { Navigation } from "../../../../components/layout/Navigation";
import { Footer } from "../../../../components/layout/Footer";
import { Button, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Form } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLottie } from "../../../../components/layout/ButtonLottie";
import { useEffect } from "react";
import { VerticalHeader } from "../../../../components/layout/VerticalHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { cleanString, displayAddress, displayAddressDefault, formatPrice } from "../../../../helpers/Common";
import moment from "moment/moment";
import { MovingBoxesPrices } from "./MovingBoxesPrices";

export const MovingBoxesStep4 = () => {
    const boxesProgress = sessionStorage.getItem('boxes_progress') ? JSON.parse(sessionStorage.getItem('boxes_progress')) : [];
    var   boxesStep1    = sessionStorage.getItem('boxes_step_1')   ? sessionStorage.getItem('boxes_step_1') : '';
    var   boxesStep2    = sessionStorage.getItem('boxes_step_2')   ? JSON.parse(sessionStorage.getItem('boxes_step_2')) : '';
    var   boxesStep2cp    = sessionStorage.getItem('boxes_step_2_size_objects')   ? JSON.parse(sessionStorage.getItem('boxes_step_2_size_objects')) : [];
    var   boxesStep3  = sessionStorage.getItem('boxes_step_3')   ? JSON.parse(sessionStorage.getItem('boxes_step_3')) : [];
    const navigate = useNavigate();
    const [totalPrice, setTotalPrice] = useState(0);
    const fromAddress = sessionStorage.getItem('from_address') ? sessionStorage.getItem('from_address') : {}
    const toAddress  = sessionStorage.getItem('to_address') ? sessionStorage.getItem('to_address') : {}
    const [isLottie, setIsLottie]   = useState(false);


    useEffect(() => {
        const prices = document.getElementsByClassName('prices');
        var tempPrice = 0;
        if(prices.length > 0){
            for (let index = 0; index < prices.length; index++) {
                const element = prices[index];
                const price = element.dataset.price;
                
                tempPrice = tempPrice + parseInt(price);
            }
            setTotalPrice(tempPrice);
            sessionStorage.setItem('boxes_total_items_price', tempPrice);
        }

    }, []);

    const handleBtnConfirm = () => {
        sessionStorage.setItem('boxes_order_confirmed', true);
        setIsLottie(true);

        //add to progress for the progress bar in the homepage
        if(!boxesProgress.includes('step4')){
            boxesProgress.push('step4');
            sessionStorage.setItem('boxes_progress', JSON.stringify(boxesProgress));
        }
        // setTimeout(function(){
            navigate('/verticals/moving-order/boxes/complete-order');
        // }, 1000)
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Fullfør bestilling' progress={4} totalProgress={4} />

            <div className="pt-4">
                <h3>Bestillingsdetaljer</h3>
                <div className="my-4">
                    {boxesStep1 === 'friendly_boxes' &&
                    <div>
                        <h3>Hente detaljer</h3>
                        <p className="mb-0">{displayAddressDefault(fromAddress)}</p>
                        <p><span className="text-bold">Dato:</span> {moment(boxesStep2.pickup_date).format('MM.DD.YYYY')} </p>
                    </div>
                    }
                    <div>
                        <h3>Leverings detaljer</h3>
                        <p className="mb-0">{displayAddressDefault(toAddress)}</p>
                        <p><span className="text-bold">Dato:</span> {moment(boxesStep2.delivery_date).format('MM.DD.YYYY')} </p>
                    </div>
                </div>
                <div className="my-4">
                    <h3>Leveringsinformasjon</h3>
                    <p>Vi vil kontakte deg senest 2 dager før hente- og leveringsdato å bekrefte noen siste detaljer. </p>
                    <p>Har du noen spørsmål i mellomtiden kan du ta kontakt med våre flyttekonsulenter.</p>
                </div>
                <MovingBoxesPrices />
                <Row className="text-center my-4">
                    <Col>
                        <Link to="/verticals/moving-order/boxes/step-3" className="btn-between-white w-100">Gå tilbake</Link>
                    </Col>
                    <Col>
                        {/* <Button onClick={ handleBtnConfirm } className="w-100 btn-between">
                            { isLottie ?  <ButtonLottie button='Send bestilling' left='80px' /> :  <span>Send bestilling</span> }    
                        </Button> */}
                        <Button onClick={ handleBtnConfirm } className="w-100 btn-between">Send bestilling 
                        </Button>
                    </Col>
                </Row>
            </div>
            <Footer/>
        </div>
    );
}