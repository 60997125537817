import React from 'react';
import { ListGroupItem, ListGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Navigation } from '../../components/layout/Navigation';
import { Footer } from '../../components/layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PeopleList from '../registration/PeopleList';


export const SettingsUsers = () => {
        
    const verticalObjects = [{
            position : '1',
            title : 'Adresser',
            icon: 'map-pin',
            path : '/settings/address',
        },{
            position : '3',
            title : 'Personer i hustanden',
            icon: 'users',
            path : '/settings/users'
        },
    ];

    const verticalObjects2 = [{
            position : '2',
            title : 'Personvern',
            icon: 'shield-alt',
            path : '/settings/privacy'
        }, {
            position : '3',
            title : 'Ofte stilte spørsmål',
            icon: 'comments',
            path : '/settings/faq'
        }, {
            position : '4',
            title : 'Vilkår',
            icon: 'file-circle-check',
            path : '/settings/terms'
        }, 
    ];

    const verticalItems = verticalObjects.map((verticalObject) => 
        <Link to={ verticalObject.path }>
            <ListGroupItem key={verticalObject.position} className='py-0'> 
                <table className="w-100">
                    <tbody>
                        <tr>
                            <td width="30px">
                                <FontAwesomeIcon icon={['far', verticalObject.icon]} />
                            </td>
                            <td className={`py-3 ${verticalObject.position !== '3' && ' border-bottom'}`}>
                                { verticalObject.title }
                                <FontAwesomeIcon icon={['far', 'chevron-right']} style={{color : '#727C8E'}} className='float-end mt-1'  />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ListGroupItem>
        </Link>
    );

    const verticalItems2 = verticalObjects2.map((verticalObject2) => 
        <Link to={ verticalObject2.path }>
            <ListGroupItem key={verticalObject2.position} className='py-0'> 
                <table className="w-100">
                    <tr>
                        <td width="30px">
                           <FontAwesomeIcon icon={['far', verticalObject2.icon]} />
                        </td>
                            <td className={`py-3 ${verticalObject2.position !== '4' && ' border-bottom'}`}>
                                { verticalObject2.title }
                                <FontAwesomeIcon icon={['far', 'chevron-right']} style={{color : '#727C8E'}} className='float-end mt-1'  />
                            </td>
                    </tr>
                </table>
            </ListGroupItem>
        </Link>
    );

    return (
        <div className='AppContainer bg-between'>
            <Navigation />
            <div className='pt-4'>
                <p className='f-30 mb-4 text-bold'>Personer i hustanden</p>
            </div>
            <div className='container-white py-4 mh-sm'>
                <PeopleList type='settings'/>
                <div className='mt-5'>
                    <Link to="/settings/user/new/over-18" className='dark-link'>
                        <FontAwesomeIcon icon={['far', 'plus']} className='text-violet'/> Legg til person <b>over 18</b> år i husstanden
                    </Link> <br /><br />
                    <Link to="/settings/user/new/under-18" className='dark-link'>
                        <FontAwesomeIcon icon={['far', 'plus']} className='text-violet' /> Legg til person <b>under  18</b> år i husstanden
                    </Link>
                </div>
                <div className='my-5'>
                    <div className='pb-4 text-center'>
                        <Link  to="/settings" className='dark-link text-underline' >Tilbake</Link>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}