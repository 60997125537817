import React from 'react';
import {  Progress, UncontrolledAccordion, AccordionItem, AccordionBody, AccordionHeader, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import { ProgressBar } from '../../../../components/layout/ProgressBar';

export const MovingOrderGarbageServicesSelect = () => {
        const imgStyle = [{position : 'absolute', width: '100%'}];
        
        const verticalObjects = [{
                position : '1',
                title : 'iSekk',
                image : '/images/isekk.png',
                price: '1 265',
            },{
                position : '2',
                title : 'Ragn Sells - 10 m2 Container',
                image : '/images/ragn-sells.png',
                price: '10 303',
            },
        ];

        const verticalItems = verticalObjects.map((vObj) => 
            <AccordionItem>
                <AccordionHeader targetId={vObj.position}> {vObj.title} </AccordionHeader>
                <AccordionBody accordionId={vObj.position}>
                    <div className='equipment-item'>
                        <Row>
                            <Col><img src={vObj.image} /></Col>
                            <Col>
                            <img src="/images/5-stars.png" className='img-star' />
                            <p className='text-right mt-2'>250 verifiserte flytteoppdrag</p>
                            <p className='text-right f-20 mt-4'>Kr. {Math.round(vObj.price)},-</p>
                            </Col>
                        </Row>
                        <Link to="/verticals/moving-order/garbage-services/complete-order" className='btn-between w-100 mt-2'>Velg</Link>
                        {/* <button className='btn-between w-100 mt-2'>Velg</button> */}
                    </div>
                </AccordionBody>
            </AccordionItem>
        );

        return (
            <div className='AppContainer bg-between'>
                <Navigation />
                <div className='pt-4'>
                    <img src='/images/Between_moving_van.png' alt='between icon hello'
                        style={{position : "absolute",
                                width : "162.62px",
                                height : "75.97",
                                right : 0,
                                top : 170}} />
                    <p className='p-header position-relative'>Søppeltjenester</p>
                    <p className='mb-5 mt-4'>
                    Vi hjelper deg finne den <br/> beste søppeltjenesten <br/>  for dinbolig
                    </p>
                </div>
                <div className='container-white pt-4'>
                    <p className='f-20'>Kaste søppel?</p>
                    <ProgressBar progress="66" classes="my-3"/>
                    <p className='text-bold'>Steg 2/3</p>

                    <div className='px-2'>
                        <p className='f-14'>Velg søppeltjeneste</p>
                        <Row>
                            <Col>
                                <div className='options-white p-3'>
                                    <img src='/images/truck-icon.png'  style={{width: '80px'}}/>
                                    <p className='f-14  mb-0'>Varebil</p>
                                </div>
                            </Col>
                            <Col>
                                <div className='options-white p-3'>
                                    <img src='/images/garbage-bag-icon.png'  style={{width: '80px'}}/>
                                    <p className='f-14  mb-0'>Avfalllsekk</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <div className='options-white p-3'>
                                    <img src='/images/container-icon.png'  style={{width: '80px'}}/>
                                    <p className='f-14  mb-0'>Container</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <UncontrolledAccordion defaultOpen={["1"]} className='py-4'>
                        {verticalItems}
                    </UncontrolledAccordion>
                </div>
                <Footer/>
            </div>
        );
}