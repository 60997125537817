import { Navigation } from "../../../../components/layout/Navigation";
import { Footer } from "../../../../components/layout/Footer";
import { Button, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Form } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLottie } from "../../../../components/layout/ButtonLottie";
import { useEffect } from "react";
import { VerticalHeader } from "../../../../components/layout/VerticalHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight, faMinus, faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { cleanString, displayAddress, displayAddressDefault, formatPrice } from "../../../../helpers/Common";
import moment from "moment/moment";
import { MovingBoxesPrices } from "./MovingBoxesPrices";
import { MovingBoxesTimeline } from "./MovingBoxesTimeline";
import { VippsButton } from "../../../../components/elements/VippsButton";
import Rating from 'react-rating';
import { faStar as starSolid } from "@fortawesome/free-solid-svg-icons";
import checkCircle from "../../../../lottie/check-circle-sm.json";
import Lottie from "lottie-react";


export const MovingBoxesPayment = () => {
    const boxesProgress = sessionStorage.getItem('boxes_progress') ? JSON.parse(sessionStorage.getItem('boxes_progress')) : [];
    var   boxesStep2 = sessionStorage.getItem('boxes_step_2')   ? JSON.parse(sessionStorage.getItem('boxes_step_2')) : '';
    const toAddress  = sessionStorage.getItem('to_address') ? sessionStorage.getItem('to_address') : {}
    const [rating, setRating] = useState(sessionStorage.getItem('boxes_rating') ? sessionStorage.getItem('boxes_rating') : 0);
    const [isLottie, setIsLottie]   = useState(false);
    const navigate = useNavigate();

    const timelineObjects = [{
        id : 1,
        title : 'Order received',
        date: '25.06.23',
        status : 'finished'
    },{
        id : 2,
        title : 'Order prepared',
        date: '25.06.23',
        status : 'finished'
    },{
        id : 3,
        title : 'Order sent',
        date: '25.06.23',
        status : 'finished'
    },{
        id : 4,
        title : 'Order on the way',
        date: null,
        order_number : '331991238823',
        status: 'finished'
    },{
        id : 5,
        title : 'Order delivered',
        date: '25.06.23',
        status: 'current'
    }];
    const handleRatingClick = ( value ) => {
        setRating( value );
        sessionStorage.setItem('boxes_rating', value);
    }
    const handleBtnConfirm = () => {
        sessionStorage.setItem('boxes_order_confirmed', true);
        setIsLottie(true);

        //add to progress for the progress bar in the homepage
        if(!boxesProgress.includes('step4')){
            boxesProgress.push('step4');
            sessionStorage.setItem('boxes_progress', JSON.stringify(boxesProgress));
        }
        setTimeout(function(){
            navigate('/verticals/moving-order/boxes/confirm');
        }, 1000)
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <VerticalHeader title='Ordrestatus' progress={false}/>
            <div className="my-4">
                <div className="mb-2">
                    <p className="mb-0 text-bold">#35346</p>
                    <p className="mb-0">Forventet levering: {moment(boxesStep2.delivery_date).format("DD MMMM, YYYY")}</p>
                </div>
                <div>
                    <p className="mb-0">Leveringsadresse:</p>
                    <p className="mb-0 text-bold">{displayAddressDefault(toAddress)}</p>
                </div>
            </div>
             <hr className="fluid" />
            <div className="my-4">
                <div className="bg-light br-20 text-center">
                    <h3>Hvordan var din opplevelse?</h3>
                    <p>Tilbakemeldingen din vil hjelpe oss å forbedre oss. </p>

                    <div className="my-4">
                    <Rating
                        onClick={ handleRatingClick }
                        initialRating={rating}
                        emptySymbol={<FontAwesomeIcon icon={faStar} style={{fontSize: '40px', color : '#c6cef2'}}/>}
                        fullSymbol={<FontAwesomeIcon icon={starSolid} style={{fontSize: '40px', color: '#9C78FF'}} />}
                    />
                    </div>
                    <p className="f-14 text-bold mb-0">Gikk noe galt?</p>
                    <p className="f-14">Kontakt oss på +47 99887744 eller, <br />send oss en melding her</p>
                </div>
            </div>
            <hr className="fluid" />

            <MovingBoxesTimeline timeline={timelineObjects} />
            
            <hr className="fluid" />
            
            <MovingBoxesPrices title={'Sammendrag'} />
                    <div className="my-4 text-center">
                        <Button onClick={handleBtnConfirm} 
                            className="position-relative text-bold text-white py-2 btn-vipps br-5 w-100">
                        { isLottie ?  
                        <>
                        <Lottie animationData={checkCircle} loop={false} className="lottie-container-sm" style={{position : 'absolute', left : 60, bottom : 7}} />
                        <span className="me-1">Betal med</span> 
                        </>
                        : 
                        <span className="me-1">Betal med</span> 
                        }
                         <img src='/images/vipps-white.png' alt='vipps white icon' />
                        </Button>
                    </div>
            <Footer/>
        </div>
    );
}