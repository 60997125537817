import React, {useState, useEffect} from 'react';
import { Accordion, Button, AccordionItem, AccordionBody, AccordionHeader, Input, Label, Row, Col, FormGroup, Modal, ModalHeader, ModalBody, Card, CardTitle, CardBody } from 'reactstrap';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import { ButtonLottie } from '../../../../components/layout/ButtonLottie';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl, getHeaders } from '../../../../helpers/Common';
import { StringToArray } from '../../../../components/elements/ParseString';
import { ProductImage } from '../../../../components/layout/ProductImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faAngleRight } from '@fortawesome/pro-regular-svg-icons';

export const MovingCompanyPower = () => {
    const navigate = useNavigate();
    const [providers, setProviders] = useState([]);
    const [isLottie, setIsLottie] = useState(false);
    const [powerItems, getPowerItems] = useState(JSON.parse(sessionStorage.getItem('power_items')));    
    const [open, setOpen] = useState('');
    const [selected, setSelected] = useState(new Set([]));
    const [modalInfo, setModalInfo] = useState(null);
    const [infoOpen, setInfoOpen] = useState(0);

    const powerInfo = [
        {'title' : 'Våre avtaler',
         'body' : 'Historisk har du kommet best ut med en god spotprisavtale, derfor er alle våre avtaler spotpris med minst 12 måneders prisgaranti. Da risikerer du ikke at prisen blir satt opp innen kort tid.'},
         {'title' : 'Hva er en spotprisavtale?', 
          'body' : 'En spotprisavtale følger markedsprisen på strøm som fastsettes av kraftbørsen.  Prisen varierer fra time til time. I tillegg til markedsprisen betaler du et påslag og/eller et fastbeløp som bestemmes av strømselskapet. Siden strømprisen er regulert av kraftbørsen, er det påslaget og fastbeløpet som avgjør om du har en dyr eller billig avtale. '},
         {'title' : 'Hva er prisgaranti?',
          'body' : 'Prisgaranti betyr at strømselskapet garanterer for at prisen på påslaget/fastbeløpet i din strømavtale ikke økes i løpet av garantiperioden. Alle våre avtaler har prisgaranti på minst 12 måneder.'}
    ]

    const toggle = (id) => {
        open === id ? setOpen() : setOpen(id);
    };
    const toggle2 = (data) => {
        setModalInfo(!modalInfo)
    };
    const infoToggle = (id) => {
        infoOpen === id ? setInfoOpen() : setInfoOpen(id);
    };
    const handlePowerFilter = ( num ) => {
        const newSelectedItems = new Set(selected)
        if (!newSelectedItems.has(num)) {
            newSelectedItems.add(num)
        } else {
            newSelectedItems.delete(num)
        }
        setSelected(newSelectedItems)
    }

    const isButtonSuccess = (e) => {
        const link      = e.currentTarget.dataset.link;
        const position  = e.currentTarget.dataset.position;
        const pid       = e.currentTarget.dataset.pid;
        const title     = e.currentTarget.dataset.pid;

        setIsLottie(position);

        sessionStorage.setItem('power_items', JSON.stringify({pid : pid, title, position : position}));
        
        setTimeout(function(){
            navigate(link);
        }, 1200)
    }

    const fetchProducts = () => {
        axios.get(baseUrl()+'power_service_offers/', getHeaders()
        ).then(response => setProviders(response.data.data))
        .catch(error => console.error(error))
    }

    useEffect(() => {
        fetchProducts();

    }, []);


    //users came from confirmation page
    useEffect(() => {
        if(powerItems){
            setOpen(powerItems.position);
            setIsLottie(powerItems.position);
        }
    }, [])

 
    const handleNextStep = (e) => {
        navigate('/verticals/moving-company/power/complete-order');
    }

    const verticalItems = providers.map((vObj, i) => {
    const position = 'pos-'+i;
    return <>
        {(selected.has(i) || selected.size === 0) &&
        <AccordionItem className='products-vertical mb-4' key={position}>
            <AccordionHeader targetId={position} className='products-vertical-header'>
                <div className='w-100 mb-4'>
                    <Row>
                        <Col xs={5} className='d-flex justify-content-center align-items-center'>
                            {vObj.title && <ProductImage image={vObj.title} className='w-65px' />}
                        </Col>
                        <Col xs={7}>
                            <p className='text-bold mb-0'><h4 className='mb-1'>{vObj.title}</h4></p>
                            <img src='/images/5stars.svg' style={{marginTop: '-17px', width : '80px'}} alt='5 stars'></img>
                                <table className='text-normal'>
                                    <tbody>
                                        <tr>
                                            <td>✓</td>
                                            <td>Strøm til spotpris</td>
                                        </tr>
                                        <tr>
                                            <td>✓</td>
                                            <td>Ingen bindingstid</td>
                                        </tr>
                                        <tr>
                                            <td>✓</td>
                                            <td>Kun 49 kr/mnd</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </Col>
                    </Row>
                </div>
                {/* <button className='btn-between w-100 mb-4'>Velg</button>      */}
            </AccordionHeader>

            <AccordionBody accordionId={position} className='pt-0'>
                <ul className='ps-4'>
                    <li>0 kroner i påslag på strømmen</li>
                    <li>Du får selve strømmen til den samme prisen som den koster på strømbørsen</li>
                    <li>Fastbeløpet har en tolv måneders prisgaranti.</li>
                    <li>Fastbeløp på kun 49 kr/mnd</li>
                    <li>Ingen forskuddsfakturering</li>
                </ul>
                <div className='my-3'>
                    <table>
                        <tr>
                            <td><input type={'checkbox'} id={`check_${vObj.id}`} /></td>
                            <td className='ps-2'>
                                <Label className='normal-label' for={`check_${vObj.id}`}>
                                    <span>Jeg har lest og akseptert viIkår og pris for strømavtalen og jeg er kjent med angreretten min.</span>
                                </Label>
                            </td>
                        </tr>
                    </table>
                
                </div>
                <div className='equipment-item'>
                    <div className='mt-4 mb-3 px-2'>
                        <Button onClick={ isButtonSuccess }
                            data-link="/verticals/moving-company/power/complete-order" 
                            className='btn-between w-100 mt-4' 
                            data-position={position}
                            data-pid={vObj.id}>
                            { isLottie === position ? 
                            <ButtonLottie  button='Bestill' left='80px' /> : 
                            <span id={'btn-text-'+vObj.position}>Bestill</span>
                            }
                        </Button>
                    </div>

                    
                </div>
            </AccordionBody>
        </AccordionItem>
        }
        </>
        }
    );

    return (<>
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Strøm</h1>
                {/* <p className='mb-4'>
                Velg strømleverandør <br/>under
            </p> */}
                <ProgressBar progress="50" classes="my-3"/>
                <h4 className='mb-3'>Steg 2/4</h4>
            </div>
            <hr className='fluid' />
            <div className='container-white pt-4'>
                <div>
                <h3 className='mb-3'>Avtaletype:</h3>
                <Row>
                    <Col xs={5}>
                        <FormGroup switch>
                            <Input type="switch" id='filter-0' checked={selected.has(0)} onClick={() => { handlePowerFilter(0); }} />
                            <Label check className='text-normal' for='filter-0'>App</Label>
                        </FormGroup>
                    </Col>
                    <Col xs={7}>
                        <FormGroup switch>
                            <Input type="switch" id='filter-1' checked={selected.has(1)} onClick={() => { handlePowerFilter(1); }} />
                            <Label check className='text-normal' for='filter-1'>Forskuddsbetaling</Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={5}>
                        <FormGroup switch>
                            <Input type="switch" id='filter-2' checked={selected.has(2)} onClick={() => { handlePowerFilter(2); }} />
                            <Label check className='text-normal' for='filter-2'>Smartlading</Label>
                        </FormGroup>
                    </Col>
                    <Col xs={7}>
                        <FormGroup switch>
                            <Input type="switch" id='filter-3' checked={selected.has(3)} onClick={() => { handlePowerFilter(3); }} />
                            <Label check className='text-normal' for='filter-3'>Fornybar energi</Label>
                        </FormGroup>
                    </Col>
                </Row>
                <a href='#' className='text-underline'onClick={(e) => toggle2(e.target.dataset)}><FontAwesomeIcon icon={faInfoCircle} />  Informasjon om strømavtaler</a>
                </div>
                <h3 className='my-3'>Anbefalte avtaler for deg:</h3>
                <Accordion open={open} toggle={toggle}>
                    {verticalItems}
                </Accordion>
                {/* {powerItems &&
                <>
                <hr className='fluid' />
                <div className='mx-4 my-4'>
                    <button className='w-100 btn-between' onClick={ handleNextStep }>Gå videre</button>
                </div>
                </>
                } */}
            </div>
            <Footer/>

            <Modal isOpen={modalInfo} toggle={toggle2}>
                    <ModalHeader className='text-center d-block p-4 bg-light'>
                        <span>Generelt om strømavtaler</span>
                        <button className='btn-close float-end pt-3' onClick={ toggle2 }></button>
                    </ModalHeader>
                    <ModalBody className='p-4 bg-light'>
                        {powerInfo.map((info, i) => {
                        return <Accordion open={infoOpen} className='mb-2' key={i} toggle={infoToggle}>
                                <AccordionItem key={i} className='vertical-classic p-2'>
                                    <AccordionHeader targetId={i}> <span>{info.title} </span> </AccordionHeader>
                                    <AccordionBody accordionId={i}>
                                    {info.body}
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        })}
                        <center>
                            <button className='btn-between w-190' onClick={ toggle2 }>Lukk</button>
                        </center>
                    </ModalBody>
                </Modal>

        </div>
                </>
    );
}