import { Navigation } from "../../../../components/layout/Navigation";
import { Footer } from "../../../../components/layout/Footer";
import { Button, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Form } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLottie } from "../../../../components/layout/ButtonLottie";
import { useEffect } from "react";
import { VerticalHeader } from "../../../../components/layout/VerticalHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { cleanString, formatPrice } from "../../../../helpers/Common";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import moment from "moment";

const schema = yup.object().shape({
    delivery_date: yup.date('Ugyldig dato').typeError('Ugyldig dato')
    .required('Ugyldig dato')
    .min(new Date(), "Ugyldig dato"),
    pickup_date: yup.date('Ugyldig dato').typeError('Ugyldig dato')
    .required('Ugyldig dato')
    .min(new Date(), "Ugyldig dato"),
});

export const MovingBoxesStep2 = () => {

    const boxesProgress = sessionStorage.getItem('boxes_progress') ? JSON.parse(sessionStorage.getItem('boxes_progress')) : [];
    var   boxesStep1    = sessionStorage.getItem('boxes_step_1')   ? sessionStorage.getItem('boxes_step_1') : '';
    var   boxesStep2    = sessionStorage.getItem('boxes_step_2')   ? JSON.parse(sessionStorage.getItem('boxes_step_2')) : {};
    const [isLottie, setIsLottie]   = useState(null);
    const [totalCustomPrice, setTotalCustomPrice] = useState(boxesStep2.total_custom_price ? boxesStep2.total_custom_price : 0);
    const navigate = useNavigate();
    const [infoModal, setInfoModal] = useState(false);
    const [productSelected, setproductSelected] = useState(boxesStep2.selected_product ? boxesStep2.selected_product : null);

    const { register, handleSubmit, unregister, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues : {
            delivery_date : boxesStep2.delivery_date && boxesStep2.delivery_date,
            pickup_date : boxesStep2.pickup_date && boxesStep2.pickup_date
        }
    });

    const toggleInfoModal  = () => {
        setInfoModal(!infoModal);
    };

    const friendlyBoxFixedPackage = [
        '40 stk flyttesker',
        '4 stk glass inndelere',
        '3 stk tallerkeninndeler',
        '80 etiketter',
        '2 kassetraller',
        'Gratis henting og levering',
    ];

    const regularBoxFixedPackage = [
        '40 stk store flyttesker',
        '20 stk små flytteesker',
        '1 pakke med silkepapir (500stk)',
        '2 garderobe esker',
        'Bobleplast 75cmx150cm',
        '3 teipruller',
        '2 ruller avfallsekker',
        'Gratis levering',
    ]

    const friendlyProductPackage = [
        {title : 'Flytteeskser',
        content : 'Kapasitet: 72 liter. Flyttekassene er generelt større enn lignende pappesker på markedet. De har ingen vektbegrensninger.',
        size : null,
        quantity :10,
        position : 1,
        price : 699,
        price2 : 1199,
        price3 : 1599,},
        {title : 'Glassinnsats', 
        content : 'Våre eksklusive innsatser til glass sørger for en rask, enkel og sikker pakking. Plasser hvert glass i en av de 18 inndelte rommene.',
        size : null,
        quantity :0,
        position : 2,
        price : 29},
        {title : 'Tallerkeninnsats', 
        content : 'Våre eksklusive innsatser til tallerkener sørger for en rask, enkel og sikker pakking. Plasser hver tallerken i en av de 16 inndelte rommene.',
        size : null,
        quantity :0,
        position : 3,
        price : 29},
        {title : 'Etiketter', 
        content : 'Våre eksklusive innsatser til tallerkener sørger for en rask, enkel og sikker pakking. Plasser hver tallerken i en av de 16 inndelte rommene.',
        size : null,
        hide : true, 
        quantity :0,
        position : 4,
        price : 10},
        {title : 'Kassetralle', 
        content : 'Klar Kasse flyttetralle, spesialdesignet for våre kasser. Vi anbefaler minst 1 tralle pr. 20 kasser.',
        size : null,
        quantity :0,
        position : 5,
        price : 89}
    ]

    const regularProductPackage = [
        {title : 'Store Flytteeskser',
        content : 'Våre flytteesker er av markedes beste kvalitet. Flytteesken leveres flatpakket.',
        size : 'Mål: 59x39x39 cm',
        quantity :0,
        position : 1,
        price : 49},
        {title : 'Liten flytteske',
        content : 'Perfekt til flytting av tyngre ting som bøker. Leveres flatpakket',
        size : 'Mål: 39x29x39 cm',
        quantity :0,
        position : 2,
        price : 39},
        {title : 'Silkepapir (500stk)',
        content : 'Velegnet silkepapir for innpakking av skjøre gjenstander som f.eks glass, servise, porselen og pyntegjenstander.',
        size : null,
        quantity :0,
        position : 3,
        price : 139},
        {title : 'Garderobeske',
        content : 'I garderobeeskene kan du henge klærne dine på kleshengere rett i esken.',
        size : null,
        quantity :0,
        position : 4,
        price : 169},
        {title : 'Bobleplast',
        content : '75cm x 150m',
        size : null,
        quantity :0,
        position : 5,
        price : 119},
        {title : 'Teipruller',
        content : 'I garderobeeskene kan du henge klærne dine på kleshengere rett i esken.',
        size : null,
        quantity :0,
        position : 6,
        price : 29},
        {title : 'Avfallsekker',
        content : 'I garderobeeskene kan du henge klærne dine på kleshengere rett i esken.',
        size : null,
        quantity :0,
        position : 7,
        price : 29},
    ]

    const [sizeObjects, setSizeObjects] = useState(sessionStorage.getItem('boxes_step_2_size_objects') ? 
        JSON.parse(sessionStorage.getItem('boxes_step_2_size_objects')) : 
        (boxesStep1 === 'friendly_box' ? friendlyProductPackage : regularProductPackage)); 

    const displayProductPackage = ( productPackage ) => {
        return productPackage.map((product, i) => {
            if(!product.hide){
            return <div className="bg-white p-4 mb-4 br-20" key={i}>
                <Row>
                    <Col xs={3} className="d-flex align-items-center justify-content-center">
                        <img src={`/images/boxes/${cleanString(product.title)}.png`} 
                            alt={product.title}
                            className='img-fluid'
                        />
                    </Col>
                    <Col>
                    <p className="mb-0 text-bold">{product.title}</p>
                    <p className="mb-0">{product.content}</p>
                    </Col>
                </Row>
            </div>
            }
        })
    }

    const handleButtonClick2 = (key, type, count) => e => {
        const val = (key === 0 && boxesStep1 === 'friendly_box') ? 10 : 1;
        const min = (key === 0 && boxesStep1 === 'friendly_box') ? 10 : 0;
        console.log(key, type, count)
        var currentCount = type === '+' ? count + val : count - val;
        if(currentCount >= min && currentCount <= 50){
            let newSizeObjects = [...sizeObjects];
            newSizeObjects[key].quantity = currentCount;
            setSizeObjects(newSizeObjects);
            sessionStorage.setItem('boxes_step_2_size_objects', JSON.stringify(newSizeObjects));
        }

        //change price of the custom product;
        handleCustomPrice();
    }
    

    const handleCustomPrice = () => {
        var total = 0;
        sizeObjects.map((obj, i) => {
            var subTotal = 0;
            if(i === 0 && boxesStep1 === 'friendly_box'){
                subTotal = obj.quantity === 10 ? obj.price : (obj.quantity === 20 ? obj.price2 : obj.price3);
            }else{
                subTotal = obj.quantity * obj.price;
            }
            total += subTotal;
        })

        setTotalCustomPrice(total);

        boxesStep2['total_custom_price'] = total;
        sessionStorage.setItem('boxesStep2', JSON.stringify(boxesStep2));
    }

    const items = sizeObjects.map((obj, key) => 
      <Row className='mb-2 bg-white br-15 py-3 px-2 mx-0' key={key}>
            <Col xs={7}><p className="mb-0 f-14 text-bold">{ obj.title }</p></Col>
            <Col>
                <div className='d-flex w-100 justify-content-end'>
                    <a className='btn-circle-sm' onClick={ handleButtonClick2(key, '-', obj.quantity) }>
                        <FontAwesomeIcon icon={faMinus} />
                    </a> 
                    <span id={obj.type} className='text-center mx-1' 
                        style={{width : '20px'}}>{ obj.quantity }
                    </span>

                    <a className='btn-circle-sm' onClick={ handleButtonClick2(key, '+', obj.quantity) }>
                        <FontAwesomeIcon icon={faPlus} />
                    </a>
                </div>
            </Col>
        </Row>
    );

    const handleSaveStep2 = ( data ) => {
        boxesStep2['pickup_date']       = moment(data.pickup_date).format('YYYY-MM-DD');
        boxesStep2['delivery_date']     = moment(data.delivery_date).format('YYYY-MM-DD');
        boxesStep2['selected_product']  = productSelected;
        boxesStep2['total_price']       = productSelected === 'custom' ? totalCustomPrice : 1899;

        if(boxesStep2){

            setIsLottie(productSelected);
            sessionStorage.setItem('boxes_step_2', JSON.stringify(boxesStep2));

            //add to progress for the progress bar in the homepage
            if(!boxesProgress.includes('step2')){
                boxesProgress.push('step2');
                sessionStorage.setItem('boxes_progress', JSON.stringify(boxesProgress));
            }
            
            // //proceed to step 3;
            setTimeout((function(){
                navigate('/verticals/moving-order/boxes/step-3');
            }), 1000);
        }
    }

    useEffect(() => {
        if(boxesStep2 !== ""){
            setIsLottie(productSelected);
        }

        handleCustomPrice();
        console.log(boxesStep1);
        //if the selected box is regular box, remove pickup date
        if(boxesStep1 === 'regular_box'){
            setValue('pickup_date', new Date());
        }

    }, []);

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Flyttemateriell' progress={2} totalProgress={4} />

                <Form onSubmit={handleSubmit(handleSaveStep2)} id='user-form'>
                    <Row>
                        <Col>
                        <FormGroup className="my-4">
                            <h4 className="mb-1 f-20">1. Når ønsker du at vi leverer? </h4>
                            <input {...register('delivery_date')} type={'date'} className={`input-square form-control ${errors.delivery_date && 'is-invalid'}`} />
                            <p className="form-error mb-0">{errors.delivery_date?.message}</p>
                        </FormGroup>
                        {boxesStep1 === 'friendly_box' &&
                        <FormGroup className="my-4">
                            <h4 className="mb-1 f-20">2. Når ønsker du at vi henter?  </h4>
                            <input {...register('pickup_date')} type={'date'} className={`input-square form-control ${errors.pickup_date && 'is-invalid'}`} />
                            <p className="form-error mb-0">{errors.pickup_date?.message}</p>
                            <p className="f-14">Du kan enkelt utsette leieforholdet senere hvis du trenger mer tid.</p>
                        </FormGroup>
                        }
                        <FormGroup className="my-4">
                            <h4 className="mb-1 f-20">
                                {boxesStep1 === 'friendly_box' ? '3' : '2'}. Bestill antall
                            </h4>
                            <div className="bg-light p-4 br-15">
                                <Row>
                                    <Col xs={10}>
                                        <p className="text-bold mb-2">
                                            Vi anbefaler denne pakken basert på lignende kunder
                                        </p>
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={faInfoCircle} onClick={ toggleInfoModal } className='f-20 cursor-pointer'/>
                                    </Col>
                                </Row>
                                <ul className="ps-3">
                                    {
                                    boxesStep1 === 'friendly_box' ?
                                    friendlyBoxFixedPackage.map((qty, i) => {
                                        return <li key={i}>{ qty }</li>
                                    })
                                    :
                                    regularBoxFixedPackage.map((qty, i) => {
                                        return <li key={i}>{ qty }</li>
                                    })
                                    }
                                </ul>
                                <Row>
                                    <Col>
                                        <Button 
                                            type="submit"
                                            className='btn btn-between w-100' 
                                            data-value='packaged'
                                            disabled={isLottie === 'packaged' && true}
                                            onClick={ (e) => setproductSelected('packaged')} > 
                                                { isLottie === 'packaged' ?  <ButtonLottie button='Bestill' left='80px' /> :  <span>Bestill</span> }
                                        </Button>
                                    </Col>
                                    <Col className="d-flex align-items-center justify-content-center">
                                        <h3 className="mb-0">{ formatPrice(1899) }</h3>
                                    </Col>
                                </Row>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className="bg-light p-4 br-15">
                                <Row>
                                    <Col xs={10}>
                                        <p className="text-bold mb-2">
                                            Tilpass og lag din egen
                                        </p>
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={faInfoCircle} onClick={ toggleInfoModal } className='f-20 cursor-pointer'/>
                                    </Col>
                                </Row>
                                <Row className="my-4">
                                    <Col>
                                    {items}
                                    <p className="mb-0 f-14">Gratis levering og henting.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button 
                                            type="submit"
                                            className='btn btn-between w-100' 
                                            data-value='custom' 
                                            disabled={isLottie === 'custom' && true}
                                            onClick={ (e) => setproductSelected('custom')}>
                                                { isLottie === 'custom' ?  <ButtonLottie button='Bestill' left='80px' /> :  <span>Bestill</span> }
                                        </Button>
                                    </Col>
                                    <Col className="d-flex align-items-center justify-content-center">
                                        <h3 className="mb-0">{totalCustomPrice !== 0 && `${formatPrice(totalCustomPrice)}`}</h3>
                                    </Col>
                                </Row>
                            </div>
                        </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <Row className="text-center my-4 mx-3">
                <Col>
                    <Link to="/verticals/moving-order/boxes/step-1" className="btn-between-white">Tilbake</Link>
                </Col>
                {(boxesProgress.includes('step2') && boxesStep2) &&
                <Col>
                    <Link to="/verticals/moving-order/boxes/step-3" className="btn-between w-100">Neste</Link>
                </Col>
                }
                </Row>
            <Footer/>
            
            {/* INFO BOX MODAL */}
            <Modal isOpen={infoModal} toggle={ toggleInfoModal } centered>
                <ModalHeader toggle={ toggleInfoModal} className='bg-light'>
                <h3 className="mb-0">Produkter pakken innholder:</h3>
                    </ModalHeader>
                <ModalBody className="bg-light p-4">
                    <Row className="mt-4">
                        <Col>
                            { displayProductPackage(boxesStep1 === 'friendly_box' ? friendlyProductPackage : regularProductPackage) }
                            <Row className="mt-4 mx-5 text-center">
                                <Col>
                                    <Button 
                                        className='btn btn-between-white no-black w-100' 
                                        style={{maxWidth : '200px'}}
                                        onClick={ toggleInfoModal }>
                                            Lukk
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    );
}