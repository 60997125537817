import React, { useEffect, useState } from 'react';
import {  Button, Col, Row } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';  
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl, getHeaders, formatPrice, displayAddress } from '../../../../helpers/Common';
import moment from 'moment';

export const MovingOrderEquipmentsCompleteOrder = () => {
    const navigate = useNavigate();
    const moving_order = sessionStorage.getItem('moving_order');
    const [movingMaterialItems, getMovingMaterialItems] = useState(JSON.parse(sessionStorage.getItem('moving_materials')));    

    const handleClickSend = () => {
        axios.post(baseUrl()+'moving_orders/'+moving_order+'/material',
        {units: [{ quantity : 1, offer_id : movingMaterialItems.pid }] }, getHeaders()
        ).then(response => {
            if(response.data.status.code === '200'){
                navigate('/verticals/moving-order/equipments/confirm', { replace: true });
            }else{
                //already exists, back to home
                navigate('/', {replace : true});
            }
            console.log(response);

        } 
            
        ).catch(error =>{
            console.error(error)
            navigate('/', {replace : true});
        }
        );
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Fullfør bestilling</h1>
                    <ProgressBar progress="100" classes="my-3"/>
                    <h4 className='mb-3'>Steg 2/2</h4>
                {/* <p className='mb-4'>For å fullføre bestilling må vi bekrefte noe informasjon og  informasjon om hvordan du ønsker å betale. </p> */}
            </div>
            <hr className='fluid' />
            <div className='container-white pt-3'>
                    <h3>Bestillingsdetaljer</h3>

                <div className='mt-4'>
                    <h3 className='mb-0'>Leveringsadresse:</h3>
                        <p>{ displayAddress(sessionStorage.getItem('from_address')) }</p>
                </div>

                <div className='mt-4'>
                    <h3 className='mb-0'>Leveringsdato:</h3>
                        <p>{ moment(sessionStorage.getItem('moving_date')).format("DD, MM YYYY") }</p>
                </div>
                <div className='mt-4'>
                    <h3 className='mb-0'>Leveringsinformasjon:</h3>
                    <p>Vi har 2-5 dager leveringstid til din adresse. Vi ringer 1 time før leverering, vanligvis mellom 17:00 og 21:00.</p>
                </div>
                <div className='mt-4'>
                    <h3 className='mb-0'>Pris:</h3>
                    <table className='w-100'>
                        <tbody>
                            <tr>
                                <td>{ movingMaterialItems.title }</td>
                                <td align='right' className='text-bold'>Kr. { formatPrice(movingMaterialItems.price) },-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='mt-4'>
                    <h3 className='mb-0'>Betalingsmetode:</h3>
                    <table>
                        <tbody>
                            <tr>
                                {/* <td><FontAwesomeIcon icon={faCircle} style={{color : 'red', width: '10px'}}/></td> */}
                                <td>
                                    Ordren settes på samlefaktura som har forfall 14 dager etter du flytter. Fakturaen finner du på min side.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='my-4'>
                    <Row>
                        <Col>
                            <Link to='/verticals/moving-order/equipments' className='btn-between-white w-100'>Gå tilbake</Link>
                        </Col>
                        <Col>
                            <Button data-link='/verticals/moving-order/equipments/confirm' className='btn-between w-100' onClick={ handleClickSend }>Send bestilling</Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer/>
        </div>
    );
}