import React, { useEffect, useState } from 'react';
import { ListGroupItem, ListGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Navigation } from '../../../components/layout/Navigation';
import { Footer } from '../../../components/layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';
// import { faCircleChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { baseUrl, formatPrice, getHeaders, getMovingOrderId } from '../../../helpers/Common';
import axios from 'axios';
import { ProductImage } from '../../../components/layout/ProductImage';
import moment from 'moment';
import jsPDF from 'jspdf';
import ReactDOMServer from "react-dom/server";
import { OrderInvoice } from '../../../files/OrderInvoice';
 

export const ProfileOrdersAll = () => {
    const [orders, getOrders] = useState({});   
    var total = 0.00;
    var verticalItems = '';

    const fetchOrders = (type) => axios.get(baseUrl()+'moving_orders/'+getMovingOrderId()+'/orders', getHeaders()
    ).then(response => getOrders(response.data.data))
    .catch(error => console.error(error));

    useEffect(() => {
        fetchOrders();
    }, [])

    
    const exportPDF = () => {
        let element = (<OrderInvoice orders={orders}/>);

        const doc = new jsPDF("p", "pt", "letter");
        doc.html(ReactDOMServer.renderToString(element), {
            callback: function (doc) {
            doc.save('order-invoice.pdf');
            }
        });
    }; 

    if(orders){
        verticalItems = Object.keys(orders).map((key, index) => {
            const order   = orders[key];
            var details = [];
            var price   = formatPrice(order.total)+',-';
            var date = moment(order.created_at).format("D.M.YYYY");
            if(key === 'cleaning'){
                details = order.provider;
                date = moment(order.date).format("D.M.YYYY");
            }else if(key === 'garbage'){
                details = order.offer;
                date = moment(order.delivery_date).format("D.M.YYYY");
            }else if(key === 'power'){
                details = order.offer;
                price = formatPrice(order.offer.subscription_price)+',-';
            }else if(key === 'insurance'){
                details = order.provider;
                price = <Link to='/' className='dark-link text-underline'>Se avtale</Link>;
                details.supplier = details.title;
            }else if(key === 'material'){
                details = order.units[0].offer;
            }else if(key === 'postbox'){
                details = order.units[0].offer;
            }
            
            if(key !== 'address_change' && key !== 'insurance'){
            
            total = parseInt(total) + parseInt(order.total);
            
            return <Link to={'/profile/orders/'+key} state={{product : key}} key={index}>
                    <ListGroupItem key={order.position} className={`mx-3 px-0 ${order.position !== "3" && "border-bottom"}`}> 
                        <table className="w-100">
                            <tbody>
                                <tr>
                                    <td width="70px" valign='middle'>
                                        <div className='white-box d-flex align-items-center justify-content-center px-1'>
                                            {details.supplier &&
                                            <ProductImage image={ details.supplier } className='w-95p' /> 
                                            }
                                        </div>
                                    </td>
                                    <td className='ps-2'>
                                        { key !== 'relocation' ? details.title : 'Flyttelass' }
                                        {key !== 'insurance' &&
                                        <p className='text-blur'>{ details.supplier } <br/> Leveringsdato: { date }</p>
                                        }
                                        <p>Pris: { price }</p>
                                    </td>
                                    <td align='right'>
                                        <FontAwesomeIcon icon={ faCircleChevronRight } style={{color : '#727C8E'}} />
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ListGroupItem>
                </Link>
                }
            }
        );

    }
    
    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Alle ordre</h1>
                {/* <p className='mb-4'>Under ser du en oversikter for alle bestilinger du har gjennomført. </p> */}
            </div>
            <hr className='fluid'/>
            <div className='container-white pb-4 pt-2' style={{minHeight : '420px'}}>
                
                { orders ? 
                    <>
                    <ListGroup className='light-box profile-list-group py-2 px-0 mt-3'>
                        {verticalItems}
                        <ListGroupItem>
                            <table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td width={'50%'} className='text-bold'>Sum</td>
                                        <td align='right' className='text-bold'>Kr. { formatPrice(total) },-</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} className='text-italic f-12'>Samlefaktura sendes på flyttedato.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan='2' className='pt-4 text-center'>
                                        <span onClick={ exportPDF } type="primary" className='text-bold mb-0 cursor-pointer'>Se faktura som PDF</span> 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ListGroupItem>
                    </ListGroup>
                    {/*<div className='gray-box my-4 p-4'>
                        <p className='text-bold f-20 text-center'>Foreløpig beregning</p>
                        <p className='text-lightalic px-2'>Samlefaktura sendes på flyttedato</p>

                        <table className='w-100'>
                            <tbody>
                                {Object.keys(orders).map((key, index) => {
                                const order   = orders[key];
                                var details = [];
                                var price   = formatPrice(order.total)+',-';
                                if(key == 'cleaning'){
                                    details = order.provider;
                                }else if(key == 'garbage'){
                                    details = order.offer;
                                }else if(key == 'power'){
                                    details = order.offer;
                                    price = formatPrice(order.offer.subscription_price)+',-';
                                    order.total = order.offer.subscription_price;
                                }else if(key == 'insurance'){
                                    details = order.provider;
                                    price = 'Pri';
                                }else if(key === 'material'){
                                    details = order.units[0].offer;
                                }else if(key === 'relocation'){
                                    order.total = order.total;
                                }

                                if(key !== 'insurance' && key !== 'address_change'){
                                    total = parseInt(total) + parseInt(order.total);
                                
                                    return <tr>
                                    <td>{ key === 'relocation' ? 'Flyttelass' : (key === 'postbox' ? 'Postkasseskilt' : details.title) }</td>
                                    <td align='right'>{ price }</td>
                                    </tr>
                                }
                            
                            }
                        )}
                            </tbody>
                        </table>
                         <hr />
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td width={'50%'}>Foreløpig sum</td>
                                    <td align='right' className='text-bold'>Kr. { formatPrice(total) },-</td>
                                </tr>
                                <tr>
                                    <td colSpan='2' className='pt-4'>
                                    <span onClick={ exportPDF } type="primary" className='text-bold mb-0 cursor-pointer'>Se faktura som PDF</span> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div> */}
                    </>
                : 
                <div className='d-flex align-items-center h-100' style={{height: '200px'}}>
                    <p className='f-20 text-center py-4'>Du har ikke gjennomført noen bestillinger enda</p>
                </div>
                }
            </div>
            <Footer isFixed={true}/>
        </div>
    );
}