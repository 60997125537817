import { Navigate, Outlet } from "react-router-dom";
import { getToken } from '../../helpers/Common';

export const ProtectedRoute = () => {
  const token = getToken();

  if (!token) {
    // user is nost asuthenticated
    return <Navigate to="/" replace />;
  }

  return (
    <div>
        <Outlet/>
    </div>
  ) 
};