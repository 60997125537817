import React, {useState, useEffect} from 'react';
import {  Progress, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import { ButtonLottie } from '../../../../components/layout/ButtonLottie';
import axios from 'axios';
import { baseUrl, getHeaders, upFirst } from '../../../../helpers/Common';
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import e from 'cors';

export const AddressAndPostMailbox = () => {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [memberItems, setMemberItems] = useState([]);
    const movingOrderId = sessionStorage.getItem('moving_order');
    const [isPbnames, setIsPbNames] = useState(false);
    const [isNoAds, setIsNoAds] = useState(false);    
    const [isDoorSign, setIsDoorSign] = useState(false);    
    const [products, setProducts] = useState([[], [], []]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [names, setNames] = useState(memberItems);
    const [postboxItems, setPostboxItems] = useState(JSON.parse(sessionStorage.getItem('postbox_selected_products')));    
    const [postboxNames, setPostboxNames] = useState(JSON.parse(sessionStorage.getItem('postbox_names')));    

    const toggle = () => setModal(!modal);

    //get users of moving order
    const fetchUsers = () => {
        axios.get(baseUrl()+'moving_orders/'+movingOrderId+'/users', getHeaders()
        ).then(response => setMemberItems(response.data.data))
        .catch(error => console.error(error))
    }

    //handle the input box on change
    function handleInputChange() {
        var allNames = [];
        for (let index = 0; index < 5; index++) {
            var pbInput = document.getElementById("pb-input-"+index).value;
            
            if(pbInput !== ""){
                allNames.push(pbInput);
            }
            
        }

        handleNameBox(allNames);
        setNames(allNames);
    }

    //populate the name box
    function handleNameBox(names){
        names = names.toString();
        var  finalText = names.replace(/,/g, '<br>');
        document.getElementById("names-box").innerHTML = finalText;
    }

    useEffect(() => {
        if(!postboxNames){
            fetchUsers();
        }else{
            var arr = [];
            postboxNames.map((name) => {
                arr.push({name : name});
            })
            setMemberItems(arr);
        }

    
    }, []);
    

    //name inputs array
    const nameInputs = ["Linje 1", "Linje 2", "Linje 3",  "Linje 4", "Linje 5", ];
    const nameInputsitems = nameInputs.map((nameInputsObj, key) =>
        <div className='mb-2' key={key}>
            <p className='mb-0'>{ nameInputsObj }</p>
            <input type="text" onChange={ handleInputChange } className="form-control input-square ps-2" id={'pb-input-'+key} defaultValue={memberItems[key] && memberItems[key].name} /> 
        </div> 
    );


    //get postbox products
    const fetchProducts = () => { 
        axios.get(baseUrl()+'postbox_product_offers', getHeaders()
        ).then(response => setProducts(response.data.data))
        .catch(error => console.error(error))
    }

    useEffect(() => {
            fetchProducts();
    }, []);

    function handleProductClick(e){
        var data = e.target.dataset;
        var details = []
        if(data.type === 'pbNames'){ 
            if(names.length !== 0){
                details = names;
            }else{
                memberItems.map((member) => details.push(member.name));
            }
            setIsPbNames(true);
        }else if(data.type === 'noAds'){
            setIsNoAds(true);

        }else if(data.type === 'doorSign'){
            setIsDoorSign(true);
        }

        //push products to the state for the confirmation page
        if(data.id){
            console.log(data.id === '1');
            setSelectedProducts([...selectedProducts, {offer_id : data.id, quantity : 1, details : details, price : data.price, title : data.title}]);
        
            if(data.id === '1'){
                setPostboxNames(details);
                sessionStorage.setItem('postbox_names', JSON.stringify(details));
            }
        }
    }



    const handleNextStepButton = () => { 
        //update the names
        var allNames = [];
        for (let index = 0; index < 5; index++) {
            var pbInput = document.getElementById("pb-input-"+index).value;
            if(pbInput !== ""){
                allNames.push(pbInput);
            }
        }

        //update the names in storage
        sessionStorage.setItem('postbox_names', JSON.stringify(allNames));

        if(selectedProducts.length > 0){
            sessionStorage.setItem('postbox_selected_products', JSON.stringify(selectedProducts));
            navigate('/verticals/address-and-post/mailbox/complete-order');
        }
    }


    useEffect(() => {
        setSelectedProducts([]);
        var p = []

        if(postboxItems){
            postboxItems.map((item) => {
                if(item.offer_id === '1'){
                    item.details.map((name, i) => {
                        // document.getElementById('pb-input-'+i).value = name;
                    })
                    setIsPbNames(true);
                }else if(item.offer_id === '2'){
                    setIsNoAds(true);
                }else if(item.offer_id === '3'){
                    setIsDoorSign(true);
                }
                
                p.push(item);

            });
            setSelectedProducts(p);
        }
    }, []);
    
    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Søk etter selskaper</h1>
                <ProgressBar progress="50" classes="my-3"/>
                <h4 className='mb-3'>Steg 1/2</h4>
                {/* <p className='mb-4'>Sørg for at posten kommer frem med et tydelig postkasseskilt.</p> */}
            </div>
            <hr className='fluid' />
            <div className='container-white py-4'>
                
                {/* 1ST PRODUCT */}
                <div className='light-box px-3'>
                    <h3 className='mb-0'>{ products[0].title }</h3>
                    <p>{ products[0].short_description}</p>
                    <p className='f-14 mt-2'>Størrelse: 32x78mm (Postens standardformat)</p>

                    <div className='mt-4'>
                        { nameInputsitems } 
                    </div>

                    <div className='mt-4'>
                        <p className='mb-0'>Ditt skilt</p>
                        <div className='d-flex align-items-center justify-content-center text-center names-box' id='names-box'>
                            {memberItems.map((obj) => {
                                return( 
                                <>{ obj.name }<br /></>
                                )
                            })}
                        </div>

                        <div className='mt-4'>
                            <h3 className='mb-0'>{ Math.round(products[0].price)} kr</h3>
                            <p>Prisen inkluderer frakt og mva.</p>
                        </div>

                        <div className='mt-4 px-2'>
                            <Button onClick={ (e) => { handleProductClick(e); } } 
                                data-id={products[0].id}
                                data-price={products[0].price}
                                data-title={products[0].title}
                                className='btn-between w-100' 
                                data-type="pbNames">
                                { isPbnames ? 
                                <ButtonLottie/> : 
                                'Legg til'
                                }
                            </Button>
                        </div>
                    </div>
                </div>
                
                {/* 2ND PRODUCT */}
                <div className='light-box mt-4 pb-4'>
                    <h3 className='mb-0'>{ products[1].title }</h3>
                    <p>{ products[1].short_description }</p>

                    <div className='my-3'>
                        <div className='p-3 red-box'>
                        NEI TAKK TIL REKLAME
                        </div>
                    </div>
                    <div>
                        <h3 className='mb-0'>{ Math.round(products[1].price)} kr</h3>
                        <p>Prisen inkluderer frakt og mva.</p>
                    </div>

                    <div className='mt-4 px-2'>
                        <Button onClick={ (e) => { handleProductClick(e); } } 
                            data-id={products[1].id}
                            data-price={products[1].price}
                            data-title={products[1].title}
                            className='btn-between w-100' 
                            data-type="noAds">
                            { isNoAds ? 
                            <ButtonLottie/> : 
                                'Legg til'
                            }
                        </Button>
                    </div>
                </div>
                
                {/* 3RD PRODUCT */}
                <div className='light-box my-4 pb-4'>
                    <h3 className='mb-0'>{ products[2].title } <a  onClick={toggle} className='cursor-pointer'><FontAwesomeIcon icon={faQuestionCircle}/></a></h3>
                    <p>Kjøp oblat og reserver deg mot uønsket besøk</p>

                    <div className='my-3 px-4'>
                        <img src='/images/svg/door-sign.jpg' className='img-fluid'/>
                    </div>

                    <div>
                        <h3 className='mb-0 text-bold f-20'>{ Math.round(products[2].price)} kr <a className='f-14 float-end me-4 pt-2 cursor-pointer'  onClick={toggle}>Les mer</a></h3>
                        <p>Prisen inkluderer frakt og mva.</p>
                    </div>

                    <div className='mt-4 px-2'>
                        <Button onClick={ (e) => { handleProductClick(e); } } 
                                data-id={products[2].id}
                                data-price={products[2].price}
                                data-title={products[2].title}
                                className='btn-between w-100' 
                                data-type="doorSign">
                                { isDoorSign ? 
                                <ButtonLottie/> : 
                                'Legg til'
                                }
                        </Button>
                    </div>
                </div>
                <hr className='fluid'/>
                <div className='px-4 my-4'>
                    <button onClick={ handleNextStepButton }  className='btn btn-between w-100'>Gå videre</button>
                </div>
            </div>
            <Footer/>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className='d-block'>
                    <span>Reserver deg i dørsalgsregisteret og stopp dørsalg for godt.</span>
                    <button className='btn-close float-end pt-3' onClick={ toggle }></button>
                </ModalHeader>
                <ModalBody>
                    <p>2,2 millioner nordmenn har reservert seg mot telefonsalg og 1,3 millioner nordmenn har reservert seg mot adressert reklame. Nå kan du også reservere din adresse mot dørsalg. Er du reservert og har oblat så unngår du uønsket besøk av en selger.</p>
                    <p>Det er mange grunner til å reservere seg mot dørsalg. Bor du i et område som har mye aktivitet for dørsalg og opplever at selgere kommer til ubeleilige tider? Kanskje du ønsker å reservere de eldre i familien din for å unngå at de blir lurt av en uærlig selger? Eller kanskje du rett og slett bare ikke ønsker å ha besøk av selgere?</p>
                    <p>Uansett årsak, vårt oblat stopper uønsket besøk umiddelbart.</p>
                    <center className='mt-4'>
                        <button className='btn-between w-190' onClick={ toggle }>Lukk</button>
                    </center>
                </ModalBody>
           </Modal>

        </div>
    );
}