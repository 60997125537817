import React from 'react';
import { Navigation } from '../../components/layout/Navigation';
import { RegistrationHeader } from '../../components/layout/RegistrationHeader';
import PeopleList from '../registration/PeopleList';
import { UserAddMinorForm } from '../../components/layout/users/UserAddMinorForm';

export const SettingsUserAddMinor = () => {


    return (
        <div id='CreateKidUserContainer'>
            <Navigation />
            <div className='py-4'>
                <p className='f-30 mb-0 text-bold'>Personer i hustanden</p>
            </div>
            <div className='container-white pb-4'>
                <PeopleList type='settings' />
                <UserAddMinorForm type='settings' />
            </div>
        </div>
    )
}