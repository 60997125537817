import React, { useEffect, useState } from 'react';
import { Navigation } from '../../../components/layout/Navigation';
import { Footer } from '../../../components/layout/Footer';
import axios from 'axios';
import { baseUrl, getHeaders, getMovingOrderId, getDisabledTasks } from '../../../helpers/Common';
import { VerticalItem } from '../../../components/layout/VerticalItem';
import { useNavigate } from 'react-router-dom';
import e from 'cors';

export const MovingOrderHome = () => {
    const [orders, getOrders] = useState({});  
    const [open, setOpen] = useState([]);
    const [disabledTasks, setDisabledTasks] = useState(getDisabledTasks());
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(true);

    const toggle = (id) => {
        console.log(id);
        open === id ? setOpen() : setOpen(id);
    };

    const untoggle = () => {

    }

    const verticalObjects = [{
            position : '1',
            title : 'Bestill flyttemateriell',
            description: 'Trenger du kanskje pappesker, teip og bobleplast?',
            image: '../../images/purple/svg/moving-material.svg',
            progress: 0,
            button: 'Sjekk utvalget',
            path : '/verticals/moving-order/equipments',
            type : 'material',
            enabled : true
        }, {
            position: '2',
            title: 'Bestill utvask',
            description: 'Trenger du kanskje pappesker, teip og bobleplast?', 
            image: '../../images/svg/Between_cleaning.svg',
            progress: 0,
            button: 'Finn vaskebyrå',
            path : '/verticals/moving-order/cleaning-materials',
            type : 'cleaning',
            enabled : true
        }, {
            position: '3',
            title: 'Bestill søppeltjenester',
            description: 'Har du søppel som må kastes? Her får du hjelp til å bli kvitt den.',
            image: '../../images/purple/svg/garbage.svg',
            progress: 0,
            button: 'Finn leverandør',
            path : '/verticals/moving-order/garbage-services',
            type : 'garbage',
            enabled : true
        }
    ];

    const fetchOrders = (type) => axios.get(baseUrl()+'moving_orders/'+getMovingOrderId()+'/orders', getHeaders()
    ).then(response => {
        if(response.data.data){
            
            setOpen([]);
            getOrders(response.data.data);

            verticalObjects.map((obj) => {
                if((!response.data.data[obj.type] && !open.includes(obj.position)) && !disabledTasks.includes(obj.type)){
                    setOpen(open => [...open, obj.position]);  
                }
            })
        }else{
            verticalObjects.map((obj) => {
                if(!disabledTasks.includes(obj.type)){
                    setOpen(open => [...open, obj.position]);  
                }
            })
        }
    })
    .catch(error => console.error(error));


    useEffect(() => {
        fetchOrders();

    }, [])

    var vo = verticalObjects;
    if(refresh){
        vo = verticalObjects.reverse().sort((a, b) => !disabledTasks.includes(a.type) ? -1 : !disabledTasks.includes(b.type) ? 1 : 1);
     }

    const verticalItems = vo.map((obj) => 
    // const verticalItems = verticalObjects.reverse().sort((a, b) => !disabledTasks.includes(a.type) ? -1 : !disabledTasks.includes(b.type) ? 1 : 1).map((obj) => 
    <>
        <VerticalItem setRefresh={setRefresh} orders={orders} obj={obj} open={open} setOpen={setOpen} disabledTasks={disabledTasks} setDisabledTasks={setDisabledTasks} />
    </>
    );

    

    return (
        <div className='AppContainer bg-between'>
            <Navigation />
            <div className='pt-4'>
                <p className='p-header'>Gjør boligen <br/>flytteklar</p>
                <p className='mb-4 p-details'> Her kan du bestille <br/> pappesker, container, utvask, <br/> Fretexpose og alt annet som <br/> hører med når du skal gjøre <br/> boligen din flytteklar. </p>
                <img src='/images/Between_moving_boxes.png' alt='between icon hello'
                    style={{position : "absolute",
                            width : "190px", 
                            top : '100px',
                            right : -55}} />
            </div>
            <div className='container-white pt-4'>
                {verticalItems}
            </div>
            <Footer/>
        </div>
    );
}