import React, {useEffect, useState} from 'react';
import { Button, Col, Modal, ModalHeader, ModalBody, Row, FormGroup, Input, Label } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';  
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import { displayAddress, baseUrl, getHeaders } from '../../../../helpers/Common';
import axios from 'axios';

export const AddressAndPostMailboxCompleteOrder = () => {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const location = useLocation();
    const toggle = () => setModal(!modal);
    var totalPrice = 0;
    const moving_order = sessionStorage.getItem('moving_order');
    const [postboxItems, setPostboxItems] = useState(JSON.parse(sessionStorage.getItem('postbox_selected_products')));    
    const [postboxSelectedAddress, setPostboxSelectedAddress] = useState(sessionStorage.getItem('postbox_selected_address') ? sessionStorage.getItem('postbox_selected_address') : 'to_address');    


    console.log(sessionStorage.getItem('postbox_selected_address'));

    const handleClickSend = () => {
        axios.post(baseUrl()+'moving_orders/'+moving_order+'/postbox',
        {units: postboxItems }, getHeaders()
        ).then(response => {
            if(response.data.status.code === '200'){
                navigate('/verticals/address-and-post/mailbox/confirm', { replace: true });
            }else{
                //already exists, back to home
                // navigate('/', {replace : true});
            }
            console.log(response);
        } 
            
        ).catch(error =>{
            console.error(error)
            // navigate('/', {replace : true});
        }
        );
    }

    const handleAddressChange = () => {
        var radios = document.getElementsByName('endreRadio');
        for( var i = 0; i < radios.length; i++ ) {
            if( radios[i].checked ) {
                setPostboxSelectedAddress(radios[i].value);
                
                //save the address to the session
                sessionStorage.setItem('postbox_selected_address', radios[i].value);
            }
        }

        toggle();
    }

    useEffect(() => {
        var radios = document.getElementsByName('endreRadio');
        for( var i = 0; i < radios.length; i++ ) {
            // if(radios[i].value === sessionStorage.getItem(postboxSelectedAddress)){
            //     radios[i].checked = true;

            //     console.log('hey', radios[i].id)
            // }

            console.log(radios[i].value, sessionStorage.getItem(postboxSelectedAddress))
        }

        console.log(radios);

    }, [])

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Fullfør bestilling</h1>
                <ProgressBar progress="100" classes="my-3"/>
                <h4 className='mb-3'>Steg 2/2</h4>
            </div>
            <hr className='fluid' />
            <div className='container-white pt-4'>
                <h3 className='mb-0'>Bestillingsdetaljer</h3>
                <div className='mt-4'>
                    <h3 className='mb-0'>Leveringsadresse skilt:</h3>
                    <Row>
                        <Col>
                            { displayAddress(sessionStorage.getItem(postboxSelectedAddress)) }
                        </Col>
                        <Col className='text-right'>
                            <a className='text-bold text-decoration-none cursor-pointer' onClick={ toggle }>Endre</a>
                        </Col>
                    </Row>
                </div>
                <div className='mt-4'>
                    <h3 className='mb-0'>Pris:</h3>
                    <table className='w-100'>
                        <tbody>
                            {postboxItems.map((product) => {
                                totalPrice = totalPrice + Math.round(product.price);
                            return <tr>
                                <td>{product.title}</td>
                                <td align='right' className='text-bold'>Kr. { Math.round(product.price)},-</td>
                            </tr>
                            })}
                            <tr>
                                <td colSpan={2}><hr className='mt-2'/></td>
                            </tr>
                            <tr>
                                <td className='text-bold'>Totalt</td>
                                <td align='right' className='text-bold'>Kr. {Math.round(totalPrice)} ,-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='mt-4'>
                    <h3 className='mb-0'>Betalingsmetode:</h3>
                    <table>
                        <tr>
                            {/* <td><FontAwesomeIcon icon={faCircle} style={{color : 'red', width: '10px'}}/></td> */}
                            <td>
                                Ordren settes på samlefaktura som har forfall 14 dager etter du flytter. Fakturaen finner du på min side.
                            </td>
                        </tr>
                    </table>
                </div>
                <div className='my-4'>
                    <Row>
                        <Col>
                            <Link to='/verticals/address-and-post/mailbox' className='btn-between-white w-100'>Gå tilbake</Link>
                        </Col>
                        <Col>
                            <Button data-link='/verticals/moving-order/equipments/confirm' className='btn-between w-100' onClick={ handleClickSend }>Send bestilling</Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer/>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className='d-block'>
                    <span className='upfirst'>Hvilken adresse ønsker du at vi sender postkasseskiltet til?</span>
                    <button className='btn-close float-end pt-3' onClick={ toggle }></button>
                </ModalHeader>
                <ModalBody>
                <table className='table'>
                    <tbody>
                        <tr>
                            <td>
                                <FormGroup>
                                    <Input type='radio' id='fromRadio' name='endreRadio' defaultChecked={postboxSelectedAddress === 'from_address' ? true : false} value='from_address'/> 
                                    <Label for='fromRadio' className='ms-4'>{displayAddress(sessionStorage.getItem('from_address'), true )}</Label>
                                </FormGroup>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FormGroup>
                                    <Input type='radio' id='toRadio' name='endreRadio' defaultChecked={postboxSelectedAddress === 'to_address' ? true : false} value='to_address'/> 
                                    <Label for='toRadio' className='ms-4'>{displayAddress(sessionStorage.getItem('to_address'), true)}</Label>
                                </FormGroup>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* <button className=' mt-4 btn-between w-100 br-min' >Lagre</button> */}
                <button className=' mt-4 btn-between w-100 br-min' onClick={ handleAddressChange } >Lagre</button>
                </ModalBody>
            </Modal>
        </div>
    );
}