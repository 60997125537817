import React, {  useState, useEffect } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';  
import axios from 'axios';
import moment from 'moment';
import { baseUrl, getHeaders, getMovingOrderId, formatDate, displayAddress, formatPrice } from '../../../../helpers/Common';
import { ProgressBar } from '../../../../components/layout/ProgressBar';

export const MovingOrderGarbageServicesCompleteOrder = () => {
    const navigate = useNavigate();
    const moving_order = getMovingOrderId();
    const [garbageServicesItems, getGarbageServicesItems] = useState(JSON.parse(sessionStorage.getItem('garbage_services'))); 
    const deliveryDate = moment(garbageServicesItems.pickupDate).add(parseInt(garbageServicesItems.number_of_days, 'days'));

    console.log(garbageServicesItems);

    const handleClickSend = () => {
        axios.post(baseUrl()+'moving_orders/'+moving_order+'/garbage',
        { offer_id : garbageServicesItems.pid, 
            pickup_date : moment(garbageServicesItems.pickup_date).format('YYYY-MM-DD'), 
            // pickup_date : parseInt(garbageServicesItems.number_of_days !== 0) ? moment(garbageServicesItems.pickup_date).format('YYYY-MM-DD') : null, 
            delivery_date : moment(deliveryDate).format('YYYY-MM-DD'), 
            delivery_time : garbageServicesItems.delivery_time } , getHeaders()
        ).then(response => {
            if(response.data.status.code === '200'){
                navigate('/verticals/moving-order/garbage-services/confirm', { replace: true });
            }else{
                //already exists, back to home
                // navigate('/', {replace : true});
            }
            console.log(response);
        } 
            
        ).catch(error =>{ 
            console.error(error)
            // navigate('/', {replace : true});
        }
        );
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Fullfør bestilling</h1>
                    <ProgressBar progress="100" classes="my-3"/>
                    <h4 className='mb-3'>Steg 2/2</h4>
                {/* <p className='mb-4'>For å fullføre bestilling må vi bekrefte noe informasjon og  informasjon om hvordan du ønsker å betale. </p> */}
            </div>
            <hr className='fluid' />
            <div className='container-white pt-3'>
                    <h3>Bestillingsdetaljer</h3>

                <div>
                    <p className='f-20 text-bold mb-0'>Adresse:</p>
                    <p>{ displayAddress(sessionStorage.getItem('from_address'), true) }</p>
                    <p className='mb-0'></p>
                    <p></p>
                </div>
                <div className='mt-4'>
                    <h3 className='mb-0'>Dato:</h3>
                    <table className='w-100'>
                        <tbody>
                            <tr>
                                <td>Levering: { moment(deliveryDate).format('DD.MM.YYYY') }, Mellom {garbageServicesItems.delivery_time}</td>
                            </tr>
                            <tr>
                                <td>Henting: {moment(garbageServicesItems.pickup_date).format('DD.MM.YYYY')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='mt-4'>
                    <p className='f-20 text-bold mb-0'>Leveringsinformasjon:</p>
                    <p>Vi vil kontakte deg senest 4 dager før selve flyttedagen og bekrefte noen siste detaljer.</p>
                    <p>Har du noen spørsmål i mellomtiden kan du ta kontakt med våre flyttekonsulenter.</p>
                </div>
                <div className='mt-4'>
                    <h3 className='mb-0'>Pris:</h3>
                    <table className='w-100'>
                        <tbody>
                            <tr>
                                <td>{ garbageServicesItems.title }</td>
                                <td align='right' className='text-bold'>Kr { formatPrice(garbageServicesItems.price) },-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <p className='f-20 text-bold mb-0 mt-4'>Betalingsmetode:</p>
                    <table>
                        <tbody>
                            <tr>
                                {/* <td><FontAwesomeIcon icon={faCircle} style={{color : 'red', width: '10px'}}/></td> */}
                                <td>
                                    Ordren settes på samlefaktura som har forfall 14 dager etter du flytter. Fakturaen finner du på min side.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='my-4'>
                    <Row>
                        <Col>
                            <Link to='/verticals/moving-order/garbage-services' className='btn-between-white w-100'>Gå tilbake</Link>
                        </Col>
                        <Col>
                            <Button className='btn-between w-100' onClick={ handleClickSend }>Send bestilling</Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer/>
        </div>
    );
}