import React, { useEffect, useState } from 'react';
import {  Button, Col, Row } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';  
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl, getHeaders, getMovingOrderId, formatDate, displayAddress, formatPrice } from '../../../../helpers/Common';
import moment from 'moment';

export const MovingOrderCleaningMaterialsCompleteOrder = () => {
    const navigate = useNavigate();
    const [pickupDate, setPickupDate] = useState( 0 );
    const [deliveryTime, setDeliveryTime] = useState( null );
    const moving_order = getMovingOrderId();
    const [cleaningServicesItems, getMovingMaterialItems] = useState(JSON.parse(sessionStorage.getItem('cleaning_services')));    
    console.log(cleaningServicesItems);

    const handleClickSend = () => {
        axios.post(baseUrl()+'moving_orders/'+moving_order+'/cleaning',
        { provider_id : cleaningServicesItems.pid, date : moment(cleaningServicesItems.pickup_date).format('YYYY-MM-DD'), delivery_time : cleaningServicesItems.deliveryTime } , getHeaders()
        ).then(response => {
            if(response.data.status.code === '200'){
                navigate('/verticals/moving-order/cleaning-materials/confirm', { replace: true });
            }else{
                //already exists, back to home
                // navigate('/', {replace : true});
            }
            console.log(response);
        } 
            
        ).catch(error =>{
            console.error(error)
            // navigate('/', {replace : true});
        }
        );
    }

        return (
            <div className='AppContainer bg-white'>
                <Navigation />
                <div className='pt-4'>
                    <p className='p-header position-relative'>Fullfør bestilling</p>
                        <ProgressBar progress="100" classes="my-3"/>
                        <h4 className='mb-3'>Steg 2/2</h4>
                    {/* <p className='mb-4'>For å fullføre bestilling må vi bekrefte noe informasjon og  informasjon om hvordan du ønsker å betale. </p> */}
                </div>
                <hr className='fluid' />
                <div className='container-white pt-3'>
                    <h3>Bestillingsdetaljer</h3>
                    <div className='mt-4'>
                        <h3 className='mb-0'>Leveringsadresse:</h3>
                    <p>{ displayAddress(sessionStorage.getItem('from_address')) }</p>
                    </div>

                    <div className='mt-4'>
                        <h3 className='mb-0'>Dato:</h3>
                    <p>Henting: { moment(cleaningServicesItems.pickup_date).format("DD, MM YYYY") }, Mellom {cleaningServicesItems.delivery_time}</p>
                    </div>

                    <div className='mt-4'>
                        <p className='f-20 text-bold mb-0'>Leveringsinformasjon:</p>
                        <p>Vaskebyrået vil kontakte deg senest 4 dager før selve flyttedagen og bekrefte noen siste detaljer.</p>
                        <p>Har du noen spørsmål i mellomtiden kan du ta kontakt med våre flyttekonsulenter.</p>
                    </div>
                    <div className='mt-4'>
                        <h3 className='mb-0'>Pris:</h3>
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td>{ cleaningServicesItems.title }</td>
                                    <td align='right' className='text-bold'>Kr { formatPrice(cleaningServicesItems.price) },-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-4'>
                        <h3 className='mb-0'>Betalingsmetode:</h3>
                        <table>
                            <tr>
                                {/* <td><FontAwesomeIcon icon={faCircle} style={{color : 'red', width: '10px'}}/></td> */}
                                <td>
                                    Ordren settes på samlefaktura som har forfall 14 dager etter du flytter. Fakturaen finner du på min side.
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='my-4'>
                        <Row>
                            <Col>
                                <Link to='/verticals/moving-order/cleaning-materials' className='btn-between-white w-100'>Gå tilbake</Link>
                            </Col>
                            <Col>
                                <Button className='btn-between w-100' onClick={ handleClickSend }>Send bestilling</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Footer/>
            </div>
        );
}