import React, {useState, useEffect} from 'react';
import { Button, Col, Accordion, Row } from 'reactstrap';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';
import { ButtonLottie } from '../../../../components/layout/ButtonLottie';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl,  getHeaders, formatPrice } from '../../../../helpers/Common';
import { ParseString } from '../../../../components/elements/ParseString';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export const MovingOrderEquipments = () => {
    const navigate = useNavigate();
    const [providers, setProviders] = useState([]);
    const [isLottie, setIsLottie] = useState(false);
    const [movingMaterialItems, getMovingMaterialItems] = useState(JSON.parse(sessionStorage.getItem('moving_materials')));    
    const [open, setOpen] = useState('pos-0');

    const toggle = (id) => {
        console.log(id);
        open === id ? setOpen() : setOpen(id);
    };
    const isButtonSuccess = (e) => {
        const link = e.currentTarget.dataset.link;
        const position = e.currentTarget.dataset.position;
        const pid   = e.currentTarget.dataset.pid;
        const price   = e.currentTarget.dataset.price;
        const title   = e.currentTarget.dataset.title;

        setIsLottie(position);

        //add to session storage for future use
        sessionStorage.setItem('moving_materials', JSON.stringify({pid : pid, price : price, title, position : position}));
        setTimeout(function(){
            navigate(link);
        }, 1200)
    }

    const fetchProducts = () => {
        axios.get(baseUrl()+'moving_material_offers/', getHeaders()
        ).then(response => setProviders(response.data.data))
        .catch(error => console.error(error))
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    //users came from confirmation page
    useEffect(() => {
        console.log(movingMaterialItems);
        if(movingMaterialItems){
            setOpen(movingMaterialItems.position);
            setIsLottie(movingMaterialItems.position);
        }
    }, [])

    const handleNextStep = (e) => {
        navigate('/verticals/moving-order/equipments/complete-order');
    }


    const [sizeObjects, setSizeObjects] = useState([{
        position : '1',
        title : 'små esker',
        count:  0,
    },{
        position : '2',
        title : 'medium esker',
        count: 0,
    },{
        position : '3',
        title : 'store esker',
        count: 0,
    },{
        position : '4',
        title : 'ruller med bobleplast',
        count:  0,
    },
]); 
    //handle the + / -  button, then update the size objects
    const handleButtonClick2 = (key, type, count) => e => {
        let newSizeObjects = [...sizeObjects];
        newSizeObjects[key].count = type === '+' ? count + 1 : (count > 1 ? count - 1 : 0);
        setSizeObjects(newSizeObjects);
    }
    const items = sizeObjects.map((obj, key) => 
        <Row className='mb-1' key={key}>
            <Col xs={7}>
            <span id={obj.type} className='text-center receiver-counter'>{ obj.count }</span> { obj.title}</Col>
            <Col className='text-right'>
                <div className='d-flex w-100 justify-content-end'>
                    <button className='btn-circle-sm' onClick={ handleButtonClick2(key, '-', obj.count) }>
                        <FontAwesomeIcon icon={faMinus} />
                    </button> 
                    <button className='btn-circle-sm ms-1' onClick={ handleButtonClick2(key, '+', obj.count) }>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
            </Col>
        </Row>
    );

    const verticalItems = providers.map((obj, i) => {
        const id = 'pos-'+i;
        return  <>
        <div className={`bg-gray p-4 br-20 ${i === 0 ? 'mt-3' : 'mt-4'}`} key={i}>
            <h4 className='mb-0'>{obj.title}
                <FontAwesomeIcon icon={faChevronRight} className='float-end' />
            </h4>
                {i !== 5 ?
            <Row className='mt-2'>
                <Col xs={7}>
                    <ParseString string={obj.description} />
                </Col>
                <Col className='ps-0 d-flex align-items-center text-bold f-20 justify-content-end position-relative'>
                    <h3>
                        Kr. { formatPrice(obj.price) },-
                    </h3>
                </Col>
            </Row>
                    :
            <Row className='mt-2'>
                <Col>{items}</Col>
            </Row>
                    } 
                    
            <div className='mt-4 mb-2'>
                <Button onClick={ isButtonSuccess } 
                    data-link="/verticals/moving-order/equipments/complete-order"
                    className='btn-between w-100'
                    data-position={id}
                    data-price={obj.price}
                    data-title={obj.title}
                    data-pid={ obj.id }>
                    { isLottie === id ? 
                    <ButtonLottie button='Pakke valgt' left='130px'/> : 
                    <span id={'btn-text-'+id}>Velg</span>
                    }
                </Button>
            </div>
        </div>
    {/* <AccordionItem className='products-vertical mb-4 equipment-vertical' key={i}>
        <AccordionHeader targetId={ id }> <span>{obj.title}</span> </AccordionHeader>
            <AccordionBody accordionId={ id }>
                <div className='equipment-item'>
                    <table className="w-100">
                        <tbody> 
                    {i == 0 && 
                        <tr>
                            <td className='text-italic pb-2'>
                                <i>{displayAddress(sessionStorage.getItem('from_address'), true)}</i><br/>
                                <i>150 kvm enebolig</i><br/>
                                <i>4 soverom</i>
                            </td>
                        </tr>
                    }
                    <tr> 
                        <td>
                            {i !== 5 ?
                            <ParseString string={obj.description} />
                            :
                            <>
                                {items}
                            </>
                            } 
                        </td> 
                    </tr>
                        <tr>
                            <td align='right' className='f-20 text-bold pt-4'></td>
                        </tr>
                        </tbody> 
                    </table>
                    <div className='mt-4 mb-2'>
                        <Button onClick={ isButtonSuccess } 
                            data-link="/verticals/moving-order/equipments/complete-order"
                            className='btn-between w-100'
                            data-position={id}
                            data-price={obj.price}
                            data-title={obj.title}
                            data-pid={ obj.id }>
                            { isLottie === id ? 
                            <ButtonLottie button='Pakke valgt' left='130px'/> : 
                            <span id={'btn-text-'+id}>Velg</span>
                            }
                        </Button>
                    </div>
                </div>
        </AccordionBody>
    </AccordionItem>  */}


</>   
    })

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div>
                {/* <img src='/images/Between_moving_boxes.png' alt='between icon hello'
                    style={{position : "absolute",
                            width : "178px", 
                            right : -20,
                            top : 100}} /> */}
                <h1 className='mt-4'>Flyttemateriell</h1>
                <ProgressBar progress='50' classes='my-3' />
                <h4 className='mb-4'>Steg 1/2</h4>
                {/* <p>
                Velg fra våre ferdig <br/> 
                sammensatte pakker av <br/> flytteesker, teip og <br/> boblepast hjelper.
                Pakken <br/> markert i gul er laget basert på gjennomsnittsforbruk for din boligprofil.
                </p> */}
                {/* <p>Velg fra våre ferdig <br/>
                sammensatte pakker <br /> 
                eller lag en selv. Pakken <br/>
                markert i gul er laget <br/> basert på gjennomsnittsforbruk <br/>
                for din boligprofil.</p> */}
            </div>
            <hr className='fluid' />
            <div className='container-white pt-4'>
                <h3>Våre flyttemateriell pakker:</h3>
                {/* <Accordion open={open} className='py-4' toggle={toggle}> */}
                    {verticalItems}
                {/* </Accordion> */}
                {movingMaterialItems &&
                <>
                <hr className='fluid mt-4' />
                <div className='mt-4 px-4'>
                    <button className='w-100 btn-between' onClick={ handleNextStep }>Gå videre</button>
                </div>
                </>
                }
            </div>
            <Footer/>
        </div>
    );
}