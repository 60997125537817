import React, { useEffect, useState } from 'react';
import { UncontrolledAccordion, AccordionItem, AccordionBody, AccordionHeader, Accordion } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Navigation } from '../../../components/layout/Navigation';
import { Footer } from '../../../components/layout/Footer';
import { ProgressBar } from '../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl, getHeaders, getMovingOrderId, getDisabledTasks } from '../../../helpers/Common';
import { TaskStatus } from '../../../components/layout/TaskStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VerticalItem } from '../../../components/layout/VerticalItem';

export const MovingCompanyHome = () => {
    const [orders, getOrders] = useState({});  
    const [open, setOpen] = useState([]);
    const [disabledTasks, setDisabledTasks] = useState(getDisabledTasks());

    const toggle = (id) => {
        console.log(id);
        open === id ? setOpen() : setOpen(id);
    };

    const untoggle = () => {

    }
        
    const verticalObjects = [{
            position : '1',
            title : 'Sett opp strømabonnement',
            description: 'Her kan du finne strømavtale til din nye bolig',
            image: '../../images/svg/Between_electricity.svg',
            progress: 0,
            button: 'Finn strømavtale',
            path : '/verticals/moving-company/power',
            type : 'power',
            enabled : true,
        }, {
            position: '2',
            title: 'Flytt Forsikring',
            description: 'Når du flytter må du også flytte forsikringene dine. Vi hjelper deg her.', 
            image: '../../images/svg/Between_agreement.svg',
            progress: 0,
            button: 'Flytt forsikring',
            path : '/verticals/moving-company/insurance',
            type : 'insurance',
            enabled : true,
        },
    ];

    const fetchOrders = (type) => axios.get(baseUrl()+'moving_orders/'+getMovingOrderId()+'/orders', getHeaders()
    ).then(response => {
        if(response.data.data){
            
            setOpen([]);
            getOrders(response.data.data);

            verticalObjects.map((obj) => {
                if((!response.data.data[obj.type] && !open.includes(obj.position)) && !disabledTasks.includes(obj.type)){
                    setOpen(open => [...open, obj.position]);  
                }
            })
        }else{
            verticalObjects.map((obj) => {
                if(!disabledTasks.includes(obj.type)){
                    setOpen(open => [...open, obj.position]);  
                }
            })
        }
    })
    .catch(error => console.error(error));

    useEffect(() => {
        fetchOrders();
 
    }, [])

    const verticalItems = verticalObjects.reverse().sort((a, b) => !disabledTasks.includes(a.type) ? -1 : !disabledTasks.includes(b.type) ? 1 : 1).map((obj) =>     
    <>
        <VerticalItem orders={orders} obj={obj} open={open} setOpen={setOpen} disabledTasks={disabledTasks} setDisabledTasks={setDisabledTasks} />
    </>
    );

    return (
        <div className='AppContainer bg-between'>
            <Navigation />
            <div className='pt-4'>
                <img src='/images/Between_moving_boxes.png' alt='between icon hello'
                    style={{position : "absolute",
                            width : "140px",
                            top : '90px', 
                            right : -18}} />
                <p className='p-header'>Flytt eller bytt avtaler</p>
                <p className='mb-4'>Her kan du flytte eller bytte <br/> forsikringsavtale og strømavtale for din nye bolig.</p>
                
            </div>
            <div className='container-white pt-4'>
                    {verticalItems}
            </div>
            <Footer/>
        </div>
    );
}