import React, { useEffect, useState } from 'react';
import {  Button, Col, Row } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';  
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl, getHeaders, getMovingOrderId, formatDate, displayAddress, formatPrice } from '../../../../helpers/Common';
import moment from 'moment';

export const MovingLoadCompanyCompleteOrder = () => {
    const moving_order = getMovingOrderId();
    const navigate = useNavigate();
    const [step1Items, getStep1Items] = useState(JSON.parse(sessionStorage.getItem('moving_service_step_1')));    
    const [step2Items, getStep2Items] = useState(JSON.parse(sessionStorage.getItem('moving_service_step_2')));    
    const [step3Items, getStep3Items] = useState(JSON.parse(sessionStorage.getItem('moving_service_step_3')));    
    var packingHelp = [];

    var totalPrice = parseInt(step2Items.price) + 
    (step3Items.storage ? parseInt(step3Items.storage.storage_price) : 0);
    
    console.log(step3Items)
    useEffect(() => {
        if(step3Items.unpack && step3Items.unpack.length > 0){
                step3Items.unpack.map((pack) => {
                    if(!packingHelp.includes(pack.type)){
                        packingHelp.push(pack.type)
                    }
                })
        }
    }, []);

    //temporarily save total price to session storage
    sessionStorage.setItem('moving_service_total_price', totalPrice);
    //submit the orders to api
    const handleClickSend = () => {
        axios.post(baseUrl()+'moving_orders/'+moving_order+'/relocation',
        { provider_id : step2Items.pid,
            pickup_date : step2Items.pickupDate,
            storage_days : step3Items.storage ? step3Items.storage.number_of_days : 0,
            box_quantity : step1Items.box_quantity,
            small_item_quantity : step1Items.small_item_quantity,
            big_item_quantity : step1Items.big_item_quantity,
            special_item_quantity : step1Items.special_item_quantity,
            packing_help_section : packingHelp }, getHeaders()
        ).then(response => {
            if(response.data.status.code === '200'){
                navigate('/verticals/moving-load/company/confirm', { replace: true });
            }else{
                //already exists, back to home
                // navigate('/', {replace : true});
            }
            console.log(response);
        } 
            
        ).catch(error =>{
            console.error(error)
            // navigate('/', {replace : true});
        }
        );
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Fullfør bestilling</h1>
                <ProgressBar progress="100" classes="my-3"/>
                <h4 className='mb-4'>Steg 4/4</h4>
            </div>
            <hr className='fluid' />
            <div className='container-white pt-4'>
                <h3 className='mb-4'>Bestillingsdetaljer</h3>

                <div>
                <h3 className='mb-0'>Adresse:</h3>
                    <table className='w-100'>
                        <tbody>
                            <tr>
                                <td>Flytter fra:  { displayAddress(sessionStorage.getItem('from_address'), true) }</td>
                            </tr>
                            <tr>
                                <td>Flytter till: { displayAddress(sessionStorage.getItem('to_address'), true) }</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className='mb-0'></p>
                    <p></p>
                </div>

                <div className='mt-4'>
                <h3 className='mb-0'>Dato:</h3>
                    <p>{moment(sessionStorage.getItem('moving_date')).format('DD.MM.YYYY')}</p>
                </div>

                <div className='mt-4'>
                <h3 className='mb-0'>Leveringsinformasjon:</h3>
                    <p>Flyttebyrået vil kontakte deg senest 4 dager før selve flyttedagen og bekrefte noen siste detaljer.</p>
                    <p>Har du noen spørsmål i mellomtiden kan du ta kontakt med våre flyttekonsulenter.</p>
                </div>

                <div className='mt-4'>
                    <h3 className='mb-0'>Pris</h3>
                    <table className='w-100'>
                        <tbody>
                            <tr>
                                <td>{ step2Items.title}</td>
                                <td align='right' className='text-bold'>Kr. {formatPrice(step2Items.price)},-</td>
                            </tr>
                            {step3Items.unpack &&

                            step3Items.unpack.map((pack) => {
                                totalPrice = totalPrice + parseInt(pack.price);
                                return <tr>
                                    <td>{pack.title}</td>
                                    <td align='right' className='text-bold'>Kr. {formatPrice(pack.price)},-</td>
                                </tr>
                            })
                            
                            
                            }
                            {step3Items.storage &&
                            <tr>
                                <td>{step3Items.storage.title}</td>
                                <td align='right' className='text-bold'>Kr. {formatPrice(step3Items.storage.storage_price)},-</td>
                            </tr>
                            }
                            <tr>
                                <td colSpan={2}><hr className='mt-2' /></td>
                            </tr>
                            <tr>
                                <td className='text-bold'>Totalt</td>
                                <td align='right' className='text-bold'>Kr. {formatPrice(totalPrice)},-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='my-4 '>
                    <h3 className='mb-0'>Betalingsmetode:</h3>
                    <p>Ordren settes på samlefaktura som har forfall 14 dager etter du flytter. Fakturaen finner du på min side.</p>
                </div>
                <div className='pt-3 pb-4'>
                    <Row>
                        <Col>
                            <Link to='/verticals/moving-load/company/step-3' className='btn-between-white w-100'>Gå tilbake</Link>
                        </Col>
                        <Col>
                            <Button className='btn-between w-100' onClick={ handleClickSend }>Send bestilling</Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer/>
        </div>
    );
}