import React from 'react';
import { ListGroupItem, ListGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Navigation } from '../../components/layout/Navigation';
import { Footer } from '../../components/layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SettingsHome = () => {
        
    const verticalObjects = [{
            position : '1',
            title : 'Adresser',
            icon: 'map-pin',
            path : '/settings/address',
        },{
            position : '3',
            title : 'Personer i hustanden',
            icon: 'users',
            path : '/settings/users'
        },
    ];

    const verticalObjects2 = [{
            position : '2',
            title : 'Personvern',
            icon: 'shield-alt',
            path : '/settings/privacy'
        },{
            position : '4',
            title : 'Vilkår',
            icon: 'file-circle-check',
            path : '/settings/terms'
        }, 
    ];

    const verticalItems = verticalObjects.map((verticalObject) => 
    <Link to={ verticalObject.path }>
        <div class="gray-box mb-3" key={verticalObject.position}>
            <div class="gray-box-header">
                <table className="w-100">
                    <tr>
                        <td width="30px">
                        <FontAwesomeIcon icon={['far', verticalObject.icon]} />
                        </td>
                        <td>
                            { verticalObject.title }
                            <FontAwesomeIcon icon={['far', 'chevron-right']} style={{color : '#727C8E'}} className='float-end mt-1'  />
                        </td>
                    </tr>
                </table>          
            </div>
        </div>
    </Link>
    );

    const verticalItems2 = verticalObjects2.map((verticalObject2) => 
    <Link to={ verticalObject2.path }>
        <div class="gray-box mb-3" key={verticalObject2.position}>
            <div class="gray-box-header">
                <table className="w-100">
                    <tr>
                        <td width="30px">
                        <FontAwesomeIcon icon={['far', verticalObject2.icon]} />
                        </td>
                        <td>
                            { verticalObject2.title }
                            <FontAwesomeIcon icon={['far', 'chevron-right']} style={{color : '#727C8E'}} className='float-end mt-1'  />
                        </td>
                    </tr>
                </table>          
            </div>
        </div>
    </Link>
    );

    return (
        <div className='AppContainer bg-between-green'>
            <Navigation />
            <div className='py-4'>
                <h1 className='mb-0 text-white'>Innstillinger</h1>
            </div>
            <hr className='fluid' />
            <div className='py-4 mh-sm'>
                    {verticalItems}

                    {verticalItems2}
            </div>
            <Footer/>
        </div>
    );
}