import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../src/fonts/flaticon/flaticon.css';
import { Link } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import { useLocation } from 'react-router-dom';

export const Footer = ({isFixed = false}) => {
    const location = useLocation();

    const menuObjects = [{
        id : 1,
        icon : 'clipboard-list',
        path : '/dashboard',
        title : 'Hjem'
    },  {
        id : 2,
        icon : 'timeline',
        path : '/timeline',
        title : 'Min side'
    },  {
        id : 3,
        icon : 'user',
        path : '/profile',
        title : 'Kontakt oss'
    }, ];

    const menu = menuObjects.map((menuObj, i) => 
        <span className='footer-menu-item d-flex align-items-center justify-content-around' key={i}>
            <Link to={menuObj.path} className={location.pathname === menuObj.path ? 'active' : ''}>
                {/* <FontAwesomeIcon icon={['far', menuObj.icon]}/> */}
            <img src={`/images/svg${menuObj.path}-icon-${location.pathname === menuObj.path ? 'active' : 'inactive'}.svg`} alt={menuObj.icon} height={25} />
            </Link>
            
        </span>
    );
    
    return (

        // <div className={`between-footer-menu w-100 ${isMobile ? 'position-fixed' : 'position-absolute'}`}>
        //     <div className='d-flex align-items-center justify-content-around py-2'>
        //     { menu }
        //     </div>
        // </div>
        <div className={`between-footer-menu`}>
            <div className='d-flex align-items-center justify-content-around'>
                { menu }
            </div>
        </div>
    )
}