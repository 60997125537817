import React from "react";
import { computeProgress } from "../../helpers/Common";
import { ProgressBar } from "./ProgressBar";

export const VerticalHeader = ( props ) => {

    return (
        <div className='pt-4'>
            <h1 className={!props.progress ? 'mb-4' : ''}>{props.title}</h1>
            {props.progress &&
            <>
            <ProgressBar progress={computeProgress(props.progress, props.totalProgress)} />
            <h4 className='my-3'>Steg {props.progress}/{props.totalProgress}</h4>
            </>
            }
            <hr className="fluid" />
        </div>
    )
}