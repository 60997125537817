import React from 'react';
import { DefaultHeaderLogo } from '../../components/elements/DefaultHeaderLogo';
import { BetweenLogo } from '../../components/elements/BetweenLogo';
import {FormGroup, Button} from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseUrl, getHeaders } from '../../helpers/Common';
import { Alert } from '../../components/utils/Alert';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
    email: yup.string().email("Ugyldig epost").required("Vennligst fyll ut feltet"),
    password: yup.string().min(8, "Passordet skal inneholde minst 8 tegn").max(32, "Passord må være på maksimalt 32 tegn").required("Vennligst fyll ut feltet"),
});

export const Login = (props) => {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      });
    
      const onSubmitHandler = (data) => {
        axios.post(baseUrl()+'login', data,
        {
            headers : {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'text/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(response => {
            if(response.data.status.code === "200"){
                const newToken = response.data.data.access_token;
                const moId = response.data.data.moving_order_id;

                console.log('login', response.data)
    
                //set user token access
                sessionStorage.setItem("token", newToken);
                sessionStorage.setItem("moving_order", moId);

                //get user profile details
                axios.get(baseUrl()+'users/me', getHeaders()).then(response => {
                    //set user data to session
                    console.log('profile', response.data);
                    sessionStorage.setItem("user", JSON.stringify(response.data.data));

                    //get moving orde details
                    axios.get(baseUrl()+'moving_orders/'+moId, getHeaders()).then(response => {
                        //set user data to session
                    console.log('address', response.data.data);
                        
                    sessionStorage.setItem("from_address", response.data.data ? JSON.stringify(response.data.data.old_address) : null);
                    sessionStorage.setItem("to_address", response.data.data ? JSON.stringify(response.data.data.new_address) : null);
                    sessionStorage.setItem("moving_date", response.data.data ? JSON.stringify(response.data.data.date) : null);
                    //login success - alert message then redirect to profile pages
                    navigate("/dashboard", { replace: true });
                         
                    }).catch(error => {
                        console.error(error);
                    });
                    
                }).catch(error => {
                    console.error(error);
                });
            }else{
                console.log('hey', response.data.status.message)
                Alert('error', 'Error '+response.data.status.code, 'Ugyldige påloggingsdetaljer', '<a href="/register">Vær så snill, prøv på nytt</a>', null)
            }
        }).catch(error => {
            console.error(error);
            Alert('error', 'Error '+error.code, 'Ugyldige påloggingsdetaljer', '<a href="/register">Vær så snill, prøv på nytt</a>', null)
        });
      };

    return (
        <div className='AuthContainer pb-4 bg-between-green'>
            <div className='pb-4'>
                <DefaultHeaderLogo  />
                <div className='mt-2'>
                    <BetweenLogo />
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <FormGroup>
                    <input {...register("email")} placeholder="Brukernavn" type="email" className="form-control" />
                    <p className="form-error">{errors.email?.message}</p>
                </FormGroup>
                <FormGroup>
                    <input {...register("password")} placeholder="Passord" type="password" className="form-control" />
                    <p className="form-error">{errors.password?.message}</p>
                </FormGroup>
                <FormGroup className='mt-4 text-center'>
                    <Button type='submit' className='btn-between' style={{width : '250px'}}>Logg inn</Button>
                </FormGroup>
            </form>
            <div className='text-center'>
                <Link to="/register" className='dark-link text-underline'>Registrer deg</Link>
            </div>
        </div>
    )
}