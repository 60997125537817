import React, {useState} from 'react';
import axios from 'axios';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { Navigation } from '../../components/layout/Navigation';
import { capsFirst } from '../../helpers/Common';
import { AuthFooter } from '../../components/elements/AuthFooter';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import { baseUrl, getMovingOrderId, getHeaders } from '../../helpers/Common';

const schema = yup.object().shape({
    moving_from_address: yup.string().required("Vennligst fyll ut feltet"),
    moving_to_address: yup.string().required("Vennligst fyll ut feltet"),
    moving_date: yup.string().required("Vennligst fyll ut feltet").test("birthdate", "Vennligst velg en gyldig fødselsdato", (value) => {
        var today = new Date();
        var selectedDate = new Date(value);
        // console.log(selectedDate > today);
        return selectedDate > today;
      }),
});

export const RegistrationNewAddress = () => {
    const [timer, setTimer] = useState(null);
    const [far, setFar] = useState([]);
    var far2 = [];
    const navigate = useNavigate();
    const invitationData = JSON.parse(sessionStorage.getItem('invitationData'));

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      });

    const handleInputChange = (e) => {
        const value = e.target.value;
        const type  = e.target.dataset.type;
        const optionCont = document.getElementById(type+'-address-container');
        const optionList = document.getElementById(type+'-address-options');

        //hide and empty the options
        optionCont.classList.add('d-none');
        optionList.innerHTML = "<li>Laster...</li>";

        //search for address
        if(timer){
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() =>{
                if(value.length > 1){
                    optionCont.classList.remove('d-none');
                    // load options using API call
                    axios.post("https://addresses-microservices.betwn.no/rest-api/addresses/v1/address/search", 
                    { "address-search" : value } ).then(result => {
                        //remove loading message for the results
                        optionList.innerHTML = "";
                        if(result.data.status.code === '110'){
                            createAddressNode(type, e.target, optionList, null);
                        }else{
                            // return result.data.data.addresses;
                            const addresses = result.data.data.addresses;
                            for (var key in addresses) {
                                const address = addresses[key];
                                createAddressNode(type, e.target, optionList, address);
                            }
                            
                        }
                    })
    
                }
            }, 500)
        )
    }

    const handleAddressClick = (type, input, address) => {
        var inputField = document.getElementById(input.id);
        inputField.value = getAddressText(address);

        //check if there's floor and rooms;
        const addressId = address.id;
        axios.get("https://addresses-microservices.betwn.no/rest-api/addresses/v1/address/"+addressId+"/details").then(result => {
            const details = result.data.data.address_details.details.sections;
            var floorsAndRooms = [];
            var rooms  = [];
            var previousKey = null;
            const floorsAndRoomsCont = document.getElementById(type+"-floor-container");

            for (var key in details) {
                if(key.substring(0, 1) !== "U"  && key.substring(0, 1) !== "Z"){
                    const roomNumber = details[key].id;
                    var floorNumber = key.substring(2, 3);
                    const currentKey = floorNumber;

                    if(previousKey !== currentKey){
                        rooms = [roomNumber];
                        floorsAndRooms[floorNumber] = rooms;
                    }else{
                        rooms.push(roomNumber);
                    }
                    previousKey = currentKey;
                }
            };

            //add floor and rooms to state;
            // setFar(floorsAndRooms);
            far2 = floorsAndRooms;
            setFar(floorsAndRooms);
            
            //delay to wait for the state;
            setTimeout(function(){
                if(floorsAndRooms.length > 0){
                    floorsAndRoomsCont.classList.remove('d-none');
                    document.getElementById(type+'-floors').innerHTML = "";
                    document.getElementById(type+'-rooms').innerHTML = "";
                    
                    createFloorsNode(type, floorsAndRooms);

                    // const hideType = type === 'from' ? 'to' : 'from';
                    // clearRoomsAndFloors(hideType);
                }else{
                    floorsAndRoomsCont.classList.add('d-none');
                }
            }, 200);

        })


        //clear inputs
        clearInputs();
    }

    const createFloorsNode = (type, options) => {
        
        for (var key in options) {
            
            const floorSelect = document.getElementById(type+'-floors');

            var option = document.createElement('option');
            option.innerHTML = key;
            option.value = key;
            
            floorSelect.appendChild(option);
        }

        createRoomsNode(type, 1);
    }

    const createRoomsNode = (type, floor) => {
        
        //get rooms select
        const roomSelect = document.getElementById(type+'-rooms');
        
        console.log('create rooms: ', far2[floor]);
        
        far2[floor].forEach(element => {
            var option = document.createElement('option');
            option.innerHTML = element;
            
            roomSelect.appendChild(option);
        });
        
    }

    const createAddressNode = (type, input, ul, address) => {
        var addressText = address ? getAddressText(address) : 'Adressen ble ikke funnet';
        var li = document.createElement("li");
        var text = document.createTextNode(addressText);

        if(address){
            li.onclick = () => handleAddressClick(type, input, address);
        }

        li.appendChild(text);
        ul.appendChild(li);
    }

    const clearInputs = () => {
        const inputFromContainer = document.getElementById('from-address-container');
        const inputFromOptions   = document.getElementById('from-address-options');
        const inputToContainer   = document.getElementById('to-address-container');
        const inputToOptions     = document.getElementById('to-address-options');

        inputFromContainer.classList.add('d-none');
        inputToContainer.classList.add('d-none');

        inputFromOptions.innerHTML = "";
        inputToOptions.innerHTML = "";

    }

    const getAddressText = (address) => {
        return capsFirst(address.street) + ', ' + address.zipcode + ' '+capsFirst(address.place)
    }

    const handleChangeFloor = (e, type,  far) => {
        console.log( far[e.value] );
        const floorsSelect = document.getElementById(type+'-rooms');
    
        //clear the floors
        floorsSelect.innerHTML = "";
    
        far[e.value].forEach(element => {
            var option = document.createElement('option');
            option.innerHTML = element;
            option.value = element;

            floorsSelect.appendChild(option);
        });
    }
    const onSubmitHandler = (data) => {

        const formData = {'old_address' : parseInt(invitationData.moving_from_address_id), 
                          'new_address' : parseInt(invitationData.moving_to_address_id), 
                          'date' : invitationData.moving_date};

                          console.log(formData);
        //send the address to create moving address
        axios.put(baseUrl()+'moving_orders/'+getMovingOrderId(), formData, getHeaders()
        ).then(response => {
            console.log(response);
            if(response.data.status.code === "200" && response.data.status.message === "ok"){

                //save address on on local
                sessionStorage.setItem("from_address", data.moving_from_address);
                sessionStorage.setItem("from_address_id", parseInt(invitationData.moving_from_address_id));
                sessionStorage.setItem("to_address", data.moving_to_address);
                sessionStorage.setItem("to_address_id", parseInt(invitationData.moving_to_address_id));
                sessionStorage.setItem("moving_date", data.movingDate);

                navigate("/registration/users", { replace: true });

            }
        }).catch(error => {
            console.error(error);
        });
    }

    return ( 
    <div id='CreateUserContainer' className='AppContainer bg-between-green'>
    <Navigation isReg={true} />
    <div className='mt-4'>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <p className='p-header text-white'>Adresse</p>
            <FormGroup className='mt-4'>
                <Label className='fw-bold text-white'>Where are you moving from?</Label>
                <input  
                {...register("moving_from_address")}
                type='text' 
                onChange={(e) => handleInputChange(e) } 
                data-type='from' 
                id='from-address-input'
                className='form-control'
                defaultValue={invitationData.moving_from_address} />
                <p className="form-error">{errors.moving_from_address?.message}</p>

                <div className='position-relative'>
                    <div className='address-options d-none position-absolute' id='from-address-container'>
                        <ul id='from-address-options'></ul>
                    </div>
                </div>
                <div id="from-floor-container" className='mt-4 d-none'>
                    <div className='row'>
                        <div className='col'>
                            <select id='from-floors' className='form-select' onChange={ (e) => handleChangeFloor(e.target, 'from', far) }></select>
                        </div>
                        <div className='col'>
                            <select id='from-rooms' className='form-select'></select>
                        </div>
                    </div>
                </div>
            </FormGroup>
            <FormGroup className='mt-4'>
                <Label className='fw-bold text-white'>Where are you moving to?</Label>
                <input 
                {...register("moving_to_address")}
                type='text' 
                className='form-control'
                onChange={(e) => handleInputChange(e) } 
                data-type='to' 
                id='to-address-input'
                defaultValue={invitationData.moving_to_address} />
                <p className="form-error">{errors.moving_to_address?.message}</p>
                <div className='position-relative'>
                    <div className='address-options d-none position-absolute' id='to-address-container'>
                        <ul id='to-address-options'></ul>
                    </div>
                </div>
                <div id="to-floor-container" className='mt-4 d-none'>
                    <div className='row'>
                        <div className='col'>
                            <select id='to-floors' className='form-select' onChange={ (e) => handleChangeFloor(e.target, 'to', far) }></select>
                        </div>
                        <div className='col'>
                            <select id='to-rooms' className='form-select'></select>
                        </div>
                    </div>
                </div>
            </FormGroup>
            <FormGroup className='mt-4'>
                <Label className='fw-bold text-white'>Når flytter du inn?</Label>
                {/* {!dateFocus && movingDate === null && isMobile &&
                <span className="date-placeholder" style={{color : 'color: hsl(0, 0%, 20%)!important', marginTop : '54px'}}>Flyttedato</span>
                } */}
                <input type="date" className="form-control" {...register("moving_date")} defaultValue={invitationData.moving_date}  />
                <p className="form-error">{errors.moving_date?.message}</p>
            </FormGroup>
            <FormGroup className='mt-4 text-center'>
                <Button type='submit' className='btn-between' style={{width : '250px'}}>Neste</Button>
            </FormGroup>
        </form>
    </div>
    
    <AuthFooter />
    </div>
    );
}