import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { baseUrl, getHeaders, getMovingOrderId } from '../../helpers/Common';
import axios from 'axios';

export const ValidateRoute = ({type}) => {
  const [orders, getOrders] = useState({});  
  const fetchOrders = () => axios.get(baseUrl()+'moving_orders/'+getMovingOrderId()+'/orders', getHeaders()
  ).then(response => {
        getOrders(response.data.data);
      }
    )
  .catch(error => console.error(error));

  useEffect(() => {
      fetchOrders();
  }, [])
  
  if(orders && orders[type]){
    console.log('already ordered');
    return <Navigate to="/" replace />;
  }

  return (
    <div>
        <Outlet/>
    </div>
  ) 
};