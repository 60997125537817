import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { Alert, Button, Col, Form, FormGroup,  Label,  Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import * as yup from "yup";
import { capsFirst, computeProgress, } from '../../../helpers/Common';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { VerticalHeader } from "../../../components/layout/VerticalHeader";

const schema = yup.object().shape({
    seller_address: yup.string().required("Vennligst fyll ut feltet"),
});

export const ProtocolStep3 = () => {

    const user = JSON.parse(sessionStorage.getItem('user'));
    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    const sellerAddress = sessionStorage.getItem('seller_address_object') ? JSON.parse(sessionStorage.getItem('seller_address_object')) : null;
    var sellerAddressFloor = sessionStorage.getItem('seller_address_floor') ? sessionStorage.getItem('seller_address_floor') : null;
    var sellerAddressRoom = sessionStorage.getItem('seller_address_room') ? sessionStorage.getItem('seller_address_room') : null;
    const [timer, setTimer] = useState(null);
    const [far, setFar] = useState([]);
    var far2 = [];
    const navigate = useNavigate();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      });

    const handleInputChange = (e) => {
        const value = e.target.value;
        const type  = e.target.dataset.type;
        const optionCont = document.getElementById(type+'-address-container');
        const optionList = document.getElementById(type+'-address-options');

        //hide and empty the options
        optionCont.classList.add('d-none');
        optionList.innerHTML = "<li>Laster...</li>";

        //search for address
        if(timer){
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() =>{
                if(value.length > 1){
                    optionCont.classList.remove('d-none');
                    // load options using API call
                    axios.post("https://addresses-microservices.betwn.no/rest-api/addresses/v1/address/search", 
                    { "address-search" : value } ).then(result => {
                        //remove loading message for the results
                        optionList.innerHTML = "";
                        if(result.data.status.code === '110'){
                            createAddressNode(type, e.target, optionList, null);
                        }else{
                            // return result.data.data.addresses;
                            const addresses = result.data.data.addresses;
                            for (var key in addresses) {
                                const address = addresses[key];
                                createAddressNode(type, e.target, optionList, address);
                            }
                            
                        }
                    })
    
                }
            }, 500)
        )
    }

    const handleAddressClick = (type, input, address, isNew = false) => {

        console.log(type, input, address);
        var inputField = document.getElementById(input.id);
        inputField.value = getAddressText(address);

        setValue('seller_address', getAddressText(address));
        setValue('seller_address_floor', null);
        setValue('seller_address_room', null);

        if(isNew){
            sellerAddressFloor = null;
            sellerAddressRoom = null;
        }
        //save to session the selected address object
        sessionStorage.setItem('seller_address_object', JSON.stringify(address));

        //check if there's floor and rooms;
        const addressId = address.id;
        axios.get("https://addresses-microservices.betwn.no/rest-api/addresses/v1/address/"+addressId+"/details").then(result => {
            const details = result.data.data.address_details.details.sections;
            var floorsAndRooms = [];
            var rooms  = [];
            var previousKey = null;
            const floorsAndRoomsCont = document.getElementById(type+"-floor-container");

            for (var key in details) {
                if(key.substring(0, 1) !== "U"  && key.substring(0, 1) !== "Z"){
                    const roomNumber = details[key].id;
                    var floorNumber = key.substring(2, 3);
                    const currentKey = floorNumber;

                    if(previousKey !== currentKey){
                        rooms = [roomNumber];
                        floorsAndRooms[floorNumber] = rooms;
                    }else{
                        rooms.push(roomNumber);
                    }
                    previousKey = currentKey;
                }
            };

            //add floor and rooms to state;
            // setFar(floorsAndRooms);
            far2 = floorsAndRooms;
            setFar(floorsAndRooms);
            
            //delay to wait for the state;
            setTimeout(function(){
                if(floorsAndRooms.length > 0){
                    floorsAndRoomsCont.classList.remove('d-none');
                    document.getElementById(type+'-floors').innerHTML = "";
                    document.getElementById(type+'-rooms').innerHTML = "";
                    
                    createFloorsNode(type, floorsAndRooms);

                    // const hideType = type === 'from' ? 'to' : 'from';
                    // clearRoomsAndFloors(hideType);
                }else{
                    floorsAndRoomsCont.classList.add('d-none');
                }
            }, 200);

        })


        //clear inputs
        clearInputs();
    }

    const createFloorsNode = (type, options) => {

        setValue('seller_address_floor', '1');
        
        for (var key in options) {
            
            const floorSelect = document.getElementById(type+'-floors');

            var option = document.createElement('option');
            option.innerHTML = key;
            option.value = key;
            
            floorSelect.appendChild(option);
        }

        if(sellerAddressFloor !== null){
            setValue('seller_address_floor', sellerAddressFloor);
            createRoomsNode(type, parseInt(sellerAddressFloor));
        }else{
            createRoomsNode(type, 1);
        }

    }

    const createRoomsNode = (type, floor) => {
        
        setValue('seller_address_room', 'H0101');
        //get rooms select
        const roomSelect = document.getElementById(type+'-rooms');
        
        far2[floor].forEach(element => {
            var option = document.createElement('option');
            option.innerHTML = element;
            
            roomSelect.appendChild(option);
        });

        if(sellerAddressRoom){
            setValue('seller_address_room', sellerAddressRoom);
        }
    }

    const createAddressNode = (type, input, ul, address) => {
        var addressText = address ? getAddressText(address) : 'Adressen ble ikke funnet';
        var li = document.createElement("li");
        var text = document.createTextNode(addressText);

        if(address){
            li.onclick = () => handleAddressClick(type, input, address, true);
        }

        li.appendChild(text);
        ul.appendChild(li);
    }

    const clearInputs = () => {
        const inputFromContainer = document.getElementById('from-address-container');
        const inputFromOptions   = document.getElementById('from-address-options');
        inputFromContainer.classList.add('d-none');
        inputFromOptions.innerHTML = "";
    }

    const getAddressText = (address) => {
        return capsFirst(address.street) + ', ' + address.zipcode + ' '+capsFirst(address.place)
    }

    const handleChangeFloor = (e, type,  far) => {
        console.log( far[e.value] );
        setValue('seller_address_room', far[e.value][0]);

        const floorsSelect = document.getElementById(type+'-rooms');
    
        //clear the floors
        floorsSelect.innerHTML = "";
    
        far[e.value].forEach(element => {
            var option = document.createElement('option');
            option.innerHTML = element;
            option.value = element;

            floorsSelect.appendChild(option);
        });
    }
    const onSubmitHandler = (data) => {
        sessionStorage.setItem('seller_address_room', data.seller_address_room);
        sessionStorage.setItem('seller_address_floor', data.seller_address_floor);

        if(!protocolProgress.includes('step3')){
            protocolProgress.push('step3');
            sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
        }

        navigate('/protocol/step-4');
    }

    useEffect(() => {
        console.log(sellerAddress);   
        if(sellerAddress){
            const input = document.getElementById('from-address-input');
            handleAddressClick('from', input, sellerAddress);
            console.log(sellerAddressFloor, sellerAddressRoom);
        }  
    }, [])

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Ny adresse selger' progress={3} totalProgress={14} />

            <div className="pt-4">
                <h4>Fyll inn eller korriger selgers nye adresse.</h4>
                <Row className="mt-4">
                    <Col>
                        <p className="text-bold mb-0"> { user.name } </p>
                        <form onSubmit={handleSubmit(onSubmitHandler)}>
                            <FormGroup>
                                <Label for='seller_address' className="mb-0 text-normal">Adresse</Label>
                                <input  
                                {...register("seller_address")}
                                type='text' 
                                onChange={(e) => handleInputChange(e) } 
                                data-type='from' 
                                id='from-address-input'
                                className={`input-square form-control ${errors.seller_address && 'is-invalid'}`} />
                                <p className="form-error mb-0">{errors.seller_address?.message}</p>

                                <div className='position-relative'>
                                    <div className='address-options d-none position-absolute bg-light' id='from-address-container'>
                                        <ul id='from-address-options'></ul>
                                    </div>
                                </div>
                                <div id="from-floor-container" className='mt-2 d-none'>
                                    <div className='row'>
                                        <div className='col'>
                                            <Label for='seller_address_floor' className="mb-0 text-normal">Etasje</Label>
                                            <select 
                                            {...register("seller_address_floor")} 
                                            id='from-floors' 
                                            className='form-select input-square' 
                                            onChange={ (e) => handleChangeFloor(e.target, 'from', far) }>
                                                
                                            </select>
                                        </div>
                                        <div className='col'>
                                        <Label for='seller_address_room' className="mb-0 text-normal">Leilighetsnummer</Label>
                                            <select 
                                            {...register("seller_address_room")} 
                                            id='from-rooms' 
                                            className='form-select input-square'></select>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup className="px-5">
                                <Button type="submit" className="btn btn-between w-100">Neste</Button>
                            </FormGroup>
                        </form>
                    </Col>
                </Row>

                <Row className="text-center my-4 mx-5">
                    <Col>
                        <Link to={'/protocol/step-2'} className="btn-between-white" style={{maxWidth : '150px'}}>Tilbake</Link>
                    </Col>
                </Row>
                </div>
            <Footer/>

        </div>
    );
}