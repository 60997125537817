import React, { useState } from 'react';
import {  Button, Col, Row } from 'reactstrap';
import { Link,  useNavigate } from 'react-router-dom';
import { Navigation } from '../../../../components/layout/Navigation';
import { Footer } from '../../../../components/layout/Footer';  
import { ProgressBar } from '../../../../components/layout/ProgressBar';
import axios from 'axios';
import { baseUrl, getHeaders, displayAddress, upFirst } from '../../../../helpers/Common';

export const AddressAndPostRelocationCompleteOrder = () => {
    const navigate = useNavigate();
    const moving_order = sessionStorage.getItem('moving_order');
    const [companiesSelected, setCompaniesSelected] = useState(sessionStorage.getItem("companies_selected") ? JSON.parse(sessionStorage.getItem("companies_selected")) : []); 

    const handleClickSend = () => {
        axios.post(baseUrl()+'moving_orders/'+moving_order+'/address_change',
        {organizations: companiesSelected }, getHeaders()
        ).then(response => {
            if(response.data.status.code === '200'){

                //save companies to session
                navigate('/verticals/address-and-post/relocation/confirm', { replace: true });
            }else{
                //already exists, back to home
                // navigate('/', {replace : true});
            }
            console.log(response);
        } 
            
        ).catch(error =>{
            console.error(error)
            // navigate('/', {replace : true});
        }
        );
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />
            <div className='pt-4'>
                <h1>Fullfør bestilling</h1>
                <ProgressBar progress="100" classes="my-3"/>
                <h4 className='mb-3'>Steg 2/2</h4>
            </div>
            <hr className='fluid' />
            <div className='container-white pt-4'>
                <h3>Bestillingsdetaljer</h3>

                <div className='mt-4'>
                    <h3 className='mb-0'>Flytter fra:</h3>
                    <p>{ displayAddress(sessionStorage.getItem('from_address')) }</p>
                </div>

                <div className='mt-4'>
                    <h3 className='mb-0'>Flytter til:</h3>
                    <p>{ displayAddress(sessionStorage.getItem('to_address')) }</p>
                </div>

                <div className='mt-4'>
                    <h3 className='mb-0'>Leveringsinformasjon:</h3>
                    <p>Vi sender ut flyttemeldingen til alle leverandørene du har valgt. Det kan hende at noen av leverandørene dine kontakter deg for å bekrefte flyttemeldingen.</p>
                </div>

                <div className='mt-4'>
                    <h3 className='mb-0'>Send flyttemelding til:</h3>
                    { Object.keys(companiesSelected).map((key, index) => {
                        return  <Row key={key} >
                                <Col xs={8}>
                                <span>{ upFirst(companiesSelected[key].organization_name) }</span>
                                </Col>
                                <Col xs={4}>
                                    <button className='btn-close-white float-end mt-2' data-id={companiesSelected[key].organization_number}></button>
                                </Col>
                            </Row>
                        }) }
                </div>

                <div className='my-4'>
                    <Row>
                        <Col>
                            <Link to='/verticals/address-and-post/relocation' className='btn-between-white w-100'>Gå tilbake</Link>
                        </Col>
                        <Col>
                            <Button data-link='/verticals/address-and-post/relocation/confirm' className='btn-between w-100' onClick={ handleClickSend }>Send</Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer/>
        </div>
    );
}