import { Navigation } from "../../../components/layout/Navigation";
import { Footer } from "../../../components/layout/Footer";
import { ProgressBar } from "../../../components/layout/ProgressBar";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLottie } from "../../../components/layout/ButtonLottie";
import { useEffect } from "react";
import { computeProgress } from "../../../helpers/Common";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { VerticalHeader } from "../../../components/layout/VerticalHeader";

const schema = yup.object().shape({
    comment: yup.string()
});
export const ProtocolStep9 = () => {

    const protocolProgress = sessionStorage.getItem('protocol_progress') ? JSON.parse(sessionStorage.getItem('protocol_progress')) : [];
    var proStep9  = sessionStorage.getItem('protocol_step_9') ? JSON.parse(sessionStorage.getItem('protocol_step_9')) : {};
    const navigate = useNavigate();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues : {
            comment : proStep9.comment ? proStep9.comment : '',
            røykvarsler : proStep9.røykvarsler ? true : false,
            brannslukningsapparat : proStep9.brannslukningsapparat ? true : false,
            brannslange : proStep9.brannslange ? true : false,
            boligalarm : proStep9.boligalarm ? true : false,
        }
    });
    
    const handleFormSubmit = (data) => {

        proStep9 = data;
        sessionStorage.setItem('protocol_step_9', JSON.stringify(proStep9));
        if(!protocolProgress.includes('step9')){
            protocolProgress.push('step9');
            sessionStorage.setItem('protocol_progress', JSON.stringify(protocolProgress));
        }

        navigate('/protocol/step-10');
    }

    return (
        <div className='AppContainer bg-white'>
            <Navigation />

            <VerticalHeader title='Brannsikkerhet' progress={9} totalProgress={14} />
            
            <div className="pt-4">

                <h3 className="text-bold">Følgende utstyr finnes i eiendommen: </h3>

                <form onSubmit={handleSubmit( handleFormSubmit )} className='mt-4'>
                    <FormGroup check className="ps-0">
                        <input type="checkbox" {...register("røykvarsler")} id="røykvarsler" />
                        <Label check className="mb-0 text-normal ms-2" for='røykvarsler'>Røykvarsler</Label>
                    </FormGroup>
                    <FormGroup check className="ps-0">
                        <input type="checkbox" {...register("brannslukningsapparat")} id="brannslukningsapparat" />
                        <Label check className="mb-0 text-normal ms-2" for='brannslukningsapparat'>Brannslukningsapparat</Label>
                    </FormGroup>
                    <FormGroup check className="ps-0">
                        <input type="checkbox" {...register("brannslange")} id="brannslange" />
                        <Label check className="mb-0 text-normal ms-2" for='brannslange'>Brannslange</Label>
                    </FormGroup>
                    <FormGroup check className="ps-0">
                        <input type="checkbox" {...register("boligalarm")} id="boligalarm" />
                        <Label check className="mb-0 text-normal ms-2" for='boligalarm'>Tilknyttet boligalarm</Label>
                    </FormGroup>
                    <FormGroup className="mt-4">
                        <Label for='comment' className="mb-0 text-normal">Kommentar</Label>
                        <textarea {...register("comment")} className={`form-control input-square ${errors.comment && 'is-invalid'}`} />
                        <p className="form-error">{errors.comment?.message}</p>
                    </FormGroup>
                    <FormGroup className="px-5">
                        <Button type="submit" className="btn btn-between w-100">Neste</Button>
                    </FormGroup>
                </form>
                <Row className="text-center mb-4 mt-2 mx-5">
                    <Col>
                        <Link to={'/protocol/step-8'} className="btn-between-white" style={{maxWidth : '150px'}}>Tilbake</Link>
                    </Col>
                </Row>
                </div>
            <Footer/>
        </div>
    );
}